import React from "react";
import dontPg from '../../assets/img/noData.png'
import complete from '../../assets/img/complete.png'
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

function UserMailSendComplPg() {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const moveToMainPg = (() => {
        navigate("/")
    })

    return(
        <div className="user-center" style={{height:"80vh"}}>
            <div className="user-center" style={{minWidth:"500px", minHeight:"500px"}}>
                <div style={{textAlign:"center"}}>
                    <img style={{width:"70px", height:"70px", opacity:"0.7"}} src={complete} alt="dontPage"/>
                    <p style={{margin:"0", fontSize:"18px", marginTop:"20px", color:"black"}}>{t('umscCont1')} !</p>
                    <span style={{fontSize:"14px", marginTop:"10px", color:"dimgrey"}}>{t('umscCont2')}</span>
                    <div className="user-center">
                    <button className="colorFullBt user-center" style={{
                        width: "150px",
                        height: "50px",
                        textAlign: "center",
                        fontSize: "19px",
                        marginTop:"20px"
                    }} onClick={moveToMainPg}>
                        <p style={{margin:"0", fontSize:"16px", color:"white"}}>{t('umscBt')}</p>
                    </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserMailSendComplPg
