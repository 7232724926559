import "../../css/mobile/MSectionMain.css";
import React, {useState, useEffect, useRef} from 'react';
import Search from '../../assets/img/commonSearch.svg';

import {useNavigate} from "react-router-dom";

import MainReview from '../../assets/img/mainReviewHeather.png';
import MainNotice from '../../assets/img/mainNotice.png';
import MainFaq from '../../assets/img/mainFaq.png';
import CenterLogo from '../../assets/img/CenterIcon.png';
import LookUp from '../../assets/img/LookupImage.png';
import PayMethod from '../../assets/img/mainPayMethod.png';
import DeliveryType from '../../assets/img/mainDeliveryType.png';
import ServiceCenter from '../../assets/img/mainServiceCenter.png';
import BtorageLogo from '../../assets/img/BtorageLogo.png';
import MFooterSection from "../../components/mobile/MFooterSection";
import {useTranslation} from "react-i18next";
import axios from "axios";
import DOMPurify from "dompurify";
import MainService from '../../assets/img/mainService.png';
import MainCart from '../../assets/img/detailCart.png';
import MainOrder from '../../assets/img/detailOrder.png';
import MainDelivery from '../../assets/img/detailDelivery.png';
import MainGuide from '../../assets/img/detailGuide.png';
import MainCustomInfo from '../../assets/img/detailCustomInfo.png';
import MainShipCompare from '../../assets/img/detailShipCompare.png';
import MainChildOrder from '../../assets/img/mainChildOrder.png';
import MainChildOffline from '../../assets/img/mainChildOffline.png';
import MainChildTransfer from '../../assets/img/mainChildTransfer.png';
import MainChildDomestic from '../../assets/img/mainChildDomestic.png';
import DepSaving from '../../assets/img/detailDepSaving.png';
import noReviewPhoto from "../../assets/img/btorage_noimage.png";
import noData from "../../assets/img/noData.png";
import btoragePay from "../../assets/img/BtorageLogo.png";
import creditCard from "../../assets/img/payFormHeader.png";
import PaypalLogo from "../../assets/img/PaypalLogo.png";
import WireBarleyLogo from "../../assets/img/WireBarleyLogo.png";
import SentbeLogo from "../../assets/img/SentbeLogo.png";
import StripeLogo from "../../assets/img/StripeLogo.png";
import TransferWiseLogo from "../../assets/img/TransferWiseLogo.png";
import AlipayLogo from "../../assets/img/AlipayLogo.png";
import WechatPayLogo from "../../assets/img/WechatPayLogo.png";
import Etc from '../../assets/img/commonEtc.png';
import FedexLogo from "../../assets/img/FedexLogo.png";
import EmsLogo from "../../assets/img/EmsLogo.png";
import UpsLogo from "../../assets/img/UpsLogo.png";
import Kpacket from "../../assets/img/Kpacket.png";
import SmallPacket from "../../assets/img/commonSmallPacket.png";
import SeaShipLogo from "../../assets/img/SeaShipLogo.png";
import {usePopup} from "../../components/contextApi/FooterOpenChatContext";
import MSideQuickMenu from "../../components/mobile/MSideQuickMenu";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import i18n from "../../i18n";
import MUserMainPopModal from "./MUserMainPopModal";
import {BiCaretDown} from "react-icons/bi";
import MCommonTermsPopup from "../../components/mobile/MCommonTermsPopup";

const MSectionMain = () => {
    const {t} = useTranslation();

    const [serviceMenuSt, setServiceMenuSt] = useState(false);
    const [orderMenuSt, setOrderMenuSt] = useState(false);

    const serviceMenuOpen = (() => {
        setServiceMenuSt(!serviceMenuSt);
        setOrderMenuSt(false);
    })
    const orderMenuOpen = (() => {
        setOrderMenuSt(!orderMenuSt);
        setServiceMenuSt(false);
    })

    const [termsOfUsePopup, setTermsOfUsePopup] = useState(false);
    const closeTermsOfUsePopup = (() => {
        setTermsOfUsePopup(false);
    })
    const [privacyPolicyPopup, setPrivacyPolicyPopup] = useState(false);
    const closePrivacyPolicyPopup = (() => {
        setPrivacyPolicyPopup(false);
    })
    const {setIsPopupOpen} = usePopup();

    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');
    const navigate = useNavigate();
    const [reviewAllList, setReviewAllList] = useState([]);

    const selectReviewAllList = () =>
        axios({
            method: 'get',
            url: '/review/selectReviewAllList',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => b.seqNum - a.seqNum);
                setReviewAllList(sortedData)
            })

    useEffect(() => {
        selectReviewAllList();
        bringToShipCo();
    }, [])

    const formatDate = (dateStr) => {
        if (dateStr.length !== 8) return "";
        return `${dateStr.substring(2, 4)}-${dateStr.substring(4, 6)}-${dateStr.substring(6, 8)}`;
    }

    const reviewPgMove = () => () => {
        navigate("/UserReviewPg");
    };

    const useSanitizedHTML = ((rawHTML) => {
        if (!rawHTML) return "";
        return DOMPurify.sanitize(rawHTML.replace(/\n/g, '<br>'));
    })

    const [shipcoList, setShipcoList] = useState([]);
    const [domesticList, setDomesticList] = useState([]);
    const [selectedShipco, setSelectedShipco] = useState('');

    const bringToShipCo = () => axios({
        method: 'get',
        url: '/shipcompany/shipcoselect/',
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        },
    })
        .then((response) => {
            const newDomesticList = response.data
                .filter(item => item.shippingCompanyCode === "0" && item.courierCompanyName !== "DHL")
                .map(item => item.courierCompanyName);
            setDomesticList(newDomesticList);

            const newShipcoList = response.data
                .filter(item => item.shippingCompanyCode === "1" && item.courierCompanyName !== "DHL")
                .map(item => item.courierCompanyName);
            setShipcoList(newShipcoList);
        })

    const [shipcoShow, setShipcoShow] = useState(false);
    const shipcoRef = useRef(null);

    const toggleShipcoShow = () => {
        setShipcoShow(!shipcoShow);
        setDomesticShow(false);
    };

    const handleShipcoChange = (shipco) => {
        setSelectedShipco(shipco);
        setShipcoShow(false);
    };

    const [selectedDomestic, setSelectedDomestic] = useState('');

    const [domesticShow, setDomesticShow] = useState(false);
    const domesticRef = useRef(null);

    const toggleDomesticShow = () => {
        setDomesticShow(!domesticShow);
        setShipcoShow(false);
    };
    const handleDomesticChange = (domestic) => {
        setSelectedDomestic(domestic);
    };

    const [osTrackNum, setOsTrackNum] = useState('');
    const [dsTrackNum, setDsTrackNum] = useState('');

    const handleOsTrackNum = ((e) => {
        setOsTrackNum(e);
    })

    const handleDsTrackNum = ((e) => {
        const filteredValue = e.replace(/\D/g, '');
        setDsTrackNum(filteredValue);
    })

    const selectOsTrackNum = (() => {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const width = 1200;
        const height = 800;
        const left = (screenWidth / 2) - (width / 2);
        const top = (screenHeight / 2) - (height / 2);

        if (selectedShipco === "FedEx") {
            const url = `https://www.fedex.com/fedextrack/?trknbr=${osTrackNum}`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedShipco === "DHL") {
            const url = `https://www.dhl.com/kr-ko/home/tracking/tracking-ecommerce.html?submit=1&tracking-id=${osTrackNum}`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedShipco === "EMS" || selectedShipco === "SHIP" || selectedShipco === "UPS" || selectedShipco === "K-Packet" || selectedShipco === "Small-Packet") {
            const url = `https://service.epost.go.kr/trace.RetrieveEmsRigiTraceList.comm?POST_CODE=${osTrackNum}&displayHeader=N`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        }
    })

    const selectDsTrackNum = (() => {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const width = 1200;
        const height = 800;
        const left = (screenWidth / 2) - (width / 2);
        const top = (screenHeight / 2) - (height / 2);

        if (selectedDomestic === "CJ대한통운") {
            const url = `https://trace.cjlogistics.com/next/tracking.html?wblNo=${dsTrackNum}`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "로젠택배") {
            const url = `https://www.ilogen.com/web/personal/trace/${dsTrackNum}`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "우체국") {
            const url = `https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?sid1=${dsTrackNum}&displayHeader=N`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "한진택배") {
            const url = `https://www.hanjin.com/kor/CMS/DeliveryMgr/WaybillResult.do?mCode=MN038&schLang=KR&wblnumText2=${dsTrackNum}`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "롯데택배") {
            const url = `https://www.lotteglogis.com/open/tracking?invno=${dsTrackNum}`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "경동택배") {
            const url = `https://kdexp.com/newDeliverySearch.kd?barcode=${dsTrackNum}`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        }
        // etc 대신택배/일양택배 등
    })

    const [noticeList, setNoticeList] = useState([]);
    const selectAllNoticeList = (lang) => axios({
        method: 'get',
        url: '/notice/selectFixFirstCondition/',
        params: {
            limit: 10,
            lang: lang
        }
    })
        .then((response) => {
            setNoticeList(response.data);
        })

    const [faqDtList, setFaqDtList] = useState([]);
    const selectFixFirstCondition = (lang) => axios({
        method: 'get',
        url: '/faq/selectFixFirstCondition/',
        params: {
            limit: 10,
            lang: lang
        }
    })
        .then((response) => {
            setFaqDtList(response.data);
        })

    const moveToNoticeDtPg = ((seqNum) => {
        navigate(`/UserNoticeDtPg/${seqNum}`)
    })

    const moveToFaqDtPg = ((seqNum) => {
        navigate(`/UserFaqDtPg/${seqNum}`)
    })

    const newTextPrintFl = (regDate) => {
        const today = new Date();
        const todayFormatted = `${today.getFullYear()}${(today.getMonth() + 1).toString().padStart(2, '0')}${today.getDate().toString().padStart(2, '0')}`;
        return regDate === todayFormatted;
    };

    const clearDropdownShow = () => {
        setShipcoShow(false);
        setDomesticShow(false);
    }

    const [expandService, setExpandService] = useState(false);

    const [showAppPopup, setShowAppPopup] = useState(false);
    const [showMUserMainPopup, setShowMUserMainPopup] = useState(false);

    useEffect(() => {
        const isMobile = /Mobi|Android/i.test(navigator.userAgent);
        const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
        const popupStorage = localStorage.getItem('popupStorage');

        if (isMobile && !isIOS && !popupStorage) {
            setShowAppPopup(true);
        } else {
            setShowMUserMainPopup(true);
        }
    }, []);

    const handleAppLaunch = () => {
        const appScheme = "your-app-scheme://";
        const appPlayStoreUrl = "https://play.google.com/store/apps/우리꺼앱경로";
        window.location.href = appScheme;

        setTimeout(() => {
            window.location.href = appPlayStoreUrl;
        }, 1000);

        setShowAppPopup(false);
    };

    const handleDismissPopup = () => {
        const expirationTime = new Date();
        expirationTime.setDate(expirationTime.getDate() + 1);
        localStorage.setItem('popupStorage', JSON.stringify({ dismissed: true, expireAt: expirationTime }));
        setShowAppPopup(false);
        setShowMUserMainPopup(true);
    };

    useEffect(() => {
        const popupData = JSON.parse(localStorage.getItem('popupStorage'));

        if (popupData) {
            const currentTime = new Date();
            const expireTime = new Date(popupData.expireAt);

            if (currentTime > expireTime) {
                localStorage.removeItem('popupStorage');
                setShowAppPopup(true);
            } else {
                setShowAppPopup(false);
            }
        } else {
            setShowAppPopup(true);
        }
    }, []);

    // 메인배너
    const [currentIndex, setCurrentIndex] = useState(1);
    const [banners, setBanners] = useState([]);
    const scrollRef = useRef();

    const selectMainBanners = (lang) => {
        axios({
            method: 'get',
            url: '/banner/selectFixFirstCondition/',
            params: {
                limit: 100,
                type: '0',
                lang: lang
            }

        })
            .then((response) => {
                const bannerData = response.data.map((data) => ({
                    source: { uri: "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/bannerImg/" + data?.bannerPhotoDTO?.[0]?.fileNm }
                }));
                // setBanners([bannerData[bannerData.length - 1], ...bannerData, bannerData[0]]);
                setBanners(bannerData);
                setCurrentIndex(1);
            })
            .catch((error) => {
                console.error("배너조회 오류: ", error);
            });
    };

    useEffect(() => {
        const handleLanguageChange = (lng) => {
            let lang;

            switch (lng) {
                case 'en':
                    lang = '1';
                    break;
                case 'ja':
                    lang = '2';
                    break;
                case 'zh':
                    lang = '3';
                    break;
                case 'id':
                    lang = '4';
                    break;
                default:
                    lang = '0';
            }
            selectMainBanners(lang);
            selectAllNoticeList(lang);
            selectFixFirstCondition(lang);
        };

        handleLanguageChange(i18n.language);

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, []);

    return (
        <div className='m-a-user-main-pg-container font-t-cont' onClick={clearDropdownShow}>
            <div className="banner-slide-frame">
                {banners.length > 0 && (
                    <Swiper className="scroll-container"
                            modules={[Autoplay, Pagination, Navigation]}
                            slidesPerView={1}
                            loop={true}
                            centeredSlides={true}
                            autoplay={{delay: 2500, disableOnInteraction: false}}
                            pagination={{type: 'fraction'}}
                            onSlideChange={(swiper) => setCurrentIndex(swiper.realIndex + 1)}
                    >
                        {banners.map((banner, index) => (
                            <SwiperSlide className="image-container" key={index}>
                                <img className="banner-image" src={banner.source.uri} alt={`Slide ${index}`} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}
            </div>
            {/*모바일 메뉴*/}
            <div className={`m-a-user-main-pg-menu-frame p-l-10 p-r-10 ${expandService && 'expanded'}`}>
                {/*메인메뉴*/}
                <div className="display-flex flex-row just-cont-sb">
                    {/*Service*/}
                    <div className="m-a-user-main-pg-menu-box" onClick={serviceMenuOpen}>
                        <div className="m-a-user-main-pg-menu-icon-box">
                            <img className="m-a-user-main-pg-menu-icon" src={MainService} alt='Icon'/>
                        </div>
                        <div className="m-a-user-main-pg-menu-title font-t-title">Service</div>
                    </div>
                    {/*장바구니*/}
                    <div className="m-a-user-main-pg-menu-box" onClick={() => navigate('/UserOrderPg', {state: {cartState: true}})}>
                        <div className="m-a-user-main-pg-menu-icon-box">
                            <img className="m-a-user-main-pg-menu-icon" src={MainCart} alt='Icon'/>
                        </div>
                        <div className="m-a-user-main-pg-menu-title font-t-title">{t(`cartTitle`)}</div>
                    </div>
                    {/*구매대행*/}
                    <div className="m-a-user-main-pg-menu-box" onClick={orderMenuOpen}>
                        <div className="m-a-user-main-pg-menu-icon-box">
                            <img className="m-a-user-main-pg-menu-icon" src={MainOrder} alt='Icon'/>
                        </div>
                        <div className="m-a-user-main-pg-menu-title font-t-title">{t(`orderTitle`)}</div>
                    </div>
                    {/*배송대행*/}
                    <div className="m-a-user-main-pg-menu-box" onClick={() => navigate('/UserOrderPg', {state: {deliveryState: true}})}>
                        <div className="m-a-user-main-pg-menu-icon-box">
                            <img className="m-a-user-main-pg-menu-icon" src={MainDelivery} alt='Icon'/>
                        </div>
                        <div className="m-a-user-main-pg-menu-title font-t-title">{t(`deliveryTitle`)}</div>
                    </div>
                </div>
                {/*Service 하위메뉴*/}
                { serviceMenuSt ?
                <div className="display-flex flex-row just-cont-sb m-t-10">
                    <div className="m-a-user-main-pg-menu-box" onClick={()=> navigate("/UserUsageGuidePg")}>
                        <div className="m-a-user-main-pg-menu-icon-box">
                            <img className="m-a-user-main-pg-menu-icon" src={MainGuide} alt='Icon'/>
                        </div>
                        <div className="m-a-user-main-pg-menu-title font-t-title">{t(`hdSMenu2`)}</div>
                    </div>
                    <div className="m-a-user-main-pg-menu-box" onClick={()=> navigate("/UserCustomInfoPg")}>
                        <div className="m-a-user-main-pg-menu-icon-box">
                            <img className="m-a-user-main-pg-menu-icon" src={MainCustomInfo} alt='Icon'/>
                        </div>
                        <div className="m-a-user-main-pg-menu-title font-t-title">{t(`hdSMenu3`)}</div>
                    </div>
                    <div className="m-a-user-main-pg-menu-box" onClick={()=> navigate("/UserCompareShipPg")}>
                        <div className="m-a-user-main-pg-menu-icon-box">
                            <img className="m-a-user-main-pg-menu-icon" src={MainShipCompare} alt='Icon'/>
                        </div>
                        <div className="m-a-user-main-pg-menu-title font-t-title">{t(`hdSMenu4`)}</div>
                    </div>
                    <div className="m-a-user-main-pg-menu-box" onClick={()=> navigate("/UserDepChargPg")}>
                        <div className="m-a-user-main-pg-menu-icon-box">
                            <img className="m-a-user-main-pg-menu-icon" src={DepSaving} alt='Icon'/>
                        </div>
                        <div className="m-a-user-main-pg-menu-title font-t-title">{t(`hdSMenu5`)}</div>
                    </div>
                </div>
                    :
                    null
                }
                {/*구매대행 하위메뉴*/}
                {orderMenuSt ?
                <div className="display-flex flex-row just-cont-sb m-t-10">
                    <div className="m-a-user-main-pg-menu-box" onClick={() => navigate('/UserOrderPg', {state: {orderState: true}})}>
                        <div className="m-a-user-main-pg-menu-icon-box">
                            <img className="m-a-user-main-pg-menu-icon" src={MainChildOrder} alt='Icon'/>
                        </div>
                        <div className="m-a-user-main-pg-menu-title font-t-title">{t(`hdSMenu6`)}</div>
                    </div>
                    <div className="m-a-user-main-pg-menu-box" onClick={() => navigate('/UserOrderPg', {state: {offlineState: true}})}>
                        <div className="m-a-user-main-pg-menu-icon-box">
                            <img className="m-a-user-main-pg-menu-icon" style={{width:"30px", height:"30px"}} src={MainChildOffline} alt='Icon'/>
                        </div>
                        <div className="m-a-user-main-pg-menu-title font-t-title">{t(`hdSMenu8`)}</div>
                    </div>
                    <div className="m-a-user-main-pg-menu-box" onClick={() => navigate('/UserOrderPg', {state: {transferState: true}})}>
                        <div className="m-a-user-main-pg-menu-icon-box">
                            <img className="m-a-user-main-pg-menu-icon" style={{width:"40px", height:"40px"}} src={MainChildTransfer} alt='Icon'/>
                        </div>
                        <div className="m-a-user-main-pg-menu-title font-t-title">{t(`hdSMenu7`)}</div>
                    </div>
                    <div className="m-a-user-main-pg-menu-box" onClick={() => navigate('/UserOrderPg', {state: {domesticState: true}})}>
                        <div className="m-a-user-main-pg-menu-icon-box">
                            <img className="m-a-user-main-pg-menu-icon" style={{width:"37px", height:"37px"}} src={MainChildDomestic} alt='Icon'/>
                        </div>
                        <div className="m-a-user-main-pg-menu-title font-t-title">{t(`hdSMenu9`)}</div>
                    </div>
                </div>
                    :
                    null
                }
            </div>
            <div className="m-a-user-main-pg-bold-divider"/>
            <div className="m-a-user-main-pg-sub-title p-l-20 p-r-20">
                <img className="m-a-user-main-pg-sub-title-icon" src={MainReview} alt='Review'/>
                <p className="m-a-user-main-pg-sub-title-text font-t-title p-t-5">{t(`smHeader`)}</p> {/*이용후기*/}
            </div>
            {reviewAllList.length > 0 ? (
                <div className="m-a-user-main-pg-review-frame p-l-20 p-r-20 font-t-cont">
                    <div className={'m-a-user-main-pg-review-list'}>
                        {reviewAllList.slice(0, 10).map((review, index) => (
                            <div className="m-a-user-main-pg-review-item" key={index}
                                 onClick={() => navigate(`/UserReviewDetailPg/${review.seqNum}`)}>
                                {review && review.reviewPhotoDTO[0] && review.reviewPhotoDTO[0].fileNm ?
                                    (
                                        <img alt="" className="m-a-user-main-pg-review-img"
                                             src={"https://toeloperfile.s3.ap-northeast-2.amazonaws.com/reviewPhoto/" + review.reviewPhotoDTO[0].fileNm}/>
                                    ) : (
                                        <img alt="" className="m-a-user-main-pg-review-img" src={noReviewPhoto}
                                             style={{backgroundColor: "#f6f6f6"}}/>
                                    )
                                }
                                <div className="m-a-user-main-pg-review-sub">[{review.country}-{review.shipco}]</div>
                                <div className="m-a-user-main-pg-review-title">{review.title}</div>
                                <div className="m-a-user-main-pg-review-data">
                                    <div className="m-a-user-main-pg-review-date">{formatDate(review.regDate)}</div>
                                    {newTextPrintFl(review.regDate) &&
                                        <div className="m-a-user-main-pg-review-new">New</div>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                    {reviewAllList.length > 4 ? (
                        <button className="bt-btn-C ht-35 font-s-12 p-l-20 p-r-20 max-wd-100" onClick={reviewPgMove()}>More</button>
                    ) : (
                        <button className="bt-btn-C ht-35 font-s-12 p-l-20 p-r-20 max-wd-100" onClick={reviewPgMove()}>Write</button>
                    )}
                </div>
            ) : (
                <div className={'m-a-user-main-pg-review-no-data-frame'}>
                    <img className={'m-a-user-main-pg-no-data-img'} src={noData} alt={'No Data'}/>
                    <div className={'m-a-user-main-pg-no-data-text'}>No Data</div>
                </div>
            )}
            <div className='m-a-user-main-pg-bold-divider'/>
            {/*메인공지&FAQ 컨테이너(3줄씩표시)*/}
            <div className='m-a-user-main-pg-sub-title p-l-20 p-r-20'>
                <img className='m-a-user-main-pg-sub-title-icon' src={MainNotice} alt=''/>
                <p className='m-a-user-main-pg-sub-title-text font-t-title p-t-5'>{t(`smNotice`)}</p> {/*공지사항*/}
            </div>
            <div className='m-a-user-main-pg-notice-frame p-l-20 p-r-20 font-t-cont'>
                <div className={'m-a-user-main-pg-notice-list'}>
                    {noticeList && noticeList.map((notice, index) => (
                        <div className="m-a-user-main-pg-notice-item gap3" key={index}
                             onClick={() => moveToNoticeDtPg(notice?.seqNum)}>
                            <div className="m-a-user-main-pg-notice-item-left wd-75pc">
                                <p className="font-s-12 m-a-text-cut p-r-5 wd-80pc">{notice?.title}</p>
                                <div className="wd-20pc">
                                    {newTextPrintFl(notice.regDate) &&
                                    <p className="font-c-btorage font-s-11">New</p>
                                    }
                                </div>
                            </div>
                            <div className="m-a-user-main-pg-notice-date wd-25pc text-right">
                                <p>{formatDate(notice?.regDate)}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <button className="bt-btn-C ht-35 font-s-12 p-l-20 p-r-20 max-wd-100" onClick={() => navigate("/UserNoticePg")}>More</button>
            </div>
            <div className='m-a-user-main-pg-bold-divider'/>
            <div className='m-a-user-main-pg-sub-title p-l-20 p-r-20'>
                <img className='m-a-user-main-pg-sub-title-icon' src={MainFaq} alt=''/>
                <p className='m-a-user-main-pg-sub-title-text font-t-title p-t-5'>{t(`smFaq`)}</p> {/*자주묻는질문*/}
            </div>
            <div className='m-a-user-main-pg-notice-frame p-l-20 p-r-20'>
                <div className={'m-a-user-main-pg-notice-list'}>
                    {faqDtList && faqDtList.map((faq, index) => (
                        <div className="m-a-user-main-pg-notice-item gap3" key={index} onClick={() => moveToFaqDtPg(faq?.seqNum)}>
                            <div className="m-a-user-main-pg-notice-item-left wd-75pc">
                                <p className="font-s-12 m-a-text-cut"><a style={{color: "#a1a1a1"}}>[{faq?.faqMstDTO?.title}]</a> {faq?.title}</p>
                            </div>
                            <div className="m-a-user-main-pg-notice-date wd-25pc text-right">
                                <p>{formatDate(faq?.regDate)}</p>
                            </div>
                        </div>
                    ))}
                </div>
                <button className="bt-btn-C ht-35 font-s-12 p-l-20 p-r-20 max-wd-100" onClick={() => navigate("/UserFaqPg")}>More</button>
            </div>
            <div className='m-a-user-main-pg-bold-divider'/>
            <div className='m-a-user-main-pg-bottom-frame p-l-20 p-r-20'>
                <div className={'m-a-user-main-pg-bottom-img-box'}>
                    <img className='m-a-user-main-pg-bottom-img' src={CenterLogo} alt=''/>
                </div>
                <div className='m-a-user-main-pg-bottom-title'><p className="font-t-title font-s-15">{t(`smCenter`)}</p></div> {/*배송대행 물류센터*/}
                <div className='m-a-user-main-pg-bottom-text'>
                    - {t(`smZipcode`)} <br/>
                    - {t(`smAddress`)} : 경기도 광주시 곤지암읍 연곡길42번길 32-15 <br/>
                    - {t(`smTell`)} <br/>
                    ※ {t(`smMailbox`)}
                </div>
                <div className='m-a-user-main-pg-button-transparent'> {t(`smPickupBt`)}</div>
            </div>
            <div className='m-a-user-main-pg-bottom-frame p-l-20 p-r-20'>
                <div className={'m-a-user-main-pg-bottom-img-box'}>
                    <img className='m-a-user-main-pg-bottom-img' src={LookUp} alt='Look Up'/>
                </div>
                <div><p className="font-t-title font-s-15">{t(`smTrackHeader`)}</p></div> {/*배송조회*/}
                <div className='m-a-user-main-pg-bottom-text'>{t(`smTrackContent1`)}</div>
                {/*국제배송 셀렉트*/}
                <div className="ht-45 wd-100pc position-relative" tabIndex="0" ref={shipcoRef}
                     onClick={(e) => {
                         toggleShipcoShow()
                         e.stopPropagation()
                     }}
                >
                    <button className="selectParentBt wd-100pc ht-100pc">
                        <p className="font-s-14 font-c-default">{selectedShipco === "" ? t(`ordOsDvLabel`) : selectedShipco}</p> {/*해외배송사*/}
                        <span className="position-absolute ht-100pc wd-40" style={{right: "0", top: "0"}}>
                                                    <i className="display-flex just-cont-center align-center ht-100pc font-s-20" style={{color: "darkgray"}}>
                                                        <BiCaretDown/>
                                                    </i>
                                                </span>
                    </button>
                    {shipcoShow ?
                        <div className="wd-100pc position-absolute m-t-5" style={{
                            maxHeight: "220px",
                            overflowY: "auto",
                            border: "solid 1px lightgray",
                            top: "100%",
                            zIndex: 1,
                        }}>
                            {shipcoList.filter(shipco => shipco !== "국내배송").map((shipcoItem, index) => (
                                <div className="selectChildBt display-flex justify-content-center align-center c-point font-s-14"
                                     key={index} value={shipcoItem} onClick={() => {handleShipcoChange(shipcoItem);}} style={{padding: "10px", zIndex: 99}}>
                                    <p className="font-s-14">{shipcoItem}</p>
                                </div>
                            ))}
                        </div>
                        :
                        null
                    }
                </div>
                {/*<div ref={shipcoRef} className='m-a-user-main-pg-button-transparent'*/}
                {/*     onClick={(e) => {*/}
                {/*         toggleShipcoShow()*/}
                {/*         e.stopPropagation()*/}
                {/*     }}>*/}
                {/*    {selectedShipco === "" ? t(`ordOsDvLabel`) : selectedShipco}*/}
                {/*</div>*/}
                {/*{shipcoShow && <div className={'m-a-user-main-pg-ship-co-list'}>*/}
                {/*    {shipcoList.filter(shipco => shipco !== "국내배송").map((shipcoItem, index) => (*/}
                {/*        <div className='m-a-user-main-pg-ship-item' key={index} value={shipcoItem}*/}
                {/*             onClick={() => {handleShipcoChange(shipcoItem);}}>*/}
                {/*            {shipcoItem}*/}
                {/*        </div>*/}
                {/*    ))}*/}
                {/*</div>}*/}
                {/*국제배송 인풋*/}
                <div className='m-a-user-main-pg-search-frame'>
                    <input className='m-a-user-main-pg-search-input'
                           value={osTrackNum}
                           onChange={(e) => handleOsTrackNum(e.target.value)}
                           onKeyDown={(e) => {
                               if (e.key === 'Enter') selectOsTrackNum()
                           }}/>
                    <img className='m-a-user-main-pg-search-icon' src={Search} alt='Search'
                         onClick={selectOsTrackNum}/>
                </div>
                {/*국내배송 셀렉트*/}
                <div className="ht-45 wd-100pc position-relative" tabIndex="0" ref={shipcoRef}
                     onClick={(e) => {
                         toggleDomesticShow()
                         e.stopPropagation()
                     }}
                >
                    <button className="selectParentBt wd-100pc ht-100pc">
                        <p className="font-s-14 font-c-default">{selectedDomestic === "" ? t(`ordDsDvLabel`) : selectedDomestic}</p> {/*국내배송사*/}
                        <span className="position-absolute ht-100pc wd-40" style={{right: "0", top: "0"}}>
                                                    <i className="display-flex just-cont-center align-center ht-100pc font-s-20" style={{color: "darkgray"}}>
                                                        <BiCaretDown/>
                                                    </i>
                                                </span>
                    </button>
                    {domesticShow ?
                        <div className="wd-100pc position-absolute m-t-5" style={{
                            maxHeight: "220px",
                            overflowY: "auto",
                            border: "solid 1px lightgray",
                            top: "100%",
                            zIndex: 1,
                        }}>
                            {domesticList.filter(dom => dom !== "기타택배" && dom !== "쿠팡").map((domestic, index) => (
                                <div className="selectChildBt display-flex justify-content-center align-center c-point font-s-14"
                                     key={index} value={domestic} onClick={() => {handleDomesticChange(domestic);}} style={{padding: "10px", zIndex: 99}}>
                                    <p className="font-s-14">{domestic}</p>
                                </div>
                            ))}
                        </div>
                        :
                        null
                    }
                </div>
                {/*<div ref={domesticRef} className='m-a-user-main-pg-button-transparent'*/}
                {/*     onClick={(e) => {*/}
                {/*         toggleDomesticShow()*/}
                {/*         e.stopPropagation()*/}
                {/*     }}>*/}
                {/*    {selectedDomestic === "" ? t(`ordDsDvLabel`) : selectedDomestic}*/}
                {/*</div>*/}
                {/*{domesticShow && domesticList &&*/}
                {/*    <div className={'m-a-user-main-pg-ship-co-list'}>*/}
                {/*        {domesticList.filter(dom => dom !== "기타택배" && dom !== "쿠팡")*/}
                {/*            .map((domestic, index) => (*/}
                {/*                <div className='m-a-user-main-pg-ship-item'*/}
                {/*                     key={index} value={domestic}*/}
                {/*                     onClick={() => {*/}
                {/*                         handleDomesticChange(domestic)*/}
                {/*                     }}>*/}
                {/*                    {domestic}*/}
                {/*                </div>*/}
                {/*            ))}*/}
                {/*    </div>*/}
                {/*}*/}
                {/*국내배송 인풋*/}
                <div className='m-a-user-main-pg-search-frame'>
                    <input className='m-a-user-main-pg-search-input'
                           value={dsTrackNum}
                           onChange={(e) => handleDsTrackNum(e.target.value)}
                           onKeyDown={(e) => {
                               if (e.key === 'Enter') selectDsTrackNum()
                           }}/>
                    <img className='m-a-user-main-pg-search-icon' src={Search} alt='Searcg'
                         onClick={selectDsTrackNum}/>
                </div>
            </div>
            <div className='m-a-user-main-pg-bold-divider'/>
            <div className='m-a-user-main-pg-sub-title p-l-20 p-r-20'>
                <img className='m-a-user-main-pg-sub-title-icon' src={PayMethod} alt=''/>
                <p className='m-a-user-main-pg-sub-title-text font-t-title p-t-5'> {t(`smPayty`)}</p> {/*결제수단*/}
            </div>
            <div className='m-a-user-main-pg-img-list-container'>
                <div className='m-a-user-main-pg-img-list-frame'>
                    <img className='m-a-user-main-pg-img' style={{width:"20%", height:"80px"}} src={btoragePay} alt='pay'/>
                    <img className='m-a-user-main-pg-img' style={{width:"20%", height:"80px"}} src={creditCard} alt='pay'/>
                    <img className='m-a-user-main-pg-img' style={{width:"20%", height:"80px"}} src={PaypalLogo} alt='pay'/>
                    <img className='m-a-user-main-pg-img' style={{width:"20%", height:"80px"}} src={WireBarleyLogo} alt='pay'/>
                    <img className='m-a-user-main-pg-img' style={{width:"20%", height:"80px"}} src={SentbeLogo} alt='pay'/>
                </div>
                <div className='m-a-user-main-pg-img-list-frame'>
                <img className='m-a-user-main-pg-img' style={{width:"20%", height:"80px"}} src={StripeLogo} alt='pay'/>
                <img className='m-a-user-main-pg-img' style={{width:"20%", height:"80px"}} src={TransferWiseLogo} alt='pay'/>
                <img className='m-a-user-main-pg-img' style={{width:"20%", height:"80px"}} src={AlipayLogo} alt='pay'/>
                <img className='m-a-user-main-pg-img' style={{width:"20%", height:"80px"}} src={WechatPayLogo} alt='pay'/>
                <img className='m-a-user-main-pg-img' style={{width:"20%", height:"80px"}} src={Etc} alt='pay'/>
                </div>
            </div>
            {/*<div className={'m-a-user-main-pg-text-box'}>*/}
            {/*    <div className={'m-a-user-main-pg-text'}>*/}
            {/*        {t(`smPaytyContent`)}*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*배송사종류*/}
            <div className='m-a-user-main-pg-bold-divider'/>
            <div className='m-a-user-main-pg-sub-title p-l-20 p-r-20'>
                <img className='m-a-user-main-pg-sub-title-icon' src={DeliveryType} alt=''/>
                <p className='m-a-user-main-pg-sub-title-text font-t-title p-t-5'> {t(`smShipcoHeader`)}</p>
            </div>
            <div className='m-a-user-main-pg-img-list-container'>
                <div className='m-a-user-main-pg-img-list-frame'>
                    <img className='m-a-user-main-pg-img' style={{width:"33.3%", height:"80px"}} src={FedexLogo} alt='pay'/>
                    <img className='m-a-user-main-pg-img' style={{width:"33.3%", height:"85px"}} src={UpsLogo} alt='pay'/>
                    <img className='m-a-user-main-pg-img' style={{width:"33.3%", height:"80px"}} src={SmallPacket} alt='pay'/>
                </div>
                <div className='m-a-user-main-pg-img-list-frame'>
                <img className='m-a-user-main-pg-img' style={{width:"33.3%", height:"80px", padding:"25px"}} src={EmsLogo} alt='pay'/>
                <img className='m-a-user-main-pg-img' style={{width:"33.3%", height:"80px"}} src={Kpacket} alt='pay'/>
                <img className='m-a-user-main-pg-img' style={{width:"33.3%", height:"80px"}} src={SeaShipLogo} alt='pay'/>
                </div>
            </div>
            {/*<div className={'m-a-user-main-pg-text-box'}>*/}
            {/*    <div className={'m-a-user-main-pg-text'}>*/}
            {/*        {t(`smShipcoContent1`)} <br/> {t(`smShipcoContent2`)}*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className='m-a-user-main-pg-footer-new m-t-50'>
                <div className='wd-100pc display-flex flex-row align-center'>
                    <img className='wd-20 ht-20 m-r-5' src={ServiceCenter} alt=''/>
                    <sub className='font-t-title font-s-17'> {t(`fmCt`)} </sub>
                </div>
                <div className='text-left wd-100pc p-t-3 font-s-14 m-t-7 m-b-20'>
                    <p>{t(`fmCs`)}</p>
                    <p>{t(`fmLunch`)}</p>
                </div>
                <div className='wd-100pc display-flex flex-row align-center gap15 m-b-20'>
                    <div className='m-a-user-main-pg-footer-button-new' onClick={() => setIsPopupOpen(true)}> {t(`fmChatBt`)}</div> {/*1:1 채팅*/}
                    <div className='m-a-user-main-pg-footer-button-new' onClick={() => navigate("/UserMailInquiryPg")}> {t(`fmContBt`)}</div> {/*1:1 메일*/}
                </div>
                <div className='m-a-user-main-pg-footer-guide-new m-b-40'>
                    <div className='m-a-user-main-pg-footer-guide-row-new'>
                        <div className='m-a-user-main-pg-footer-guide-text-new'
                             onClick={() => navigate("/UserUsageGuidePg")}> {t(`fmSmallMenu5`)}</div>
                        <div className='m-a-user-main-pg-footer-guide-text-new'> {t(`fmSmallMenu4`)}</div>
                    </div>
                    <div className='m-a-user-main-pg-footer-guide-row-new'>
                        <div className='m-a-user-main-pg-footer-guide-text-new'
                             onClick={() => setPrivacyPolicyPopup(true)}> {t(`fmSmallMenu3`)}</div>
                        <div className='m-a-user-main-pg-footer-guide-text-new'
                             onClick={() => setTermsOfUsePopup(true)}> {t(`fmSmallMenu2`)}</div>
                    </div>
                </div>
                <div className="m-a-user-main-pg-footer-company-info-new">
                    <div className='m-a-user-main-pg-footer-company-info-logo-new m-b-5'>
                        <img className='m-a-user-main-pg-footer-company-info-logo-img-new m-b-2' src={BtorageLogo} alt=''/>
                        <div className='m-a-user-main-pg-footer-company-info-text-new'><p className="font-t-title">{t(`fmCompNm`)}</p></div>
                    </div>
                    <div className='m-a-user-main-pg-footer-text-md-new'>
                        {t(`fmCompCeo`)} | {t(`fmCompInfoAdmin`)} <br/>
                        {t(`fmCompNum`)} <br/>
                        {t(`fmCompMail`)} <br/>
                        경기도 광주시 곤지암읍 연곡길42번길 32-15
                    </div>
                    <div className="wd-100pc font-s-11">
                        © 2022 Btorage Corp. All rights reserved.
                    </div>
                </div>
            </div>
            <MSideQuickMenu/>
            <MFooterSection/>
            {termsOfUsePopup ?
                <MCommonTermsPopup termsOfUsePopup={termsOfUsePopup} closeTermsOfUsePopup={closeTermsOfUsePopup}/>
                :
                privacyPolicyPopup ?
                    <MCommonTermsPopup privacyPolicyPopup={privacyPolicyPopup}
                                      closePrivacyPolicyPopup={closePrivacyPolicyPopup}/>
                    :
                    null
            }
            {showAppPopup && (
                <div className="m-u-app-popup font-t-cont">
                    <div className="m-u-app-popup-cont display-flex flex-column just-cont-center">
                        <div className="display-flex flex-row just-cont-center ht-80 align-center m-b-15">
                            <div className="m-u-app-icon-frame">
                                <div className="m-u-app-icon-container">
                                    <img alt="" src={BtorageLogo} />
                                </div>
                            </div>
                            <div className="display-flex flex-column font-s-20 m-l-15">
                                <p className="font-t-title" style={{color: "#2c2c2c"}}>앱으로 더 편하게!</p>
                                <p className="font-t-title" style={{color: "#2c2c2c"}}>다양한 혜택을 받으세요!</p>
                            </div>
                        </div>
                        <div className="m-t-5">
                            <button className="m-a-app-p-bt m-a-app-p-bt-text font-t-cont wd-90pc ht-50 font-b" onClick={handleAppLaunch}>비토리지 앱으로 가기</button>
                        </div>
                        <div className="">
                            <div onClick={handleDismissPopup}>
                                <p className="font-s-14 font-c-default">괜찮아요. 모바일웹으로 볼래요.</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {!showAppPopup && showMUserMainPopup && (
                <MUserMainPopModal />
            )}
        </div>
    )
}

export default MSectionMain;
