import React, {useCallback, useEffect, useRef, useState} from 'react';
import axios from "axios";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import AdminYmdEvent from "../../components/web/layoutsadmin/AdminYmdEvent";
import AdminSearchEvent from "../../components/web/layoutsadmin/AdminSearchEvent";
import {MdKeyboardArrowDown} from "react-icons/md";
import SaveMsgModal from "../../components/web/modal/SaveMsgModal";
import maCateImg from "../../assets/img/m-a-cate.png";
import {useNavigate} from "react-router-dom";
import {useWebSocket} from "../../services/WebsocketContext";

function AdminBuyItemPg() {

    /** region DataTable */

    const [data, setData] = useState([]);

    /** endregion */

    /** region Function */

    const LCAT = localStorage.getItem('adminToken');
    const navigate = useNavigate();

    const [userNm, setUserNm] = useState('');
    const [adminInfo, setAdminInfo] = useState([]);
    const selectAdminInfo = (() => {
        axios({
            method: 'get',
            url: '/member/modiInfo',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response)=> {
                setAdminInfo(response.data[0])
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        selectAdminInfo();
    }, [])

    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!adminInfo.mbNum || isSubscribedRef.current) return;

            if (!stompClient && !isConnected) {
                await websocketConnect(adminInfo.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                isSubscribedRef.current = false;
            }
        };

    }, [adminInfo.mbNum, isConnected, stompClient]);

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [data.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
            if (data.length > displayLimit && displayLimit < 30) {
                setDisplayLimit(prevLimit => prevLimit + perPg);
            }
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPg);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const handleSelectChange = (e) => {
        const newPerPage = parseInt(e.target.value);
        setperPg(newPerPage);
        setDisplayLimit(newPerPage);
        setCurrentPage(1);
    };

    useEffect(() => {
        setDisplayLimit(perPg);
    }, [perPg]);

    // endregion

    //region Alert

    const [alertShow, setAlertShow] = useState(false);
    const msgConfirmData1 = "저장 하시겠습니까?";
    const msgConfirmData2 = "승인이 완료됩니다.";
    const modalCancel = useCallback (() => {setAlertShow(false);})
    const alert = (()=> {setAlertShow(true)})

    //endregion

    //region CheckBox

    const [checked, setChecked] = useState([]);

    const checkBoxChange = (id) => {
        setChecked((prevSelectedData) => {
            if (prevSelectedData.includes(id)) {
                return prevSelectedData.filter((item) => item !== id);
            } else {
                return [...prevSelectedData, id];
            }
        });
    };

    const allcheckBoxChange = (e) => {
        let visibleData = data.slice(0, displayLimit);
        if (e.target.checked) {
            const visibleIds = visibleData.map((item) => item.seqNum);
            setChecked(visibleIds);
        } else {
            setChecked([]);
        }
    };

    //endregion

    //region Search

    const [searchInput, setSearchInput] = useState('');
    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const searchData = data.filter((cData) => {
        return cData.regNm.toLowerCase().includes(searchInput.toLowerCase());
    });

    //endregion

    //region DateSelect

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const startHandle = (e) => {setStartDate(e.target.value);};
    const endHandle = (e) => {setEndDate(e.target.value);};

    const currentDate = new Date();
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(currentDate.getDate() - 30);

    const defaultMonth = () => {
        const stringStartDate = formatDate(twoWeeksAgo);
        const stringEndDate = formatDate(currentDate);
        setStartDate(stringStartDate);
        setEndDate(stringEndDate);
    }

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    //endregion DateSelect

    /** endregion */

    /** region Event */

    useEffect(() => {
        defaultMonth();
        select();
    }, [endDate])

    const select = (() => {
        axios({
            method: 'post',
            url: '/order/selectConditionProdList',
            params: {
                prodSt: "IS04"
            },
        })
            .then((response) => {
                setData(response.data)
            })
            .catch((error) => {
                console.log("adminErr[00] : ", error)
                const status = error?.response?.status;
                const errorPages = [400, 401, 402, 403, 404, 405, 500, 501, 502, 503];
                if (status && errorPages.includes(status)) {
                    window.location.href = '/ErrStatusPg';
                }
            })
    })

    // region Excel

    const date = new Date();
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const DateViewValue = year + month + day;

    const excelDown = () => {
        try {
            const selectedRows = searchData.filter((item) => checked.includes(item.mbNum));

            const excel = require('exceljs');
            const workbook = new excel.Workbook();
            workbook.created = new Date();
            workbook.modified = new Date();

            function mapDataToExcelRow(item, index) {
                return {
                    '번호': index + 1,
                    '고객명': item.regNm,
                    '구매일시': item.modiTime || "N/A",
                    '상품명': item.name,
                    'URL': item.url,
                    '상품금액': item.price || 0,
                    '몰배송비': item.mallShipPrice || 0,
                    '수량': item.quantity,
                    '상품메모': item.adminMemo
                };
            }

            const sheetConfigs = {
                '구매상품내역': {
                    name: '구매상품내역',
                    columns: [
                        {header: '번호', key: '번호', hidden: false, width: 6, style: { alignment: { horizontal: 'center' }}},
                        {header: '고객명', key: '고객명', width: 20, style: { alignment: { horizontal: 'center' }}},
                        {header: '구매일시', key: '구매일시', width: 22, style: { alignment: { horizontal: 'center' }}},
                        {header: '상품명', key: '상품명', width: 40},
                        {header: 'URL', key: 'URL', width: 25},
                        {header: '상품금액(\\)', key: '상품금액', width: 14, style: { alignment: { horizontal: 'center' }}},
                        {header: '몰배송비(\\)', key: '몰배송비', width: 14, style: { alignment: { horizontal: 'center' }}},
                        {header: '수량', key: '수량', width: 8, style: { alignment: { horizontal: 'center' }}},
                        {header: '상품메모', key: '상품메모', width: 55},
                    ],
                }
            };

            const borderHeaderStyle = {
                top: { style: 'thin', color: { argb: 'FF000000' } },
                left: { style: 'thin', color: { argb: 'FF000000' } },
                bottom: { style: 'thin', color: { argb: 'FF000000' } },
                right: { style: 'thin', color: { argb: 'FF000000' } }
            };

            const applyBordersToRow = (row, border) => {
                row.eachCell({ includeEmpty: true }, (cell) => {
                    cell.border = border;
                });
            };

            const applyStylesToHeader = (sheet, height) => {
                const invisibleRow = sheet.getRow();
                invisibleRow.height = 1;

                const headerRow = sheet.getRow(1);
                headerRow.font = { name: '맑은 고딕', bold: true, size: 10 };
                headerRow.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
                headerRow.height = height;

                headerRow.eachCell(() => {});
                applyBordersToRow(headerRow, borderHeaderStyle);
            };

            const createSheet = (name, columns) => {
                const sheet = workbook.addWorksheet(name);
                sheet.columns = columns;
                applyStylesToHeader(sheet, 23);
                return sheet;
            };

            const addRowsToSheet = (sheet, rows, border) => {
                rows.forEach((item) => {
                    const row = sheet.addRow(item);
                    for (let i = 1; i <= sheet.columns.length; i++) {
                        row.getCell(i).font = { name: '맑은 고딕', size: 10 };
                    }
                    applyBordersToRow(row, border);
                });
            };

            let transformedSelectedRows;
            transformedSelectedRows = selectedRows.map(mapDataToExcelRow);

            if (sheetConfigs) {
                const sheet = createSheet(sheetConfigs['구매상품내역'].name, sheetConfigs['구매상품내역'].columns);
                applyStylesToHeader(sheet, 23);
                addRowsToSheet(sheet, transformedSelectedRows, borderHeaderStyle);
            }

            let fileName = `구매상품내역_${DateViewValue}.xlsx`;
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = fileName;
                anchor.click();
                window.URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.error(error);
        }
    };

    // endregion

    /** endregion */

    const [adminProdMemos, setAdminProdMemos] = useState({});
    const handleProdMemoChange = (seqnum, value) => {
        setAdminProdMemos(prevMemos => ({
            ...prevMemos,
            [seqnum]: value
        }));
    };

    const [saveMsgSt, setSaveMsgSt] = useState(false);
    const [savePopupMsg, setSavePopupMsg] = useState('');

    const modifyAdminProdMemo = ((seqnum, memo) => {
        axios({
            method: 'post',
            url: '/order/modifyAdminProdMemo',
            headers: {
                Authorization: LCAT
            },
            data: {
                seqNum: seqnum,
                memo: memo
            }
        })
            .then((response) => {
                setSavePopupMsg("저장 완료");
                setSaveMsgSt(true);
                setTimeout(() => setSaveMsgSt(false), 1000);
            })
            .catch((error) => {
                console.log("adminErr[03] : ", error)
                const status = error?.response?.status;
                const errorPages = [400, 401, 402, 403, 404, 405, 500, 501, 502, 503];
                if (status && errorPages.includes(status)) {
                    window.location.href = '/ErrStatusPg';
                } else {
                    setSavePopupMsg("저장 실패");
                    setSaveMsgSt(true);
                    setTimeout(() => setSaveMsgSt(false), 1000);
                }
            })
    })

    const saveProdMemo = (e, seqnum) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            const memo = adminProdMemos[seqnum] || '';
            modifyAdminProdMemo(seqnum, memo);
        }
    };

    useEffect(() => {
        const initialMemos = {};
        searchData.forEach(prod => {
            initialMemos[prod.seqnum] = prod.adminMemo;
        });
        // 상품메모 초기설정
        setAdminProdMemos(initialMemos);
    }, [data]);

    return (
        <div className="AdminBuyItemPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">구매상품관리</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card">
                    <div className="admin-event-wrap">
                        <div className="admin-event-wrap-btn">
                            <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                            <AdminYmdEvent startDate={startDate} endDate={endDate} startHandle={startHandle} endHandle={endHandle}/>
                            <AdminSearchEvent searchInput={searchInput} handleSearchInputChange={handleSearchInputChange} />
                        </div>
                        <div className="admin-event-wrap-btn gap10">
                            <button onClick={excelDown} className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event">EXCEL</button>
                        </div>
                    </div>
                    <div className="div-tb m-b-10">
                        <div className="div-tb-head ht-60">
                            <div style={{width: "40px"}}>
                                <input className="admin-input-chkBox m-0-a" type="checkbox" checked={checked.length === data.length}
                                       onChange={allcheckBoxChange}/>
                            </div>
                            <div style={{width: "40px"}}><p>번호</p></div>
                            <div style={{width: "150px"}}><p>고객명</p></div>
                            <div style={{width: "150px"}}>
                                <p>주문번호</p>
                                <p>구매일시</p>
                            </div>
                            <div style={{width: "230px"}}><p>상품명</p></div>
                            <div style={{width: "150px"}}><p>URL</p></div>
                            <div style={{width: "100px"}}><p>상품금액(&#8361;)</p></div>
                            <div style={{width: "100px"}}><p>수수료(&#8361;)</p></div>
                            <div style={{width: "100px"}}><p>몰배송비(&#8361;)</p></div>
                            <div style={{width: "100px"}}><p>합계</p></div>
                            <div style={{width: "50px"}}><p>수량</p></div>
                            <div style={{width: "280px"}}><p>상품메모</p></div>
                        </div>
                        {searchData.slice(0, displayLimit).map((cData, i) => (
                            <div key={i} className="div-tb-body ht-70">
                                <div style={{width: "40px"}}>
                                    <input className="admin-input-chkBox m-0-a" type="checkbox" name={cData.seqNum}
                                           checked={checked.includes(cData.seqNum)}
                                           onChange={() => checkBoxChange(cData.seqNum)}/>
                                </div>
                                <div style={{width: "40px"}}><p>{i + 1}</p></div>
                                <div style={{width: "150px"}}><p className="font-b">{cData.regNm}</p></div>
                                <div style={{width: "150px", cursor: "pointer"}} onClick={() => window.open(`/btcorpad/orderdtmodi/${cData.orderNumber}`, '_blank')}>
                                    <a className="font-b" style={{color: "#FFAF34"}}>{cData.orderNumber}</a>
                                    <p>{cData.orderDate}</p>
                                </div>
                                <div className="admin-tb-text-cut" style={{width: "230px", cursor: "pointer"}}>
                                    <a className="text-underline" href={cData.url} target="_blank">{cData.name}</a>
                                </div>
                                <div className="admin-tb-text-cut wd-150">
                                    <a className="wd-140 text-underline" href={cData.url} target="_blank" style={{color: "#276ab7"}}>{cData.url}</a>
                                </div>
                                <div style={{width: "100px"}}>
                                    <p className="font-b">{cData.price.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p>
                                </div>
                                <div style={{width: "100px"}}>
                                    <p className="font-b">{cData.fee.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p>
                                </div>
                                <div style={{width: "100px"}}>
                                    <p className="font-b">{cData.mallShipPrice.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p>
                                </div>
                                <div style={{width: "100px"}}>
                                    <p className="font-b">{"\u20A9 " + (cData.price + cData.fee + cData.mallShipPrice).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}</p>
                                </div>
                                <div style={{width: "50px"}}><p className="font-b">{cData.quantity}</p></div>
                                {/*<div style={{width: "270px"}}><textarea className="admin-input-line prod-textarea-memo" cols="50" rows="3"></textarea></div>*/}
                                <div style={{width: "280px"}}>
                                    <textarea className="admin-input-line prod-textarea-memo p-l-5"
                                        cols="50"
                                        rows="3"
                                        value={adminProdMemos[cData.seqnum] || ''}
                                        onChange={(e) => handleProdMemoChange(cData.seqnum, e.target.value)}
                                        onKeyDown={(e) => saveProdMemo(e, cData.seqnum)}
                                    ></textarea>
                                </div>

                            </div>
                        ))}
                    </div>
                    {displayLimit >= 30 && data.length > displayLimit && (
                        <div className="text-center m-t-20">
                            <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                            </button>
                        </div>
                    )}
                </div>
            </div>
            {
                saveMsgSt ?
                    <SaveMsgModal message={savePopupMsg} />
                    :
                    null
            }
        </div>
    );
}

export default AdminBuyItemPg
