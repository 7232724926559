import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {BiCaretDown} from "react-icons/bi";
import {BsTrash} from "react-icons/bs";
import reviewUpload from '../../assets/img/reviewUpload.png';
import {useNavigate} from "react-router-dom";
import withAuth from '../../auth/UserRouteAuth'
import {useTranslation} from "react-i18next";
import {useWebSocket} from "../../services/WebsocketContext";
import commomHeader from "../../assets/img/commonHeader.png";

function UserReviewWritePg() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');

    const [titleErrorMsg, setTitleErrorMsg] = useState(false);
    const [countryErrorMsg, setCountryErrorMsg] = useState(false);
    const [shipcoErrorMsg, setShipcoErrorMsg] = useState(false);
    const [reviewErrorMsg, setReviewErrorMsg] = useState(false);

    // 여기서부터 커스텀 셀렉트
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({ value: '', label: t(`ordCrLabel`)});
    const [countryShow, setCountryShow] = useState(false);
    const [filteredCountryList, setFilteredCountryList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const countryRef = useRef(null);

    const toggleCountryShow = () => {
        setCountryShow(prev => !prev);
    };

    const handleCountryChange = (country) => {
        setSelectedCountry(country);
        setSearchKeyword('');
        // setCountryShow(false);
        setCountryShow(true);
    };

    const countryNameMapping = {
        'USA': 'United States of America',
    };

    const handleCountrySearch = (event) => {
        const keyword = event.target.value;
        setSearchKeyword(keyword);

        const mappedKeyword = countryNameMapping[keyword.toUpperCase()] || keyword;

        const filteredCountries = countryList.filter(country =>
            country.label.toLowerCase().includes(mappedKeyword.toLowerCase())
        );

        setFilteredCountryList(filteredCountries);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (countryRef.current && !countryRef.current.contains(event.target)) {
                setCountryShow(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 커스텀 셀렉트

    const [memberInfo, setMemberInfo] = useState("");
    const findMember = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response)=> {
                setMemberInfo(response.data)
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        if (SSAT || LCAT) {
            findMember();
        }
    }, [SSAT, LCAT])

    // 웹소켓
    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!memberInfo.mbNum || isSubscribedRef.current) return;
            if (!stompClient && !isConnected) {
                await websocketConnect(memberInfo.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                isSubscribedRef.current = false;
            }
        };

    }, [memberInfo.mbNum, isConnected, stompClient]);

    const selectAllCountry = () => {
        axios.get('/order/selectCountry')
            .then(response => {
                const countries = response.data.map(country => ({
                    value: country.countryCode,
                    label: `${country.countryNm} (${country.countryEngNm})`
                })).sort((a, b) => a.label.localeCompare(b.label));

                setCountryList(countries);
                setFilteredCountryList(countries);
            })
            .catch(error => {
                console.error("Error:", error);
            });
    };

    useEffect(() => {
        selectAllCountry();
        bringToShipCo();
    }, [])

    const [shipcoList, setShipcoList] = useState([]); // DB데이터

    const [selectedShipco, setSelectedShipco] = useState('');

    const shipCode = "1"
    const bringToShipCo = () => axios({
        method: 'get',
        url: '/shipcompany/bringToShipCo/' + shipCode,
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        },
    })
        .then((response) => {
            setShipcoList(response.data)
        })

    // 여기서부터 커스텀 셀렉트
    const [shipcoShow, setShipcoShow] = useState(false);
    const shipcoRef = useRef(null);

    const toggleShipcoShow = () => {
        setShipcoShow(!shipcoShow);
    };

    const handleShipcoChange = (shipco) => {
        setSelectedShipco(shipco);
        setShipcoShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (shipcoRef.current && !shipcoRef.current.contains(event.target)) {
                setShipcoShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 커스텀 셀렉트


    const [titleText, setTitleText] = useState('');
    const handleChangeTitleText = (e) => {
        setTitleText(e.target.value);
    };

    const [reviewText, setReviewText] = useState('');
    const handleChangeReviewText = (e) => {
        setReviewText(e.target.value);
    };


    const [reviewContainers, setReviewContainers] = useState([{ reviewImg: reviewUpload, reviewImgSt: false, reviewImgError: false }]);
    const reviewImgHandle = (idx) => (e) => {
        const file = e.target.files[0];

        if (file) {
            setReviewContainers((prev) => {
                const newContainers = [...prev];

                if (!file.type.startsWith('image/')) {
                    newContainers[idx] = {
                        ...newContainers[idx],
                        reviewImgError: true,
                    };
                    return newContainers;
                } else {
                    newContainers[idx] = {
                        ...newContainers[idx],
                        reviewImg: URL.createObjectURL(file),
                        reviewImgSt: true,
                        reviewImgError: false,
                        file: file
                    };
                    if (idx === newContainers.length - 1) {
                        addForm(newContainers);
                    }
                    return newContainers;
                }
            });
        }
    };

    const reviewImgInit = (idx) => () => {
        setReviewContainers((prev) => {
            const newContainers = [...prev];

            newContainers.splice(idx, 1);

            const lastContainer = newContainers[newContainers.length - 1];
            if (lastContainer && lastContainer.reviewImgSt) {
                newContainers.push({ reviewImg: reviewUpload, reviewImgSt: false, reviewImgError: false });
            }

            return newContainers;
        });
        // formData.delete('reviewImg');
    };


    const addForm = (newContainers) => {
        const lastContainer = newContainers[newContainers.length - 1];
        if (!lastContainer.reviewImgSt) {
            return;
        }

        newContainers.push({ reviewImg: reviewUpload, reviewImgSt: false, reviewImgError: false });
    };

    const validation = () => {
        if (titleText === '' || !titleText) setTitleErrorMsg(true);
        if (selectedCountry.value === '' || !selectedCountry) setCountryErrorMsg(true);
        if (selectedShipco === '' || !selectedShipco) setShipcoErrorMsg(true);
        if (reviewText === '' || !reviewText) setReviewErrorMsg(true);
        return !!(titleText && selectedCountry && selectedShipco && reviewText)
    }

    const reviewSave = () => {

        const newFormData = new FormData();
        const crEngNm = selectedCountry.label.match(/\(([^)]+)\)/);

        newFormData.append('title', titleText);
        newFormData.append('country', crEngNm[1]);
        newFormData.append('shipco', selectedShipco);
        newFormData.append('reviewText', reviewText);

        reviewContainers.forEach((container, idx) => {
            if (container.file) {
                newFormData.append('reviewImg', container.file);
            }
        });

        return axios({
            method: 'post',
            url: '/review/reviewSave',
            data: newFormData,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then(async (response) => {
                saveReviewAlarmData(response.data);
                navigate(`/UserReviewDetailPg/${response.data.seqNum}`);
            });
    }

    const saveReviewAlarmData = ((reviewData) => {
        axios({
            method: 'post',
            url: '/order/saveReviewData',
            headers: {
                Authorization: LCAT ? LCAT : SSAT,
                'Content-Type': 'application/json',
            },
            data: reviewData
        })
            .then(async () => {
                await stompClient.send("/app/reviewAlarm", {}, JSON.stringify({sendFl:true}));
            })
            .catch((error) => {
                console.log("알림데이터 저장실패 : ", error)
            })
    })

    const valiAndSave = () => {
        if (validation()) {
            reviewSave();
        }
    }

    return (
        <div className="font-t-cont">
            <div style={{display:"flex", alignItems:"flex-start", padding:"10px 0"}}>
                <img className="wd-25 ht-25" src={commomHeader} alt="header"/>
                <p className="font-s-20 m-l-10 font-t-title font-c-default">{t(`rvWtHeader`)}</p> {/*이용후기작성*/}
            </div>
            <div>
                <div className="font-t-cont" style={{height: "10%", display: "flex"}}>
                <div style={{width: "65%", paddingRight: "5px"}}>
                    <div className="ReviewPgSearchFrame-new acc-icon">
                        <div className="ReviewPgInputForm" style={{width: "97%"}}>
                            <input className="ReviewInput user-input"
                                   style={{width: "100%", marginLeft: "10px", height: "100%"}} type="text"
                                   placeholder="" value={titleText} maxLength={35} onChange={handleChangeTitleText}/>
                            <label className="JoinLabel" style={{fontSize: "14px"}}>{t(`rvWtTp`)}</label>
                        </div>
                    </div>
                    { titleErrorMsg ?
                    <div className="JoinErrorMsg">
                        <p className="JoinErrMsg">&nbsp;{t(`rvWtErr3`)}</p>
                    </div>
                        :
                        null
                    }
                </div>
                {/*국가*/}
                <div style={{width: "20%", height: "60px", padding: "0 5px 0 5px"}}>
                    {/*여기서부터 커스텀 국가셀렉트*/}
                    <div ref={countryRef} className="ht-50 wd-100pc c-point position-relative m-b-20" onClick={toggleCountryShow}>
                        <div className="order-rec-country-input display-flex flex-row wd-100pc ht-100pc">
                            {selectedCountry.value ? (
                                <div className="wd-15pc text-center align-center-c">
                                    <div className={`m-l-6 font-s-20 fi fi-${selectedCountry.value.toLowerCase()}`} />
                                </div>
                            ) : (
                                <div className="wd-15pc text-center align-center-c p-l-5">
                                    <div className="wd-29 ht-22 text-center font-s-12 m-0-a country-select-flag">?</div>
                                </div>
                            )}
                            <input className="country-select-in-input wd-100pc ht-100pc c-point font-s-12 p-l-10"
                                   type="text" placeholder={t(`modSmallMenu3-1`)} autoComplete="off"
                                   value={searchKeyword || (selectedCountry.value ? selectedCountry.label : '') || (selectedCountry.value ? selectedCountry.label : '')}
                                   onChange={handleCountrySearch} />
                            <i className="wd-20pc text-center align-center-c font-s-20 p-b-2 font-c-darkgrey">
                                <BiCaretDown />
                            </i>
                        </div>
                        {countryShow && (
                            <div className="country-select-data wd-100pc position-absolute m-t-5">
                                {filteredCountryList.map((country, index) => (
                                    <div className="selectChildBt display-flex c-point p-t-10 p-b-10 p-l-5" key={index}
                                         onClick={() => handleCountryChange(country)}>
                                        <div className={`font-s-17 m-r-8 fi fi-${country.value.toLowerCase()}`}></div>
                                        <p className="font-s-15">{country.label}</p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    {/*여기까지 커스텀 국가셀렉트*/}
                    {countryErrorMsg ?
                        <div className="JoinErrorMsg">
                            <p className="JoinErrMsg">&nbsp;{t(`recCrErr`)}</p>
                        </div>
                        :
                        null
                    }
                </div>
                {/*배송사*/}
                <div style={{width: "15%", height: "50px", padding: "0 5px 0 5px"}}>
                    {/*여기서부터 커스텀 배송사셀렉트*/}
                    <div ref={shipcoRef} style={{height: "100%", width: "100%", position: "relative"}}>
                        <button className="selectParentBt" style={{width: "100%", height: "100%"}}
                                onClick={toggleShipcoShow}>
                            <p style={{margin: "0"}}>{selectedShipco === "" ? "이용배송사" : selectedShipco}</p>
                            <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                <i style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "100%"
                                }}><BiCaretDown/></i>
                            </span>
                        </button>
                        {shipcoShow ?
                            <div style={{
                                width:"100%",
                                border: "solid",
                                height: "166px",
                                borderWidth: "thin 1px",
                                borderColor: "lightgray",
                                position: "absolute",
                                top:"100%",
                                bottom:"100%",
                                zIndex:1,
                                marginTop: "5px",
                            }}>
                                {shipcoList.map((shipco, index) => (
                                    <div className="selectChildBt" key={index} value={shipco}
                                         onClick={() => handleShipcoChange(shipco)} style={{
                                        display: "flex", padding: "10px", justifyContent: "center",
                                        alignItems: "center", cursor: "pointer", fontSize: "14px",
                                    }}>{shipco}</div>
                                ))}
                            </div>
                            :
                            null
                        }
                    </div>
                    {/*여기까지 커스텀 배송사셀렉트*/}

                    { shipcoErrorMsg ?
                        <div className="JoinErrorMsg">
                            <p className="JoinErrMsg">&nbsp;{t(`recNoSelectOsdvErr`)}</p>
                        </div>
                        :
                        null
                    }

                </div>
            </div>
                <div style={{height: "auto", display: "flex", marginTop: "20px"}}>
                <div style={{width: "65%"}}>
                    <div className="ordChatInputContainer" style={{height: "100%", minHeight: "600px"}}>
                    <textarea
                        placeholder={t(`myCont`)}
                        spellCheck="false"
                        value={reviewText}
                        maxLength={10000}
                        style={{
                            borderRadius: "6px",
                            height: "100%",
                            width: "100%",
                            borderWidth: "thin 1px",
                            borderColor: "lightgray",
                            resize: "none",
                            overflow: "auto",
                            outline: "none",
                            color: "dimgrey",
                            padding: "18px",
                            fontSize: "14px",
                            ':focus': {
                                borderColor: "orange"
                            },
                        }} onChange={handleChangeReviewText}/>
                    </div>
                    <div className="JoinErrorMsg">
                        {reviewErrorMsg && <p className="JoinErrMsg">&nbsp;{t(`rvWtErr1`)}</p>}
                    </div>
                </div>
                <div style={{width: "35%", paddingLeft: "10px"}}>
                    {reviewContainers.map((container, idx) => (
                    <div key={idx} style={{
                        border: "solid",
                        height: "305px",
                        borderWidth: "thin 1px",
                        borderColor: "lightgray",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        {!container.reviewImgSt ?
                            <div style={{display: "block", textAlign:"center", cursor:"pointer"}} onClick={(e) => {
                                const parentDiv = e.target.parentNode;
                                const fileInput = parentDiv.querySelector('input[type="file"]');
                                if (fileInput) fileInput.click();
                            }}>
                                <input type="file" accept="image/*" style={{display: "none"}} onChange={reviewImgHandle(idx)}/>
                                <img alt="uploadImg" style={{
                                    width: "80px",
                                    height: "80px",
                                    padding: "10px",
                                    filter: "opacity(0.2) drop-shadow(0 0 0 gray)",
                                }} src={container.reviewImg}/>
                                <p style={{color: "rgba(112,112,109,0.67)", fontSize: "18px"}}>Click me !</p>
                                <div className="JoinErrorMsg">
                                    {container.reviewImgError && <p className="JoinErrMsg">&nbsp;{t(`rvWtErr2`)}😅</p>}
                                </div>
                            </div>
                            :
                            <div style={{width:"90%", display:"flex", height:"90%", position:"relative"}}>
                                <img alt="reviewImg" style={{
                                    display:"flex",
                                    justifyContent:"center",
                                    alignItems:"center",
                                    width:"100%",
                                    height:"100%"
                                }} src={container.reviewImg}/>
                                <button className="orderItemHandleBt" style={{
                                    position:"absolute",
                                    width: "40px",
                                    height: "40px",
                                    top:"85%",
                                    left:"90%",
                                    borderRadius:"4px",
                                    opacity:"0.6"
                                }} onClick={reviewImgInit(idx)}>
                                    <i style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        fontSize:"26px"
                                    }}><BsTrash/></i>
                                </button>
                            </div>
                        }
                    </div>
                        ))}

                </div>
            </div>
                <div style={{
                    height: "100px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "end"
                }}>
                    <button className="bt-btn-B wd-100 ht-45 font-s-15" onClick={valiAndSave}>{t(`rvdtBt`)}</button>
                </div>
            </div>

        </div>
    )
}

export default withAuth(UserReviewWritePg)
