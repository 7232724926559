import React, {useEffect} from "react";
import warnIcon from '../../../assets/img/warnIcon.png'
import complDown from '../../../assets/img/complete.png'
import {useTranslation} from "react-i18next";

function MsgCheckModal({
                           googleLoginErrorMsg,
                           googleLoginErrorClose,
                           naverLoginErrorMsg,
                           naverLoginErrorClose,
                           editMstDeleteModal,
                           deleteMaster,
                           editDtDeleteModal,
                           deleteDetail,
                           deleteMstClose,
                           deleteDtClose,
                           nUserChatRemoveSt,
                           nUserChatRemoveClose,
                           nUserChatRemove,
                           chatConnectFailSt,
                           setChatConnectFailSt,
                           deleteMacroMaster,
                           deleteMacroDetail,
                           deleteMacroDtClose,
                           deleteMacroMstClose,
                           editMacroMstDeleteModal,
                           editMacroDtDeleteModal,
                           dontTrackingShipcoSt,
                           closeDontTrackingShipco,
                           downNoSelectSt,
                           closeDownNoSelect,
                           orderDivideMergeSt,
                           moveToMyPg,
                           dpPayNullSt,
                           closeDpPayNullSt,
                           returnNullSt,
                           closeReturnNullSt,
                           allUpdateProdNullSt,
                           closeAllUpdateProdNullSt,
                           payinfoSelectOrderErrSt,
                           closePayinfoSelectOrderErr,
                           payinfoSelectOrderErrMsg,
                           nonEmsDataSt,
                           closeNonEmsDataSt,
                           nonSelectOrderSt,
                           closeNonSelectOrderSt,
                           biggerCpPriceSt,
                           closeBiggerCpPriceSt,
                           commonError,
                           closeCommonError,
                           fileSizeOverSt,
                           closeFileSizeOverSt,
                           downloadFileComplSt,
                           closeDownloadFileCompl,
                           saveFailSt,
                           closeSaveFailSt,
                           selectedShipCrError,
                           closeSelectedShipCrError,
                           formUploadErrSt,
                           closeFormUploadErrSt
                       }) {

    const {t} = useTranslation();

    useEffect(() => {
        const handleEnterKey = (event) => {
            if (event.key === 'Enter') {
                if (googleLoginErrorMsg) {
                    googleLoginErrorClose();
                } else if (naverLoginErrorMsg) {
                    naverLoginErrorClose();
                } else if (editMstDeleteModal) {
                    deleteMaster();
                } else if (editDtDeleteModal) {
                    deleteDetail();
                } else if (editMacroMstDeleteModal) {
                    deleteMacroMaster();
                } else if (editMacroDtDeleteModal) {
                    deleteMacroDetail();
                } else if (nUserChatRemoveSt) {
                    nUserChatRemove();
                } else if (chatConnectFailSt) {
                    nUserChatRemove();
                } else if (dontTrackingShipcoSt) {
                    closeDontTrackingShipco();
                } else if (downNoSelectSt) {
                    closeDownNoSelect();
                } else if (orderDivideMergeSt) {
                    moveToMyPg();
                } else if (dpPayNullSt) {
                    closeDpPayNullSt();
                } else if (returnNullSt) {
                    closeReturnNullSt();
                } else if (allUpdateProdNullSt) {
                    closeAllUpdateProdNullSt();
                } else if (payinfoSelectOrderErrSt) {
                    closePayinfoSelectOrderErr();
                } else if (nonEmsDataSt) {
                    closeNonEmsDataSt();
                } else if (nonSelectOrderSt) {
                    closeNonSelectOrderSt();
                } else if (biggerCpPriceSt) {
                    closeBiggerCpPriceSt();
                } else if (commonError) {
                    closeCommonError();
                } else if (fileSizeOverSt) {
                    closeFileSizeOverSt();
                } else if (downloadFileComplSt) {
                    closeDownloadFileCompl();
                } else if (saveFailSt) {
                    closeSaveFailSt();
                } else if (selectedShipCrError) {
                    closeSelectedShipCrError();
                } else if (formUploadErrSt) {
                    closeFormUploadErrSt();
                }
            }
        };
        document.addEventListener('keydown', handleEnterKey);

        return () => {
            document.removeEventListener('keydown', handleEnterKey);
        };
    }, []);

    return (
        <div style={{position: "fixed", top: "0", left: "0", width: "100%", height: "100%", zIndex: 40,
            background: "rgba(0, 0, 0, 0.5)", display: "flex", justifyContent: "center", alignItems: "center"
        }}>
            <div className="modalFrame-lang-price-err border-rd6">
                <div style={{borderBottom: "solid 1px lightgray", height: "35%", display: "flex", padding: "10px"}}>
                    <div className="wd-20pc ht-100pc display-flex just-cont-center align-center">
                        <img className="wd-40 ht-40" alt='' src={downloadFileComplSt ? complDown : warnIcon}/>
                    </div>
                    <div className="font-t-title font-s-18 ht-100pc wd-80pc display-flex align-center">
                        {googleLoginErrorMsg || naverLoginErrorMsg ?
                            <p>{t(`mcmHeader1`)}</p>
                            :
                            editMstDeleteModal ?
                                <p>Delete Master</p>
                                :
                                editDtDeleteModal ?
                                    <p>Delete Detail</p>
                                    :
                                    editMacroMstDeleteModal ?
                                        <p>Delete Master</p>
                                        :
                                        editMacroDtDeleteModal ?
                                            <p>Delete Detail</p>
                                            :
                                            nUserChatRemoveSt ?
                                                <p>{t(`mcmHeader2`)}</p>
                                                :
                                                chatConnectFailSt ?
                                                    <p>{t(`mcmHeader3`)}</p>
                                                    :
                                                    dontTrackingShipcoSt ?
                                                        <p>{t(`mcmHeader4`)}</p>
                                                        :
                                                        downNoSelectSt ?
                                                            <p>{t(`mcmHeader5`)}</p>
                                                            :
                                                            orderDivideMergeSt ?
                                                                <p>{t(`modMdHd1`)}</p>
                                                                :
                                                                dpPayNullSt ?
                                                                    <p>Data Null Error</p>
                                                                    :
                                                                    returnNullSt ?
                                                                        <p>Data Null Error</p>
                                                                        :
                                                                        allUpdateProdNullSt ?
                                                                            <p>Data Null Error</p>
                                                                            :
                                                                            payinfoSelectOrderErrSt ?
                                                                                <p>Selection Error</p>
                                                                                :
                                                                                nonEmsDataSt ?
                                                                                    <p>Select Data Error</p>
                                                                                    :
                                                                                    nonSelectOrderSt ?
                                                                                        <p>No Select Error</p>
                                                                                        :
                                                                                        biggerCpPriceSt ?
                                                                                            <p>{t('modCUseBtErr1')}</p>
                                                                                            :
                                                                                            commonError ?
                                                                                                <p>Data Null Error</p>
                                                                                                :
                                                                                                fileSizeOverSt ?
                                                                                                    <p>{t('uploadSizeOverHd')}</p>
                                                                                                    :
                                                                                                    downloadFileComplSt ?
                                                                                                        <p>{t('downloadComplHd')}</p>
                                                                                                        :
                                                                                                        saveFailSt ?
                                                                                                            <p>Upload Fail</p>
                                                                                                            :
                                                                                                            selectedShipCrError ?
                                                                                                                <p>{t('ciModalHd')}</p>
                                                                                                                :
                                                                                                                formUploadErrSt ?
                                                                                                                    <p>{t('formErrHd')}</p>
                                                                                                                    :
                                                                                                    null
                        }
                    </div>
                </div>
                <div className="font-t-cont font-s-17 display-flex align-center" style={{height: "30%", padding: "30px"}}>
                    {googleLoginErrorMsg || naverLoginErrorMsg ?
                        <p>{t(`mcmCont1`)}</p>
                        :
                        editMstDeleteModal ?
                            <p>하위 Detail 데이터들까지 삭제됩니다</p>
                            :
                            editDtDeleteModal ?
                                <p>삭제 시 복구가 불가합니다</p>
                                :
                                editMacroMstDeleteModal ?
                                    <p>하위 Detail 데이터들까지
                                        삭제됩니다</p>
                                    :
                                    editMacroDtDeleteModal ?
                                        <p>삭제 시 복구가
                                            불가합니다</p>
                                        :
                                        nUserChatRemoveSt ?
                                            <p>{t(`mcmCont2`)}</p>
                                            :
                                            chatConnectFailSt ?
                                                <p>{t(`mcmCont3`)}</p>
                                                :
                                                dontTrackingShipcoSt ?
                                                    <p>{t(`mcmCont4`)}</p>
                                                    :
                                                    downNoSelectSt ?
                                                        <p>{t(`mcmCont5`)}</p>
                                                        :
                                                        orderDivideMergeSt ?
                                                            <p>{t(`modMdMsg1`)}</p>
                                                            :
                                                            dpPayNullSt ?
                                                                <p>결제하실 데이터를 선택해주세요</p>
                                                                :
                                                                allUpdateProdNullSt ?
                                                                    <p>입고처리하실 데이터를 선택해주세요</p>
                                                                    :
                                                                    payinfoSelectOrderErrSt ?
                                                                        <p>배송료타입만 선택해주세요</p>
                                                                        :
                                                                        nonEmsDataSt ?
                                                                            <p>EMS 데이터만 선택해주세요</p>
                                                                            :
                                                                            nonSelectOrderSt ?
                                                                                <p>데이터를 선택해주세요</p>
                                                                                :
                                                                                biggerCpPriceSt ?
                                                                                    <p>{t('modCUseBtErr2')}</p>
                                                                                    :
                                                                                    commonError ?
                                                                                        <p>{t('recInfoNullErr')}</p>
                                                                                        :
                                                                                        fileSizeOverSt ?
                                                                                            <p>{t('uploadSizeOverCont')}</p>
                                                                                            :
                                                                                            downloadFileComplSt ?
                                                                                                <p>{t('downloadComplCont')}</p>
                                                                                                :
                                                                                                saveFailSt ?
                                                                                                    <p>업로드가 실패되었습니다</p>
                                                                                                    :
                                                                                                    selectedShipCrError ?
                                                                                                        <p>{t('ciModalCont')}</p>
                                                                                                        :
                                                                                                        formUploadErrSt ?
                                                                                                            <p>{t('formErrCont')}</p>
                                                                                                            :
                                                                                            null
                    }
                </div>
                <div style={{
                    height: "35%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    padding: "10px"
                }}>
                    {googleLoginErrorMsg ?
                        <button onClick={googleLoginErrorClose} className="orderItemHandleBt" style={{
                            width: "90px",
                            height: "45px",
                            fontSize: "15px",
                            borderRadius: "4px",
                            padding: "0 5px"
                        }}>{t(`st6`)}</button>
                        :
                        naverLoginErrorMsg ?
                            <button onClick={naverLoginErrorClose} className="orderItemHandleBt" style={{
                                width: "90px",
                                height: "45px",
                                fontSize: "15px",
                                borderRadius: "4px",
                                padding: "0 5px",
                            }}>확인</button>
                            :
                            editMstDeleteModal ?
                                <div style={{display: "flex"}}>
                                    <button onClick={() => deleteMstClose(false)} className="ReviewPgWriteBtn" style={{
                                        width: "70px",
                                        height: "45px",
                                        fontSize: "14px",
                                        borderWidth: "thin 1px",
                                        borderRadius: "4px",
                                        marginLeft: "5px",
                                        padding: "0 10px"
                                    }}>Cancel
                                    </button>
                                    <button onClick={deleteMaster} className="orderItemHandleBt" style={{
                                        width: "70px",
                                        height: "45px",
                                        fontSize: "15px",
                                        borderRadius: "4px",
                                        padding: "0 10px"
                                    }}>Go
                                    </button>
                                </div>
                                :
                                editDtDeleteModal ?
                                    <div style={{display: "flex"}}>
                                        <button onClick={() => deleteDtClose(false)} className="ReviewPgWriteBtn"
                                                style={{
                                                    width: "70px",
                                                    height: "45px",
                                                    fontSize: "14px",
                                                    borderWidth: "thin 1px",
                                                    borderRadius: "4px",
                                                    marginLeft: "5px"
                                                }}>Cancel
                                        </button>
                                        <button onClick={deleteDetail} className="orderItemHandleBt" style={{
                                            width: "70px",
                                            height: "45px",
                                            fontSize: "15px",
                                            borderRadius: "4px"
                                        }}>Go
                                        </button>
                                    </div>
                                    :
                                    editMacroMstDeleteModal ?
                                        <div style={{display: "flex"}}>
                                            <button onClick={() => deleteMacroMstClose(false)}
                                                    className="ReviewPgWriteBtn" style={{
                                                width: "70px",
                                                height: "45px",
                                                fontSize: "14px",
                                                borderWidth: "thin 1px",
                                                borderRadius: "4px",
                                                marginLeft: "5px"
                                            }}>Cancel
                                            </button>
                                            <button onClick={deleteMacroMaster} className="orderItemHandleBt" style={{
                                                width: "70px",
                                                height: "45px",
                                                fontSize: "15px",
                                                borderRadius: "4px",
                                            }}>Go
                                            </button>
                                        </div>
                                        :
                                        editMacroDtDeleteModal ?
                                            <div style={{display: "flex"}}>
                                                <button onClick={() => deleteMacroDtClose(false)}
                                                        className="ReviewPgWriteBtn" style={{
                                                    width: "70px",
                                                    height: "45px",
                                                    fontSize: "14px",
                                                    borderWidth: "thin 1px",
                                                    borderRadius: "4px",
                                                    marginLeft: "5px"
                                                }}>Cancel
                                                </button>
                                                <button onClick={deleteMacroDetail} className="orderItemHandleBt"
                                                        style={{
                                                            width: "70px",
                                                            height: "45px",
                                                            fontSize: "15px",
                                                            borderRadius: "4px",
                                                        }}>Go
                                                </button>
                                            </div>
                                            :
                                            nUserChatRemoveSt ?
                                                <div style={{display: "flex"}}>
                                                    <button onClick={() => nUserChatRemoveClose(false)}
                                                            className="ReviewPgWriteBtn" style={{
                                                        width: "70px",
                                                        height: "45px",
                                                        fontSize: "14px",
                                                        borderWidth: "thin 1px",
                                                        borderRadius: "4px",
                                                        marginLeft: "5px"
                                                    }}>{t(`st3`)}
                                                    </button>
                                                    <button onClick={nUserChatRemove} className="orderItemHandleBt"
                                                            style={{
                                                                width: "90px",
                                                                height: "45px",
                                                                fontSize: "15px",
                                                                borderRadius: "4px",
                                                                padding: "0 5px"
                                                            }}>{t(`st4`)}</button>
                                                </div>
                                                :
                                                chatConnectFailSt ?
                                                    <div style={{display: "flex"}}>
                                                        <button onClick={() => setChatConnectFailSt(false)}
                                                                className="ReviewPgWriteBtn" style={{
                                                            width: "70px",
                                                            height: "45px",
                                                            fontSize: "14px",
                                                            borderWidth: "thin 1px",
                                                            borderRadius: "4px",
                                                            marginLeft: "5px"
                                                        }}>{t(`st3`)}
                                                        </button>
                                                        <button onClick={nUserChatRemove} className="orderItemHandleBt"
                                                                style={{
                                                                    width: "90px",
                                                                    height: "45px",
                                                                    fontSize: "15px",
                                                                    borderRadius: "4px",
                                                                    padding: "0 5px"
                                                                }}>{t(`st5`)}</button>
                                                    </div>
                                                    :
                                                    dontTrackingShipcoSt ?
                                                        <button onClick={closeDontTrackingShipco}
                                                                className="orderItemHandleBt" style={{
                                                            width: "90px",
                                                            height: "45px",
                                                            fontSize: "15px",
                                                            borderRadius: "4px",
                                                            padding: "0 5px"
                                                        }}>{t(`st6`)}</button>
                                                        :
                                                        downNoSelectSt ?
                                                            <button onClick={closeDownNoSelect}
                                                                    className="orderItemHandleBt" style={{
                                                                width: "90px",
                                                                height: "45px",
                                                                fontSize: "15px",
                                                                borderRadius: "4px",
                                                                padding: "0 5px"
                                                            }}>{t(`st6`)}</button>
                                                            :
                                                            orderDivideMergeSt ?
                                                                <button onClick={moveToMyPg}
                                                                        className="orderItemHandleBt" style={{
                                                                    width: "90px",
                                                                    height: "45px",
                                                                    fontSize: "15px",
                                                                    borderRadius: "4px",
                                                                    padding: "0 5px"
                                                                }}>{t(`st6`)}</button>
                                                                :
                                                                dpPayNullSt ?
                                                                    <button onClick={closeDpPayNullSt}
                                                                            className="orderItemHandleBt" style={{
                                                                        width: "75px",
                                                                        height: "40px",
                                                                        fontSize: "15px",
                                                                        borderRadius: "4px",
                                                                        padding: "0 5px"
                                                                    }}>Check</button>
                                                                    :
                                                                    dpPayNullSt ?
                                                                        <button onClick={closeReturnNullSt}
                                                                                className="orderItemHandleBt" style={{
                                                                            width: "75px",
                                                                            height: "40px",
                                                                            fontSize: "15px",
                                                                            borderRadius: "4px",
                                                                            padding: "0 5px"
                                                                        }}>Check</button>
                                                                        :
                                                                        allUpdateProdNullSt ?
                                                                            <button onClick={closeAllUpdateProdNullSt}
                                                                                    className="orderItemHandleBt"
                                                                                    style={{
                                                                                        width: "75px",
                                                                                        height: "40px",
                                                                                        fontSize: "15px",
                                                                                        borderRadius: "4px",
                                                                                        padding: "0 5px"
                                                                                    }}>Confirm</button>
                                                                            :
                                                                            payinfoSelectOrderErrSt ?
                                                                                <button
                                                                                    onClick={closePayinfoSelectOrderErr}
                                                                                    className="orderItemHandleBt"
                                                                                    style={{
                                                                                        width: "75px",
                                                                                        height: "40px",
                                                                                        fontSize: "15px",
                                                                                        borderRadius: "4px",
                                                                                        padding: "0 5px"
                                                                                    }}>Confirm</button>
                                                                                :
                                                                                nonEmsDataSt ?
                                                                                    <button onClick={closeNonEmsDataSt}
                                                                                            className="orderItemHandleBt"
                                                                                            style={{
                                                                                                width: "75px",
                                                                                                height: "40px",
                                                                                                fontSize: "15px",
                                                                                                borderRadius: "4px",
                                                                                                padding: "0 5px"
                                                                                            }}>Check</button>
                                                                                    :
                                                                                    nonSelectOrderSt ?
                                                                                        <button
                                                                                            onClick={closeNonSelectOrderSt}
                                                                                            className="orderItemHandleBt"
                                                                                            style={{
                                                                                                width: "75px",
                                                                                                height: "40px",
                                                                                                fontSize: "15px",
                                                                                                borderRadius: "4px",
                                                                                                padding: "0 5px"
                                                                                            }}>Check</button>
                                                                                        :
                                                                                        biggerCpPriceSt ?
                                                                                            <button
                                                                                                onClick={closeBiggerCpPriceSt}
                                                                                                className="orderItemHandleBt"
                                                                                                style={{
                                                                                                    width: "75px",
                                                                                                    height: "40px",
                                                                                                    fontSize: "15px",
                                                                                                    borderRadius: "4px",
                                                                                                    padding: "0 5px"
                                                                                                }}>Check</button>
                                                                                            :
                                                                                            commonError ?
                                                                                                <button
                                                                                                    onClick={closeCommonError}
                                                                                                    className="orderItemHandleBt"
                                                                                                    style={{
                                                                                                        width: "90px",
                                                                                                        height: "45px",
                                                                                                        fontSize: "15px",
                                                                                                        borderRadius: "4px",
                                                                                                        padding: "0 5px"
                                                                                                    }}>{t(`st6`)}</button>
                                                                                                :
                                                                                                fileSizeOverSt ?
                                                                                                    <button
                                                                                                        onClick={closeFileSizeOverSt}
                                                                                                        className="orderItemHandleBt"
                                                                                                        style={{
                                                                                                            width: "90px",
                                                                                                            height: "45px",
                                                                                                            fontSize: "15px",
                                                                                                            borderRadius: "4px",
                                                                                                            padding: "0 5px"
                                                                                                        }}>{t(`st6`)}</button>
                                                                                                    :
                                                                                                    downloadFileComplSt ?
                                                                                                        <button
                                                                                                            onClick={closeDownloadFileCompl}
                                                                                                            className="orderItemHandleBt"
                                                                                                            style={{
                                                                                                                width: "90px",
                                                                                                                height: "45px",
                                                                                                                fontSize: "15px",
                                                                                                                borderRadius: "4px",
                                                                                                                padding: "0 5px"
                                                                                                            }}>{t(`st6`)}</button>
                                                                                                        :
                                                                                                        saveFailSt ?
                                                                                                            <button
                                                                                                                onClick={closeSaveFailSt}
                                                                                                                className="orderItemHandleBt"
                                                                                                                style={{
                                                                                                                    width: "90px",
                                                                                                                    height: "45px",
                                                                                                                    fontSize: "15px",
                                                                                                                    borderRadius: "4px",
                                                                                                                    padding: "0 5px"
                                                                                                                }}>Check</button>
                                                                                                            :
                                                                                                            selectedShipCrError ?
                                                                                                                <button
                                                                                                                    onClick={closeSelectedShipCrError}
                                                                                                                    className="orderItemHandleBt"
                                                                                                                    style={{
                                                                                                                        width: "90px",
                                                                                                                        height: "45px",
                                                                                                                        fontSize: "15px",
                                                                                                                        borderRadius: "4px",
                                                                                                                        padding: "0 5px"
                                                                                                                    }}>Check</button>
                                                                                                                :
                                                                                                                formUploadErrSt ?
                                                                                                                    <button
                                                                                                                        onClick={closeFormUploadErrSt}
                                                                                                                        className="orderItemHandleBt"
                                                                                                                        style={{
                                                                                                                            width: "90px",
                                                                                                                            height: "45px",
                                                                                                                            fontSize: "15px",
                                                                                                                            borderRadius: "4px",
                                                                                                                            padding: "0 5px"
                                                                                                                        }}>Check</button>
                                                                                                                    :
                                                                                                    null
                    }
                </div>
            </div>
        </div>
    )
}

export default MsgCheckModal
