import React, {useEffect, useRef, useState} from 'react';
import '../../css/web/AdminOrderChatPg.css'
import AdminProfileBase from "../../assets/img/AdminProfileBase.jpg";
import {IoSettingsOutline, IoStar, IoStarOutline} from "react-icons/io5";
import {AiOutlinePaperClip} from "react-icons/ai";
import {MdInsertEmoticon} from "react-icons/md";
import {IoIosSend} from "react-icons/io";
import Picker from '@emoji-mart/react'
import axios from "axios";
import {BsBookmarkStar} from "react-icons/bs";
import chatFile from "../../assets/img/chatFile.png";
import chatStart from "../../assets/img/chatStart.png";
import adminChatStart from "../../assets/img/adminChatStart.png";
import adminParent from "../../assets/img/adminParent.png";
import adminChild from "../../assets/img/adminChild.png";
import adminList from "../../assets/img/adminList.png";
import adminNew from "../../assets/img/adminNew.png";
import adminEdit from "../../assets/img/adminEdit.png";
import adminCounselor from "../../assets/img/mainChatPeople.png";
import adminMacro from "../../assets/img/adminMacro.png";
import commonBottom from "../../assets/img/commonBottom.png";
import commonExchange from "../../assets/img/commonExchange.png";
import commonList from "../../assets/img/commonList.png";
import trash from "../../assets/img/mainChatTrash.png";

import AdminSearchEvent from "../../components/web/layoutsadmin/AdminSearchEvent";
import AdminYmdEvent from "../../components/web/layoutsadmin/AdminYmdEvent";
import chatFileImg from "../../assets/img/chatFolderImg.png";
import Linkify from "react-linkify";
import DOMPurify from "dompurify";
import {BiCaretDown} from "react-icons/bi";
import AdminMasterAdd from "../../components/web/modal/AdminMasterAdd";
import MsgCheckModal from "../../components/web/modal/MsgCheckModal";
import profileDefaultImg from "../../assets/img/profileDefaultImg.png";
import profileImage from "../../assets/img/profileDefaultImg.png";
import mainChatSend from "../../assets/img/mainChatSend.png";
import mainChatEmoji from "../../assets/img/mainChatEmoji.png";
import mainChatUpload from "../../assets/img/mainChatUpload.png";
import ChatFileUpload from "../../components/web/modal/ChatFileUpload";
import ChatFileDown from "../../components/web/modal/ChatFileDown";
import ChatModifyModal from "../../components/web/modal/ChatModifyModal";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import maCateImg from "../../assets/img/m-a-cate.png";
import {useWebSocket} from "../../services/WebsocketContext";

function AdminOrderChatPg() {

    /** region DataTable */

    const [macroData, setMacroData] = useState([]);
    const [adminInfo, setAdminInfo] = useState([]);
    const LCAT = localStorage.getItem('adminToken');

    /** endregion */

    /** region Function */

    const [showEmoji, setShowEmoji] = useState(false);
    const [macroOpen, setMacroOpen] = useState(false);
    const [favFn, setFavFn] = useState(false);

    const toggleMenu = () => {
        setFavFn(!favFn);
    };

    //region DateSelect

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const startHandle = (e) => {
        setStartDate(e.target.value);
    };
    const endHandle = (e) => {
        setEndDate(e.target.value);
    };
    const stringStartDate = startDate.replace(/-/g, '');
    const stringEndDate = endDate.replace(/-/g, '');

    const currentDate = new Date();
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(currentDate.getDate() - 180); //6개월

    const defaultMonth = () => {
        const stringStartDate = formatDateChat(twoWeeksAgo);
        const stringEndDate = formatDateChat(currentDate);
        setStartDate(stringStartDate);
        setEndDate(stringEndDate);
    }

    const formatDateChat = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    //endregion DateSelect

    //region Search

    const [searchInput, setSearchInput] = useState('');
    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    //endregion

    const [selectedUserName, setSelectedUserName] = useState(null);
    const [selectedUserOrderNumbers, setSelectedUserOrderNumbers] = useState([]);
    const [focusedIndex, setFocusedIndex] = useState(null);
    const [focusedOrderIndex, setFocusedOrderIndex] = useState(null);
    const [chatStarted, setChatStarted] = useState(false);
    const [mstMbNum, setMstMbNum] = useState(null);
    const [mstUserNm, setMstUserNm] = useState(null);

    const handleUserNameClick = (data, index) => {
        selectedOrderNumbers(data.mstMbNum);
        setSelectedUserName(data.memberDTO.userName);
        setFocusedIndex(index);
        setFocusedOrderIndex(null);
    };

    const selectedOrderNumbers = ((mstMbNum) => {
        axios({
            method: 'get',
            url: '/order/orderNumbers/',
            params: {
                mstMbNum: mstMbNum,
                startDate: stringStartDate,
                endDate: stringEndDate
            },
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                const sortData = response.data.sort((a, b) => b.seqnum - a.seqnum);
                setSelectedUserOrderNumbers(sortData)
            })
    })

    const [selectedOrderNumber, setSelectedOrderNumber] = useState(null);

    const handleOrderNumberlick = (orderNumber, index) => {
        handleChatMessages(orderNumber)
        setFocusedOrderIndex(index);
        setMstMbNum(orderNumber.mstMbNum);
        setMstUserNm(orderNumber.mstUserNm);
        setSelectedOrderNumber(orderNumber.chatOrdNum);
        setChatStarted(true);
    };

    /** endregion */

    /** region Event */

    function macroViewOpen() {
        setMacroOpen(!macroOpen);
    }

    //region Chatting

    const findAdmin = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setAdminInfo(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    })

    // 주문서채팅 시작
    const [memberList, setMemberList] = useState([]);
    const [message, setMessage] = useState('');
    const [chatMessages, setChatMessages] = useState([]);
    const [userProfileImg, setUserProfileImg] = useState(profileImage);
    const [selectedFileUrl, setSelectedFileUrl] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showChatFile, setShowChatFile] = useState(false);

    const messagesEndRef = useRef(null);
    const fileInputRef = useRef();

    useEffect(() => {
        findAdmin();
    }, []);

    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!adminInfo.mbNum || isSubscribedRef.current) return;

            if (!stompClient && !isConnected) {
                await websocketConnect(adminInfo.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                // 주문서채팅
                subscribe(`/topic/userOrdMessage`, onMessageReceived);
                subscribe(`/topic/userOrdChatRead`, onReadMessageReceived);
                subscribe(`/topic/adminChatUpdate`, onChatAdminUpdate);
                // 비회원채팅
                subscribe(`/topic/nonUserMessage`, nonOnMessageReceived);
                subscribe(`/topic/nonUserChatRead`, nonOnReadMessageReceived);
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                unsubscribe(`/topic/userOrdMessage`)
                unsubscribe(`/topic/userOrdChatRead`)
                unsubscribe(`/topic/adminChatUpdate`)
                unsubscribe(`/topic/nonUserMessage`)
                unsubscribe(`/topic/nonUserChatRead`)
                isSubscribedRef.current = false;
            }
        };

    }, [adminInfo.mbNum, isConnected, stompClient]);

    const selectedOrderNumberRef = useRef(selectedOrderNumber);
    const chatStartedRef = useRef(chatStarted);
    const mstUserNmRef = useRef(mstUserNm);
    const selectedOrderNumbersRef = useRef(selectedOrderNumbers);

    // 함수 내 비동기로 인해 미리 값 세팅
    useEffect(() => {
        selectedOrderNumberRef.current = selectedOrderNumber;
        chatStartedRef.current = chatStarted;
        mstUserNmRef.current = mstUserNm;
        selectedOrderNumbersRef.current = selectedOrderNumber
    }, [selectedOrderNumber, chatStarted, mstUserNm, selectedOrderNumber]);

    const onMessageReceived = async (payload) => {
        let newMessage = JSON.parse(payload.body);
        if (newMessage.chatKey.startsWith('chatFile')) {
            const filename = newMessage.chatKey.split('/').pop();
            if (isVideo(filename)) {
                newMessage.isVideo = true;
            } else if (isImage(filename)) {
                newMessage.isImage = true;
            } else {
                newMessage.isFile = true;
            }
        }


        // // new 메시지로 챗리스트 전체 업뎃
        // if (newMessage.memberDTO.role === "0") {
        //     setChatMessages(chatMessages => [...chatMessages, newMessage]);
        //     const isDuplicate = chatMessages.some(message => message.seqnum === newMessage.seqnum);
        //     if (isDuplicate) return chatMessages;
        // }

        // // 중복방지
        // const isDuplicate = chatMessages && chatMessages.some(message => message.seqnum === newMessage.seqnum);
        // if (isDuplicate) return;
        //
        // // new 메시지로 챗리스트 전체 업데이트
        // if (newMessage.memberDTO.role === "0") { // 관리자공유로 인해 중복수신 없도록
        //     setChatMessages(chatMessages => [...chatMessages, newMessage]);
        // }

        // chatMessages가 배열인지 확인(not iterable 오류방지)
        setChatMessages(chatMessages => {
            const validChatMessages = Array.isArray(chatMessages) ? chatMessages : [];

            // 중복 메시지 체크
            const isDuplicate = validChatMessages.some(message => message.seqnum === newMessage.seqnum);
            if (isDuplicate) return validChatMessages; // 이미 메시지가 존재하면 추가하지 않음

            // 새로운 메시지 추가
            return [...validChatMessages, newMessage];
        });

        // memberList 업데이트
        setMemberList(prevMembers => {
            let existingMemberIndex = prevMembers.findIndex(member => String(member.mstMbNum) === String(newMessage.mstMbNum));
            const isUserRole = newMessage.memberDTO && newMessage.memberDTO.role === '0';
            // 첫메시지일 경우에는 새롭게 memberList 출력업뎃
            if (existingMemberIndex === -1) {
                const newMember = {
                    mstMbNum: newMessage.mstMbNum,
                    mstUserNm: newMessage.mstUserNm,
                    seqnum: newMessage.seqnum,
                    unreadCount: isUserRole ? 1 : 0,
                    chatKey: newMessage.chatKey,
                    regTime: newMessage.regTime,
                    memberDTO: newMessage.memberDTO
                };
                return [...prevMembers, newMember].sort((a, b) => b.seqnum - a.seqnum);
            } else {
                // 기존 멤버리스트의 추가메시지만 업뎃
                return prevMembers.map((member, index) => {
                    if (index === existingMemberIndex) {
                        return {
                            ...member,
                            mstMbNum: newMessage.mstMbNum,
                            mstUserNm: newMessage.mstUserNm,
                            seqnum: newMessage.seqnum,
                            unreadCount: isUserRole ? member.unreadCount + 1 : member.unreadCount,
                            chatKey: newMessage.chatKey,
                            regTime: newMessage.regTime,
                            memberDTO: newMessage.memberDTO
                        };
                    }
                    return member;
                }).sort((a, b) => b.seqnum - a.seqnum);
            }
        });

        // 기존 오더리스트 메시지에서 new 오더리스트 메시지만 추가업뎃
        setSelectedUserOrderNumbers(prevOrderNumbers => {
            const updatedOrderNumbers = prevOrderNumbers.map(orderNumber => {
                if (orderNumber.chatOrdNum === newMessage.chatOrdNum) {
                    const isOrderRole = newMessage.memberDTO.role === '0';
                    return {
                        ...orderNumber,
                        seqnum: newMessage.seqnum,
                        // unreadCount: isOrderRole ? orderNumber.unreadCount + 1 : orderNumber.unreadCount,
                        unreadCount: isOrderRole ? newMessage.unreadCount : orderNumber.unreadCount,
                        chatKey: newMessage.chatKey
                    };
                }
                return orderNumber;
            });
            return updatedOrderNumbers.sort((a, b) => b.seqnum - a.seqnum);
        });

        // 새로 등록된 메시지의 chatOrdNum과 관리자가 선택한 chatDt.주문번호가 동일 && chatStarted가 true 두 조건 모두 충족할 경우 자동읽음처리
        if (newMessage.chatOrdNum === selectedOrderNumberRef.current && chatStartedRef.current) {
            handleChatMessages(newMessage);
            await stompAdminRead(newMessage.chatOrdNum, newMessage.mstUserNm, newMessage.mstMbNum);
        }
    }

    const onChatAdminUpdate = ((payload) => {
        let adminUpdateData = JSON.parse(payload.body);
        if (adminUpdateData !== null) {
            fetchChatMessages(adminUpdateData);
        }
    })

    const formatDate = (dateString) => {
        let options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
        return new Date(dateString).toLocaleDateString(undefined, options)
    }

    const isImage = (fileName) => {
        const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "webp"];
        const fileExtension = fileName.split('.').pop().toLowerCase();
        return imageExtensions.includes(fileExtension);
    }

    const isVideo = (filename) => {
        const extension = filename.split('.').pop().toLowerCase();
        return ['mp4', 'webm', 'ogg'].includes(extension);
    };

    const handleChatMessages = async (chat) => {
        try {
            await axios({
                method: 'post',
                url: `/order/chat/unread/`,
                params: {
                    chatOrdNum: chat.chatOrdNum,
                    userFl: false
                },
                headers: {
                    Authorization: LCAT
                }
            })
                .then(async () => {
                    await stompClient.send("/app/chat/totalCount", {}, JSON.stringify({sendFl: true}));
                    // chatDt 클릭 후 멤버상태변경
                    setMemberList((prevMembers) =>
                        prevMembers.map((member) => {
                            if (member.mstMbNum === chat.mstMbNum) {
                                return {
                                    ...member,
                                    unreadCount: member.unreadCount - chat.unreadCount,
                                };
                            }
                            return member;
                        })
                    );

                    // chatDt.unreadCount를 리셋
                    setSelectedUserOrderNumbers((prevChats) =>
                        prevChats.map((chatDt) => {
                            if (chatDt.seqnum === chat.seqnum) {
                                stompAdminRead(chatDt.chatOrdNum, chatDt.mstUserNm, chatDt.mstMbNum);
                                return {
                                    ...chatDt,
                                    unreadCount: 0,
                                };
                            }
                            return chatDt;
                        })
                    );
                })
        } catch (error) {
            console.error(error);
        }
    };

    const oneMonthFromNow = (deadline) => {
        return deadline.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
    }

    function isSameDay(date1, date2) {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    }

    function isFirstMessageOfDay(current, previous) {
        if (!previous) return true;
        const isSame = isSameDay(new Date(current.regTime), new Date(previous.regTime));
        return !isSame;
    }

    const formatTime = (timeString) => {
        return new Date(timeString).toTimeString().split(' ')[0];
    }

    const fetchChatMessages = async (chatOrdNum) => {
        if (!chatOrdNum) {
            return;
        }
        const response = await axios({
            method: 'get',
            url: '/order/chatFetch',
            headers: {
                Authorization: LCAT
            },
            params: {
                chatOrdNum: chatOrdNum
            }
        });
        if (!response || response.data === false) {
            console.log('데이터없음');
            return;
        }
        const data = response.data;

        for (let message of data) {
            if (message.chatKey.startsWith('chatFile')) {
                const filename = message.chatKey.split('/').pop();
                if (isVideo(filename)) {
                    message.fileUrl = "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/ordChatMessage/" + message.chatKey;
                    message.isVideo = true;
                } else if (isImage(filename)) {
                    message.fileUrl = "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/ordChatMessage/" + message.chatKey;
                    message.isImage = true;
                } else {
                    message.fileUrl = "file";
                }
            }
        }
        // setChatMessages((prevMessages) => {
        //     const newMessages = data.filter(
        //         (newMessage) => !prevMessages.some((message) => message.seqnum === newMessage.seqnum)
        //     );
        //     return [...prevMessages, ...newMessages];
        // });
        setChatMessages(data);
        return data;
    }

    useEffect(() => {
        fetchChatMessages(selectedOrderNumber && selectedOrderNumber)
            .then(data => setChatMessages(data))
            .catch(err => console.error(err));
    }, [selectedOrderNumber]);

    // 사용자 -> 관리자 읽음처리 수신
    const onReadMessageReceived = async (payload) => {
        // 사용자가 읽었을 경우 관리자채팅 안읽은메시지수 0으로 초기화
        let userReadData = JSON.parse(payload.body);
        if (userReadData.readerRole === "USER" && chatStartedRef.current) {
            await new Promise(resolve => setTimeout(resolve, 500));
            setChatMessages(currentChatMessages => {
                const updatedMessages = [...currentChatMessages];
                let hasUpdates = false;
                for (let message of updatedMessages) {
                    if (message.chatOrdNum === String(userReadData.chatOrdNum) && message.unreadCount > 0) {
                        message.unreadCount = 0;
                        hasUpdates = true;
                    }
                }
                return updatedMessages;
            })
        }
    };

    const stompAdminRead = (async (chatOrdNum, mstUserNm, mstMbNum) => {
        if (!stompClient) {
            return;
        }
        // 관리자 -> 사용자 읽음전송
        if (chatStartedRef.current && chatOrdNum && mstUserNm) {
            const readChat = {
                chatOrdNum: chatOrdNum,
                mstMbNum: mstMbNum,
                mstUserNm: mstUserNm,
                readerRole: "ADMIN",
            };
            await stompClient.send("/app/chat/adminRead", {}, JSON.stringify(readChat));
        }
    });

    const sendMessage = async (printFilename, originalFilename) => {
        if (stompClient && printFilename.trim() !== '') {
            if (selectedOrderNumber === null) {
                console.log("주문서가 선택되지 않았습니다.");
                return;
            }
            const chatMessage = {
                chatKey: printFilename,
                fileNm: originalFilename,
                chatOrdNum: selectedOrderNumber,
                mbNum: adminInfo.mbNum,
                mstMbNum: mstMbNum,
                mstUserNm: mstUserNm,
                memberDTO: adminInfo,
                sendMailFl: true,
                autoChatFl: false,
            };
            await stompClient.send("/app/chat/adminMessage", {}, JSON.stringify(chatMessage));

            // 관리자채팅에도 업데이트
            const newMessagePromise = new Promise((resolve) => {
                stompClient.subscribe(`/user/${mstMbNum}/queue/adminMessage`, (response) => {
                    const newMessage = JSON.parse(response.body);
                    resolve(newMessage);
                });
            });

            const newMessage = await newMessagePromise;
            updateMemberAndOrderNumber(newMessage)

        } else {
            console.log("메시지전송 실패");
        }
    };

    const updateMemberAndOrderNumber = ((newMessage) => {
        // new 메시지로 챗리스트 전체 업뎃
        setChatMessages(chatMessages => [...chatMessages, newMessage]);

        // 기존 멤버리스트 메시지에서 new 멤버리스트 메시지만 추가업뎃
        setMemberList(prevMembers => {
            const updatedMembers = prevMembers.map(member => {
                if (member.mstMbNum === newMessage.mstMbNum) {
                    return {
                        ...member,
                        seqnum: newMessage.seqnum,
                        unreadCount: member.unreadCount,
                        chatKey: newMessage.chatKey,
                        regTime: newMessage.regTime,
                        memberDTO: newMessage.memberDTO
                    };
                }
                return member;
            });
            return updatedMembers.sort((a, b) => b.seqnum - a.seqnum);
        });

        // 기존 오더리스트 메시지에서 new 오더리스트 메시지만 추가업뎃
        setSelectedUserOrderNumbers(prevOrderNumbers => {
            const updatedOrderNumbers = prevOrderNumbers.map(orderNumber => {
                if (orderNumber.chatOrdNum === newMessage.chatOrdNum) {
                    return {
                        ...orderNumber,
                        seqnum: newMessage.seqnum,
                        unreadCount: orderNumber.unreadCount,
                        chatKey: newMessage.chatKey
                    };
                }
                return orderNumber;
            });
            return updatedOrderNumbers.sort((a, b) => b.seqnum - a.seqnum);
        });
    })

    const handleFileUpload = (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            setSelectedFile(file);
            setSelectedFileUrl(URL.createObjectURL(file));
        }
    };

    const chatInputRef = React.useRef(null);
    const resizeTextarea = () => {
        const textArea = chatInputRef.current;
        const chatBody = messagesEndRef.current; // 채팅 바디의 ref를 추가합니다.
        const initialChatBodyHeight = 670; // 초기 채팅 바디 높이를 설정합니다.

        textArea.style.height = '42px';
        textArea.style.height = `${textArea.scrollHeight}px`;
        textArea.scrollTop = 0;

        // 채팅 바디의 높이를 조정합니다.
        const textAreaHeight = textArea.clientHeight;
        const chatBodyHeight = initialChatBodyHeight - (textAreaHeight - 42);
        chatBody.style.height = `${chatBodyHeight}px`;
        chatScrollBottom();
    };

    const handleChatInput = (e) => {
        setMessage(e.target.value);
    };

    useEffect(() => {
        if (message) {
            resizeTextarea();
        }
    }, [message]);

    useEffect(() => {
        chatScrollBottom();
    }, [chatMessages]);

    const chatScrollBottom = (() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTo({
                top: messagesEndRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    })


    const [fileSizeOverSt, setFileSizeOverSt] = useState(false);
    const closeFileSizeOverSt = (() => {
        setFileSizeOverSt(false);
    })

    const saveChatFile = async () => {
        if (!selectedFile || !selectedFile.size) {
            setShowChatFile(false);
            return;
        }
        const MAX_FILE_SIZE = 128 * 1024 * 1024; // 최대파일크기 128MB(백엔드에 맞추기)
        if (selectedFile.size > MAX_FILE_SIZE) {
            setShowChatFile(false);
            resetFileInput();
            setFileSizeOverSt(true);
            return;
        }
        const formData = new FormData();
        formData.append('file', selectedFile);
        axios({
            method: 'post',
            url: '/order/upload',
            headers: {
                Authorization: LCAT
            },
            data: formData
        })
            .then(async (response) => {
                const fileKeys = response.data;
                await sendMessage(fileKeys.printFilename, fileKeys.originalFilename);
                setChatStarted(true);
                setShowChatFile(false);
                resetFileInput();
            })
    };

    const downloadFile = async (filename, originalFilename, type) => {
        const response = await axios({
            method: 'get',
            url: `/order/chatFileDownload/${filename}`,
            params: {
                type: type
            },
            responseType: 'blob',
        });
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', originalFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setSelectedClickSt(false);
        openDownloadFileCompl();
    };
    const [downloadFileComplSt, setDownloadFileComplSt] = useState(false);
    const openDownloadFileCompl = (() => {
        setDownloadFileComplSt(true);
    })
    const closeDownloadFileCompl = (() => {
        setDownloadFileComplSt(false);
    })

    const showChatFileClose = (() => {
        setShowChatFile(false);
        resetFileInput();
    })

    const triggerFileInput = () => {
        fileInputRef.current.click();
        setShowChatFile(true);
    };

    const resetFileInput = () => {
        fileInputRef.current.value = "";
        setSelectedFile(null);
        setSelectedFileUrl(null);
    };

    const handleEmojiClick = (emoji) => {
        const newMessage = message + emoji.native;
        setMessage(newMessage);
        setShowEmoji(false);
    };

    const handleUpload = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
    };

    const [chatModifySt, setChatModifySt] = useState(false)
    const [chatFileModifySt, setChatFileModifySt] = useState(false)
    const [messageToModify, setMessageToModify] = useState(null);

    const chatModifyFalseFn = (() => {
        setChatModifySt(false);
    })
    const chatFileModifyFalseFn = (() => {
        setChatFileModifySt(false);
    })

    const openModifyModal = (message) => {
        setMessageToModify(message);
        if (message.chatKey.startsWith("chatFile")) {
            setChatFileModifySt(true);
        } else {
            setChatModifySt(true);
        }
    };

    const updateChatMessage = (modifiedMessage) => {
        const index = chatMessages.findIndex(msg => msg.seqnum === modifiedMessage.seqnum);
        const updatedChatMessages = [...chatMessages];
        updatedChatMessages[index] = modifiedMessage;
        setChatMessages(updatedChatMessages);
    };

    const chatFileModify = (changeFile, changeData) => {

        if (changeData.chatKey.startsWith('chatFile')) {
            const formData = new FormData();
            formData.append('changeFile', changeFile);
            formData.append('seqnum', changeData.seqnum);
            axios({
                method: 'post',
                url: '/order/chatFileModify/',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: LCAT
                },
            })
                .then(async (response) => {
                    const data = response.data;
                    const userInfo = {
                        userMbNum: data.mstMbNum,
                        userName: data.userName,
                        keyNumber: data.chatOrdNum,
                        sendFl: true
                    };
                    await updateChatMessage(data)
                    await onModifyMessageUpdate(data)
                    await stompClient.send("/app/chat/modifyMessage", {}, JSON.stringify(userInfo));
                    setChatFileModifySt(false);
                })
        } else {
            axios({
                method: 'post',
                url: '/order/chatFileModify/',
                params: {
                    seqnum: changeData.seqnum
                },

                data: changeFile,

                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: LCAT
                },
            })
                .then(async (response) => {
                    const data = response.data;
                    const userInfo = {
                        userMbNum: data.mstMbNum,
                        userName: data.userName,
                        keyNumber: data.chatOrdNum,
                        sendFl: true
                    };
                    await updateChatMessage(response.data)
                    await onModifyMessageUpdate(response.data)
                    await stompClient.send("/app/chat/modifyMessage", {}, JSON.stringify(userInfo));
                    setChatModifySt(false);
                })
        }
    };

    const onModifyMessageUpdate = ((modifyMessage) => {
        // 멤버리스트 채팅데이터 업뎃
        setMemberList(prevMembers => prevMembers.map(member => {
            if (member.mstMbNum === modifyMessage.mstMbNum) {
                return {
                    ...member,
                    ...modifyMessage,
                };
            }
            return member;
        }));

        // 오더리스트 채팅데이터 업뎃
        setSelectedUserOrderNumbers(prevOrderNumbers => prevOrderNumbers.map(orderNumber => {
            if (orderNumber.chatOrdNum === modifyMessage.chatOrdNum) {
                return {
                    ...orderNumber,
                    ...modifyMessage,
                };
            }
            return orderNumber;
        }));
    })

    const [chatDeleteSt, setChatDeleteSt] = useState(false);
    const chatDeleteFalseFn = (() => {
        setChatDeleteSt(false);
    })
    const chatDeleteMsg1 = "해당 채팅메시지를 삭제하시겠습니까?"
    const chatDeleteMsg2 = "삭제 시 복구가 불가능합니다"

    const openDeleteModal = ((chatData) => {
        if (chatData !== null) {
            setMessageToModify(chatData);
        }
        setChatDeleteSt(true);
    })

    const deleteChat = ((deleteMessage) => {
        axios({
            method: 'post',
            url: '/order/deleteChat/',
            params: {
                seqnum: deleteMessage.seqnum
            },
            headers: {
                Authorization: LCAT
            },
        })
            .then(async () => {
                const userInfo = {
                    userMbNum: deleteMessage.mstMbNum,
                    userName: deleteMessage.userName,
                    keyNumber: deleteMessage.chatOrdNum,
                    sendFl: true
                };
                await setChatMessages((prevChatMessages) => prevChatMessages.filter(message => message.seqnum !== deleteMessage.seqnum));
                await selectAllChatList();
                await selectedOrderNumbers(deleteMessage.mstMbNum);
                await stompClient.send("/app/chat/deleteMessage", {}, JSON.stringify(userInfo));
                setChatDeleteSt(false);
            })
    })

    const [selectedClickFile, setSelectedClickFile] = useState(null);
    const [selectedClickSt, setSelectedClickSt] = useState(false);
    const clickFileDetail = ((file) => {
        setSelectedClickFile(file);
        setSelectedClickSt(true);
    })
    const clickFileClose = (() => {
        setSelectedClickSt(false);
    })

    //endregion

    useEffect(() => {
        const timer = setTimeout(() => {
            defaultMonth();
            // selectAllData();
            selectAllChatList();
        })
        return () => {
            clearTimeout(timer);
        }
    }, [endDate])

    const selectAllChatList = (() => {
        axios({
            method: 'get',
            url: '/order/selectAllChatList',
            headers: {
                Authorization: LCAT
            },
            params: {
                startDate: stringStartDate,
                endDate: stringEndDate,
            },
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => b.seqnum - a.seqnum);
                setMemberList(sortedData)
            })
            .catch(() => {
                console.log("실패")
            })
    })

    const [ordChat, setOrdChat] = useState(true);
    const [nonMember, setNonMember] = useState(false);
    const [autoRes, setAutoRes] = useState(false);
    const [macro, setMacro] = useState(false);

    const handleTab = ((state) => {
        if (state === 'ordChat') {
            setOrdChat(true);
            setNonMember(false);
            setAutoRes(false);
            setMacro(false);
            setNonChatShow(false);
            setChatStarted(false);
        } else if (state === 'nonMember') {
            setNonMember(true);
            setAutoRes(false);
            setOrdChat(false);
            setMacro(false);
            setNonChatShow(false);
            setChatStarted(false);
        } else if (state === "autoRes") {
            setAutoRes(true);
            setOrdChat(false);
            setNonMember(false);
            setMacro(false);
            setNonChatShow(false);
            setChatStarted(false);
        } else {
            setAutoRes(false);
            setOrdChat(false);
            setNonMember(false);
            setMacro(true);
            setNonChatShow(false);
            setChatStarted(false);
        }
    })

    // 비회원채팅 시작
    const [selectNonMemberShow, setSelectNonMemberShow] = useState(false);
    const [selectNonMemberDate, setSelectNonMemberDate] = useState("1개월");
    const settingNonMemberPeriod = ["1개월", "3개월", "6개월", "1년", "2년"];

    const [showNonEmoji, setShowNonEmoji] = useState(false);
    const [nonUnreadCount, setNonUnreadCount] = useState(null);
    const [nonLastChatKey, setNonLastChatKey] = useState(null);
    const [nonLastFileKey, setNonLastFileKey] = useState(null);
    const [nonChatColor, setNonChatColor] = useState(false);
    const [nonMessage, setNonMessage] = useState('');
    const [nonChatShow, setNonChatShow] = useState(false);
    const [nonMemberList, setNonMemberList] = useState([]);
    const [nonChatMessages, setNonChatMessages] = useState([]);
    const [nonSelectedFileUrl, setNonSelectedFileUrl] = useState(null);
    const [nonSelectedFile, setNonSelectedFile] = useState(null);
    const [nonShowChatFile, setNonShowChatFile] = useState(false);

    const [nonSelectedMacroMstIndex, setNonSelectedMacroMstIndex] = useState(null);

    const nonFileInputRef = useRef();

    const [sendMsgNum, setSendMsgNum] = useState('');

    const selectNonMemberMsg = (() => {
        axios({
            method: 'get',
            url: '/member/selectNonMemberMsg',
            headers: {
                Authorization: LCAT
            },
            params: {
                dateParam: selectNonMemberDate
            },
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => b.seqnum - a.seqnum);
                setNonMemberList(sortedData)
            })
            .catch(() => {
                console.log("실패")
            })
    })

    const selectNonMacroList = () => axios({
        method: 'get',
        url: '/member/selectMacroList/',
        headers: {
            Authorization: LCAT
        },
    })
        .then((response) => {
            setMacroMstList(response.data)
        })

    useEffect(() => {
        if (nonMember || ordChat) {
            selectNonMacroList();
        }
    }, [nonMember, ordChat]);

    const fetchNonMacroDtList = ((index) => {
        if (nonSelectedMacroMstIndex === index) {
            setNonSelectedMacroMstIndex(null);
            setMacroDtList([]);
        } else {
            setNonSelectedMacroMstIndex(index);
            setMacroDtList(macroMstList[index].macroDt);
        }
    })

    const sendNonMacroMessage = (content) => {
        if (nonChatShow) {
            setNonMessage(content)
        }
    }

    const sendOrdMacroMessage = (content) => {
        if (chatStarted) {
            setMessage(content)
        }
    }

    // 관리자 비회원채팅 응대
    const nonSelectedOrderNumberRef = useRef(sendMsgNum);
    const nonChatShowRef = useRef(nonChatShow);
    const nonMemberListRef = useRef(nonMemberList);
    const nonChatMessagesRef = useRef(nonChatMessages);

    useEffect(() => {
        nonSelectedOrderNumberRef.current = sendMsgNum;
        nonChatShowRef.current = nonChatShow;
        nonMemberListRef.current = nonMemberList
        nonChatMessagesRef.current = nonChatMessages
    }, [sendMsgNum, nonChatShow, nonMemberListRef, nonChatMessages]);

    const nonOnMessageReceived = (payload) => {
        let newMessage = JSON.parse(payload.body);

        if (newMessage.chatKey.startsWith('chatFile')) {
            const filename = newMessage.chatKey.split('/').pop();
            if (isVideo(filename)) {
                newMessage.isVideo = true;
            } else if (isImage(filename)) {
                newMessage.isImage = true;
            } else {
                newMessage.isFile = true;
            }
        }

        // 새로 등록된 메시지의 msgNum과 관리자가 선택한 chatDt.msgNum이 동일 && nonChatShow가 true 두 조건 모두 충족할 경우 자동읽음처리
        if (newMessage.msgNum === nonSelectedOrderNumberRef.current && nonChatShowRef.current) {
            handleNonChatMessages(newMessage);
            setNonChatMessages(chatMessages => {
                const newChatMessages = [...chatMessages, newMessage];
                newChatMessages.sort((a, b) => a.seqnum - b.seqnum);
                return newChatMessages;
            })

            // 현재 선택된 좌측 nonMemberList 채팅메시지를 newMessage로 업데이트
            setNonMemberList(currentList => {
                const matchingIndex = currentList.findIndex(item => item.msgNum === newMessage.msgNum);
                if (matchingIndex !== -1) {
                    const updatedMessage = {...newMessage, unreadCount: 0};
                    const updatedList = [...currentList];
                    updatedList[matchingIndex] = updatedMessage;
                    return updatedList;
                }
                return currentList;
            });
        } else {
            // 현재 선택되지 않은 좌측 nonMemberList 채팅메시지를 newMessage로 업데이트
            setNonMemberList(currentList => {
                const matchingIndex = currentList.findIndex(item => item.msgNum === newMessage.msgNum);
                if (matchingIndex !== -1) {
                    const updatedMessage = {...newMessage, unreadCount: newMessage.unreadCount};
                    const updatedList = [...currentList];
                    updatedList[matchingIndex] = updatedMessage;
                    return updatedList;
                } else {
                    selectNonMemberMsg();
                    return currentList;
                }
            });
        }
    }

    useEffect(() => {
        selectNonMemberMsg();
    }, [selectNonMemberDate])

    const handleNonMemberPeriod = ((period) => {
        setSelectNonMemberDate(period);
        setSelectNonMemberShow(false);
    })

    const nonMessagesEndRef = useRef(null);

    const nonChatScrollBottom = (() => {
        if (nonMessagesEndRef.current) {
            nonMessagesEndRef.current.scrollTo({
                top: nonMessagesEndRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    })

    useEffect(nonChatScrollBottom, [nonChatMessages]);

    const nonDownloadFile = async (filename, originalFilename) => {
        const response = await axios({
            method: 'get',
            url: `/member/chatFileDownload/${filename}`,
            responseType: 'blob',
        });
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', originalFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setNonSelectedClickSt(false);
        openDownloadFileCompl();
    };

    const handleNonChatMessages = async (chat) => {
        setSendMsgNum(chat.msgNum)
        setNonChatShow(true);
        if (chat.unreadCount > 0 && chat.role === "1") {
            await axios({
                method: 'post',
                url: `/member/chat/unread/`,
                params: {
                    msgNum: chat.msgNum,
                },
                headers: {
                    Authorization: LCAT
                }
            })
                .then(async () => {
                    await stompClient.send("/app/chat/totalCount", {}, JSON.stringify({sendFl: true}));
                    setNonMemberList(prevNonMemberList => {
                        return prevNonMemberList.map(item => {
                            if (chat.msgNum === item.msgNum) {
                                nonStompAdminRead(chat.msgNum);
                                return {...item, unreadCount: 0};
                            }
                            return item;
                        });
                    });
                })
                .catch(error => console.error(error));
        }
    }

    const nonFetchChatMessages = async (msgNum, sendFl) => {
        if (!msgNum) {
            return;
        }
        try {
            const response = await axios({
                method: 'get',
                url: '/member/chatFetch',
                params: {
                    msgNum: msgNum
                }
            });
            if (!response || response.data === false) {
                console.log('데이터없음');
                return;
            }
            const data = response.data;
            // data.sort((a, b) => b.seqnum - a.seqnum);

            for (let nonMessage of data) {
                if (nonMessage.chatKey.startsWith('chatFile')) {
                    const filename = nonMessage.chatKey.split('/').pop();
                    if (isVideo(filename)) {
                        nonMessage.fileUrl = "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/nUserChatMsg/" + nonMessage.chatKey;
                        nonMessage.isVideo = true;
                    } else if (isImage(filename)) {
                        nonMessage.fileUrl = "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/nUserChatMsg/" + nonMessage.chatKey;
                        nonMessage.isImage = true;
                    } else {
                        nonMessage.fileUrl = "file";
                    }
                }
            }

            // 현재 선택된 좌측 nonMemberList 채팅메시지를 newMessage로 업데이트
            setNonMemberList(currentList => {
                const matchingIndex = currentList.findIndex(item => item.msgNum === msgNum);
                if (matchingIndex !== -1) {
                    const updatedMessage = {...data[data.length - 1], unreadCount: 0};
                    const updatedList = [...currentList];
                    updatedList[matchingIndex] = updatedMessage;
                    updatedList.sort((a, b) => b.seqnum - a.seqnum);
                    return updatedList;
                }
                return currentList;
            });
            setNonChatMessages(data);
            return data;
        } catch (error) {
            console.log("채팅페치오류 : ", error)
        }
    }

    useEffect(() => {
        if (sendMsgNum) {
            nonFetchChatMessages(sendMsgNum && sendMsgNum)
                .then(data => setNonChatMessages(data))
                .catch(err => console.error(err));
        }
    }, [sendMsgNum]);

    // 사용자 -> 관리자 읽음수신 코드
    const nonOnReadMessageReceived = (payload) => {
        // 사용자가 읽었을 경우 관리자채팅 안읽은메시지 수 '0'으로 초기화
        let userReadData = JSON.parse(payload.body);
        if (userReadData && userReadData.readerRole === "USER") {
            setNonChatMessages(currentChatMessages => {
                const updatedMessages = [...currentChatMessages];
                let hasUpdates = false;
                for (let nonMessage of updatedMessages) {
                    if (nonMessage.msgNum === String(userReadData.msgNum) && nonMessage.unreadCount > 0) {
                        nonMessage.unreadCount = 0;
                        hasUpdates = true;
                    }
                }
                return updatedMessages;
            })
        }
    };

    const nonSendMessage = async (printFilename, originalFilename, msgNum) => {
        if (stompClient && printFilename.trim() !== '') {
            const nonChatMessage = {
                chatKey: printFilename,
                fileNm: originalFilename,
                msgNum: msgNum,
                role: '0',
                autoFl: false
            };
            await stompClient.send("/app/nonUserChat/adminMessage", {}, JSON.stringify(nonChatMessage));
            await new Promise(resolve => setTimeout(resolve, 500));
            nonFetchChatMessages(msgNum, true);
        } else {
            console.log("메시지 전송실패")
        }
    };

    // 관리자 -> 사용자 읽음 전송
    const nonStompAdminRead = (msgNum) => {
        if (stompClient) {
            const readChat = {
                msgNum: msgNum,
                readerRole: "ADMIN",
            };
            stompClient.send("/app/nonUserChat/adminRead", {}, JSON.stringify(readChat));
        } else {
        }
    };

    const nonChatInputRef = React.useRef(null);
    const nonResizeTextArea = () => {
        const textArea = nonChatInputRef.current;
        const chatBody = nonMessagesEndRef.current; // 채팅 바디의 ref를 추가합니다.
        const initialChatBodyHeight = 561; // 초기 채팅 바디 높이를 설정합니다.

        textArea.style.height = '42px';
        textArea.style.height = `${textArea.scrollHeight}px`;
        textArea.scrollTop = 0;

        // 채팅 바디의 높이를 조정합니다.
        const textAreaHeight = textArea.clientHeight;
        const chatBodyHeight = initialChatBodyHeight - (textAreaHeight - 42);
        chatBody.style.height = `${chatBodyHeight}px`;
    };
    const nonHandleChatInput = (e) => {
        setNonMessage(e.target.value);
        nonResizeTextArea();
    };

    useEffect(() => {
        if (nonMessage) {
            nonResizeTextArea();
        }
    }, [nonMessage]);

    const saveNonChatFile = async () => {
        if (!nonSelectedFile || !nonSelectedFile.size) {
            setNonShowChatFile(false);
            return;
        }
        const MAX_FILE_SIZE = 128 * 1024 * 1024; // 최대파일크기 128MB(백엔드에 맞추기)
        if (nonSelectedFile.size > MAX_FILE_SIZE) {
            setNonShowChatFile(false);
            nonResetFileInput();
            setFileSizeOverSt(true);
            return;
        }
        const formData = new FormData();
        formData.append('file', nonSelectedFile);
        axios({
            method: 'post',
            url: '/member/upload',
            headers: {
                Authorization: LCAT
            },
            data: formData
        })
            .then(async (response) => {
                const fileKeys = response.data;
                await nonSendMessage(fileKeys.printFilename, fileKeys.originalFilename, sendMsgNum);
                setNonChatShow(true)
                setNonShowChatFile(false);
                nonResetFileInput();
            })
    };

    const nonTriggerFileInput = () => {
        nonFileInputRef.current.click();
        setNonShowChatFile(true);
    };

    const nonShowChatFileClose = (() => {
        nonResetFileInput();
        setNonShowChatFile(false);
    })

    const nonResetFileInput = () => {
        nonFileInputRef.current.value = "";
        setNonSelectedFile(null);
        setNonSelectedFileUrl(null);
    };

    const nonHandleFileUpload = (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            setNonSelectedFile(file);
            setNonSelectedFileUrl(URL.createObjectURL(file));
        }
    };

    const nonHandleEmojiClick = (emoji) => {
        const newMessage = nonMessage + emoji.native;
        setNonMessage(newMessage);
        setShowNonEmoji(false);
    };

    const [nonChatModifySt, setNonChatModifySt] = useState(false)
    const [nonChatFileModifySt, setNonChatFileModifySt] = useState(false)
    const [nonMessageToModify, setNonMessageToModify] = useState(null);

    const nonChatModifyFalseFn = (() => {
        setNonChatModifySt(false);
    })
    const nonChatFileModifyFalseFn = (() => {
        setNonChatFileModifySt(false);
    })

    const openNonModifyModal = (message) => {
        setNonMessageToModify(message);
        if (message.chatKey.startsWith("chatFile")) {
            setNonChatFileModifySt(true);
        } else {
            setNonChatModifySt(true);
        }
    };

    const updateNonChatMessage = (modifiedMessage) => {
        const index = nonChatMessages.findIndex(msg => msg.seqnum === modifiedMessage.seqnum);
        const updatedChatMessages = [...nonChatMessages];
        updatedChatMessages[index] = modifiedMessage;
        setNonChatMessages(updatedChatMessages);
    };

    const nonChatFileModify = (changeFile, changeData) => {
        if (changeData.chatKey.startsWith('chatFile')) {
            const formData = new FormData();
            formData.append('changeFile', changeFile);
            formData.append('seqnum', changeData.seqnum);
            axios({
                method: 'post',
                url: '/member/chatFileModify/',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: LCAT
                },
            })
                .then(async (response) => {
                    const data = response.data;
                    console.log("비회원 data : ", data)
                    const userInfo = {
                        keyNumber: data.msgNum,
                        sendFl: true
                    };
                    await updateNonChatMessage(data);
                    await onModifyNonUserChatUpdate(data);
                    await stompClient.send("/app/nonUserChat/modifyMessage", {}, JSON.stringify(userInfo));
                    setNonChatFileModifySt(false);
                })
        } else {
            axios({
                method: 'post',
                url: '/member/chatFileModify/',
                params: {
                    seqnum: changeData.seqnum
                },

                data: changeFile,

                headers: {
                    'Content-Type': 'text/plain',
                    Authorization: LCAT
                },
            })
                .then(async (response) => {
                    const data = response.data;
                    const userInfo = {
                        keyNumber: data.msgNum,
                        sendFl: true
                    };
                    await updateNonChatMessage(data);
                    await onModifyNonUserChatUpdate(data);
                    await stompClient.send("/app/nonUserChat/modifyMessage", {}, JSON.stringify(userInfo));
                    setNonChatModifySt(false);
                })
        }
    };

    const onModifyNonUserChatUpdate = ((modifyMessage) => {
        // 멤버리스트 채팅데이터 업뎃
        setNonMemberList(prevMembers => prevMembers.map(member => {
            if (member.seqnum === modifyMessage.seqnum) {
                return {
                    ...member,
                    ...modifyMessage,
                };
            }
            return member;
        }));
    })

    const [nonChatDeleteSt, setNonChatDeleteSt] = useState(false);
    const nonChatDeleteFalseFn = (() => {
        setNonChatDeleteSt(false);
    })

    const openNonDeleteModal = ((chatData) => {
        if (chatData !== null) {
            setNonMessageToModify(chatData);
        }
        setNonChatDeleteSt(true);
    })

    const deleteNonChat = ((deleteChat) => {
        axios({
            method: 'post',
            url: '/member/deleteChat/',
            params: {
                seqnum: deleteChat.seqnum
            },
            headers: {
                Authorization: LCAT
            },
        })
            .then(async () => {
                const userInfo = {
                    keyNumber: deleteChat.msgNum,
                    sendFl: true
                };
                await setNonChatMessages((prevChatMessages) => prevChatMessages.filter(message => message.seqnum !== deleteChat.seqnum));
                await selectNonMemberMsg();
                await stompClient.send("/app/nonUserChat/deleteMessage", {}, JSON.stringify(userInfo));
                nonChatDeleteFalseFn();
            })
    })

    const [nonSelectedClickFile, setNonSelectedClickFile] = useState(null);
    const [nonSelectedClickSt, setNonSelectedClickSt] = useState(false);

    const nonClickFileDetail = ((file) => {
        setNonSelectedClickFile(file);
        setNonSelectedClickSt(true);
    })
    const nonClickFileClose = (() => {
        setNonSelectedClickSt(false);
    })

    const convertToNonMemberDate = ((regDate) => {
        const regTime = new Date(regDate);
        const currentTime = new Date();
        const timeDifference = currentTime - regTime;

        // 날짜 차이를 분으로 변환
        const minutesDiff = Math.floor(timeDifference / 60000);
        const hoursDiff = Math.floor(minutesDiff / 60);
        const daysDiff = Math.floor(hoursDiff / 24);

        // 날짜 차이가 1일 미만일 때
        if (daysDiff < 1) {
            if (minutesDiff < 1) return "방금";
            if (minutesDiff < 60) return `${minutesDiff}분 전`;
            return `${hoursDiff}시간 전`;
        } else if (daysDiff === 1) {
            return "어제";
        } else if (daysDiff === 2) {
            return "2일 전";
        } else if (daysDiff < 7) {
            return `${daysDiff}일 전`;
        } else {
            // 7일 이상 차이날 때는 월-일 형식으로 반환
            return `${(regTime.getMonth() + 1).toString().padStart(2, '0')}-${regTime.getDate().toString().padStart(2, '0')}`;
        }
    })

    // 자동응답시작
    useEffect(() => {
        if (autoRes) {
            selectAutoResList();
        }
    }, [autoRes])

    const [autoResMstList, setAutoResMstList] = useState([]);
    const [autoResDtList, setAutoResDtList] = useState([]);

    const [selectedNewMstTitle, setSelectedNewMstTitle] = useState('');
    const [selectedNewMstSeqnum, setSelectedNewMstSeqnum] = useState('');
    const [newDtTitleText, setNewDtTitleText] = useState('');
    const [newDtContentText, setNewDtContentText] = useState('');
    const [newDtTitleLength, setNewDtTitleLength] = useState(0);
    const [newDtContentLength, setNewDtContentLength] = useState(0);

    const [editMstTitleText, setEditMstTitleText] = useState('');

    const selectAutoResList = () => axios({
        method: 'get',
        url: '/member/selectAutoResList/',
        headers: {
            Authorization: LCAT
        },
    })
        .then((response) => {
            setAutoResMstList(response.data)
        })

    const [autoMstShow, setAutoMstShow] = useState(false);
    const [masterAddPopup, setMasterAddPopup] = useState(false);

    const toggleAutoResShow = () => {
        setAutoMstShow(!autoMstShow);
    };

    const handleAutoResChange = (autoRes) => {
        setSelectedNewMstTitle(autoRes.title);
        setSelectedNewMstSeqnum(autoRes.seqnum);
        setAutoMstShow(false);
    };

    const newMstRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
            if (newMstRef.current && !newMstRef.current.contains(event.target)) {
                setAutoMstShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleNewDtTitleText = (e) => {
        setNewDtTitleText(e.target.value);
        setNewDtTitleLength(e.target.value.length)
    };

    const handleNewDtContentText = (e) => {
        setNewDtContentText(e.target.value);
        setNewDtContentLength(e.target.value.length)
    };

    const [selectedEditMstTitle, setSelectedEditMstTitle] = useState('');
    const [editMstTitleSeqnum, setEditMstTitleSeqnum] = useState('');
    const [editDtTitleSeqnum, setEditDtTitleSeqnum] = useState('');
    const [editDtTitleText, setEditDtTitleText] = useState('');
    const [editDtContentText, setEditDtContentText] = useState('');
    const [editMstTitleLength, setEditMstTitleLength] = useState(0);
    const [editDtTitleLength, setEditDtTitleLength] = useState(0);
    const [editDtContentLength, setEditDtContentLength] = useState(0);

    const [masterModifyForm, setMasterModifyForm] = useState(false);
    const [detailModifyForm, setDetailModifyForm] = useState(false);

    const [autoDtShow, setAutoDtShow] = useState(false);
    const editMstRef = useRef(null);

    const toggleDtShow = () => {
        setAutoDtShow(!autoDtShow);
    };

    const handleDtSelectChange = (autoRes) => {
        setSelectedEditMstTitle(autoRes.title);
        setAutoDtShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (editMstRef.current && !editMstRef.current.contains(event.target)) {
                setAutoDtShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // 여기서부터 newMst 커스텀 셀렉트
    const selectedMstLang = [
        { title: "한국어", value: 0 },
        { title: "영어", value: 1 },
        { title: "일본어", value: 2 },
        { title: "중국어(간체)", value: 3 },
        { title: "인도네시아어", value: 4 },
    ];
    const [selectedLangShow, setSelectedLangShow] = useState(false);
    const langRef = useRef(null);
    const [selectedMstLangTitle, setSelectedMstLangTitle] = useState(null);
    const [selectedMstLangValue, setSelectedMstLangValue] = useState(null);

    const toggleMstLangShow = () => {
        setSelectedLangShow(!selectedLangShow);
    };

    const handleMstLangChange = (lang) => {
        setSelectedMstLangTitle(lang.title);
        setSelectedMstLangValue(lang.value)
        setSelectedLangShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (langRef.current && !langRef.current.contains(event.target)) {
                setSelectedLangShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 newMst 커스텀 셀렉트

    // 여기서부터 newDt 커스텀 셀렉트
    const selectedNewDtLang = [
        { title: "한국어", value: 0 },
        { title: "영어", value: 1 },
        { title: "일본어", value: 2 },
        { title: "중국어(간체)", value: 3 },
        { title: "인도네시아어", value: 4 },
    ];
    const [selectedNewDtLangShow, setSelectedNewDtLangShow] = useState(false);
    const newDtLangRef = useRef(null);
    const [selectedNewDtLangTitle, setSelectedNewDtLangTitle] = useState(null);
    const [selectedNewDtLangValue, setSelectedNewDtLangValue] = useState(null);

    const toggleNewDtLangShow = () => {
        setSelectedNewDtLangShow(!selectedLangShow);
    };

    const handleNewDtLangChange = (lang) => {
        setSelectedNewDtLangTitle(lang.title);
        setSelectedNewDtLangValue(lang.value)
        setSelectedNewDtLangShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (newDtLangRef.current && !newDtLangRef.current.contains(event.target)) {
                setSelectedNewDtLangShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 newDt 커스텀 셀렉트

    // 여기서부터 editDt 커스텀 셀렉트
    const selectedEditDtLang = [
        { title: "한국어", value: 0 },
        { title: "영어", value: 1 },
        { title: "일본어", value: 2 },
        { title: "중국어(간체)", value: 3 },
        { title: "인도네시아어", value: 4 },
    ];
    const [selectedEditDtLangShow, setSelectedEditDtLangShow] = useState(false);
    const editDtLangRef = useRef(null);
    const [selectedEditDtLangTitle, setSelectedEditDtLangTitle] = useState(null);
    const [selectedEditDtLangValue, setSelectedEditDtLangValue] = useState(null);

    const toggleEditDtLangShow = () => {
        setSelectedEditDtLangShow(!selectedLangShow);
    };

    const handleEditDtLangChange = (lang) => {
        setSelectedEditDtLangTitle(lang.title);
        setSelectedEditDtLangValue(lang.value)
        setSelectedEditDtLangShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (editDtLangRef.current && !editDtLangRef.current.contains(event.target)) {
                setSelectedEditDtLangShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 editDt 커스텀 셀렉트

    const handleEditMstTitleText = (e) => {
        setEditMstTitleText(e.target.value);
        setEditMstTitleLength(e.target.value.length)
    };

    const handleEditDtTitleText = (e) => {
        setEditDtTitleText(e.target.value);
        setEditDtTitleLength(e.target.value.length)
    };

    const handleEditDtContentText = (e) => {
        setEditDtContentText(e.target.value);
        setEditDtContentLength(e.target.value.length)
    };

    const [masterBtFocused, setMasterBtFocused] = useState(null);
    const [detailBtFocused, setDetailBtFocused] = useState(null);
    const [editMstDeleteModal, setEditMstDeleteModal] = useState(false);
    const [editDtDeleteModal, setEditDtDeleteModal] = useState(false);

    const selectedMasterBt = ((master, index) => {
        setMasterModifyForm(true);
        setDetailModifyForm(false);
        setMasterBtFocused(index);
        setDetailBtFocused(null);
        setAutoResDtList(master.autoResDt);
        setEditMstTitleText(master.title);
        setEditMstTitleLength(master.title.length);
        setEditMstTitleSeqnum(master.seqnum);
        if (master.lang === '1') {
            setSelectedMstLangTitle("영어");
            setSelectedMstLangValue(1)
        } else if (master.lang === '2') {
            setSelectedMstLangTitle("일본어");
            setSelectedMstLangValue(2)
        } else if (master.lang === '3') {
            setSelectedMstLangTitle("중국어(간체)");
            setSelectedMstLangValue(3)
        } else if (master.lang === '4') {
            setSelectedMstLangTitle("인도네시아어");
            setSelectedMstLangValue(4)
        } else {
            setSelectedMstLangTitle("한국어");
            setSelectedMstLangValue(0)
        }
        // 마스터 클릭 시 New 자동선택
        setSelectedNewMstTitle(master.title);
        setSelectedNewMstSeqnum(master.seqnum);
        setSelectedNewDtLangTitle(null);
        setSelectedNewDtLangValue(null);

    })

    const selectedDetailBt = ((detail, index) => {
        setMasterModifyForm(false);
        setDetailModifyForm(true);
        setDetailBtFocused(index);
        setEditDtTitleSeqnum(detail.seqnum);
        setSelectedEditMstTitle(editMstTitleText);
        setEditDtTitleText(detail.title);
        setEditDtContentText(detail.content);
        setEditDtTitleLength(detail.title.length);
        setEditDtContentLength(detail.title.length);
        if (detail.lang === '1') {
            setSelectedEditDtLangTitle("영어");
            setSelectedEditDtLangValue(1)
        } else if (detail.lang === '2') {
            setSelectedEditDtLangTitle("일본어");
            setSelectedEditDtLangValue(2)
        } else if (detail.lang === '3') {
            setSelectedEditDtLangTitle("중국어(간체)");
            setSelectedEditDtLangValue(3)
        } else if (detail.lang === '4') {
            setSelectedEditDtLangTitle("인도네시아어");
            setSelectedEditDtLangValue(4)
        } else {
            setSelectedEditDtLangTitle("한국어");
            setSelectedEditDtLangValue(0)
        }
        setSelectedNewDtLangTitle(null);
        setSelectedNewDtLangValue(null);
    })

    const saveNewDetail = () => {
        axios({
            method: 'post',
            url: '/member/saveNewDetail/',
            headers: {
                Authorization: LCAT
            },
            data: {
                lang: selectedNewDtLangValue,
                title: newDtTitleText,
                content: newDtContentText
            },
            params: {
                selectedNewMstSeqnum: selectedNewMstSeqnum
            }
        })
            .then((response) => {
                const savedDetail = response.data;
                if (savedDetail.mstSeqnum === editMstTitleSeqnum) {
                    setAutoResDtList(prevDetails => [...prevDetails, savedDetail]);
                }
                selectAutoResList();
                setSelectedNewDtLangTitle(null);
                setSelectedNewDtLangValue(null);
                setSelectedNewMstTitle('Master Detail');
                setNewDtTitleText('');
                setNewDtContentText('');
            })
            .catch(() => {
                console.log("저장실패");
            });
    };

    const deleteMaster = () => axios({
        method: 'post',
        url: '/member/deleteMaster/',
        headers: {
            Authorization: LCAT
        },
        params: {
            editMstTitleSeqnum: editMstTitleSeqnum,
        }
    })
        .then(() => {
            setAutoResMstList(prevList => prevList.filter(master => master.seqnum !== editMstTitleSeqnum));
            setAutoResDtList([]);
            selectAutoResList();
            setSelectedMstLangTitle(null)
            setSelectedMstLangValue(null);
            setEditMstTitleText('');
            setEditMstDeleteModal(false);
        })
        .catch(() => {
            console.log("삭제실패")
        })

    const modifyMaster = () => axios({
        method: 'post',
        url: '/member/modifyMaster/',
        headers: {
            Authorization: LCAT
        },
        params: {
            editMstTitleSeqnum: editMstTitleSeqnum,
            lang: selectedMstLangValue,
            title: editMstTitleText
        }
    })
        .then(() => {
            selectAutoResList();
            setSelectedMstLangTitle(null);
            setSelectedMstLangValue(null);
            setEditMstTitleText('');
        })
        .catch(() => {
            console.log("변경실패")
        })

    const deleteDetail = () => axios({
        method: 'post',
        url: '/member/deleteDetail/',
        headers: {
            Authorization: LCAT
        },
        params: {
            editDtTitleSeqnum: editDtTitleSeqnum
        }
    })
        .then(() => {
            setAutoResDtList(currentList => currentList.filter(detail => detail.seqnum !== editDtTitleSeqnum));
            selectAutoResList();
            setEditDtDeleteModal(false);
            setSelectedEditDtLangTitle(null);
            setSelectedEditDtLangValue(null);
            setEditDtTitleText([]);
            setEditDtContentText([]);
        })
        .catch(() => {
            console.log("삭제실패")
        })

    const modifyDetail = () => axios({
        method: 'post',
        url: '/member/modifyDetail/',
        headers: {
            Authorization: LCAT
        },
        data: {
            seqnum: editDtTitleSeqnum,
            title: editDtTitleText,
            content: editDtContentText,
            lang: selectedEditDtLangValue
        },
        params: {
            editMstTitleSeqnum: editMstTitleSeqnum
        }
    })
        .then(() => {
            setAutoResDtList(currentList => currentList.map(detail => {
                if (detail.seqnum === editDtTitleSeqnum) {
                    return {...detail, title: editDtTitleText, content: editDtContentText};
                }
                return detail;
            }));
            selectAutoResList()
            setSelectedEditMstTitle('Master Detail');
            setEditDtTitleText('');
            setSelectedEditDtLangTitle(null);
            setSelectedEditDtLangValue(null);
            setEditDtContentText('');
        })
        .catch(() => {
            console.log("삭제실패")
        })

    // 매크로시작
    const [macroDataDrop, setMacroDataDrop] = useState(false);
    const handleMacroDrop = ((dropList) => {
        setMacroDataDrop(!macroDataDrop);
    })

    useEffect(() => {
        if (macro) {
            selectMacroList();
        }
    }, [macro])

    const [macroMstList, setMacroMstList] = useState([]);
    const [macroDtList, setMacroDtList] = useState([]);

    const [selectedNewMacroMstTitle, setSelectedNewMacroMstTitle] = useState('');
    const [selectedNewMacroMstSeqnum, setSelectedNewMacroMstSeqnum] = useState('');
    const [newMacroDtTitleText, setNewMacroDtTitleText] = useState('');
    const [newMacroDtContentText, setNewMacroDtContentText] = useState('');
    const [newMacroDtTitleLength, setNewMacroDtTitleLength] = useState(0);
    const [newMacroDtContentLength, setNewMacroDtContentLength] = useState(0);

    const [editMacroMstTitleText, setEditMacroMstTitleText] = useState('');

    const selectMacroList = () => axios({
        method: 'get',
        url: '/member/selectMacroList/',
        headers: {
            Authorization: LCAT
        },
    })
        .then((response) => {
            setMacroMstList(response.data)
        })

    const [macroMstShow, setMacroMstShow] = useState(false);
    const [masterAddMacroPopup, setMasterAddMacroPopup] = useState(false);
    const newMacroMstRef = useRef(null);

    const toggleMacroShow = () => {
        setMacroMstShow(!macroMstShow);
    };

    const handleMacroChange = (macro) => {
        setSelectedNewMacroMstTitle(macro.title);
        setSelectedNewMacroMstSeqnum(macro.seqnum);
        setMacroMstShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (newMacroMstRef.current && !newMacroMstRef.current.contains(event.target)) {
                setMacroMstShow(false);
                // setMacroMstShow({});
                // setProdToggleSt({});
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const handleNewMacroDtTitleText = (e) => {
        setNewMacroDtTitleText(e.target.value);
        setNewMacroDtTitleLength(e.target.value.length)
    };

    const handleNewMacroDtContentText = (e) => {
        setNewMacroDtContentText(e.target.value);
        setNewMacroDtContentLength(e.target.value.length)
    };

    const [selectedEditMacroMstTitle, setSelectedEditMacroMstTitle] = useState('');
    const [editMacroMstTitleSeqnum, setEditMacroMstTitleSeqnum] = useState('');
    const [editMacroDtTitleSeqnum, setEditMacroDtTitleSeqnum] = useState('');
    const [editMacroDtTitleText, setEditMacroDtTitleText] = useState('');
    const [editMacroDtContentText, setEditMacroDtContentText] = useState('');
    const [editMacroMstTitleLength, setEditMacroMstTitleLength] = useState(0);
    const [editMacroDtTitleLength, setEditMacroDtTitleLength] = useState(0);
    const [editMacroDtContentLength, setEditMacroDtContentLength] = useState(0);

    const [masterMacroModifyForm, setMasterMacroModifyForm] = useState(false);
    const [detailMacroModifyForm, setDetailMacroModifyForm] = useState(false);


    const [macroDtShow, setMacroDtShow] = useState(false);
    const editMacroMstRef = useRef(null);

    const toggleMacroDtShow = () => {
        setMacroDtShow(!macroDtShow);
    };

    const handleMacroDtSelectChange = (macro) => {
        setSelectedEditMacroMstTitle(macro.title);
        setMacroDtShow(false);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (editMacroMstRef.current && !editMacroMstRef.current.contains(event.target)) {
                setMacroDtShow(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleEditMacroMstTitleText = (e) => {
        setEditMacroMstTitleText(e.target.value);
        setEditMacroMstTitleLength(e.target.value.length)
    };

    const handleEditMacroDtTitleText = (e) => {
        setEditMacroDtTitleText(e.target.value);
        setEditMacroDtTitleLength(e.target.value.length)
    };

    const handleEditMacroDtContentText = (e) => {
        setEditMacroDtContentText(e.target.value);
        setEditMacroDtContentLength(e.target.value.length)
    };

    const [masterMacroBtFocused, setMasterMacroBtFocused] = useState(null);
    const [detailMacroBtFocused, setDetailMacroBtFocused] = useState(null);
    const [editMacroMstDeleteModal, setEditMacroMstDeleteModal] = useState(false);
    const [editMacroDtDeleteModal, setEditMacroDtDeleteModal] = useState(false);

    const selectedMasterMacroBt = ((master, index) => {
        setMasterMacroModifyForm(true);
        setDetailMacroModifyForm(false);
        setMasterMacroBtFocused(index);
        setDetailMacroBtFocused(null);
        setMacroDtList(master.macroDt);
        setEditMacroMstTitleText(master.title);
        setEditMacroMstTitleLength(master.title.length);
        setEditMacroMstTitleSeqnum(master.seqnum);
        // 마스터 클릭 시 New 자동선택
        setSelectedNewMacroMstTitle(master.title);
        setSelectedNewMacroMstSeqnum(master.seqnum);
    })

    const selectedMacroDetailBt = ((detail, index) => {
        setMasterMacroModifyForm(false);
        setDetailMacroModifyForm(true);
        setDetailMacroBtFocused(index);
        setEditMacroDtTitleSeqnum(detail.seqnum);
        setSelectedEditMacroMstTitle(editMstTitleText);
        setEditMacroDtTitleText(detail.title);
        setEditMacroDtContentText(detail.content);
        setEditMacroDtTitleLength(detail.title.length);
        setEditMacroDtContentLength(detail.title.length);
    })

    const saveNewMacroDetail = () => axios({
        method: 'post',
        url: '/member/saveNewMacroDetail/',
        headers: {
            Authorization: LCAT
        },
        data: {
            title: newMacroDtTitleText,
            content: newMacroDtContentText
        },
        params: {
            selectedNewMstSeqnum: selectedNewMacroMstSeqnum
        }
    })
        .then((response) => {
            const savedDetail = response.data;
            if (savedDetail.mstSeqnum === editMacroMstTitleSeqnum) {
                setMacroDtList(prevDetails => [...prevDetails, savedDetail]);
            }
            selectMacroList();
            setSelectedNewMacroMstTitle('Master Detail');
            setNewMacroDtTitleText('');
            setNewMacroDtContentText('');
        })
        .catch(() => {
            console.log("삭제실패")
        })

    const deleteMacroMaster = () => axios({
        method: 'post',
        url: '/member/deleteMacroMaster/',
        headers: {
            Authorization: LCAT
        },
        params: {
            editMstTitleSeqnum: editMacroMstTitleSeqnum,
        }
    })
        .then(() => {
            setMacroMstList(prevList => prevList.filter(master => master.seqnum !== editMacroMstTitleSeqnum));
            setMacroDtList([]);
            selectMacroList()
            setEditMacroMstTitleText('');
            setEditMacroMstDeleteModal(false);
        })
        .catch(() => {
            console.log("삭제실패")
        })

    const modifyMacroMaster = () => axios({
        method: 'post',
        url: '/member/modifyMacroMaster/',
        headers: {
            Authorization: LCAT
        },
        params: {
            editMstTitleSeqnum: editMacroMstTitleSeqnum,
            title: editMacroMstTitleText
        }
    })
        .then(() => {
            selectMacroList();
            setEditMacroMstTitleText('');
        })
        .catch(() => {
            console.log("삭제실패")
        })

    const deleteMacroDetail = () => axios({
        method: 'post',
        url: '/member/deleteMacroDetail/',
        headers: {
            Authorization: LCAT
        },
        params: {
            editDtTitleSeqnum: editMacroDtTitleSeqnum
        }
    })
        .then(() => {
            setMacroDtList(currentList => currentList.filter(detail => detail.seqnum !== editMacroDtTitleSeqnum));
            selectMacroList();
            setEditMacroDtDeleteModal(false);
            setEditMacroDtTitleText([]);
            setEditMacroDtContentText([]);
        })
        .catch(() => {
            console.log("삭제실패")
        })

    const modifyMacroDetail = () => axios({
        method: 'post',
        url: '/member/modifyMacroDetail/',
        headers: {
            Authorization: LCAT
        },
        data: {
            seqnum: editMacroDtTitleSeqnum,
            title: editMacroDtTitleText,
            content: editMacroDtContentText
        },
        params: {
            editMstTitleSeqnum: editMacroMstTitleSeqnum
        }
    })
        .then(() => {
            setMacroDtList(currentList => currentList.map(detail => {
                if (detail.seqnum === editMacroDtTitleSeqnum) {
                    return {...detail, title: editMacroDtTitleText, content: editMacroDtContentText};
                }
                return detail; // 다른 항목은 그대로 유지합니다.
            }));
            selectMacroList()
            setSelectedEditMacroMstTitle('Master Detail');
            setEditMacroDtTitleText('');
            setEditMacroDtContentText('');
        })
        .catch(() => {
            console.log("삭제실패")
        })

    /** endregion Event */

    return (
        <div className="AdminOrderChatPg">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">채팅&매크로관리</p>
            </div>
            <div className="font-t-title"
                 style={{height: "60px", paddingLeft: "30px", paddingRight: "30px", display: "flex"}}>
                <div onClick={() => handleTab('ordChat')}
                     style={{height: "100%", display: "flex", alignItems: "center", margin: "6px", cursor: "pointer"}}>
                    <span style={{
                        margin: "0",
                        width: "100px",
                        textAlign: "center",
                        borderStyle: ordChat ? "none" : "solid",
                        borderWidth: "thin 2px",
                        borderColor: "dimgrey",
                        padding: ordChat ? "15px" : "10px",
                        fontSize: "14px",
                        borderRadius: "22px",
                        color: ordChat ? "white" : "dimgray",
                        backgroundColor: ordChat ? "#2c2c2c" : null,
                        fontWeight: ordChat ? "lighter" : 'bold'
                    }}>주문서채팅</span>
                </div>
                <div onClick={() => handleTab('nonMember')}
                     style={{height: "100%", display: "flex", alignItems: "center", margin: "6px", cursor: "pointer"}}>
                    <span style={{
                        margin: "0",
                        width: "100px",
                        textAlign: "center",
                        borderStyle: nonMember ? "none" : "solid",
                        borderWidth: "thin 2px",
                        borderColor: "dimgrey",
                        padding: nonMember ? "15px" : "10px",
                        fontSize: "14px",
                        borderRadius: "22px",
                        color: nonMember ? "white" : "dimgray",
                        backgroundColor: nonMember ? "#2c2c2c" : null,
                        fontWeight: nonMember ? "lighter" : 'bold'
                    }}>비회원채팅</span>
                </div>
                <div onClick={() => handleTab('autoRes')}
                     style={{height: "100%", display: "flex", alignItems: "center", margin: "6px", cursor: "pointer"}}>
                    <span style={{
                        margin: "0",
                        width: "100px",
                        textAlign: "center",
                        borderStyle: autoRes ? "none" : "solid",
                        borderWidth: "thin 2px",
                        borderColor: "dimgrey",
                        padding: autoRes ? "15px" : "10px",
                        fontSize: "14px",
                        borderRadius: "22px",
                        color: autoRes ? "white" : "dimgray",
                        backgroundColor: autoRes ? "#2c2c2c" : null,
                        fontWeight: autoRes ? "lighter" : 'bold'
                    }}>자동응답</span>
                </div>
                <div onClick={() => handleTab('macro')}
                     style={{height: "100%", display: "flex", alignItems: "center", margin: "6px", cursor: "pointer"}}>
                    <span style={{
                        margin: "0",
                        width: "100px",
                        textAlign: "center",
                        borderStyle: macro ? "none" : "solid",
                        borderWidth: "thin 2px",
                        borderColor: "dimgrey",
                        padding: macro ? "15px" : "10px",
                        fontSize: "14px",
                        borderRadius: "22px",
                        color: macro ? "white" : "dimgray",
                        backgroundColor: macro ? "#2c2c2c" : null,
                        fontWeight: macro ? "lighter" : 'bold'
                    }}>매크로</span>
                </div>
            </div>
            {/*주문서채팅탭*/}
            {ordChat ?
                <div className="admin-wrap font-t-cont">
                    <div className="admin-chat-wrap">
                        <header className="admin-chat-header dark-border-top">
                            <div className="display-flex just-cont-sb flex-row">
                                <div className="admin-event-wrap-btn">
                                    <AdminSearchEvent searchInput={searchInput}
                                                      handleSearchInputChange={handleSearchInputChange}/>
                                    <AdminYmdEvent startDate={startDate} endDate={endDate} startHandle={startHandle}
                                                   endHandle={endHandle}/>
                                </div>
                                <div className="admin-event-wrap-btn">
                                    {favFn ? (
                                        <IoStar onClick={toggleMenu} style={{color: "#ffc200"}}
                                                className="chat-gear-icon btn-click-event c-point m-t-3 font-s-24 m-r-5"/>
                                    ) : (
                                        <IoStarOutline onClick={toggleMenu}
                                                       className="chat-gear-icon btn-click-event c-point m-t-3 dark-icon font-s-24 m-r-5"/>
                                    )}
                                    <IoSettingsOutline
                                        className="chat-gear-icon btn-click-event c-point m-t-3 dark-icon font-s-24 m-l-5"/>
                                </div>
                            </div>
                        </header>
                        <div className="admin-chat-main gbn-border-b">
                            <div className="admin-chat-user">
                                {memberList && memberList.map((member, i) => (
                                    <aside key={i} onClick={() => handleUserNameClick(member, i)}>
                                        <ul style={{margin: "0", paddingLeft: "0", listStyle: "none"}}>
                                            <li className={`c-point ${i === focusedIndex ? "focused" : ""}`}
                                                style={{listStyle: "none"}}>
                                                <div className="font-t-cont">
                                                    <div className="wd-70">
                                                        <div className="admin-chat-profile">
                                                            {member.memberDTO.profileDTO ?
                                                                <img className="pro-img" src={
                                                                    "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + member.memberDTO.profileDTO.filename
                                                                } alt=""/>
                                                                :
                                                                <img className="pro-img" src={AdminProfileBase} alt=""/>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="admin-chat-username c-point">
                                                        <div className="display-flex align-center">
                                                            <span>
                                                                <b className="font-c-default font-t-title font-s-15"
                                                                   style={{maxWidth: "100px",}}>{member && member.mstUserNm}</b>
                                                            </span>
                                                            <div className="m-l-3">
                                                                {member && member.unreadCount > 0 ?
                                                                    <span
                                                                        className="chat-badge">{member.unreadCount}</span>
                                                                    : null}
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis"
                                                        }}>
                                                            {(member && member.chatKey.endsWith("png") ||
                                                                member.chatKey.endsWith("PNG") ||
                                                                member && member.chatKey.endsWith("jpg") ||
                                                                member.chatKey.endsWith("JPG")) ||
                                                            member.chatKey.endsWith("jpeg") ||
                                                            member.chatKey.endsWith("JPEG") ?
                                                                <p style={{font: "18px"}}>[사진]</p> : member.chatKey.startsWith("chatFile") ?
                                                                    <p style={{font: "18px"}}>[파일]</p> :
                                                                    <p style={{font: "18px"}}>{member.chatKey}</p>}
                                                        </div>
                                                    </div>
                                                    <div className="admin-chat-count">
                                                        <div style={{height: "25px"}}><p
                                                            className="font-s-11 m-r-5">{convertToNonMemberDate(member && member.regTime)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </aside>
                                ))}
                            </div>
                            {selectedUserName && (
                                <div className="admin-chat-ord font-t-cont">
                                    <ul style={{margin: "0", paddingLeft: "0", textAlign: "center"}}>
                                        {selectedUserOrderNumbers && selectedUserOrderNumbers.map((chatDt, i) => (
                                            <li className="c-point" key={i}
                                                onClick={() => handleOrderNumberlick(chatDt, i)}>
                                                <div className={`c-point ${i === focusedOrderIndex ? "focused" : ""}`}
                                                     style={{textAlign: "center"}}>
                                                    <div style={{
                                                        margin: "0",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        wordBreak: "break-all",
                                                        height: "100%"
                                                    }}>
                                                        <div style={{
                                                            height: "50%",
                                                            display: "flex",
                                                            alignItems: "flex-end",
                                                            justifyContent: "center"
                                                        }}>
                                                            <b className="font-t-title font-s-15">{chatDt.chatOrdNum}</b>
                                                            {chatDt.unreadCount > 0 && chatDt.memberDTO.role === '0' ?
                                                                <span style={{
                                                                    borderRadius: "100%",
                                                                    width: "auto",
                                                                    backgroundColor: "orangered",
                                                                    color: "white",
                                                                    fontSize: "11px",
                                                                    margin: "0 0 2px 10px",
                                                                    padding: "0 5px"
                                                                }}>{chatDt.unreadCount}</span>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        <div style={{
                                                            height: "50%",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            marginTop: "5px"
                                                        }}>
                                                        <span style={{
                                                            margin: "0",
                                                            width: "90%",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                            wordBreak: "break-all"
                                                        }}>
                                                            {(chatDt && chatDt.chatKey.endsWith("png") ||
                                                                chatDt.chatKey.endsWith("PNG") ||
                                                                chatDt && chatDt.chatKey.endsWith("jpg") ||
                                                                chatDt.chatKey.endsWith("JPG")) ||
                                                            chatDt.chatKey.endsWith("jpeg") ||
                                                            chatDt.chatKey.endsWith("JPEG") ?
                                                                <p style={{font: "17px"}}>[사진]</p> : chatDt.chatKey.startsWith("chatFile") ?
                                                                    <p style={{font: "17px"}}>[파일]</p> :
                                                                    <p style={{font: "17px"}}>{chatDt.chatKey}</p>}
                                                        </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            <div className="admin-chat-cont font-t-cont">
                                <div className="admin-chat-cont-ripple" ref={messagesEndRef} style={{
                                    position: "relative",
                                    borderBottom: chatStarted ? "solid" : null,
                                    borderWidth: chatStarted ? "thin 1px" : null,
                                    borderColor: chatStarted ? "#EEEEEE" : null
                                }}>
                                    {/*주문서채팅 오픈*/}
                                    {selectedUserOrderNumbers && chatStarted ? (
                                        <div style={{padding: "10px"}}>
                                            <div>
                                                {/*주문서 채팅바디*/}
                                                {chatMessages && chatMessages.map((message, index) => (
                                                    <div key={index}>
                                                        {(isFirstMessageOfDay(message, chatMessages[index - 1]) &&
                                                            <div style={{
                                                                width: "100%",
                                                                height: "60px",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center"
                                                            }}>
                                                                <p style={{
                                                                    fontSize: "11px",
                                                                    margin: "0"
                                                                }}>{formatDate(new Date(message.regTime))}</p>
                                                            </div>
                                                        )}
                                                        <div style={{
                                                            display: "flex",
                                                            justifyContent: message.memberDTO && message.memberDTO.role === '0' ? 'flex-start' : 'flex-end'
                                                        }}>
                                                            {message.memberDTO && message.memberDTO.role === '0' ?

                                                                // 주문서 사용자프로필
                                                                <div style={{
                                                                    display: "flex",
                                                                    position: "relative",
                                                                    marginTop: "50px"
                                                                }}>
                                                                    <div style={{
                                                                        marginRight: "5px",
                                                                        position: "absolute",
                                                                        top: "-20px",
                                                                        left: "0",
                                                                        borderRadius: "100%",
                                                                        borderWidth: "thin 1px",
                                                                        backgroundColor: message.chatKey.startsWith("chatFile") ? "white" : "rgba(255,171,77,0.24)",
                                                                        height: "60px",
                                                                        width: "60px",
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        overflow: "hidden"
                                                                    }}>
                                                                        {message.memberDTO.profileDTO ?
                                                                            <img className="pro-img" src={
                                                                                "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + message.memberDTO.profileDTO.filename
                                                                            } alt=""/>
                                                                            :
                                                                            <img className="pro-img"
                                                                                 src={userProfileImg} alt=""/>
                                                                        }
                                                                    </div>
                                                                    <p style={{
                                                                        marginRight: "10px",
                                                                        position: "absolute",
                                                                        top: "-20px",
                                                                        left: "70px"
                                                                    }}>{message.mstUserNm}</p>
                                                                    {message.chatKey.startsWith("chatFile") ?
                                                                        // 채팅메시지 프레임
                                                                        <div
                                                                            onClick={!message.removeFl ? () => clickFileDetail(message) : null}
                                                                            style={{
                                                                                margin: "5px 0 0 70px",
                                                                                cursor: "pointer"
                                                                            }}>
                                                                            {isImage(message.chatKey) ?
                                                                                // 사용자이미지
                                                                                <div>
                                                                                    {!message.removeFl ?
                                                                                        <p style={{
                                                                                            maxWidth: "500px",
                                                                                            width: "fit-content",
                                                                                            wordBreak: "keep-all",
                                                                                            textAlign: "left",
                                                                                            fontWeight: "lighter",
                                                                                        }}>
                                                                                            <img style={{
                                                                                                maxWidth: "500px",
                                                                                                maxHeight: "700px"
                                                                                            }} src={message.fileUrl}
                                                                                                 alt="chatKey"/>
                                                                                        </p>
                                                                                        :
                                                                                        <div style={{
                                                                                            padding: "10px",
                                                                                            textAlign: "center"
                                                                                        }}>
                                                                                            <img style={{
                                                                                                width: "40px",
                                                                                                height: "40px",
                                                                                                opacity: "0.5"
                                                                                            }} src={trash}
                                                                                                 alt="remove"/>
                                                                                            <p style={{
                                                                                                margin: "0",
                                                                                                fontSize: "11px",
                                                                                                marginTop: "5px",
                                                                                                color: "darkgray"
                                                                                            }}>(기한만료)</p>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                // 사용자동영상
                                                                                isVideo(message.chatKey) ?
                                                                                    <div>
                                                                                        {!message.removeFl ?
                                                                                            <video width="350"
                                                                                                   height="210"
                                                                                                   controls>
                                                                                                <source
                                                                                                    src={message.fileUrl}
                                                                                                    type="video/mp4"/>
                                                                                            </video>
                                                                                            :
                                                                                            <div style={{
                                                                                                padding: "10px",
                                                                                                textAlign: "center"
                                                                                            }}>
                                                                                                <img style={{
                                                                                                    width: "40px",
                                                                                                    height: "40px",
                                                                                                    opacity: "0.5"
                                                                                                }} src={trash}
                                                                                                     alt="remove"/>
                                                                                                <p style={{
                                                                                                    margin: "0",
                                                                                                    fontSize: "11px",
                                                                                                    marginTop: "5px",
                                                                                                    color: "darkgray"
                                                                                                }}>(기한만료)</p>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    :
                                                                                    // 이외 파일들
                                                                                    <div>
                                                                                        {!message.removeFl ?
                                                                                            <div style={{
                                                                                                width: "150px",
                                                                                                height: "130px",
                                                                                                border: "solid",
                                                                                                backgroundColor: "white",
                                                                                                borderWidth: "thin 1px",
                                                                                                borderColor: "#EAEAEA",
                                                                                                borderRadius: "6px"
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    height: "60%",
                                                                                                    display: "flex",
                                                                                                    justifyContent: "center",
                                                                                                    alignItems: "center",
                                                                                                    overflow: "hidden"
                                                                                                }}>
                                                                                                    <img style={{
                                                                                                        width: "50%",
                                                                                                        height: "90%",
                                                                                                        paddingTop: "6px"
                                                                                                    }} src={chatFileImg}
                                                                                                         alt="fileImg"/>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="font-t-cont"
                                                                                                    style={{
                                                                                                        height: "40%",
                                                                                                    }}>
                                                                                                    <div
                                                                                                        className="user-center wd-100pc m-l-5">
                                                                                                        <div
                                                                                                            className="m-a-chatKey-fileNm-text">
                                                                                                            {message.fileNm}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <p style={{
                                                                                                        width: "100%",
                                                                                                        height: "50%",
                                                                                                        display: "flex",
                                                                                                        justifyContent: "center",
                                                                                                        margin: "0",
                                                                                                        color: "dimgrey",
                                                                                                        fontSize: "12px",
                                                                                                        paddingBottom: "5px"
                                                                                                    }}> ~ {oneMonthFromNow(message.deadline)}까지</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            <div style={{
                                                                                                padding: "10px",
                                                                                                textAlign: "center"
                                                                                            }}>
                                                                                                <img style={{
                                                                                                    width: "40px",
                                                                                                    height: "40px",
                                                                                                    opacity: "0.5"
                                                                                                }} src={trash}
                                                                                                     alt="remove"/>
                                                                                                <p style={{
                                                                                                    margin: "0",
                                                                                                    fontSize: "11px",
                                                                                                    marginTop: "5px",
                                                                                                    color: "darkgray"
                                                                                                }}>(기한만료)</p>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <div style={{
                                                                            position: 'relative',
                                                                            border: 'solid',
                                                                            borderWidth: 'thin 1px',
                                                                            borderColor: 'lightgray',
                                                                            borderRadius: '6px',
                                                                            textAlign: 'left',
                                                                            marginTop: '10px',
                                                                            marginLeft: '70px',
                                                                            padding: '5px',
                                                                            backgroundColor: '#fff'
                                                                        }}>
                                                                            <Linkify
                                                                                componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                                    <a style={{color: "orangered"}}
                                                                                       target="blank"
                                                                                       href={decoratedHref} key={key}>
                                                                                        {decoratedText}
                                                                                    </a>
                                                                                )}
                                                                            >
                                                                                <p style={{
                                                                                    margin: "8px",
                                                                                    maxWidth: "500px",
                                                                                    width: "fit-content",
                                                                                    wordBreak: "break-word",
                                                                                    textAlign: "left",
                                                                                    fontWeight: "lighter",
                                                                                    whiteSpace: "pre-wrap"
                                                                                    // 사용자 텍스트채팅
                                                                                }}>{DOMPurify.sanitize(message.chatKey)}</p>
                                                                            </Linkify>
                                                                        </div>
                                                                    }
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        width: '0',
                                                                        height: '0',
                                                                        border: '4px solid transparent',
                                                                        borderBottomColor: '#fff',
                                                                        borderRightColor: '#fff',
                                                                        transform: 'rotate(135deg)',
                                                                        left: '-4px',
                                                                        top: '11px',
                                                                    }}></div>
                                                                    <div style={{marginLeft: "5px"}}>
                                                                        {!message.removeFl ?
                                                                            <p style={{
                                                                                display: "flex",
                                                                                alignItems: "end",
                                                                                height: "100%",
                                                                                width: "100px",
                                                                                fontSize: "10px",
                                                                                margin: "0"
                                                                            }}>{formatTime(message.regTime)}
                                                                            </p>
                                                                            :
                                                                            null
                                                                        }
                                                                    </div>
                                                                </div>
                                                                :
                                                                // 관리자채팅
                                                                <div style={{
                                                                    borderRadius: "6px",
                                                                    marginTop: "10px",
                                                                    display: "flex",
                                                                }}>
                                                                    <div style={{
                                                                        display: "flex",
                                                                        alignItems: "flex-end",
                                                                        justifyContent: "flex-end",
                                                                        fontSize: "12px",
                                                                        flexDirection: "row",
                                                                        marginRight: "5px"
                                                                    }}>
                                                                        {!message.removeFl ?
                                                                            <div style={{
                                                                                display: "flex",
                                                                                marginRight: "5px",
                                                                                fontSize: "10px",
                                                                                alignItems: "flex-end"
                                                                            }}>
                                                                                {message && message.unreadCount > 0 ?
                                                                                    <p style={{
                                                                                        margin: "0",
                                                                                        marginRight: "5px",
                                                                                        color: "darkorange",
                                                                                        fontSize: "10px",
                                                                                        fontWeight: "bold",
                                                                                        height: "100%",
                                                                                    }}>1</p>
                                                                                    :
                                                                                    null
                                                                                }
                                                                                <button style={{
                                                                                    border: "none",
                                                                                    padding: "0",
                                                                                    color: "CornflowerBlue",
                                                                                }}
                                                                                        onClick={() => openModifyModal(message)}>수정
                                                                                </button>
                                                                                <button style={{
                                                                                    border: "none",
                                                                                    padding: "0",
                                                                                    color: "darkorange",
                                                                                    marginLeft: "3px"
                                                                                }}
                                                                                        onClick={() => openDeleteModal(message)}>삭제
                                                                                </button>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }
                                                                        <div>
                                                                            {!message.removeFl ?
                                                                                <p style={{
                                                                                    display: "flex",
                                                                                    // alignItems: "end",
                                                                                    height: "100%",
                                                                                    fontSize: "10px",
                                                                                    margin: "0"
                                                                                }}>{formatTime(message.regTime)}
                                                                                </p>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {/*관리자 채팅메시지 프레임*/}
                                                                    {message.chatKey.startsWith("chatFile") ?
                                                                        <div
                                                                            onClick={!message.removeFl ? () => clickFileDetail(message) : null}
                                                                            style={{cursor: "pointer"}}>
                                                                            {isImage(message.chatKey) ?
                                                                                <div>
                                                                                    {!message.removeFl ?
                                                                                        // 관리자이미지
                                                                                        <div style={{
                                                                                            maxWidth: "500px",
                                                                                            width: "fit-content"
                                                                                        }}>
                                                                                            <img
                                                                                                style={{
                                                                                                    maxWidth: "500px",
                                                                                                    maxHeight: "700px"
                                                                                                }}
                                                                                                src={message.fileUrl}
                                                                                                alt="chatKey"/>
                                                                                        </div>
                                                                                        :
                                                                                        <div style={{
                                                                                            padding: "10px",
                                                                                            textAlign: "center"
                                                                                        }}>
                                                                                            <img style={{
                                                                                                width: "40px",
                                                                                                height: "40px",
                                                                                                opacity: "0.5"
                                                                                            }} src={trash}
                                                                                                 alt="remove"/>
                                                                                            <p style={{
                                                                                                margin: "0",
                                                                                                fontSize: "11px",
                                                                                                marginTop: "5px",
                                                                                                color: "darkgray"
                                                                                            }}>(기한만료)</p>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                // 관리자동영상
                                                                                isVideo(message.chatKey) ?
                                                                                    <div style={{
                                                                                        padding: "0",
                                                                                        margin: "0",
                                                                                    }}>
                                                                                        <video width="300" height="260"
                                                                                               controls>
                                                                                            <source
                                                                                                src={message.fileUrl}
                                                                                                type="video/mp4"/>
                                                                                        </video>
                                                                                    </div>
                                                                                    :
                                                                                    <div>
                                                                                        {!message.removeFl ?
                                                                                            // 외 파일들
                                                                                            <div style={{
                                                                                                width: "150px",
                                                                                                height: "130px",
                                                                                                border: "solid",
                                                                                                backgroundColor: "white",
                                                                                                borderWidth: "thin 1px",
                                                                                                borderColor: "#EAEAEA",
                                                                                                borderRadius: "6px"
                                                                                            }}>
                                                                                                <div style={{
                                                                                                    height: "60%",
                                                                                                    display: "flex",
                                                                                                    justifyContent: "center",
                                                                                                    alignItems: "center",
                                                                                                    overflow: "hidden"
                                                                                                }}>
                                                                                                    <img style={{
                                                                                                        width: "50%",
                                                                                                        height: "90%",
                                                                                                        paddingTop: "6px"
                                                                                                    }} src={chatFileImg}
                                                                                                         alt="fileImg"/>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="font-t-cont"
                                                                                                    style={{
                                                                                                        height: "40%",
                                                                                                    }}>
                                                                                                    <div
                                                                                                        className="user-center wd-100pc m-l-5">
                                                                                                        <div
                                                                                                            className="m-a-chatKey-fileNm-text">
                                                                                                            {message.fileNm}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <p style={{
                                                                                                        width: "100%",
                                                                                                        height: "50%",
                                                                                                        display: "flex",
                                                                                                        justifyContent: "center",
                                                                                                        margin: "0",
                                                                                                        color: "dimgrey",
                                                                                                        fontSize: "12px",
                                                                                                        paddingBottom: "5px"
                                                                                                    }}> ~ {oneMonthFromNow(message.deadline)}까지</p>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                            <div style={{
                                                                                                padding: "10px",
                                                                                                textAlign: "center"
                                                                                            }}>
                                                                                                <img style={{
                                                                                                    width: "40px",
                                                                                                    height: "40px",
                                                                                                    opacity: "0.5"
                                                                                                }} src={trash}
                                                                                                     alt="remove"/>
                                                                                                <p style={{
                                                                                                    margin: "0",
                                                                                                    fontSize: "11px",
                                                                                                    marginTop: "5px",
                                                                                                    color: "darkgray"
                                                                                                }}>(기한만료)</p>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <div style={{
                                                                            backgroundColor: "#FFAA4D",
                                                                            margin: "0",
                                                                            borderRadius: "15px",
                                                                        }}>
                                                                            <Linkify
                                                                                componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                                    <a style={{color: "orangered"}}
                                                                                       target="blank"
                                                                                       href={decoratedHref} key={key}>
                                                                                        {decoratedText}
                                                                                    </a>
                                                                                )}
                                                                            >
                                                                                <p style={{
                                                                                    maxWidth: "500px",
                                                                                    width: "fit-content",
                                                                                    wordBreak: "break-word",
                                                                                    whiteSpace: "pre-wrap",
                                                                                    margin: "0",
                                                                                    padding: "8px",
                                                                                    color: "white",
                                                                                    fontSize: "14px",
                                                                                    fontWeight: "lighter"
                                                                                    // 관리자 텍스트메시지
                                                                                }}>{DOMPurify.sanitize(message.chatKey)}</p>
                                                                            </Linkify>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="text-center" style={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "40%",
                                                transform: "translate(0, -50%)"
                                            }}>
                                                <div className="chatStartImg2" style={{
                                                    margin: "0 auto",
                                                    width: "40px",
                                                    height: "40px",
                                                    verticalAlign: "middle",
                                                    position: "relative"
                                                }}>
                                                    <img className="default-img admin-chat-rocket-img" alt=""
                                                         src={chatStart}/>
                                                </div>
                                                <span className="dark-font-b font-s-28">Start Chatting ···</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {/*주문서 채팅인풋*/}
                                {chatStarted ?
                                    <div className="admin-chat-cont-input">
                                        <div className="chat-cont-input">
                                    <textarea className="chat-textarea" rows={1} maxLength={3000}
                                              placeholder="Enter키로 메시지 전송" spellCheck="false"
                                              ref={chatInputRef}
                                              value={message}
                                              onChange={handleChatInput}
                                              onInput={(e) => resizeTextarea()}
                                              onKeyDown={(e) => {
                                                  if (e.key === 'Enter' && !e.shiftKey) {
                                                      e.preventDefault();
                                                      sendMessage(message);
                                                      setMessage('');
                                                  }
                                              }}
                                    />
                                        </div>
                                        <div className="chat-enter">
                                            <li style={{listStyle: "none"}}>
                                                <MdInsertEmoticon onClick={() => setShowEmoji(!showEmoji)}
                                                                  className="chat-event-icon dark-icon btn-click-event"
                                                                  id="btorage-hover"/>
                                                {showEmoji &&
                                                    <div style={{position: "relative"}}>
                                                        <div style={{
                                                            position: "absolute",
                                                            bottom: "47px",
                                                            right: "7px"
                                                        }}>
                                                            <Picker onEmojiSelect={handleEmojiClick}
                                                                    previewPosition="none"
                                                                    searchPosition="none"
                                                                    skinTonePosition="none"
                                                                    locale="kr"
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </li>
                                            <li style={{position: "relative", listStyle: "none"}}>
                                                <input className="font-s-16" type="file" onChange={handleFileUpload}
                                                       ref={fileInputRef} style={{display: "none", border: "none"}}/>
                                                <AiOutlinePaperClip onClick={triggerFileInput}
                                                                    className="chat-event-icon dark-icon btn-click-event"
                                                                    id="btorage-hover"/>
                                            </li>
                                            <li style={{listStyle: "none"}}>
                                                <BsBookmarkStar onClick={macroViewOpen}
                                                                className="chat-event-icon-mark dark-icon btn-click-event"
                                                                id="btorage-hover"/>
                                                {macroOpen && (
                                                    <div style={{position: "relative"}}>
                                                        <div className="ord-chat-macro-wrap">
                                                            <div className="ord-chat-macro-lang">
                                                                <select className="admin-combo1" disabled="true">
                                                                    <option>KR [한국어](추후 기능추가)</option>
                                                                    <option>US [English]</option>
                                                                    <option>JP [日本語]</option>
                                                                    <option>CN [中国語]</option>
                                                                </select>
                                                            </div>
                                                            <div className="ord-chat-macro-cont">
                                                                {macroMstList.map((mst, mstIdx) => (
                                                                    <div key={mstIdx}>
                                                                        <div onClick={() => fetchNonMacroDtList(mstIdx)}
                                                                             style={{
                                                                                 height: "40px",
                                                                                 backgroundColor: "#f6f6f6",
                                                                                 display: "flex",
                                                                                 position: "relative",
                                                                                 margin: "5px 0 5px 0",
                                                                                 cursor: "pointer"
                                                                             }}>
                                                                            <div style={{
                                                                                width: "100%",
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center"
                                                                            }}>
                                                                                <p style={{
                                                                                    margin: "0",
                                                                                    fontSize: "13px"
                                                                                }}>{mst && mst.title}</p>
                                                                            </div>
                                                                            <div style={{
                                                                                position: "absolute",
                                                                                right: "10px"
                                                                            }}>
                                                                                <img alt='bottom' style={{
                                                                                    width: "10px",
                                                                                    height: "10px",
                                                                                    opacity: "0.6"
                                                                                }} src={commonBottom}/>
                                                                            </div>
                                                                        </div>
                                                                        {nonSelectedMacroMstIndex === mstIdx && macroDtList && macroDtList.map((dt, dtIdx) => (
                                                                            <div key={dtIdx} className='macroConBt'
                                                                                 tabIndex='0'
                                                                                 onClick={() => sendOrdMacroMessage(dt.content)}
                                                                                 style={{
                                                                                     height: "40px",
                                                                                     display: "flex",
                                                                                     justifyContent: "center",
                                                                                     cursor: "pointer",
                                                                                     alignItems: "center",
                                                                                     paddingLeft: "10px"
                                                                                 }}>
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    width: "10%"
                                                                                }}>
                                                                                    <img alt='list' style={{
                                                                                        width: "15px",
                                                                                        height: "15px"
                                                                                    }} src={commonList}/>
                                                                                </div>
                                                                                <div style={{
                                                                                    width: "90%",
                                                                                    textAlign: "center",
                                                                                    paddingRight: "15px"
                                                                                }}>
                                                                                    <p className="font-s-13"
                                                                                       style={{margin: "0"}}>{dt && dt.title}</p>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </li>
                                            <li style={{listStyle: "none"}}>
                                                <IoIosSend
                                                    className="send-btn-air chat-event-icon dark-icon btorage-hover btn-click-event"
                                                    onClick={() => {
                                                        sendMessage(message);
                                                        setMessage('');
                                                    }}
                                                />
                                            </li>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                :
                null
            }
            {/*비회원채팅탭*/}
            {nonMember ?
                <div className="admin-wrap font-t-cont">
                    <div className="admin-chat-wrap">
                        <div className="admin-chat-main gbn-border-b">
                            {/*전체컨테이너*/}
                            <div style={{width: "100%"}}>
                                {/*채팅헤더*/}
                                <div style={{
                                    height: "6%",
                                    backgroundColor: "#FFE5B6",
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}>
                                    <div style={{
                                        width: "50%",
                                        height: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        marginLeft: "10px"
                                    }}>
                                        <img alt='counselor' style={{width: "25px", height: "25px"}}
                                             src={adminCounselor}/>
                                        <p style={{
                                            margin: 0,
                                            fontWeight: "bold",
                                            marginLeft: "8px",
                                            color: "#FFAF34"
                                        }}>1:1 Chat</p>
                                    </div>
                                    <div style={{
                                        width: "50%",
                                        height: "100%",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                        marginRight: "10px"
                                    }}>
                                        <div tabIndex='0' className='smallMenu'>
                                            <img alt='macro' style={{width: "25px", height: "25px", cursor: "pointer"}}
                                                 src={adminMacro}/>
                                        </div>
                                        {nonChatShow ?
                                            <div onClick={() => setNonChatColor(!nonChatColor)} tabIndex='0'
                                                 className='smallMenu'>
                                                <img alt='macro' style={{
                                                    width: "25px",
                                                    height: "25px",
                                                    marginLeft: "5px",
                                                    cursor: "pointer"
                                                }} src={commonExchange}/>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                {/*컨텐츠 컨테이너*/}
                                <div style={{height: "94%", display: "flex"}}>
                                    {/*비회원리스트*/}
                                    <div style={{width: "20%", overflow: "auto"}}>
                                        <div style={{backgroundColor: "#FAFAFA", padding: "10px"}}>
                                            <div style={{position: "relative"}}>
                                                <div style={{
                                                    height: "40px",
                                                    backgroundColor: "white",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                    <button onClick={() => setSelectNonMemberShow(!selectNonMemberShow)}
                                                            style={{height: "100%", width: "100%", border: "none"}}>
                                                        <p style={{
                                                            margin: "0",
                                                            fontSize: "14px",
                                                            color: "dimgray",
                                                            fontWeight: "lighter"
                                                        }}>{selectNonMemberDate || ''}</p>
                                                    </button>
                                                </div>
                                                {selectNonMemberShow ?
                                                    <div style={{
                                                        position: "absolute",
                                                        top: "120%",
                                                        border: "solid",
                                                        borderWidth: "thin 1px",
                                                        borderColor: "lightgray",
                                                        width: "100%",
                                                        height: "auto",
                                                        zIndex: 5000
                                                    }}>
                                                        {settingNonMemberPeriod.map((period, index) => (
                                                            <div key={index}>
                                                                <button onClick={() => handleNonMemberPeriod(period)}
                                                                        className='periodBt' style={{
                                                                    height: "40px", width: "100%", border: "none"
                                                                }}>
                                                                    <p style={{
                                                                        margin: "0",
                                                                        fontSize: "15px",
                                                                        color: "dimgray",
                                                                        fontWeight: "lighter"
                                                                    }}>{period}</p>
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <div style={{height: "40px", backgroundColor: "white", marginTop: "5px"}}>
                                                <input placeholder='Search' type='text' style={{
                                                    border: "none",
                                                    outline: "none",
                                                    width: "100%",
                                                    height: "100%",
                                                    padding: "5px 8px 5px 8px",
                                                    color: "dimgrey",
                                                    fontSize: "13px"
                                                }}/>
                                            </div>
                                        </div>
                                        {/*추후 맵처리*/}
                                        {nonMemberList.map((list, index) => (
                                            <div onClick={() => handleNonChatMessages(list && list, index)} key={index}
                                                 className='nonUserBt' tabIndex='0' style={{
                                                height: "60px",
                                                display: "flex",
                                                alignItems: "center",
                                                padding: "5px",
                                                cursor: "pointer"
                                            }}>
                                                <div style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                    <img alt='profile'
                                                         style={{width: "35px", height: "35px", borderRadius: "50%"}}
                                                         src={AdminProfileBase}/>
                                                </div>
                                                <div style={{width: "85%", height: "100%", padding: "5px"}}>
                                                    <div style={{
                                                        height: "40%",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center"
                                                    }}>
                                                        <div>
                                                            <span style={{
                                                                margin: "0",
                                                                fontSize: "12px",
                                                                color: "black"
                                                            }}>{list && list.msgNum}</span>
                                                            {nonMemberList[index].unreadCount > 0 && nonMemberList[index].role === '1' ?
                                                                <span style={{
                                                                    borderRadius: "100%",
                                                                    width: "auto",
                                                                    height: "16px",
                                                                    backgroundColor: "orangered",
                                                                    color: "white",
                                                                    fontSize: "11px",
                                                                    padding: "0 5px",
                                                                    marginLeft: "10px"
                                                                }}>{nonMemberList[index].unreadCount}</span>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        <h6 style={{
                                                            margin: "0",
                                                            fontSize: "11px",
                                                            color: "dimgrey",
                                                            fontFamily: "reverg"
                                                        }}>{list && convertToNonMemberDate(list.regTime)}</h6>
                                                    </div>
                                                    <div style={{height: "60%", display: "flex", alignItems: "center"}}>
                                                        <p style={{
                                                            margin: "0",
                                                            fontSize: "13px",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                            wordBreak: "break-all"
                                                        }}>
                                                            {/*{list && list.chatKey}*/}
                                                            {(list && list.chatKey.endsWith("png") ||
                                                                list.chatKey.endsWith("PNG") ||
                                                                list.chatKey.endsWith("jpg") ||
                                                                list.chatKey.endsWith("JPG") ||
                                                                list.chatKey.endsWith("jpeg") ||
                                                                list.chatKey.endsWith("JPEG")) ?
                                                                <span style={{font: "18px"}}>[사진]</span> :
                                                                list.chatKey.startsWith("chatFile") ?
                                                                    <span
                                                                        style={{font: "18px"}}>[파일]</span> : list.chatKey}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {/*매크로리스트*/}
                                    <div style={{
                                        width: "20%",
                                        overflow: "auto",
                                        backgroundColor: "#F6F6F6",
                                        padding: "0 10px 10px 10px",
                                    }}>
                                        <div>
                                            {/*추후 맵처리 버튼*/}
                                            {macroMstList.map((mst, mstIdx) => (
                                                <div key={mstIdx}>
                                                    <div onClick={() => fetchNonMacroDtList(mstIdx)} style={{
                                                        height: "40px",
                                                        backgroundColor: "white",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        position: "relative",
                                                        margin: "10px 0 3px 0",
                                                        cursor: "pointer"
                                                    }}>
                                                        <div style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center"
                                                        }}>
                                                            <p style={{
                                                                margin: "0",
                                                                fontWeight: "lighter",
                                                                fontSize: "13px"
                                                            }}>{mst && mst.title}</p>
                                                        </div>
                                                        <div style={{position: "absolute", top: "18%", right: "10px"}}>
                                                            <img alt='bottom'
                                                                 style={{width: "10px", height: "10px", opacity: "0.4"}}
                                                                 src={commonBottom}/>
                                                        </div>
                                                    </div>
                                                    {nonSelectedMacroMstIndex === mstIdx && macroDtList && macroDtList.map((dt, dtIdx) => (
                                                        <div key={dtIdx} className='macroConBt' tabIndex='0'
                                                             onClick={() => sendNonMacroMessage(dt.content)} style={{
                                                            height: "40px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            cursor: "pointer",
                                                            alignItems: "center",
                                                            padding: "0 10px 0 10px"
                                                        }}>
                                                            <div style={{
                                                                width: "10%",
                                                                height: "100%",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                                <img alt='list' style={{width: "15px", height: "15px"}}
                                                                     src={commonList}/>
                                                            </div>
                                                            <div style={{
                                                                width: "90%",
                                                                height: "100%",
                                                                display: "flex",
                                                                alignItems: "center"
                                                            }}>
                                                <span style={{
                                                    width: "100%",
                                                    fontSize: "12px",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    wordBreak: "break-all",
                                                    marginLeft: "5px"
                                                }}>{dt && dt.title}</span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {/*채팅바디*/}
                                    <div style={{width: "75%", backgroundColor: "#FFF5E4"}}>
                                        {/*비회원채팅 오픈*/}
                                        {nonChatShow ?
                                            <div>
                                                <div style={{
                                                    height: "562px",
                                                    backgroundColor: nonChatColor ? "#FAFAFA" : "#FFF5E4",
                                                    overflow: "auto",
                                                    padding: "10px 0 10px 0"
                                                }} ref={nonMessagesEndRef}>
                                                    {nonChatMessages.map((message, index) => (
                                                        <div key={index}>

                                                            {(isFirstMessageOfDay(message, nonChatMessages[index - 1]) &&
                                                                <div style={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    marginBottom: "10px",
                                                                    justifyContent: "center",
                                                                    marginTop: "20px"
                                                                }}>
                                                                    <p style={{
                                                                        margin: "0",
                                                                        fontSize: "10px"
                                                                    }}>{formatDate(new Date(message.regTime))}</p>
                                                                </div>
                                                            )}
                                                            <div style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: message && message.role === '1' ? "flex-start" : "flex-end"
                                                            }}>
                                                                {message && message.role === '1' ?
                                                                    // 비회원 사용자채팅
                                                                    <div style={{minHeight: "45px", display: "flex"}}>
                                                                        <div style={{
                                                                            width: "40px",
                                                                            height: "40px",
                                                                            marginLeft: "10px",
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center"
                                                                        }}>
                                                                            <img alt=''
                                                                                 style={{width: "35px", height: "35px"}}
                                                                                 src={profileDefaultImg}/>
                                                                        </div>

                                                                        <div style={{display: "flex"}}>

                                                                            {message.chatKey.startsWith("chatFile") ?
                                                                                <div
                                                                                    onClick={!message.removeFl ? () => nonClickFileDetail(message) : null}
                                                                                    style={{
                                                                                        marginLeft: "3px",
                                                                                        cursor: "pointer"
                                                                                    }}>
                                                                                    {isImage(message.chatKey) ?
                                                                                        <div>
                                                                                            {!message.removeFl ?
                                                                                                <p style={{
                                                                                                    margin: "3px",
                                                                                                    width: "fit-content",
                                                                                                    wordBreak: "keep-all",
                                                                                                    textAlign: "left",
                                                                                                    fontWeight: "lighter"
                                                                                                }}><img
                                                                                                    style={{
                                                                                                        maxWidth: "300px",
                                                                                                        maxHeight: "500px"
                                                                                                    }}
                                                                                                    src={message.fileUrl}
                                                                                                    alt="chatKey"/></p>
                                                                                                :
                                                                                                <div style={{
                                                                                                    padding: "10px",
                                                                                                    textAlign: "center"
                                                                                                }}>
                                                                                                    <img style={{
                                                                                                        width: "40px",
                                                                                                        height: "40px",
                                                                                                        opacity: "0.5"
                                                                                                    }} src={trash}
                                                                                                         alt="remove"/>
                                                                                                    <p style={{
                                                                                                        margin: "0",
                                                                                                        fontSize: "11px",
                                                                                                        marginTop: "5px",
                                                                                                        color: "darkgray"
                                                                                                    }}>(기한만료)</p>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                        :
                                                                                        // 비회원 사용자동영상
                                                                                        isVideo(message.chatKey) ?
                                                                                            <div>
                                                                                                {!message.removeFl ?
                                                                                                    <div
                                                                                                        onClick={() => nonClickFileDetail(message)}
                                                                                                        style={{
                                                                                                            margin: "10px 0 0 0",
                                                                                                            padding: "0"
                                                                                                        }}>
                                                                                                        <video
                                                                                                            width="300"
                                                                                                            height="250"
                                                                                                            controls>
                                                                                                            <source
                                                                                                                src={message.fileUrl}
                                                                                                                type="video/mp4"/>
                                                                                                        </video>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div style={{
                                                                                                        padding: "10px",
                                                                                                        textAlign: "center"
                                                                                                    }}>
                                                                                                        <img style={{
                                                                                                            width: "40px",
                                                                                                            height: "40px",
                                                                                                            opacity: "0.5"
                                                                                                        }} src={trash}
                                                                                                             alt="remove"/>
                                                                                                        <p style={{
                                                                                                            margin: "0",
                                                                                                            fontSize: "11px",
                                                                                                            marginTop: "5px",
                                                                                                            color: "darkgray"
                                                                                                        }}>(기한만료)</p>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                            :
                                                                                            // 비회원 사용자파일
                                                                                            <div>
                                                                                                {!message.removeFl ?
                                                                                                    <div
                                                                                                        onClick={() => nonClickFileDetail(message)}
                                                                                                        style={{
                                                                                                            width: "150px",
                                                                                                            height: "130px",
                                                                                                            backgroundColor: "white",
                                                                                                            borderRadius: "6px"
                                                                                                        }}>
                                                                                                        <div style={{
                                                                                                            height: "60%",
                                                                                                            display: "flex",
                                                                                                            justifyContent: "center",
                                                                                                            alignItems: "center",
                                                                                                            overflow: "hidden"
                                                                                                        }}>
                                                                                                            <img
                                                                                                                style={{
                                                                                                                    width: "50%",
                                                                                                                    height: "90%",
                                                                                                                    paddingTop: "6px"
                                                                                                                }}
                                                                                                                src={chatFileImg}
                                                                                                                alt="fileImg"/>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="font-t-cont"
                                                                                                            style={{
                                                                                                                height: "40%",
                                                                                                            }}>
                                                                                                            <div
                                                                                                                className="user-center wd-100pc m-l-5">
                                                                                                                <div
                                                                                                                    className="m-a-chatKey-fileNm-text">
                                                                                                                    {message.fileNm}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <p style={{
                                                                                                                width: "100%",
                                                                                                                height: "50%",
                                                                                                                display: "flex",
                                                                                                                justifyContent: "center",
                                                                                                                margin: "0",
                                                                                                                color: "dimgrey",
                                                                                                                fontSize: "12px",
                                                                                                                paddingBottom: "5px"
                                                                                                            }}> ~ {oneMonthFromNow(message.deadline)}까지</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div style={{
                                                                                                        padding: "10px",
                                                                                                        textAlign: "center"
                                                                                                    }}>
                                                                                                        <img style={{
                                                                                                            width: "40px",
                                                                                                            height: "40px",
                                                                                                            opacity: "0.5"
                                                                                                        }} src={trash}
                                                                                                             alt="remove"/>
                                                                                                        <p style={{
                                                                                                            margin: "0",
                                                                                                            fontSize: "11px",
                                                                                                            marginTop: "5px",
                                                                                                            color: "darkgray"
                                                                                                        }}>(기한만료)</p>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                // 비회원 사용자텍스트
                                                                                <div style={{
                                                                                    backgroundColor: "white",
                                                                                    margin: "6px 0 6px 0",
                                                                                    borderRadius: "4px",
                                                                                }}>
                                                                                    <Linkify
                                                                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                                            <a style={{color: "orangered"}}
                                                                                               target="blank"
                                                                                               href={decoratedHref}
                                                                                               key={key}>
                                                                                                {decoratedText}
                                                                                            </a>
                                                                                        )}
                                                                                    >
                                                                                        <p style={{
                                                                                            border: nonChatColor ? "solid" : null,
                                                                                            borderWidth: nonChatColor ? "thin 1px" : null,
                                                                                            borderColor: nonChatColor ? "#E5E5E5" : null,
                                                                                            borderRadius: nonChatColor ? "6px" : null,
                                                                                            maxWidth: "400px",
                                                                                            width: "fit-content",
                                                                                            wordBreak: "break-word",
                                                                                            whiteSpace: "pre-wrap",
                                                                                            margin: "0",
                                                                                            padding: "8px",
                                                                                            fontSize: "13px",
                                                                                            color: "black",
                                                                                            fontWeight: "lighter"
                                                                                            // 비회원 텍스트 채팅메시지
                                                                                        }}>{DOMPurify.sanitize(message.chatKey)}</p>
                                                                                    </Linkify>
                                                                                </div>
                                                                            }
                                                                            <div style={{
                                                                                display: "flex",
                                                                                alignItems: "flex-end",
                                                                                padding: "6px"
                                                                            }}>
                                                                                {!message.removeFl ?
                                                                                    <p style={{
                                                                                        margin: "0",
                                                                                        fontSize: "10px"
                                                                                    }}>{formatTime(message.regTime)}</p>
                                                                                    :
                                                                                    null
                                                                                }
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    // 비회원 관리자채팅
                                                                    <div style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        margin: "5px"
                                                                    }}>
                                                                        <div style={{display: "flex"}}>

                                                                            {!message.autoFl ?
                                                                                <div style={{
                                                                                    display: "flex",
                                                                                    alignItems: "flex-end",
                                                                                    marginRight: "5px"
                                                                                }}>
                                                                                    {message && message.unreadCount > 0 ?
                                                                                        <p style={{
                                                                                            margin: "0",
                                                                                            marginRight: "5px",
                                                                                            fontSize: "10px",
                                                                                            color: "darkorange"
                                                                                        }}>1</p>
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                    {!message.removeFl ?
                                                                                        <div className="user-center">
                                                                                            <p onClick={() => openNonModifyModal(message)}
                                                                                               style={{
                                                                                                   margin: "0",
                                                                                                   fontSize: "10px",
                                                                                                   color: "lightblue",
                                                                                                   fontWeight: "bold",
                                                                                                   cursor: "pointer"
                                                                                               }}>수정</p>
                                                                                            <p onClick={() => openNonDeleteModal(message)}
                                                                                               style={{
                                                                                                   margin: "0",
                                                                                                   fontSize: "10px",
                                                                                                   color: "#FFAF34",
                                                                                                   marginLeft: "3px",
                                                                                                   cursor: "pointer"
                                                                                               }}>삭제</p>
                                                                                        </div>
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                    {!message.removeFl ?
                                                                                        <p style={{
                                                                                            margin: "0",
                                                                                            fontSize: "10px",
                                                                                            marginLeft: "5px"
                                                                                        }}>{formatTime(message.regTime)}</p>
                                                                                        :
                                                                                        null
                                                                                    }

                                                                                </div>
                                                                                :
                                                                                null
                                                                            }
                                                                            {message.chatKey.startsWith("chatFile") ?
                                                                                <div
                                                                                    style={{
                                                                                        marginLeft: "3px",
                                                                                        cursor: "pointer"
                                                                                    }}>
                                                                                    {/*관리자 이미지채팅*/}
                                                                                    {isImage(message.chatKey) ?
                                                                                        <div>
                                                                                            {!message.removeFl ?
                                                                                                <div
                                                                                                    onClick={() => nonClickFileDetail(message)}>
                                                                                                    <img
                                                                                                        style={{
                                                                                                            maxWidth: "300px",
                                                                                                            maxHeight: "500px"
                                                                                                        }}
                                                                                                        src={message.fileUrl}
                                                                                                        alt="chatKey"/>
                                                                                                </div>
                                                                                                :
                                                                                                <div style={{
                                                                                                    padding: "10px",
                                                                                                    textAlign: "center"
                                                                                                }}>
                                                                                                    <img style={{
                                                                                                        width: "40px",
                                                                                                        height: "40px",
                                                                                                        opacity: "0.5"
                                                                                                    }} src={trash}
                                                                                                         alt="remove"/>
                                                                                                    <p style={{
                                                                                                        margin: "0",
                                                                                                        fontSize: "11px",
                                                                                                        marginTop: "5px",
                                                                                                        color: "darkgray"
                                                                                                    }}>(기한만료)</p>
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                        :
                                                                                        // 비회원 관리자 동영상채팅
                                                                                        isVideo(message.chatKey) ?
                                                                                            <div>
                                                                                                {!message.removeFl ?
                                                                                                    <div
                                                                                                        onClick={() => nonClickFileDetail(message)}
                                                                                                        style={{
                                                                                                            margin: "0",
                                                                                                            padding: "0"
                                                                                                        }}>
                                                                                                        <video
                                                                                                            width="300"
                                                                                                            height="250"
                                                                                                            controls>
                                                                                                            <source
                                                                                                                src={message.fileUrl}
                                                                                                                type="video/mp4"/>
                                                                                                        </video>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div style={{
                                                                                                        padding: "10px",
                                                                                                        textAlign: "center"
                                                                                                    }}>
                                                                                                        <img style={{
                                                                                                            width: "40px",
                                                                                                            height: "40px",
                                                                                                            opacity: "0.5"
                                                                                                        }} src={trash}
                                                                                                             alt="remove"/>
                                                                                                        <p style={{
                                                                                                            margin: "0",
                                                                                                            fontSize: "11px",
                                                                                                            marginTop: "5px",
                                                                                                            color: "darkgray"
                                                                                                        }}>(기한만료)</p>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                            :
                                                                                            // 비회원 관리자파일
                                                                                            <div>
                                                                                                {!message.removeFl ?
                                                                                                    <div
                                                                                                        onClick={() => nonClickFileDetail(message)}
                                                                                                        style={{
                                                                                                            width: "150px",
                                                                                                            height: "130px",
                                                                                                            backgroundColor: "white",
                                                                                                            borderRadius: "6px"
                                                                                                        }}>
                                                                                                        <div style={{
                                                                                                            height: "60%",
                                                                                                            display: "flex",
                                                                                                            justifyContent: "center",
                                                                                                            alignItems: "center",
                                                                                                            overflow: "hidden"
                                                                                                        }}>
                                                                                                            <img
                                                                                                                style={{
                                                                                                                    width: "50%",
                                                                                                                    height: "90%",
                                                                                                                    paddingTop: "6px"
                                                                                                                }}
                                                                                                                src={chatFileImg}
                                                                                                                alt="fileImg"/>
                                                                                                        </div>
                                                                                                        <div
                                                                                                            className="font-t-cont"
                                                                                                            style={{
                                                                                                                height: "40%",
                                                                                                            }}>
                                                                                                            <div
                                                                                                                className="user-center wd-100pc m-l-5">
                                                                                                                <div
                                                                                                                    className="m-a-chatKey-fileNm-text">
                                                                                                                    {message.fileNm}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <p style={{
                                                                                                                width: "100%",
                                                                                                                height: "50%",
                                                                                                                display: "flex",
                                                                                                                justifyContent: "center",
                                                                                                                margin: "0",
                                                                                                                color: "dimgrey",
                                                                                                                fontSize: "12px",
                                                                                                                paddingBottom: "5px"
                                                                                                            }}> ~ {oneMonthFromNow(message.deadline)}까지</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                    <div style={{
                                                                                                        padding: "10px",
                                                                                                        textAlign: "center"
                                                                                                    }}>
                                                                                                        <img style={{
                                                                                                            width: "40px",
                                                                                                            height: "40px",
                                                                                                            opacity: "0.5"
                                                                                                        }} src={trash}
                                                                                                             alt="remove"/>
                                                                                                        <p style={{
                                                                                                            margin: "0",
                                                                                                            fontSize: "11px",
                                                                                                            marginTop: "5px",
                                                                                                            color: "darkgray"
                                                                                                        }}>(기한만료)</p>
                                                                                                    </div>
                                                                                                }
                                                                                            </div>
                                                                                    }
                                                                                </div>
                                                                                :
                                                                                // 비회원 관리자텍스트
                                                                                <div style={{
                                                                                    backgroundColor: "#FFAA4D",
                                                                                    borderRadius: "15px",
                                                                                }}>
                                                                                    <Linkify
                                                                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                                            <a style={{color: "orangered"}}
                                                                                               target="blank"
                                                                                               href={decoratedHref}
                                                                                               key={key}>
                                                                                                {decoratedText}
                                                                                            </a>
                                                                                        )}
                                                                                    >
                                                                                        <p style={{
                                                                                            maxWidth: "400px",
                                                                                            width: "fit-content",
                                                                                            wordBreak: "break-word",
                                                                                            whiteSpace: "pre-wrap",
                                                                                            margin: "0",
                                                                                            padding: "8px",
                                                                                            color: "white",
                                                                                            fontSize: "13px",
                                                                                            fontWeight: "lighter"
                                                                                            // 텍스트 채팅메시지
                                                                                        }}>{DOMPurify.sanitize(message.chatKey)}</p>
                                                                                    </Linkify>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                {/*채팅푸터*/}
                                                <div style={{backgroundColor: "#F6F6F6"}}>
                                                    <div style={{padding: "10px", borderRadius: "4px"}}>
                                                        {/*채팅메시지*/}
                                                        <div style={{backgroundColor: "white", display: "flex"}}>
                                                            <div style={{
                                                                width: "95%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center"
                                                            }}>
                                                                {/*채팅인풋*/}
                                                                <textarea ref={nonChatInputRef} placeholder=""
                                                                          spellCheck="false" value={nonMessage}
                                                                          style={{
                                                                              borderRadius: "6px",
                                                                              width: "100%",
                                                                              resize: "none",
                                                                              height: "42px",
                                                                              maxHeight: "200px",
                                                                              overflow: "auto",
                                                                              outline: "none",
                                                                              padding: "10px",
                                                                              fontSize: "14px",
                                                                              border: "1px solid transparent",
                                                                              boxSizing: "border-box"
                                                                          }}
                                                                          onChange={nonHandleChatInput}
                                                                          onInput={(e) => nonResizeTextArea()}
                                                                          onKeyDown={(e) => {
                                                                              if (e.key === 'Enter' && !e.shiftKey) {
                                                                                  e.preventDefault();
                                                                                  nonSendMessage(nonMessage, null, sendMsgNum);
                                                                                  setNonMessage('');
                                                                                  nonChatInputRef.current.style.height = '42px';
                                                                              }
                                                                          }}
                                                                />
                                                            </div>
                                                            <div onClick={() => {
                                                                nonSendMessage(nonMessage, null, sendMsgNum);
                                                                setNonMessage('');
                                                            }} style={{
                                                                width: "5%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                cursor: "pointer"
                                                            }}>
                                                                <img alt='' style={{
                                                                    width: "25px",
                                                                    height: "25px",
                                                                    opacity: nonMessage.length > 0 ? "1" : "0.6"
                                                                }} src={mainChatSend}/>
                                                            </div>
                                                        </div>
                                                        {/*emoji & upload & remove*/}
                                                        <div style={{
                                                            height: "50%",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            marginTop: "10px",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <div style={{display: "flex"}}>
                                                                <div onClick={() => setShowNonEmoji(!showNonEmoji)}
                                                                     style={{
                                                                         width: "30px",
                                                                         height: "30px",
                                                                         cursor: "pointer",
                                                                         paddingTop: "1px"
                                                                     }}>
                                                                    <img alt='' style={{width: "25px", height: "25px"}}
                                                                         src={mainChatEmoji}/>
                                                                </div>
                                                                {/*이모티콘*/}
                                                                {showNonEmoji ?
                                                                    <div style={{position: "relative"}}>
                                                                        <div style={{
                                                                            position: "absolute",
                                                                            bottom: "-10px",
                                                                            right: "50px",
                                                                        }}>
                                                                            <Picker
                                                                                onEmojiSelect={nonHandleEmojiClick}
                                                                                previewPosition="none"
                                                                                searchPosition="none"
                                                                                skinTonePosition="none"
                                                                                locale="kr"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                                {/*파일업로드*/}
                                                                <div style={{
                                                                    width: "30px",
                                                                    height: "30px",
                                                                    marginLeft: "1px",
                                                                    cursor: "pointer"
                                                                }}>
                                                                    <input
                                                                        type="file"
                                                                        ref={nonFileInputRef}
                                                                        style={{display: "none"}}
                                                                        onChange={nonHandleFileUpload}
                                                                    />
                                                                    <button style={{
                                                                        width: "30px",
                                                                        height: "30px",
                                                                        border: "none",
                                                                        display: "flex",
                                                                        overflow: "hidden",
                                                                        justifyContent: "center",
                                                                        alignItems: "center"
                                                                    }} onClick={nonTriggerFileInput}>
                                                                        <img alt=''
                                                                             style={{width: "25px", height: "25px"}}
                                                                             src={mainChatUpload}/>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            {/*{ nUserChatRemoveSt ?*/}
                                                            {/*    <MsgCheckModal nUserChatRemoveSt={nUserChatRemoveSt} nUserChatRemoveClose={setNUserChatRemoveSt} nUserChatRemove={nUserChatRemove}/>*/}
                                                            {/*    :*/}
                                                            {/*    null*/}
                                                            {/*}*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            // 비회원 스타트
                                            <div style={{
                                                backgroundColor: "white",
                                                height: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <div style={{
                                                    width: "100%",
                                                    height: "200px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}>
                                                    <div style={{opacity: "0.5"}}>
                                                        <img style={{width: "100px", height: "100px"}}
                                                             src={adminChatStart} alt=""/>
                                                        <p style={{margin: "0", fontSize: "20px"}}>Chat Start!</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                null
            }
            {/*자동응답탭*/}
            {autoRes ?
                <div className="admin-wrap font-t-cont">
                    <div className="admin-chat-wrap">
                        <div className="admin-chat-main gbn-border-b">
                            <div style={{
                                width: "100%",
                                padding: "20px",
                                display: "flex",
                                justifyContent: "space-between"
                            }}>
                                {/*List*/}
                                <div style={{width: "32%", borderWidth: "thin 1px", borderColor: "lightgray"}}>
                                    <div style={{
                                        height: "12%",
                                        borderBottom: "solid",
                                        borderWidth: "thin 1px",
                                        borderColor: "dimgrey",
                                        display: "flex",
                                        alignItems: "center"
                                    }}>
                                        <img style={{width: "30px", height: "30px"}} src={adminList} alt=""/>
                                        <p style={{margin: "0", fontSize: "18px", marginLeft: "10px"}}>List</p>
                                    </div>
                                    <div style={{height: "88%", display: "flex", justifyContent: "space-between"}}>
                                        {/*마스터*/}
                                        <div style={{
                                            width: "48%",
                                            border: "solid",
                                            borderWidth: "thin 1px",
                                            borderColor: "lightgray",
                                            marginTop: "30px",
                                            maxHeight: "600px",
                                            overflow: "auto"
                                        }}>
                                            <div style={{
                                                height: "50px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <img style={{width: "25px", height: "25px"}} src={adminParent} alt=""/>
                                                <p style={{margin: "0", marginLeft: "3px"}}>master</p>
                                            </div>
                                            {autoResMstList.map((master, index) => (
                                                <div key={index}
                                                     onClick={() => selectedMasterBt(master, index)}
                                                     style={{
                                                         backgroundColor: masterBtFocused === index ? "dimgray" : "white",
                                                         height: "50px",
                                                         margin: "8px",
                                                         cursor: "pointer",
                                                         color: masterBtFocused === index ? "white" : "dimgray",
                                                         border: "solid",
                                                         borderWidth: "thin 1px",
                                                         borderColor: masterBtFocused === index ? "dimgray" : "lightgray",
                                                     }}>
                                                    <h6 style={{
                                                        margin: "0",
                                                        width: "100%",
                                                        height: "100%",
                                                        display: "flex",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        fontSize: "12px"
                                                    }}>{master && master.title}</h6>
                                                </div>
                                            ))}
                                        </div>
                                        {/*디테일*/}
                                        <div style={{
                                            width: "48%",
                                            border: "solid",
                                            borderWidth: "thin 1px",
                                            borderColor: "lightgray",
                                            marginTop: "30px",
                                            maxHeight: "600px",
                                            overflow: "auto"
                                        }}>
                                            <div style={{
                                                height: "50px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <img style={{width: "25px", height: "25px"}} src={adminChild} alt=""/>
                                                <p style={{margin: "0", marginLeft: "5px"}}>detail</p>
                                            </div>

                                            {autoResDtList.map((detail, index) => (
                                                <div key={index}
                                                     onClick={() => selectedDetailBt(detail, index)}
                                                     style={{
                                                         backgroundColor: detailBtFocused === index ? "dimgray" : "white",
                                                         height: "50px",
                                                         margin: "8px",
                                                         cursor: "pointer",
                                                         color: detailBtFocused === index ? "white" : "dimgray",
                                                         border: "solid",
                                                         borderWidth: "thin 1px",
                                                         borderColor: detailBtFocused === index ? "dimgray" : "lightgray"
                                                     }}>
                                                    <h6 style={{
                                                        margin: "0",
                                                        width: "100%",
                                                        height: "100%",
                                                        display: "flex",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        fontSize: "12px"
                                                    }}>{detail && detail.title}</h6>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                {/*New*/}
                                <div style={{width: "32%", borderWidth: "thin 1px", borderColor: "lightgray"}}>
                                    <div style={{
                                        height: "12%",
                                        borderBottom: "solid",
                                        borderWidth: "thin 1px",
                                        borderColor: "dimgrey",
                                        display: "flex",
                                        alignItems: "center"
                                    }}>
                                        <img style={{width: "30px", height: "30px"}} src={adminNew} alt=""/>
                                        <p style={{margin: "0", fontSize: "18px", marginLeft: "10px"}}>New</p>
                                    </div>
                                    <div style={{height: "88%", display: "flex"}}>
                                        <div style={{
                                            width: "100%",
                                            border: "solid",
                                            borderWidth: "thin 1px",
                                            borderColor: "lightgray",
                                            marginTop: "30px",
                                            padding: "20px"
                                        }}>
                                            <div style={{width: "100%", height: "60px"}}>
                                                {/*new form*/}
                                                <div style={{height: "100%"}}>
                                                    {/*커스텀 mst select*/}
                                                    <div ref={newMstRef}
                                                         style={{height: "100%", width: "100%", position: "relative"}}>
                                                        <button className="selectParentBt"
                                                                style={{width: "100%", height: "100%"}}
                                                                onClick={toggleAutoResShow}>
                                                            <p style={{margin: "0"}}>{selectedNewMstTitle === "" ? "Master Title" : selectedNewMstTitle}</p>
                                                            <span style={{
                                                                position: "absolute",
                                                                left: "85%",
                                                                top: "0",
                                                                height: "100%",
                                                                width: "20%"
                                                            }}>
                                                                    <i style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        height: "100%"
                                                                    }}><BiCaretDown/></i>
                                                                </span>
                                                        </button>
                                                        {autoMstShow ?
                                                            <div style={{
                                                                position: 'absolute',
                                                                width: "100%",
                                                                border: "solid",
                                                                height: "auto",
                                                                maxHeight: "300px",
                                                                borderWidth: "thin 1px",
                                                                borderColor: "lightgray",
                                                                zIndex: 1000,
                                                                marginTop: "5px",
                                                                backgroundColor: "white",
                                                                overflow: "auto"
                                                            }}>
                                                                {autoResMstList.map((autoRes, index) => (
                                                                    <div className="selectChildBt" key={index}
                                                                         value={autoRes.title}
                                                                         onClick={() => handleAutoResChange(autoRes)}
                                                                         style={{
                                                                             display: "flex",
                                                                             padding: "15px",
                                                                             justifyContent: "center",
                                                                             alignItems: "center",
                                                                             cursor: "pointer",
                                                                             fontSize: "14px",
                                                                         }}>{autoRes.title}</div>
                                                                ))}
                                                                <div onClick={() => setMasterAddPopup(true)}
                                                                     className="selectChildBt" style={{
                                                                    display: "flex",
                                                                    padding: "15px",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    cursor: "pointer",
                                                                    fontSize: "14px",
                                                                }}>+마스터추가
                                                                </div>
                                                                {masterAddPopup ?
                                                                    <AdminMasterAdd masterAddClose={setMasterAddPopup}
                                                                                    selectAutoResList={selectAutoResList}/>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                                        {/*dt title*/}
                                                        <div className="user-input-container" style={{
                                                            marginTop: "10px",
                                                            height: "50px",
                                                            position: "relative",
                                                            width:"58%"
                                                        }}>
                                                            <input className="ReviewInput user-input"
                                                                   style={{
                                                                       width: "100%",
                                                                       paddingLeft: "10px",
                                                                       paddingRight: "10px",
                                                                       fontSize: "14px",
                                                                       color: "dimgray"
                                                                   }} maxLength={40} type="text"
                                                                   placeholder="Detail Title" value={newDtTitleText}
                                                                   onChange={handleNewDtTitleText}/>

                                                            <div style={{position: "absolute", bottom: "0", right: "3px"}}>
                                                                <span style={{fontSize: "12px", color: newDtTitleLength > 0 ? "orangered" : null}}>{newDtTitleLength}</span>
                                                                <span style={{fontSize: "12px"}}>/50</span>
                                                            </div>
                                                        </div>
                                                        {/*newDt Lang*/}
                                                        <div style={{width: "40%", height: "50px", position: "relative", marginTop:"10px"}}>
                                                            {/*여기서부터 커스텀 newDt Lang 셀렉트*/}
                                                            <div ref={newDtLangRef} style={{height: "100%", width: "100%"}}>
                                                                <button className="selectParentBt" style={{width: "100%", height: "100%"}}
                                                                        onClick={toggleNewDtLangShow}>
                                                                    <p style={{margin: "0"}}>{!selectedNewDtLangTitle ? "언어선택" : selectedNewDtLangTitle}</p>
                                                                    <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                                                        <i style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            height: "100%"
                                                                        }}><BiCaretDown/></i>
                                                                    </span>
                                                                </button>
                                                                {selectedNewDtLangShow ?
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        width: "100%",
                                                                        border: "solid",
                                                                        height: "auto",
                                                                        maxHeight: "300px",
                                                                        borderWidth: "thin 1px",
                                                                        borderColor: "lightgray",
                                                                        zIndex: 1000,
                                                                        marginTop: "5px",
                                                                        backgroundColor: "white",
                                                                        overflow: "auto",
                                                                    }}>
                                                                        {selectedNewDtLang.map((lang, index) => (
                                                                            <div className="selectChildBt" key={index}
                                                                                 onClick={() => handleNewDtLangChange(lang)} style={{
                                                                                display: "flex", padding: "10px", justifyContent: "center",
                                                                                alignItems: "center", cursor: "pointer", fontSize: "14px", borderRadius: "4px", zIndex:99,
                                                                            }}>{lang.title}</div>
                                                                        ))}
                                                                    </div>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                        </div>
                                                        {/*여기까지 커스텀 newDt Lang 셀렉트*/}
                                                    </div>
                                                    {/*dt content*/}
                                                    <div className="user-input-container" style={{
                                                        marginTop: "10px",
                                                        height: "auto",
                                                        overflowY: 'auto',
                                                        position: "relative"
                                                    }}>
                                                            <textarea
                                                                className="ReviewInput user-input"
                                                                placeholder="Detail Content"
                                                                spellCheck="false"
                                                                value={newDtContentText}
                                                                style={{
                                                                    height: "330px",
                                                                    borderRadius: "6px",
                                                                    width: "100%",
                                                                    resize: "none",
                                                                    overflow: "auto",
                                                                    outline: "none",
                                                                    color: "dimgray",
                                                                    padding: "10px",
                                                                    fontSize: "14px",
                                                                }} maxLength={3000}
                                                                onChange={(e) => handleNewDtContentText(e)}/>
                                                        <div style={{
                                                            position: "absolute",
                                                            bottom: "10px",
                                                            right: "10px"
                                                        }}>
                                                            <span style={{
                                                                fontSize: "12px",
                                                                color: newDtContentLength > 0 ? "orangered" : null
                                                            }}>{newDtContentLength}</span>
                                                            <span style={{fontSize: "12px"}}>/3000</span>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        height: "50px",
                                                        display: "flex",
                                                        alignItems: "flex-end"
                                                    }}>
                                                        <button onClick={saveNewDetail} className="ReviewPgWriteBtn"
                                                                style={{
                                                                    width: "100%",
                                                                    height: "40px",
                                                                    fontSize: "14px",
                                                                    borderWidth: "thin 1px",
                                                                    borderRadius: "4px",
                                                                    marginLeft: "5px"
                                                                }}>Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*Edit*/}
                                <div style={{width: "32%", borderWidth: "thin 1px", borderColor: "lightgray"}}>
                                    <div style={{
                                        height: "12%",
                                        borderBottom: "solid",
                                        borderWidth: "thin 1px",
                                        borderColor: "dimgrey",
                                        display: "flex",
                                        alignItems: "center"
                                    }}>
                                        <img style={{width: "30px", height: "30px"}}
                                             src={adminEdit} alt=""/>
                                        <p style={{margin: "0", fontSize: "18px", marginLeft: "10px"}}>Edit</p>
                                    </div>
                                    <div style={{height: "88%", display: "flex"}}>
                                        <div style={{
                                            width: "100%",
                                            border: "solid",
                                            borderWidth: "thin 1px",
                                            borderColor: "lightgray",
                                            marginTop: "30px",
                                            padding: "20px"
                                        }}>
                                            {masterModifyForm ?
                                                <div>
                                                    {/*Lang*/}
                                                    <div style={{width: "100%", height: "50px", position: "relative"}}>
                                                        {/*여기서부터 커스텀 Lang 셀렉트*/}
                                                        <div ref={langRef} style={{height: "100%", width: "100%"}}>
                                                            <button className="selectParentBt" style={{width: "100%", height: "100%"}}
                                                                    onClick={toggleMstLangShow}>
                                                                <p style={{margin: "0"}}>{!selectedMstLangTitle ? "언어선택" : selectedMstLangTitle}</p>
                                                                <span style={{position: "absolute", left: "85%", top: "0", height: "100%", width: "20%"}}>
                                                                <i style={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    height: "100%"
                                                                }}><BiCaretDown/></i>
                                                            </span>
                                                            </button>
                                                            {selectedLangShow ?
                                                                <div style={{
                                                                    position: 'absolute',
                                                                    width: "100%",
                                                                    border: "solid",
                                                                    height: "auto",
                                                                    maxHeight: "300px",
                                                                    borderWidth: "thin 1px",
                                                                    borderColor: "lightgray",
                                                                    zIndex: 1000,
                                                                    marginTop: "5px",
                                                                    backgroundColor: "white",
                                                                    overflow: "auto",
                                                                }}>
                                                                    {selectedMstLang.map((lang, index) => (
                                                                        <div className="selectChildBt" key={index}
                                                                             onClick={() => handleMstLangChange(lang)} style={{
                                                                            display: "flex", padding: "10px", justifyContent: "center",
                                                                            alignItems: "center", cursor: "pointer", fontSize: "14px", borderRadius: "4px", zIndex:99,
                                                                        }}>{lang.title}</div>
                                                                    ))}
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                    {/*여기까지 커스텀 Lang 셀렉트*/}

                                                    {/*edit form*/}
                                                    <div style={{width:"100%", height: "100%", marginTop: "10px"}}>
                                                        {/*dt title*/}
                                                        <div className="user-input-container" style={{
                                                            height: "50px",
                                                            position: "relative",
                                                        }}>
                                                            <input className="ReviewInput user-input"
                                                                   style={{
                                                                       width: "100%",
                                                                       paddingLeft: "10px",
                                                                       paddingRight: "10px",
                                                                       fontSize: "14px",
                                                                       color: "dimgray"
                                                                   }} maxLength={40} type="text"
                                                                   value={editMstTitleText}
                                                                   onChange={(e) => handleEditMstTitleText(e)}/>
                                                            <div style={{
                                                                position: "absolute",
                                                                bottom: "0",
                                                                right: "3px"
                                                            }}>
                                                            <span style={{
                                                                fontSize: "12px",
                                                                color: editMstTitleLength > 0 ? "orangered" : null
                                                            }}>{editMstTitleLength}</span>
                                                                <span style={{fontSize: "12px"}}>/50</span>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            height: "50px",
                                                            display: "flex",
                                                            marginTop: "20px",
                                                            justifyContent: "center"
                                                        }}>
                                                            <button onClick={() => setEditMstDeleteModal(true)}
                                                                    className="orderDeleteBt" style={{
                                                                width: "80px",
                                                                height: "40px",
                                                                textAlign: "center",
                                                                fontSize: "14px"
                                                            }}>Delete
                                                            </button>
                                                            <button onClick={modifyMaster} className="orderItemHandleBt"
                                                                    style={{
                                                                        width: "80px",
                                                                        height: "40px",
                                                                        fontSize: "14px",
                                                                        margin: "0",
                                                                        borderRadius: "4px",
                                                                        marginLeft: "8px"
                                                                    }}>Complete
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                detailModifyForm ?
                                                    // edit detail form
                                                    <div style={{height: "100%"}}>
                                                        {/*edit mst select*/}
                                                        <div ref={editMstRef} style={{
                                                            height: "60px",
                                                            width: "100%",
                                                            position: "relative"
                                                        }}>
                                                            <button className="selectParentBt"
                                                                    style={{width: "100%", height: "100%"}}
                                                                    onClick={toggleDtShow}>
                                                                <p style={{margin: "0"}}>{selectedEditMstTitle === "" ? "Master Title" : selectedEditMstTitle}</p>
                                                                <span style={{
                                                                    position: "absolute",
                                                                    left: "85%",
                                                                    top: "0",
                                                                    height: "100%",
                                                                    width: "20%"
                                                                }}>
                                                                    <i style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        height: "100%"
                                                                    }}><BiCaretDown/></i>
                                                                </span>
                                                            </button>
                                                            {autoDtShow ?
                                                                <div style={{
                                                                    position: 'absolute',
                                                                    width: "100%",
                                                                    border: "solid",
                                                                    height: "auto",
                                                                    borderWidth: "thin 1px",
                                                                    borderColor: "lightgray",
                                                                    zIndex: 1000,
                                                                    marginTop: "5px",
                                                                    backgroundColor: "white"
                                                                }}>
                                                                    {autoResMstList.map((autoRes, index) => (
                                                                        <div className="selectChildBt"
                                                                             key={index}
                                                                             value={autoRes.title}
                                                                             onClick={() => handleDtSelectChange(autoRes)}
                                                                             style={{
                                                                                 display: "flex",
                                                                                 padding: "15px",
                                                                                 justifyContent: "center",
                                                                                 alignItems: "center",
                                                                                 cursor: "pointer",
                                                                                 fontSize: "14px",
                                                                             }}>{autoRes.title}</div>
                                                                    ))}
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>

                                                        <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                                                            {/*edit dt title*/}
                                                            <div className="user-input-container" style={{
                                                                width:"58%",
                                                                marginTop: "10px",
                                                                height: "50px",
                                                                position: "relative"
                                                            }}>
                                                                <input className="ReviewInput user-input"
                                                                       style={{
                                                                           width: "100%",
                                                                           paddingLeft: "10px",
                                                                           paddingRight: "10px",
                                                                           fontSize: "14px",
                                                                           color: "dimgray"
                                                                       }} type="text"
                                                                       maxLength={40}
                                                                       value={editDtTitleText}
                                                                       onChange={(e) => handleEditDtTitleText(e)}/>
                                                                <div style={{
                                                                    position: "absolute",
                                                                    bottom: "0",
                                                                    right: "3px"
                                                                }}>
                                                                <span style={{
                                                                    fontSize: "12px",
                                                                    color: editDtTitleLength > 0 ? "orangered" : null
                                                                }}>{editDtTitleLength}</span>
                                                                    <span style={{fontSize: "12px"}}>/50</span>
                                                                </div>
                                                            </div>
                                                            {/*eidt dt Lang*/}
                                                            <div style={{width: "40%", height: "50px", position: "relative", marginTop:"10px"}}>
                                                                {/*여기서부터 커스텀 eidt dt Lang 셀렉트*/}
                                                                <div ref={editDtLangRef} style={{height: "100%", width: "100%"}}>
                                                                    <button className="selectParentBt" style={{width: "100%", height: "100%"}}
                                                                            onClick={toggleEditDtLangShow}>
                                                                        <p style={{margin: "0"}}>{!selectedEditDtLangTitle ? "언어선택" : selectedEditDtLangTitle}</p>
                                                                        <span style={{position: "absolute", left: "80%", top: "0", height: "100%", width: "20%"}}>
                                                                        <i style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                            alignItems: "center",
                                                                            height: "100%"
                                                                        }}><BiCaretDown/></i>
                                                                    </span>
                                                                    </button>
                                                                    {selectedEditDtLangShow ?
                                                                        <div style={{
                                                                            position: 'absolute',
                                                                            width: "100%",
                                                                            border: "solid",
                                                                            height: "auto",
                                                                            maxHeight: "300px",
                                                                            borderWidth: "thin 1px",
                                                                            borderColor: "lightgray",
                                                                            zIndex: 1000,
                                                                            marginTop: "5px",
                                                                            backgroundColor: "white",
                                                                            overflow: "auto",
                                                                        }}>
                                                                            {selectedEditDtLang.map((lang, index) => (
                                                                                <div className="selectChildBt" key={index}
                                                                                     onClick={() => handleEditDtLangChange(lang)} style={{
                                                                                    display: "flex", padding: "10px", justifyContent: "center",
                                                                                    alignItems: "center", cursor: "pointer", fontSize: "14px", borderRadius: "4px", zIndex:99,
                                                                                }}>{lang.title}</div>
                                                                            ))}
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                            </div>
                                                            {/*여기까지 커스텀 eidt dt Lang 셀렉트*/}
                                                        </div>

                                                        {/*edit dt content*/}
                                                        <div className="user-input-container" style={{
                                                            marginTop: "10px",
                                                            height: "auto",
                                                            overflowY: 'auto',
                                                            position: "relative"
                                                        }}>
                                                            <textarea
                                                                className="ReviewInput user-input"
                                                                spellCheck="false"
                                                                value={editDtContentText}
                                                                style={{
                                                                    height: "330px",
                                                                    borderRadius: "6px",
                                                                    width: "100%",
                                                                    resize: "none",
                                                                    overflow: "auto",
                                                                    outline: "none",
                                                                    color: "dimgray",
                                                                    padding: "10px",
                                                                    fontSize: "14px",
                                                                }} maxLength={1000}
                                                                onChange={(e) => handleEditDtContentText(e)}/>
                                                            <div style={{
                                                                position: "absolute",
                                                                bottom: "10px",
                                                                right: "10px"
                                                            }}>
                                                                <span style={{
                                                                    fontSize: "12px",
                                                                    color: editDtContentLength > 0 ? "orangered" : null
                                                                }}>{editDtContentLength}</span>
                                                                <span style={{fontSize: "12px"}}>/3000</span>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            height: "50px",
                                                            display: "flex",
                                                            alignItems: "flex-end",
                                                            justifyContent: "center"
                                                        }}>
                                                            <button onClick={() => setEditDtDeleteModal(true)}
                                                                    className="orderDeleteBt" style={{
                                                                width: "80px",
                                                                height: "40px",
                                                                textAlign: "center",
                                                                fontSize: "14px"
                                                            }}>Delete
                                                            </button>
                                                            <button onClick={modifyDetail}
                                                                    className="orderItemHandleBt"
                                                                    style={{
                                                                        width: "80px",
                                                                        height: "40px",
                                                                        fontSize: "14px",
                                                                        margin: "0",
                                                                        borderRadius: "4px",
                                                                        marginLeft: "8px"
                                                                    }}>Complete
                                                            </button>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                null
            }
            {/*매크로탭*/}
            {macro ?
                <div className="admin-wrap font-t-cont">
                    <div className="admin-chat-wrap">
                        <div className="admin-chat-main gbn-border-b">
                            <div style={{
                                width: "100%",
                                padding: "20px",
                                display: "flex",
                                justifyContent: "space-between"
                            }}>
                                {/*List*/}
                                <div style={{width: "32%", borderWidth: "thin 1px", borderColor: "lightgray"}}>
                                    <div style={{
                                        height: "12%",
                                        borderBottom: "solid",
                                        borderWidth: "thin 1px",
                                        borderColor: "dimgrey",
                                        display: "flex",
                                        alignItems: "center"
                                    }}>
                                        <img style={{width: "30px", height: "30px"}} src={adminList} alt=""/>
                                        <p style={{margin: "0", fontSize: "18px", marginLeft: "10px"}}>List</p>
                                    </div>
                                    <div style={{height: "88%", display: "flex", justifyContent: "space-between"}}>
                                        {/*마스터*/}
                                        <div style={{
                                            width: "48%",
                                            border: "solid",
                                            borderWidth: "thin 1px",
                                            borderColor: "lightgray",
                                            marginTop: "30px",
                                            maxHeight: "600px",
                                            overflow: "auto"
                                        }}>
                                            <div style={{
                                                height: "50px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <img style={{width: "25px", height: "25px"}} src={adminParent} alt=""/>
                                                <p style={{margin: "0", marginLeft: "3px"}}>master</p>
                                            </div>
                                            {macroMstList.map((master, index) => (
                                                <div key={index}
                                                     onClick={() => selectedMasterMacroBt(master, index)}
                                                     style={{
                                                         backgroundColor: masterMacroBtFocused === index ? "dimgray" : "white",
                                                         height: "50px",
                                                         margin: "8px",
                                                         cursor: "pointer",
                                                         color: masterMacroBtFocused === index ? "white" : "dimgray",
                                                         border: "solid",
                                                         borderWidth: "thin 1px",
                                                         borderColor: masterMacroBtFocused === index ? "dimgray" : "lightgray",
                                                     }}>
                                                    <h6 style={{
                                                        margin: "0",
                                                        width: "100%",
                                                        height: "100%",
                                                        display: "flex",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        fontSize: "12px"
                                                    }}>{master && master.title}</h6>
                                                </div>
                                            ))}
                                        </div>
                                        {/*디테일*/}
                                        <div style={{
                                            width: "48%",
                                            border: "solid",
                                            borderWidth: "thin 1px",
                                            borderColor: "lightgray",
                                            marginTop: "30px",
                                            maxHeight: "600px",
                                            overflow: "auto"
                                        }}>
                                            <div style={{
                                                height: "50px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}>
                                                <img style={{width: "25px", height: "25px"}} src={adminChild} alt=""/>
                                                <p style={{margin: "0", marginLeft: "5px"}}>detail</p>
                                            </div>
                                            {macroDtList.map((detail, index) => (
                                                <div key={index}
                                                     onClick={() => selectedMacroDetailBt(detail, index)}
                                                     style={{
                                                         backgroundColor: detailMacroBtFocused === index ? "dimgray" : "white",
                                                         height: "50px",
                                                         margin: "8px",
                                                         cursor: "pointer",
                                                         color: detailMacroBtFocused === index ? "white" : "dimgray",
                                                         border: "solid",
                                                         borderWidth: "thin 1px",
                                                         borderColor: detailMacroBtFocused === index ? "dimgray" : "lightgray"
                                                     }}>
                                                    <h6 style={{
                                                        margin: "0",
                                                        width: "100%",
                                                        height: "100%",
                                                        display: "flex",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        fontSize: "12px"
                                                    }}>{detail && detail.title}</h6>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                {/*New*/}
                                <div style={{width: "32%", borderWidth: "thin 1px", borderColor: "lightgray"}}>
                                    <div style={{
                                        height: "12%",
                                        borderBottom: "solid",
                                        borderWidth: "thin 1px",
                                        borderColor: "dimgrey",
                                        display: "flex",
                                        alignItems: "center"
                                    }}>
                                        <img style={{width: "30px", height: "30px"}} src={adminNew} alt=""/>
                                        <p style={{margin: "0", fontSize: "18px", marginLeft: "10px"}}>New</p>
                                    </div>
                                    <div style={{height: "88%", display: "flex"}}>
                                        <div style={{
                                            width: "100%",
                                            border: "solid",
                                            borderWidth: "thin 1px",
                                            borderColor: "lightgray",
                                            marginTop: "30px",
                                            padding: "20px"
                                        }}>
                                            <div style={{width: "100%", height: "60px"}}>
                                                {/*new form*/}
                                                <div style={{height: "100%"}}>
                                                    {/*커스텀 mst select*/}
                                                    <div ref={newMacroMstRef}
                                                         style={{height: "100%", width: "100%", position: "relative"}}>
                                                        <button className="selectParentBt"
                                                                style={{width: "100%", height: "100%"}}
                                                                onClick={toggleMacroShow}>
                                                            <p style={{margin: "0"}}>{selectedNewMacroMstTitle === "" ? "Master Title" : selectedNewMacroMstTitle}</p>
                                                            <span style={{
                                                                position: "absolute",
                                                                left: "85%",
                                                                top: "0",
                                                                height: "100%",
                                                                width: "20%"
                                                            }}>
                                                                    <i style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        height: "100%"
                                                                    }}><BiCaretDown/></i>
                                                                </span>
                                                        </button>
                                                        {macroMstShow ?
                                                            <div style={{
                                                                position: 'absolute', // 이렇게 position을 설정
                                                                width: "100%",
                                                                border: "solid",
                                                                height: "auto",
                                                                maxHeight: "300px",
                                                                borderWidth: "thin 1px",
                                                                borderColor: "lightgray",
                                                                zIndex: 1000,
                                                                marginTop: "5px",
                                                                backgroundColor: "white",
                                                                overflow: "auto"
                                                            }}>
                                                                {macroMstList.map((macro, index) => (
                                                                    <div className="selectChildBt" key={index}
                                                                         value={macro.title}
                                                                         onClick={() => handleMacroChange(macro)}
                                                                         style={{
                                                                             display: "flex",
                                                                             padding: "15px",
                                                                             justifyContent: "center",
                                                                             alignItems: "center",
                                                                             cursor: "pointer",
                                                                             fontSize: "14px",
                                                                         }}>{macro.title}</div>
                                                                ))}
                                                                <div onClick={() => setMasterAddMacroPopup(true)}
                                                                     className="selectChildBt" style={{
                                                                    display: "flex",
                                                                    padding: "15px",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    cursor: "pointer",
                                                                    fontSize: "14px",
                                                                }}>+마스터추가
                                                                </div>
                                                                {masterAddMacroPopup ?
                                                                    <AdminMasterAdd
                                                                        masterAddMacroPopup={masterAddMacroPopup}
                                                                        masterAddClose={setMasterAddMacroPopup}
                                                                        selectMacroList={selectMacroList}/>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    {/*dt title*/}
                                                    <div className="user-input-container" style={{
                                                        marginTop: "20px",
                                                        height: "50px",
                                                        position: "relative"
                                                    }}>
                                                        <input className="ReviewInput user-input"
                                                               style={{
                                                                   width: "100%",
                                                                   paddingLeft: "10px",
                                                                   paddingRight: "10px",
                                                                   fontSize: "14px",
                                                                   color: "dimgray"
                                                               }} maxLength={40} type="text"
                                                               placeholder="Detail Title" value={newMacroDtTitleText}
                                                               onChange={handleNewMacroDtTitleText}/>

                                                        <div style={{position: "absolute", bottom: "0", right: "3px"}}>
                                                            <span style={{
                                                                fontSize: "12px",
                                                                color: newMacroDtTitleLength > 0 ? "orangered" : null
                                                            }}>{newMacroDtTitleLength}</span>
                                                            <span style={{fontSize: "12px"}}>/50</span>
                                                        </div>
                                                    </div>
                                                    {/*dt content*/}
                                                    <div className="user-input-container" style={{
                                                        marginTop: "20px",
                                                        height: "auto",
                                                        overflowY: 'auto',
                                                        position: "relative"
                                                    }}>
                                                            <textarea
                                                                className="ReviewInput user-input"
                                                                placeholder="Detail Content"
                                                                spellCheck="false"
                                                                value={newMacroDtContentText}
                                                                style={{
                                                                    height: "310px",
                                                                    borderRadius: "6px",
                                                                    width: "100%",
                                                                    resize: "none",
                                                                    overflow: "auto",
                                                                    outline: "none",
                                                                    color: "dimgray",
                                                                    padding: "10px",
                                                                    fontSize: "14px",
                                                                }} maxLength={1000}
                                                                onChange={(e) => handleNewMacroDtContentText(e)}/>
                                                        <div style={{
                                                            position: "absolute",
                                                            bottom: "10px",
                                                            right: "10px"
                                                        }}>
                                                            <span style={{
                                                                fontSize: "12px",
                                                                color: newMacroDtContentLength > 0 ? "orangered" : null
                                                            }}>{newMacroDtContentLength}</span>
                                                            <span style={{fontSize: "12px"}}>/3000</span>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        height: "50px",
                                                        display: "flex",
                                                        alignItems: "flex-end"
                                                    }}>
                                                        <button onClick={saveNewMacroDetail}
                                                                className="ReviewPgWriteBtn" style={{
                                                            width: "100%",
                                                            height: "40px",
                                                            fontSize: "14px",
                                                            borderWidth: "thin 1px",
                                                            borderRadius: "4px",
                                                            marginLeft: "5px"
                                                        }}>Add
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*Edit*/}
                                <div style={{width: "32%", borderWidth: "thin 1px", borderColor: "lightgray"}}>
                                    <div style={{
                                        height: "12%",
                                        borderBottom: "solid",
                                        borderWidth: "thin 1px",
                                        borderColor: "dimgrey",
                                        display: "flex",
                                        alignItems: "center"
                                    }}>
                                        <img style={{width: "30px", height: "30px"}} src={adminEdit} alt=""/>
                                        <p style={{margin: "0", fontSize: "18px", marginLeft: "10px"}}>Edit</p>
                                    </div>
                                    <div style={{height: "88%", display: "flex"}}>
                                        <div style={{
                                            width: "100%",
                                            border: "solid",
                                            borderWidth: "thin 1px",
                                            borderColor: "lightgray",
                                            marginTop: "30px",
                                            padding: "20px"
                                        }}>
                                            {masterMacroModifyForm ?
                                                // edit form
                                                <div style={{height: "100%"}}>
                                                    {/*dt title*/}
                                                    <div className="user-input-container" style={{
                                                        height: "50px",
                                                        position: "relative"
                                                    }}>
                                                        <input className="ReviewInput user-input"
                                                               style={{
                                                                   width: "100%",
                                                                   paddingLeft: "10px",
                                                                   paddingRight: "10px",
                                                                   fontSize: "14px",
                                                                   color: "dimgray"
                                                               }} maxLength={40} type="text"
                                                               value={editMacroMstTitleText}
                                                               onChange={(e) => handleEditMacroMstTitleText(e)}/>
                                                        <div style={{position: "absolute", bottom: "0", right: "3px"}}>
                                                            <span style={{
                                                                fontSize: "12px",
                                                                color: editMacroMstTitleLength > 0 ? "orangered" : null
                                                            }}>{editMacroMstTitleLength}</span>
                                                            <span style={{fontSize: "12px"}}>/50</span>
                                                        </div>
                                                    </div>
                                                    <div style={{
                                                        height: "50px",
                                                        display: "flex",
                                                        marginTop: "20px",
                                                        justifyContent: "center"
                                                    }}>
                                                        <button onClick={() => setEditMacroMstDeleteModal(true)}
                                                                className="orderDeleteBt" style={{
                                                            width: "80px",
                                                            height: "40px",
                                                            textAlign: "center",
                                                            fontSize: "14px"
                                                        }}>Delete
                                                        </button>
                                                        <button onClick={modifyMacroMaster}
                                                                className="orderItemHandleBt"
                                                                style={{
                                                                    width: "80px",
                                                                    height: "40px",
                                                                    fontSize: "14px",
                                                                    margin: "0",
                                                                    borderRadius: "4px",
                                                                    marginLeft: "8px"
                                                                }}>Complete
                                                        </button>
                                                    </div>
                                                </div>
                                                :
                                                detailMacroModifyForm ?
                                                    <div style={{height: "100%"}}>
                                                        {/*edit mst select*/}
                                                        <div ref={editMacroMstRef} style={{
                                                            height: "60px",
                                                            width: "100%",
                                                            position: "relative"
                                                        }}>
                                                            <button className="selectParentBt"
                                                                    style={{width: "100%", height: "100%"}}
                                                                    onClick={toggleMacroDtShow}>
                                                                <p style={{margin: "0"}}>{selectedEditMacroMstTitle === "" ? "Master Title" : selectedEditMacroMstTitle}</p>
                                                                <span style={{
                                                                    position: "absolute",
                                                                    left: "85%",
                                                                    top: "0",
                                                                    height: "100%",
                                                                    width: "20%"
                                                                }}>
                                                                    <i style={{
                                                                        display: "flex",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        height: "100%"
                                                                    }}><BiCaretDown/></i>
                                                                </span>
                                                            </button>
                                                            {macroDtShow ?
                                                                <div style={{
                                                                    position: 'absolute', // 이렇게 position을 설정
                                                                    width: "100%",
                                                                    border: "solid",
                                                                    height: "auto",
                                                                    borderWidth: "thin 1px",
                                                                    borderColor: "lightgray",
                                                                    zIndex: 1000,
                                                                    marginTop: "5px",
                                                                    backgroundColor: "white"
                                                                }}>
                                                                    {macroMstList.map((macro, index) => (
                                                                        <div className="selectChildBt" key={index}
                                                                             value={macro.title}
                                                                             onClick={() => handleMacroDtSelectChange(macro)}
                                                                             style={{
                                                                                 display: "flex",
                                                                                 padding: "15px",
                                                                                 justifyContent: "center",
                                                                                 alignItems: "center",
                                                                                 cursor: "pointer",
                                                                                 fontSize: "14px",
                                                                             }}>{macro.title}</div>
                                                                    ))}
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        {/*edit dt title*/}
                                                        <div className="user-input-container" style={{
                                                            marginTop: "20px",
                                                            height: "50px",
                                                            position: "relative"
                                                        }}>
                                                            <input className="ReviewInput user-input"
                                                                   style={{
                                                                       width: "100%",
                                                                       paddingLeft: "10px",
                                                                       paddingRight: "10px",
                                                                       fontSize: "14px",
                                                                       color: "dimgray"
                                                                   }} type="text"
                                                                   maxLength={40}
                                                                   value={editMacroDtTitleText}
                                                                   onChange={(e) => handleEditMacroDtTitleText(e)}/>
                                                            <div style={{
                                                                position: "absolute",
                                                                bottom: "0",
                                                                right: "3px"
                                                            }}>
                                                                <span style={{
                                                                    fontSize: "12px",
                                                                    color: editMacroDtTitleLength > 0 ? "orangered" : null
                                                                }}>{editMacroDtTitleLength}</span>
                                                                <span style={{fontSize: "12px"}}>/50</span>
                                                            </div>
                                                        </div>
                                                        {/*edit dt content*/}
                                                        <div className="user-input-container" style={{
                                                            marginTop: "20px",
                                                            height: "auto",
                                                            overflowY: 'auto',
                                                            position: "relative"
                                                        }}>
                                                            <textarea
                                                                className="ReviewInput user-input"
                                                                spellCheck="false"
                                                                value={editMacroDtContentText}
                                                                style={{
                                                                    height: "310px",
                                                                    borderRadius: "6px",
                                                                    width: "100%",
                                                                    resize: "none",
                                                                    overflow: "auto",
                                                                    outline: "none",
                                                                    color: "dimgray",
                                                                    padding: "10px",
                                                                    fontSize: "14px",
                                                                }} maxLength={1000}
                                                                onChange={(e) => handleEditMacroDtContentText(e)}/>
                                                            <div style={{
                                                                position: "absolute",
                                                                bottom: "10px",
                                                                right: "10px"
                                                            }}>
                                                                <span style={{
                                                                    fontSize: "12px",
                                                                    color: editMacroDtContentLength > 0 ? "orangered" : null
                                                                }}>{editMacroDtContentLength}</span>
                                                                <span style={{fontSize: "12px"}}>/3000</span>
                                                            </div>
                                                        </div>
                                                        <div style={{
                                                            height: "50px",
                                                            display: "flex",
                                                            alignItems: "flex-end",
                                                            justifyContent: "center"
                                                        }}>
                                                            <button onClick={() => setEditMacroDtDeleteModal(true)}
                                                                    className="orderDeleteBt" style={{
                                                                width: "80px",
                                                                height: "40px",
                                                                textAlign: "center",
                                                                fontSize: "14px"
                                                            }}>Delete
                                                            </button>
                                                            <button onClick={modifyMacroDetail}
                                                                    className="orderItemHandleBt"
                                                                    style={{
                                                                        width: "80px",
                                                                        height: "40px",
                                                                        fontSize: "14px",
                                                                        margin: "0",
                                                                        borderRadius: "4px",
                                                                        marginLeft: "8px"
                                                                    }}>Complete
                                                            </button>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                null
            }
            {editMstDeleteModal ?
                <MsgCheckModal editMstDeleteModal={editMstDeleteModal} deleteMaster={deleteMaster}
                               deleteMstClose={setEditMstDeleteModal}/>
                :
                editDtDeleteModal ?
                    <MsgCheckModal editDtDeleteModal={editDtDeleteModal} deleteDetail={deleteDetail}
                                   deleteDtClose={setEditDtDeleteModal}/>
                    :
                    editMacroMstDeleteModal ?
                        <MsgCheckModal editMacroMstDeleteModal={editMacroMstDeleteModal}
                                       deleteMacroMaster={deleteMacroMaster}
                                       deleteMacroMstClose={setEditMacroMstDeleteModal}/>
                        :
                        editMacroDtDeleteModal ?
                            <MsgCheckModal editMacroDtDeleteModal={editMacroDtDeleteModal}
                                           deleteMacroDetail={deleteMacroDetail}
                                           deleteMacroDtClose={setEditMacroDtDeleteModal}/>
                            :
                            nonShowChatFile ?
                                <ChatFileUpload nonShowChatFileClose={nonShowChatFileClose}
                                                nonSelectedFile={nonSelectedFile} nonShowChatFile={nonShowChatFile}
                                                nonSelectedFileUrl={nonSelectedFileUrl}
                                                saveNonChatFile={saveNonChatFile}/>
                                :
                                nonSelectedClickSt ?
                                    <ChatFileDown nonSelectedClickSt={nonSelectedClickSt}
                                                  nonClickFileClose={nonClickFileClose}
                                                  nonSelectedClickFile={nonSelectedClickFile}
                                                  nonDownloadFile={nonDownloadFile}/>
                                    :
                                    nonChatFileModifySt ?
                                        <ChatFileUpload nonChatFileModifySt={nonChatFileModifySt}
                                                        nonChatFileModifyFalseFn={nonChatFileModifyFalseFn}
                                                        nonChatFileModify={nonChatFileModify}
                                                        nonMessageToModify={nonMessageToModify}/>
                                        :
                                        nonChatModifySt ?
                                            <ChatModifyModal nonChatModifyFalseFn={nonChatModifyFalseFn}
                                                             nonChatModifySt={nonChatModifySt}
                                                             nonMessageToModify={nonMessageToModify}
                                                             nonChatFileModify={nonChatFileModify}/>
                                            :
                                            nonChatDeleteSt ?
                                                <MsgSelectModal nonChatDeleteSt={nonChatDeleteSt}
                                                                nonChatDeleteFalseFn={nonChatDeleteFalseFn}
                                                                chatDeleteMsg1={chatDeleteMsg1}
                                                                chatDeleteMsg2={chatDeleteMsg2}
                                                                nonMessageToModify={nonMessageToModify}
                                                                deleteNonChat={deleteNonChat}/>
                                                :
                                                showChatFile ?
                                                    <ChatFileUpload showChatFileClose={showChatFileClose}
                                                                    selectedFile={selectedFile}
                                                                    selectedFileUrl={selectedFileUrl}
                                                                    saveChatFile={saveChatFile}/>
                                                    :
                                                    chatFileModifySt ?
                                                        <ChatFileUpload chatFileModifySt={chatFileModifySt}
                                                                        chatFileModifyFalseFn={chatFileModifyFalseFn}
                                                                        chatFileModify={chatFileModify}
                                                                        messageToModify={messageToModify}/>
                                                        :
                                                        chatModifySt ?
                                                            <ChatModifyModal chatModifyFalseFn={chatModifyFalseFn}
                                                                             messageToModify={messageToModify}
                                                                             chatFileModify={chatFileModify}/>
                                                            :
                                                            chatDeleteSt ?
                                                                <MsgSelectModal chatDeleteSt={chatDeleteSt}
                                                                                chatDeleteFalseFn={chatDeleteFalseFn}
                                                                                chatDeleteMsg1={chatDeleteMsg1}
                                                                                chatDeleteMsg2={chatDeleteMsg2}
                                                                                messageToModify={messageToModify}
                                                                                deleteChat={deleteChat}/>
                                                                :
                                                                selectedClickSt ?
                                                                    <ChatFileDown selectedClickSt={selectedClickSt}
                                                                                  clickFileClose={clickFileClose}
                                                                                  selectedClickFile={selectedClickFile}
                                                                                  downloadFile={downloadFile}/>
                                                                    :
                                                                    downloadFileComplSt ?
                                                                        <MsgCheckModal
                                                                            downloadFileComplSt={downloadFileComplSt}
                                                                            closeDownloadFileCompl={closeDownloadFileCompl}/>
                                                                        :
                                                                        fileSizeOverSt ?
                                                                            <MsgCheckModal
                                                                                fileSizeOverSt={fileSizeOverSt}
                                                                                closeFileSizeOverSt={closeFileSizeOverSt}/>
                                                                            :
                                                                        null
            }
        </div>
    );
}

export default AdminOrderChatPg
