import React, {useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/App.css';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom'

import UserMainPg from "./page/web/UserMainPg";
import AdminMainPg from "./page/web/AdminMainPg";
import AdminLoginPg from "./page/web/AdminLoginPg";
import AdminJoinPg from "./page/web/AdminJoinPg";
import UserLoginPg from "./page/web/UserLoginPg";
import UserJoinPg from "./page/web/UserJoinPg";
import UserIdPwFindPg from "./page/web/UserIdPwFindPg";
import UserModiPg from "./page/web/UserModiPg";
import TariffCustomModal from "./components/web/modal/TariffCustomModal";
import OutWaybillModal from "./components/web/modal/OutWaybillModal";
import AdminMergeModal from "./components/web/modal/AdminMergeModal";
import AdminDivideModal from "./components/web/modal/AdminDivideModal";
import AdminRegModal from "./components/web/modal/AdminRegModal";

import AppWrapper from "./AppWrapper";
import UserPayComplPg from "./page/web/UserPayComplPg";
import UserPayWaitPg from "./page/web/UserPayWaitPg";
import CommonLoading from "./components/web/modal/CommonLoading";
import NaverLoginLoading from "./components/web/modal/NaverLoginLoading";
import {BrowserView, MobileView, TabletView, isMobile, isTablet} from 'react-device-detect';

import {PopupProvider} from './components/contextApi/FooterOpenChatContext';

import i18n from './i18n';
import axios from "axios";
import ErrorPathPg from "./page/web/ErrorPathPg";
import MUserMainPg from "./page/mobile/MUserMainPg";
import MAdminMainPg from "./page/mobile/MAdminMainPg";
import MAdminLoginPg from "./page/mobile/MAdminLoginPg";
import MAdminJoinPg from "./page/mobile/MAdminJoinPg";
import AdminPwFindPg from "./page/web/AdminPwFindPg";
import MAdminPwFindPg from "./page/mobile/MAdminPwFindPg";
import MAdminModiPg from "./page/mobile/MAdminModiPg";
import MAdminMergeModal from "./components/mobile/MAdminMergeModal";
import MAdminDivideModal from "./components/mobile/MAdminDivideModal";
import MAdminRegModal from "./components/mobile/MAdminRegModal";
import MAdminReviewModifyPg from "./page/mobile/MAdminReviewModifyPg";
import MAdminNoticeModifyPg from "./page/mobile/MAdminNoticeModifyPg";
import MAdminFaqModifyPg from "./page/mobile/MAdminFaqModifyPg";
import MUserPayWaitPg from "./page/mobile/MUserPayWaitPg";
import MUserPayComplPg from "./page/mobile/MUserPayComplPg";
import MUserInfoModiPg from "./page/mobile/MUserInfoModiPg";
import MUserIdPwFindPg from "./page/mobile/MUserIdPwFindPg";
import MJoinPg from "./page/mobile/MJoinPg";
import MLoginPg from "./page/mobile/MLoginPg";
import MAdminOrderDetailPg from "./page/mobile/MAdminOrderDetailPg";
import {WebSocketProvider} from "./services/WebsocketContext";
import AdminNewRegModal from "./components/web/modal/AdminNewRegModal";
import MAdminNoticeWritePg from "./page/mobile/MAdminNoticeWritePg";
import MAdminFaqWritePg from "./page/mobile/MAdminFaqWritePg";
import {HelmetProvider} from "react-helmet-async";
import ErrStatusPg from "./page/web/ErrStatusPg";

function App() {

    const navigate = useNavigate();
    const location = useLocation();

    // 사용자
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');
    // 관리자
    const adminLCAT = localStorage.getItem('adminToken');

    // 현재 브라우저위치
    const pathName = window.location.pathname;

    // // 사용자 AT재발급
    const tokenReissue = () => {
        axios({
            method: 'post',
            url: '/member/reissue',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            withCredentials: true
        })
            //
            .then((response) => {
                const currentTime = new Date();
                if (SSAT) {
                    sessionStorage.setItem('AToken', response.headers.get('Authorization'))
                    sessionStorage.setItem('lastLoginTime', currentTime)
                }
                if (LCAT) {
                    localStorage.setItem('AToken', response.headers.get('Authorization'))
                    localStorage.setItem('lastLoginTime', currentTime)
                }
            })
            .catch(function () {
                // 로그인화면으로 보낼 시 작성중에 이동될 리스크 발생
            })
    }

    // 타임아웃으로 토큰재발급 및 자동로그아웃 axios 날리기 // 사용자
    useEffect(() => {
        if (!pathName.startsWith('/btcorpad')) {
            if (LCAT || SSAT) {
                const interval = setInterval(() => {
                    const now = new Date();
                    const lastLoginTime = LCAT ? localStorage.getItem('lastLoginTime') : sessionStorage.getItem('lastLoginTime');
                    if (!lastLoginTime) {
                        console.log("로그인 시간 정보가 없습니다.");
                        return;
                    }
                    const lastLoginDate = new Date(lastLoginTime);
                    const oneMonthAgo = new Date(lastLoginDate.getTime() + 30 * 24 * 60 * 60 * 1000); // 30일 후

                    // 로그인 후 1개월 경과 시 로그아웃
                    if (now >= oneMonthAgo) {
                        axios({
                            method: 'post',
                            url: '/member/logout',
                            headers: {
                                Authorization: LCAT ? LCAT : SSAT
                            }
                        })
                            .then(() => {
                                if (SSAT) {
                                    sessionStorage.removeItem('AToken');
                                    sessionStorage.removeItem('lastLoginTime');
                                } else {
                                    localStorage.removeItem('AToken');
                                    localStorage.removeItem('lastLoginTime');
                                    // 로그인화면으로 보낼 시 작성중에 이동될 리스크 발생
                                }
                            })
                            .catch(() => {
                                if (SSAT) {
                                    sessionStorage.removeItem('AToken');
                                    sessionStorage.removeItem('lastLoginTime');
                                } else {
                                    localStorage.removeItem('AToken');
                                    localStorage.removeItem('lastLoginTime');
                                    // 로그인화면으로 보낼 시 작성중에 이동될 리스크 발생
                                }
                            })
                    }
                    const oneHourAgo = new Date(lastLoginDate.getTime() + 60 * 60 * 1000); // 1시간 후
                    // 로그인 후 30분 경과 시 재발급
                    if (now >= oneHourAgo) {
                        tokenReissue();
                    }
                    // 1시간마다 체크
                }, 3600000);
                return () => clearTimeout(interval);
            }
        }
    }, [LCAT, SSAT])


    // 관리자 AT재발급
    const adminTokenReissue = () => {
        axios({
            method: 'post',
            url: '/member/reissue',
            headers: {
                Authorization: adminLCAT
            },
            withCredentials: true
        })
            //
            .then((response) => {
                const currentTime = new Date();
                localStorage.setItem('adminToken', response.headers.get('Authorization'))
                localStorage.setItem('adminLastLoginTime', currentTime)
            })
            .catch(() => {
                // 로그인화면으로 보낼 시 작성중에 이동될 리스크 발생
                // navigate("/btcorpad-login", {state: location});
            })
    }

    // 타임아웃으로 토큰재발급 및 자동로그아웃 axios 날리기 // 관리자
    useEffect(() => {
        if (pathName.startsWith('/btcorpad')) {
            const interval = setInterval(() => {
                const now = new Date();
                const lastLoginTime = LCAT ? localStorage.getItem('lastLoginTime') : sessionStorage.getItem('lastLoginTime');
                if (!lastLoginTime) {
                    console.log("로그인 시간정보 null");
                    return;
                }
                const lastLoginDate = new Date(lastLoginTime);
                const oneMonthAgo = new Date(lastLoginDate.getTime() + 30 * 24 * 60 * 60 * 1000); // 30일 후

                // 로그인 후 30일 경과 시 로그아웃
                if (now >= oneMonthAgo) {
                    axios({
                        method: 'post',
                        url: '/member/logout',
                        headers: {
                            Authorization: adminLCAT
                        }
                    })
                        .then(() => {
                            localStorage.removeItem('adminToken');
                            localStorage.removeItem('adminLastLoginTime');
                            // navigate("/UserLoginPg", {state: location});
                        })
                        .catch(() => {
                            localStorage.removeItem('adminToken');
                            localStorage.removeItem('adminLastLoginTime');
                            // navigate("/UserLoginPg", {state: location});
                        })
                }

                const oneHourAgo = new Date(lastLoginDate.getTime() + 60 * 60 * 1000); // 1시간 후
                // 로그인 후 1시간 경과 시 재발급
                if (now >= oneHourAgo) {
                    adminTokenReissue();
                }
                // 1시간마다 체크
                }, 3600000);
            return () => clearTimeout(interval);
        }
    }, [])

    axios.defaults.baseURL = 'https://btorage.com/api';
    // axios.defaults.baseURL = 'https://btorage-dev.com/api';
    // axios.defaults.baseURL = 'http://localhost:8080/api';

    useEffect(() => {
        if (pathName.startsWith('/btcorpad') && !adminLCAT) {
            navigate("/btcorpad-login")
        }
    }, [adminLCAT])

    // const mallProdList = [
    //     { id: 'xxx', name: 'xxx', option: 'xxx', price: 'xxx' },
    //     { id: 'xxx', name: 'xxx', option: 'xxx', price: 'xxx' },
    //     // .. 나머지 상품들 자동추가
    // ];
    //
    // import { isMobile } from 'react-device-detect';
    //r
    // useEffect(() => {
    //     if (mallProdList.length > 0) {
    //         localStorage.setItem('mallProdList', JSON.stringify(mallProdList));
    //     }
    //     if (isMobile) {
    //         webMobileAutoPath();
    //     }
    // }, []);
    //
    // const webMobileAutoPath = (() => {
    //     if (isMobile && window.location.hostname.startsWith('www.')) {
    //         window.location.href = `m.btorage.com${window.location.pathname}`;
    //     } else if (!isMobile && window.location.hostname.startsWith('m.')) {
    //         window.location.href = `www.btorage.com${window.location.pathname}`;
    //     }
    // })

    // localStorage 저장언어조회
    useEffect(() => {
        if (!pathName.startsWith('/btcorpad')) {
            const currentLanguage = localStorage.getItem('lang') || 'ko';
            i18n.changeLanguage(currentLanguage);
        }
    }, []);

    useEffect(() => {
        const detectDeviceType = () => {
            if (window.navigator.userAgent.includes('ReactNative')) {
                return 'A'; //App
            } else if (window.navigator.userAgent.includes('Mobi')) {
                return 'M'; //웹앱
            } else {
                return 'P'; //PC
            }
        };

        const deviceType = detectDeviceType();
        sendAccessInfo(deviceType);
    }, []);

    const sendAccessInfo = async (deviceType) => {
        try {
            const response = await axios.post('/init/accessInfo', {
                accessTime: new Date().toISOString(),
                machFl: deviceType,
            });
        } catch (error) {
            console.error(error);
        }
    };

    // useEffect(() => {
    //     selectReviewAllList();
    // }, []);
    //
    // const selectReviewAllList = () =>
    //     axios({
    //         method: 'get',
    //         url: '/review/selectReviewAllList',
    //     })
    //         .then(() => {})

    // const popupRoutes = ["/btcorpad/btad-Merge", "/btcorpad/btad-Divide", "/btcorpad/btad-Payreg"];
    // const isPopupRoute = popupRoutes.includes(window.location.pathname);
    //
    // const isMobileOrTablet = (isMobile || isTablet || window.innerWidth <= 1180) && !isPopupRoute;

    return (
        <WebSocketProvider>
            <PopupProvider>
                <AppWrapper>
                    <div className="app">
                        {/*PC VIEW*/}
                        {/*{!isMobileOrTablet && (*/}
                        <BrowserView>
                            <Routes>
                                <Route path="/*" element={<UserMainPg key={location.pathname} />}/> {/*고객페이지*/}
                                <Route path="/btcorpad/*" element={<AdminMainPg key={location.pathname}/>}/> {/*관리자페이지*/}

                                <Route path="/UserLoginPg" element={<UserLoginPg key={location.pathname}/>}/>
                                <Route path="/UserJoinPg" element={<UserJoinPg key={location.pathname}/>}/>
                                <Route path="/UserIdPwFindPg" element={<UserIdPwFindPg/>}/>
                                <Route path="/UserModiPg" element={<UserModiPg key={location.pathname}/>}/>
                                <Route path="/UserPayComplPg" element={<UserPayComplPg/>}/>
                                <Route path="/UserPayWaitPg" element={<UserPayWaitPg/>}/>

                                <Route path="/btcorpad-login" element={<AdminLoginPg/>}/>
                                <Route path="/btcorpad-join" element={<AdminJoinPg/>}/>
                                <Route path="/btcorpad-pwFind" element={<AdminPwFindPg/>}/>
                                <Route path="/btcorpad/btad-WabillReg" element={<OutWaybillModal/>}/>
                                <Route path="/btcorpad/btad-TariffReg" element={<TariffCustomModal/>}/>
                                <Route path="/btcorpad/btad-Merge" element={<AdminMergeModal/>}/>
                                <Route path="/btcorpad/btad-Divide" element={<AdminDivideModal/>}/>
                                <Route path="/btcorpad/btad-Payreg" element={<AdminRegModal/>}/>
                                {/*<Route path="/btcorpad/btad-Payreg2" element={<AdminNewRegModal/>}/>*/}

                                <Route path="/CommonLoading" element={<CommonLoading/>}/>
                                <Route path="/NaverLoginLoading" element={<NaverLoginLoading/>}/>
                                <Route path="*" element={<ErrorPathPg/>}/>
                                <Route path="/ErrStatusPg" element={<ErrStatusPg/>}/>
                            </Routes>
                        </BrowserView>
                        {/*)}*/}
                        {/*Mobile & Tablet VIEW*/}
                        {(isMobile || isTablet) && (
                        // {isMobileOrTablet && (
                        <MobileView style={{height: 'calc(var(--vh, 1vh) * 100)'}}>
                            <Routes>
                                {/*User*/}
                                <Route path="/*" element={<MUserMainPg/>}/>
                                <Route path="/UserLoginPg" element={<MLoginPg/>}/>
                                <Route path="/UserJoinPg" element={<MJoinPg/>}/>
                                <Route path="/UserIdPwFindPg" element={<MUserIdPwFindPg/>}/>
                                <Route path="/UserModiPg" element={<MUserInfoModiPg/>}/>
                                <Route path="/UserPayComplPg" element={<MUserPayComplPg/>}/>
                                <Route path="/UserPayWaitPg" element={<MUserPayWaitPg/>}/>
                                {/*Admin*/}
                                <Route path="/btcorpad/*" element={<MAdminMainPg/>}/> {/*관리자메인*/}
                                <Route path="/btcorpad-login" element={<MAdminLoginPg/>}/> {/*로그인페이지*/}
                                <Route path="/btcorpad-join" element={<MAdminJoinPg/>}/> {/*회원가입페이지*/}
                                <Route path="/btcorpad-pwFind" element={<MAdminPwFindPg/>}/> {/*비밀번호찾기 페이지*/}
                                <Route path="/btcorpad/btad-MergeM" element={<MAdminMergeModal/>}/> {/*비용등록*/}
                                <Route path="/btcorpad/btad-DivideM" element={<MAdminDivideModal/>}/> {/*분리*/}
                                <Route path="/btcorpad/btad-PayregM" element={<MAdminRegModal/>}/> {/*통합*/}
                                <Route path="/btcorpad/orderdtmodi/:ordNum" element={<MAdminOrderDetailPg/>}/> {/*주문상세*/}
                                {/*<Route path="/btcorpad/orderPayDt/:seqNum" element={<MAdminDeliveryPriceDtModal/>}/> /!*주문상세*!/*/}
                                <Route path="/adminInfoModi" element={<MAdminModiPg/>}/> {/*프로필수정*/}
                                <Route path="/adminreview-modify/:seqNum" element={<MAdminReviewModifyPg/>}/>{/*이용후기수정*/}
                                <Route path="/adminnotice-modify/:seqNum" element={<MAdminNoticeModifyPg/>}/> {/*공지사항수정*/}
                                <Route path="/adminnotice-write" element={<MAdminNoticeWritePg/>}/> {/*공지사항등록*/}
                                <Route path="/adminfaq-modify/:seqNum" element={<MAdminFaqModifyPg/>}/> {/*FAQ수정*/}
                                <Route path="/adminfaq-write" element={<MAdminFaqWritePg/>}/> {/*FAQ작성*/}

                                <Route path="/NaverLoginLoading" element={<NaverLoginLoading/>}/>
                                <Route path="/ErrStatusPg" element={<ErrStatusPg/>}/>
                            </Routes>
                        </MobileView>
                        )}
                    </div>
                </AppWrapper>
            </PopupProvider>
        </WebSocketProvider>
    );
}

export default App;
