import '../../css/mobile/MHeader.css';
import Back from "../../assets/img/commonBack.png";
import { useNavigate } from "react-router-dom";

const MHeader = (props) => {
	const { title, url } = props;
	const navigate= useNavigate();

	return (
		<div className="m-a-header">
			{url && (
				<div className="m-u-header-frame wd-30" style={{zIndex: 2}}>
					<img className="m-a-header-back-icon" src={Back} alt='Back' onClick={() => {navigate(url);}}/>
				</div>
			)}
			<div className="m-a-header-title wd-85pc text-center" style={{zIndex: 1}}>
				<p className="font-t-title">{title}</p>
			</div>
		</div>
	)
}


export default MHeader;
