import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import axios from "axios";
import '../../css/mobile/MAddrBookPg.css';
import commonClose from "../../assets/img/commonClose.png";
import MsgConfirmModal from "../../components/web/modal/MsgConfirmModal";
import UserOrderPg from "../web/UserOrderPg";
import warnIcon from "../../assets/img/warnIcon.png";
import MAddrBookAddPg from "./MAddrBookAddPg";
import withAuth from "../../auth/UserRouteAuth";

const MAddrBookPg = (props) => {
    const {
        addrBookCloseFn,
        orderAddrBookSt,
        addrChoiceData,
        selectedShip,
        myordBookSt,
        myordBookFalseFn,
        deliAddrBookSt,
        deliveryDetailAddrSt,
        deliveryDetailAddrFalseFn
    } = props;
    const {t} = useTranslation();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');

    const [data, setData] = useState([]);
    const [seqnumKeySt, setSeqnumKeySt] = useState(null);

    const [addrBookModifySt, setAddrBookModifySt] = useState(false);
    const [addrBookAddSt, setAddrBookAddSt] = useState(false);
    const [deleteSelectSt, setDeleteSelectSt] = useState(false);
    const [deleteConfirmSt, setDeleteConfirmSt] = useState(false);
    const [addrBookChoiceSt, setAddrBookChoiceSt] = useState(false);

    const addrBookModifyExcutionFn = (() => {
        setAddrBookModifySt(true);
    })

    const addrBookModifyBackFn = (() => {
        setAddrBookModifySt(false);
    })

    const addrBookModifyBackFn2 = (() => {
        // data 렌더링 해야 함
        setAddrBookAddSt(false);
    })

    const addrBookAddFn = (() => {
        setAddrBookAddSt(true);
    })

    const deleteBookExcutionFn = ((key) => {
        setDeleteSelectSt(true);
        setSeqnumKeySt(key)
    })

    const deleteBookCancelFn = (() => {
        setDeleteSelectSt(false);
    })

    const getBookListFn = (() => {
        axios({
            method: 'get',
            url: '/member/addrBookList',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => {
                    return (b.defaultAddrFl === true) - (a.defaultAddrFl === true);
                });
                setData(sortedData);
            })
    })

    // 수정창일 때만 주소록 가져오기
    useEffect(() => {
        const timer = setTimeout(() => {
            if (addrBookModifySt === false && addrBookAddSt === false) {
                getBookListFn();
            }
        })
        return () => {
            clearTimeout(timer);
        }
    }, [])


    const deleteAddrBook = ((seqnum) => {
        axios({
            method: 'post',
            url: '/member/addrBookDelete',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            },
            params: {
                seqnum: seqnum
            }
        })
            .then(() => {
                getBookListFn()
            })
    })

    const deleteHandleFn = () => {
        deleteAddrBook(seqnumKeySt); // axios로 해당 주소록 삭제
        setDeleteSelectSt(false);
        // 컨펌창 상태 true로 만들어서 뜨게 하기
        setDeleteConfirmSt(true);
        // getBookListFn(); // getList 업데이트
    }

    const deleteSelectMsg1 = t(`abMdMsg1`)
    const deleteSelectMsg2 = t(`abMdMsg2`)
    const deleteConfirmMsg = t(`abMdMsg3`)

    const deleteConfirmHandleFn = () => {
        getBookListFn();
        setDeleteConfirmSt(false);
    }

    const keyAndModify = (key) => {
        addrBookModifyExcutionFn();
        setSeqnumKeySt(key);
    }

    const selectedData = data.find((item) => item.seqnum === seqnumKeySt);

    return (
        <div className="m-a-addr-book-pg-background font-t-cont" style={{zIndex: 1000}}>
            <div className='m-a-addr-book-pg-container'>
                <div className='m-a-addr-book-pg-header'>
                    <div className='m-a-addr-book-pg-title font-t-title'>{t(`abHeader`)}</div> {/*배송주소록*/}
                    {orderAddrBookSt ?
                        <img className='m-a-addr-book-pg-close-icon' src={commonClose} alt='close'
                             onClick={addrBookCloseFn}/>
                        :
                        myordBookSt ?
                            <img className='m-a-addr-book-pg-close-icon' src={commonClose} alt='close'
                                 onClick={myordBookFalseFn}/>
                            :
                            deliAddrBookSt ?
                                <img className='m-a-addr-book-pg-close-icon' src={commonClose} alt='close'
                                     onClick={addrBookCloseFn}/>
                                :
                                deliveryDetailAddrSt ?
                                    <img className='m-a-addr-book-pg-close-icon' src={commonClose} alt='close'
                                         onClick={deliveryDetailAddrFalseFn}/>
                                    :
                                    null
                    }
                </div>
                <div className='m-a-addr-book-pg-content'>
                    {data.map((book) => (
                        <div className={'m-a-addr-book-frame'} key={book.seqnum}>
                            <div className="m-a-addr-book-frame-top">
                                {book.recname}
                                {book.defaultAddrFl &&
                                    <p className="font-c-progress-b font-s-13 font-b">{t(`ordDfAddr`)}</p>
                                }
                            </div>
                            <div className="m-a-addr-book-text-bold m-t-10">{book.countryDTO.countryEngNm}</div>
                            <div className="m-a-addr-book-text">{book.address1}</div>
                            {book.address2 &&
                                <div className="m-a-addr-book-text">{book.address2}</div>
                            }
                            {book.address3 &&
                                <div className="m-a-addr-book-text">{book.address3}</div>
                            }
                            {book.city &&
                                <div className="m-a-addr-book-text">{book.city}</div>
                            }
                            <div className="display-flex flex-row just-cont-sb wd-100pc m-t-10 m-b-10">
                                {book.zipcode &&
                                    <div className="m-a-addr-book-text">{book.zipcode}</div>
                                }
                                <div className="m-a-addr-book-text">{book.telnum}</div>
                            </div>
                            {book.crCode === 'ID' &&
                                <div className="m-a-addr-book-text">Tax ID : {book.taxid}</div>
                            }
                            <div className={'m-a-addr-book-buttons'}>
                                {/*{!myordBookSt ?*/}
                                    <div className={'m-a-addr-book-buttons-left'}>
                                        <button className="m-a-colorBt-B wd-70 ht-30 font-s-12" onClick={() => deleteBookExcutionFn(book.seqnum)}>{t(`st4`)}</button>
                                        <button className="m-a-colorBt-B wd-70 ht-30 font-s-12" onClick={() => keyAndModify(book.seqnum)}>{t(`modRegModiBt`)}</button> {/*수정*/}
                                    </div>
                                    {/*: null}*/}
                                {/*{orderAddrBookSt || myordBookSt || deliveryDetailAddrSt ?*/}
                                    <button className="m-a-colorBt-B wd-70 ht-30 font-s-12" onClick={() => addrChoiceData(book.seqnum)}>{t(`select`)}</button> {/*선택*/}
                                    {/*: null}*/}
                            </div>
                        </div>
                    ))}
                </div>
                <div className={'m-a-addr-book-pg-bottom'}>
                    <button className="colorFullBt" style={{width: "100%", height: "40px", textAlign: "center", fontSize: "15px"}}
                            onClick={addrBookAddFn}>{t(`abAddBt`)}</button>
                </div>
            </div>
            {
                addrBookAddSt === true ?
                    <MAddrBookAddPg addrBookModifyBackFn2={addrBookModifyBackFn2} addrBookAddSt={addrBookAddSt}
                                    getBookListFn={getBookListFn} selectedShip={selectedShip}/>
                    :
                    addrBookModifySt === true ?
                        <MAddrBookAddPg addrBookModifyBackFn={addrBookModifyBackFn}
                                        addrBookModifySt={addrBookModifySt} book={selectedData}
                                        getBookListFn={getBookListFn} selectedShip={selectedShip}/>
                        :
                        deleteSelectSt === true ?
                            <div className={'m-a-addr-delete-popup'}>
                                <div className={'m-a-addr-book-pg-popup'}>
                                    <div className={'m-a-addr-book-pg-header'}>
                                        <div className={'m-a-addr-book-pg-title'}>
                                            <img className={'m-a-addr-book-pg-popup-icon'} src={warnIcon} alt={''}/>
                                            {deleteSelectMsg1}
                                        </div>
                                    </div>
                                    <div className={'m-a-addr-book-pg-divider'}/>
                                    <div className={'m-a-addr-book-pg-text'}>
                                        {deleteSelectMsg2}
                                    </div>
                                    <div className={'m-a-addr-book-delete-popup-buttons'}>
                                        <div className={'m-a-addr-book-delete-popup-button'}
                                             onClick={deleteBookCancelFn}>{t(`st3`)}</div>
                                        <div className={'m-a-addr-book-delete-popup-button delete'}
                                             onClick={() => deleteHandleFn()}>{t(`st4`)}</div>
                                    </div>
                                </div>
                            </div>
                            :
                            deleteConfirmSt === true ?
                                <MsgConfirmModal deleteConfirmSt={deleteConfirmSt}
                                                 deleteConfirmMsg={deleteConfirmMsg}
                                                 deleteConfirmHandleFn={deleteConfirmHandleFn}/>
                                :
                                addrBookChoiceSt === true ?
                                    <UserOrderPg book={selectedData} addrBookChoiceSt={addrBookChoiceSt}/>
                                    :
                                    null
            }
        </div>
    )
}

export default withAuth(MAddrBookPg);
