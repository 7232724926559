import React, {useEffect, useRef, useState} from 'react';
import '../../css/mobile/MAdminDashBoard.css';
import '../../css/Common.css'
import mypgOrder from "../../assets/img/mypgOrder.png";
import mypgDelivery from "../../assets/img/mypgDelivery.png";
import mypgReturn from "../../assets/img/mypgReturn.png";
import totalImg from "../../assets/img/totalImg.png";
import followImg from "../../assets/img/followImg.png";
import lossImg from "../../assets/img/lossImg.png";
import todayImg from "../../assets/img/todayImg.png";
import deposit from "../../assets/img/depositPay.png";
import orderIMG from "../../assets/img/mypgPayinfo.png";
import payIMG from "../../assets/img/receiptIMG.png";
import pointPay from '../../assets/img/pointPay.png';
import {useNavigate} from "react-router-dom";

import axios from "axios";
import {orderStatusUpdate} from "../../services/AutoChangeOrderSt";
import {IoSearchOutline} from "react-icons/io5";
import {RxCheck, RxCopy} from "react-icons/rx";
import MAdminYmdEvent from "./MAdminYmdEvent";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import MsgConfirmModal from "../../components/web/modal/MsgConfirmModal";
import MAdminPageSmallEvent from "./MAdminPageSmallEvent";
import useTheme from "../../components/web/layoutscommon/DarkModeStorage";
import {FiMinus, FiPlus} from "react-icons/fi";
import MDeliveryPriceDetail from "../../components/mobile/MDeliveryPriceDetail";
import {useWebSocket} from "../../services/WebsocketContext";

function MAdminDashBoardPg() {

    /** region DataTable */

    const navigate = useNavigate();
    const [ordReceiptCountData, setOrdReceiptCountData] = useState(0);

    /** endregion DataTable*/

    /** region Function */

    // region userCount

    const [userCountTotalData, setUserCountTotalData] = useState(0);
    const [userCountFlwData, setUserCountFlwData] = useState(0);
    const [userCountLossData, setUserCountLossData] = useState(0);
    const [userCountTodayData, setUserCountTodayData] = useState(0);

    const convertCount = (count) => {
            if (count >= 1000000) {
                return (count / 1000000).toFixed(1) + 'm';
            } else if (count >= 1000) {
                return (count / 1000).toFixed(1) + 'k';
            } else {
                return count.toString();
            }
        };

    useEffect(() => {
        userCountTotalSelect();
        userCountFlwSelect();
        userCountLossSelect();
        userCountTodaySelect();
    }, [])

    const userCountTotalSelect = (() => {
        axios({
            method: 'get',
            url: '/member/usertotalcount',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setUserCountTotalData(response.data);
            })
            .catch(() => {})
    })

    const userCountFlwSelect = (() => {
        axios({
            method: 'get',
            url: '/member/userflwcount',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setUserCountFlwData(response.data);
            })
            .catch(() => {})
    })

    const userCountLossSelect = (() => {
        axios({
            method: 'get',
            url: '/member/userlosscount',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setUserCountLossData(response.data);
            })
            .catch(() => {})
    })
    const userCountTodaySelect = (() => {
        axios({
            method: 'get',
            url: '/init/usertodaycount',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setUserCountTodayData(response.data);
            })
            .catch(() => {})
    })

    // endregion userCount

    // region slide-card

    useEffect(() => {
        const sliderWrapper = document.querySelector('.m-a-slider-wrapper');
        if (!sliderWrapper) { return; }

        let isDragging = false;
        let startPosition = 0;
        let currentTranslate = 0;
        let prevTranslate = 0;
        let animationID = 0;
        let currentIndex = 0;

        const slides = document.querySelectorAll('.m-a-card-slider');
        const slideWidth = sliderWrapper.clientWidth;
        const indicators = document.querySelectorAll('.m-a-indicator');

        sliderWrapper.addEventListener('touchstart', touchStart);
        sliderWrapper.addEventListener('touchend', touchEnd);
        sliderWrapper.addEventListener('touchmove', touchMove);

        function touchStart(event) {
            isDragging = true;
            startPosition = event.touches[0].clientX;
            animationID = requestAnimationFrame(animation);
        }

        function touchEnd() {
            isDragging = false;
            cancelAnimationFrame(animationID);

            const movedBy = currentTranslate - prevTranslate;

            if (movedBy < -50 && currentIndex < slides.length - 1) {
                currentIndex += 1;
            } else if (movedBy > 50 && currentIndex > 0) {
                currentIndex -= 1;
            }

            setPositionByIndex();
            updateIndicators();
        }

        function touchMove(event) {
            if (isDragging) {
                const currentPosition = event.touches[0].clientX;
                currentTranslate = prevTranslate + currentPosition - startPosition;
            }
        }

        function animation() {
            setSliderPosition();
            if (isDragging) requestAnimationFrame(animation);
        }

        function setSliderPosition() {
            sliderWrapper.style.transform = `translateX(${currentTranslate}px)`;
        }

        function setPositionByIndex() {
            currentTranslate = currentIndex * -slideWidth;
            prevTranslate = currentTranslate;
            setSliderPosition();
        }

        function updateIndicators() {
            indicators.forEach((indicator, index) => {
                indicator.classList.toggle('active', index === currentIndex);
            });
        }

        updateIndicators();

        return () => {
            sliderWrapper.removeEventListener('touchstart', touchStart);
            sliderWrapper.removeEventListener('touchend', touchEnd);
            sliderWrapper.removeEventListener('touchmove', touchMove);
        };
    }, []);

    // endregion slide-card

    const [ordData, setOrdData] = useState([]);
    const [productsList, setProductsList] = useState([]);
    const [payinfoList, setPayinfoList] = useState([{}]);
    const [recinfoList, setRecinfoList] = useState([{}]);
    const [ordermemoList, setOrderMemoList] = useState([{}]);
    const [memberInfo, setMemberInfo] = useState("");
    const [adminInfo, setAdminInfo] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredList, setFilteredList] = useState([]);
    const LCAT = localStorage.getItem('adminToken');

    // const [isOpenDTord, setIsOpenDTord] = useState(false);
    // const [isOpenDToffline, setIsOpenDToffline] = useState(false);
    // const [isOpenDTtransfer, setIsOpenDTtransfer] = useState(false);
    // const [isOpenDTdomestic, setIsOpenDTdomestic] = useState(false);
    // const [isOpenDTdelivery, setIsOpenDTdelivery] = useState(false);
    const [selectedShipCompany, setSelectedShipCompany] = useState("배송사선택");
    // const [userInfoShow, setUserInfoShow] = useState(false);

    const [memoModiData, setMemoModiData] = useState({});

    const handleInputMemoChange = (e, seqnum, memoType) => {
        const updatedMemoModiData = { ...memoModiData, [`${seqnum}-${memoType}`]: e.target.value };
        setMemoModiData(updatedMemoModiData);

        const updatedData = ordData.map(data => {
            if (data.seqnum === seqnum) {
                return { ...data, [memoType]: { ...data[memoType], ordDtCont: e.target.value }};
            }
            return data;
        });
        setOrdData(updatedData);
    };

    const onKeyPress = (e, seqnum, memoType) => {
        if (e.key === 'Enter' || e.key === 'Done' || e.key === 'Go' || e.key === 'Search' || e.key === 13) {
            e.preventDefault();
            const value = memoModiData[`${seqnum}-${memoType}`] || '';
            ordDtMemoSave(seqnum, value, memoType);
        }
    };

    const inputRefs = useRef({});

    // 웹소켓
    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!adminInfo.mbNum || isSubscribedRef.current) return;

            if (!stompClient && !isConnected) {
                await websocketConnect(adminInfo.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                subscribe(`/topic/adminOrderStUpdate`, orderStReceived);
                subscribe(`/topic/userOrderData`, orderDataReceived);
                subscribe(`/topic/depConfirmAlarm`, depositDataReceived);
                subscribe(`/topic/depReviewAlarm`, reviewDataReceived);
                subscribe(`/topic/addTotalCount`, chatDataReceived);
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                unsubscribe(`/topic/adminOrderStUpdate`);
                unsubscribe(`/topic/userOrderData`);
                unsubscribe(`/topic/depConfirmAlarm`);
                unsubscribe(`/topic/depReviewAlarm`);
                unsubscribe(`/topic/addTotalCount`);
                isSubscribedRef.current = false;
            }
        };

    }, [adminInfo.mbNum, isConnected, stompClient]);

    const [adminProdMemos, setAdminProdMemos] = useState({});
    const handleProdMemoChange = (seqnum, value) => {
        setAdminProdMemos(prevMemos => ({
            ...prevMemos,
            [seqnum]: value
        }));
    };
    const [saveMsgSt, setSaveMsgSt] = useState(false);
    const [savePopupMsg, setSavePopupMsg] = useState('');
    const modifyAdminProdMemo = ((seqnum, memo) => {
        axios({
            method: 'post',
            url: '/order/modifyAdminProdMemo',
            headers: {
                Authorization: LCAT
            },
            data: {
                seqNum: seqnum,
                memo: memo
            }
        })
            .then((response) => {
                // setSavePopupMsg("저장 완료");
                // setSaveMsgSt(true);
                // setTimeout(() => setSaveMsgSt(false), 1000);
            })
            .catch((error) => {
                // setSavePopupMsg("저장 실패");
                // setSaveMsgSt(true);
                // setTimeout(() => setSaveMsgSt(false), 1000);
            })
    })

    const saveProdMemo = (e, seqnum) => {
        if (e.key === 'Enter' || e.key === 'Done' || e.key === 'Go' || e.key === 'Search' || e.key === 13) {
            const memo = adminProdMemos[seqnum] || '';
            modifyAdminProdMemo(seqnum, memo);
        }
    };

    useEffect(() => {
        const initialMemos = {};
        // 'orderdt' 타입의 항목만 필터링
        const orderDetails = filteredList.filter(item => item.type === 'orderdt');
        orderDetails.forEach(order => {
            order.productsDTO.forEach(prod => {
                // 각 상품seqnum마다 adminMemo 맵핑
                initialMemos[prod.seqnum] = prod.adminMemo;
            });
        });
        // 초기 메모 상태 설정
        setAdminProdMemos(initialMemos);
    }, [filteredList]);

    function formatDateTime(inputDateTime) {
        const date = new Date(inputDateTime);
        const year = date.getFullYear().toString().slice(-2);
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }

    //region CheckBox

    const [noticeChecked, setNoticeChecked] = useState([]);

    const noticeCheckBoxChange = (id) => {
        setNoticeChecked((prevSelectedData) => {
            if (prevSelectedData.includes(id)) {
                return prevSelectedData.filter((item) => item !== id);
            } else {
                return [...prevSelectedData, id];
            }
        });
    };

    //endregion

    //region Search

    const [searchInput, setSearchInput] = useState('');
    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    //endregion

    //region CheckBox

    const [checked, setChecked] = useState([]);
    const [orderCheckedCount, setOrderCheckedCount] = useState(0);

    // const checkBoxChange = (id, type) => {
    //     setChecked((prevSelectedData) => {
    //         if (prevSelectedData.includes(id)) {
    //             // 이미 체크된 경우 해제 로직
    //             const newChecked = prevSelectedData.filter((item) => item !== id);
    //             // orderdt 타입만 orderCheckedCount 조정
    //             if (type === 'orderdt') {
    //                 setOrderCheckedCount(prev => Math.max(0, prev - 1));
    //             }
    //             return newChecked;
    //         } else {
    //             const newChecked = [...prevSelectedData, id];
    //             // orderdt 타입만 orderCheckedCount 조정 = 프로세스 결제대기(배송/PS0)에서 예적금결제 제한을 위함
    //             if (type === 'orderdt') {
    //                 setOrderCheckedCount(prev => prev + 1);
    //             }
    //             return newChecked;
    //         }
    //     });
    // };

    //endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(15);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const pageChange = (currentPage) => {
        setCurrentPage(currentPage);
    };
    const handleSelectChange = (e) => {
        setperPg(parseInt(e));
        setCurrentPage(1);
    };

    //endregion

    //region DateSelect

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const startHandle = (e) => {setStartDate(e.target.value);};
    const endHandle = (e) => {setEndDate(e.target.value);};
    const stringStartDate = startDate.replace(/-/g, '');
    const stringEndDate = endDate.replace(/-/g, '');

    const currentDate = new Date();
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(currentDate.getDate() - 90); //3개월

    const defaultMonth = () => {
        const stringStartDate = formatDate(twoWeeksAgo);
        const stringEndDate = formatDate(currentDate);
        setStartDate(stringStartDate);
        setEndDate(stringEndDate);
    }

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    //endregion DateSelect

    //region 다크모드

    const [theme] = useTheme();

    //endregion

    const orderStPrint = (orderSt) => {
        const orderStStyles = {
            OS00: { color: "#98B0D4", label: "입고대기" },
            OS01: { color: "#98B0D4", label: "결제대기(상품)" },
            OS02: { color: "#98B0D4", label: "결제중(상품)" },
            OS03: { color: "#FFAF34", label: "결제완료(상품)" },
            OS04: { color: "#98B0D4", label: "구매중" },
            OS05: { color: "#FFB378", label: "구매완료" },
            OS06: { color: "#98B0D4", label: "입고중" },
            OS07: { color: "#6EDA60", label: "센터도착" },
            OS08: { color: "#98B0D4", label: "패킹중" },
            OS09: { color: "#98B0D4", label: "결제대기(배송)" },
            OS10: { color: "#98B0D4", label: "결제중(배송)" },
            OS11: { color: "#FFAF34", label: "결제완료(배송)" },
            OS12: { color: "#979696", label: "출고완료" },
            OS13: { color: "#FF8F62", label: "반송중" },
            OS14: { color: "#FF8F62", label: "신청취소" },
        };

        const style = orderStStyles[orderSt] || { color: "#5b5b5b", label: null };
        return <a style={{ color: style.color, fontSize: "16px"}}>{style.label}</a>;
    };

    const prodStPrint = (prodSt) => {
        const prodStStyles = {
            IS00: { color: "#98B0D4", label: "입고대기" },
            IS01: { color: "#98B0D4", label: "결제대기(상품)" },
            IS02: { color: "#98B0D4", label: "결제중(상품)" },
            IS03: { color: "#FFAF34", label: "결제완료(상품)" },
            IS04: { color: "#FF8600", label: "구매완료" },
            IS05: { color: "#6EDA60", label: "센터도착" },
            IS06: { color: "#98B0D4", label: "패킹중" },
            IS07: { color: "#98B0D4", label: "결제대기(배송)" },
            IS08: { color: "#98B0D4", label: "결제중(배송)" },
            IS09: { color: "#FFAF34", label: "결제완료(배송)" },
            IS10: { color: "#979696", label: "출고완료" },
            IS11: { color: "#FF8F62", label: "반송중" },
            IS12: { color: "#FF8F62", label: "신청취소" },
            IS13: { color: "#98B0D4", label: "구매중" },
            IS14: { color: "#98B0D4", label: "입고중" }
        };

        const style = prodStStyles[prodSt] || { color: "black", label: null };
        return <p style={{ margin: "0", color: style.color, fontSize: "14px" }}>{style.label}</p>;
    };

    // 메인알림로직
    const [lcCartProd, setLcCartProd] = useState([]);
    const [lcOrderProd, setLcOrderProd] = useState([]);
    const [lcOfflineProd, setLcOfflineProd] = useState([]);
    const [lcTransferProd, setLcTransferProd] = useState([]);
    const [lcDomesticProd, setLcDomesticProd] = useState([]);
    const [lcDeliveryProd, setLcDeliveryProd] = useState([]);
    const [lcPayData, setLcPayData] = useState([]);
    const [lcDepositData, setLcDepositData] = useState([]);
    const [lcReviewData, setLcReviewData] = useState([]);
    const [lcChatData, setLcChatData] = useState(0);

    const selectAlarmData = (() => {
        axios({
            method: 'get',
            url: '/order/selectAlarmData',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                const data = response.data;
                // 새 상태값을 저장할 임시 객체 생성
                const newState = {
                    lcCartProd: [],
                    lcOrderProd: [],
                    lcOfflineProd: [],
                    lcTransferProd: [],
                    lcDomesticProd: [],
                    lcDeliveryProd: [],
                };

                if (data.prodList.length > 0) {
                    // 순회하여 각 상태값에 새로운 배열을 할당
                    data.prodList.forEach((prod) => {
                        switch (prod.prodTy) {
                            case 'P00':
                                newState.lcCartProd.push(prod);
                                break;
                            case 'P01':
                                newState.lcOrderProd.push(prod);
                                break;
                            case 'P02':
                                newState.lcOfflineProd.push(prod);
                                break;
                            case 'P03':
                                newState.lcTransferProd.push(prod);
                                break;
                            case 'P04':
                                newState.lcDomesticProd.push(prod);
                                break;
                            case 'P05':
                                newState.lcDeliveryProd.push(prod);
                                break;
                            default:
                                break;
                        }
                    });

                    // 새로운 상태값으로 각 상태 업데이트
                    setLcCartProd(newState.lcCartProd);
                    setLcOrderProd(newState.lcOrderProd);
                    setLcOfflineProd(newState.lcOfflineProd);
                    setLcTransferProd(newState.lcTransferProd);
                    setLcDomesticProd(newState.lcDomesticProd);
                    setLcDeliveryProd(newState.lcDeliveryProd);
                }

                if (data.payList.length > 0) {
                    setLcPayData(data.payList)
                }

                if (data.depositList.length > 0) {
                    setLcDepositData(data.depositList)
                }

                if (data.reviewList.length > 0) {
                    setLcReviewData(data.reviewList)
                }

            })
            .catch((error) => {
                console.log("알림데이터 조회실패 : ", error)
            })
    });

    const selectChatCount = (() => {
        axios({
            method: 'get',
            url: '/order/selectChatCount',
        })
            .then((response) => {
                const totalChats = response.data.userChat + response.data.nonUserChat;
                setLcChatData(totalChats);
                // 총채팅개수 각각 산출
                // setLcUserChatCount(response.data.userChat);
                // setLcNUserChatCount(response.data.nonUserChat);
            })
            .catch((error) => console.error(error))
    })

    useEffect(() => {
        selectAlarmData();
        selectChatCount()
    }, [])

    const moveToAlarmListPg = ((type, count) => {
        if (count > 0) {
            const stateData = {
                cart: lcCartProd,
                order: lcOrderProd,
                offline: lcOfflineProd,
                transfer: lcTransferProd,
                domestic: lcDomesticProd,
                delivery: lcDeliveryProd,
                pay: lcPayData,
                deposit: lcDepositData,
                review: lcReviewData
            };
            navigate(`/btcorpad/alarmlist/${type}`, { state: stateData[type] });
        }
    })

    const orderDataReceived = ((payload) => {
        let orderData = JSON.parse(payload.body);
        selectAlarmData();
        selectedAllProcessCount();
    })

    const depositDataReceived = ((payload) => {
        let depositData = JSON.parse(payload.body);
        selectAlarmData();
        selectedAllProcessCount();
    })

    const reviewDataReceived = ((payload) => {
        let reviewData = JSON.parse(payload.body);
        selectAlarmData();
        selectedAllProcessCount();
    })

    const chatDataReceived = ((payload) => {
        let chatData = JSON.parse(payload.body);
        selectChatCount();
    })

    const [copiedIndexes, setCopiedIndexes] = useState([]);

    const handleCopy = (text, index) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setCopiedIndexes([...copiedIndexes, index]);
                setTimeout(() => {
                    setCopiedIndexes(copiedIndexes.filter(i => i !== index));
                }, 1000);
            })
            .catch((error) => {console.error("실패: ", error);});
    };

    /** endregion Function */

    /** region event */

    useEffect(() => {
        ordReceiptCount();
    }, [])

    const ordReceiptCount = (() => {
        axios({
            method: 'get',
            url: '/order/receiptCount',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setOrdReceiptCountData(response.data);
            })
            .catch(() => {})
    })

    const selectedAdminInfo = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setAdminInfo(response.data)
            })
            .catch((error) => {
                console.log("Admin 조회실패 : ", error)
            })
    })

    useEffect(() => {
        selectedAdminInfo();
        selectAllData();
    }, [])


    useEffect(() => {
        const timer = setTimeout(() => {
            defaultMonth();
        })
        return () => {
            clearTimeout(timer);
        }
    }, [endDate])

    const selectAllData = (() => {
        axios({
            method: 'get',
            url: '/order/selectAllMainData',
            headers: {
                Authorization: LCAT
            },
            params: {
                startDate: stringStartDate,
                endDate: stringEndDate,
            },
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => {
                    return new Date(a.modiTime) - new Date(b.modiTime);
                });
                setOrdData(sortedData);
                // setOrdData(response.data);

                const ProdList = response.data.map(order => {
                    const productMap = new Map();
                    order.productsDTO.forEach(product => {
                        if (!productMap.has(product.seqnum)) {
                            productMap.set(product.seqnum, product);
                        }
                    });
                    return Array.from(productMap.values());
                });
                setProductsList(ProdList);

                const payList = response.data.flatMap(order => order.payInfoDTO);
                setPayinfoList(payList);

                const recList = response.data.map(order => order.recinfoDTO);
                setRecinfoList(recList);

                setMemberInfo(response.data[0].memberDTO)
            })
            .catch(() => {})
    })

    const [orderStSelectCommonValue, setOrderStSelectCommonValue] = useState("상태(전체)");

    const convertToShipco = ((dvType, defalutShipco) => {
        if (dvType === "D00") {
            return "국내배송"
        } else if (dvType === "D01") {
            return "FedEx"
        } else if (dvType === "D02") {
            return "EMS"
        } else if (dvType === "D03") {
            return "SHIP"
        } else if (dvType === "D11") {
            return "UPS"
        } else if (dvType === "D13") {
            return "K-Packet"
        } else if (dvType === "D14") {
            return "Small-Packet"
        } else {
            return defalutShipco;
        }
    })

    useEffect(() => {
        const combined = [
            ...ordData.map((item) => ({
                type: 'orderdt',
                date: item.modiTime || item.regTime,
                mostRecentDate: new Date(Math.max(new Date(item.modiTime), new Date(item.regTime))),
                ...item,
            })),
            ...payinfoList.map((item) => ({
                type: 'payinfo',
                date: item?.modiTime || item?.regTime,
                mostRecentDate: new Date(Math.max(new Date(item.modiTime), new Date(item.regTime))),
                ...item,
            })),
        ];

        combined.sort((a, b) => b.mostRecentDate - a.mostRecentDate);
        // combined.sort((a, b) => moment(b.date).isAfter(moment(a.date)) ? 1 : 0);

        const filterStatus = (order) => {
            if (orderStSelectCommonValue === "상태(전체)") return true;
            switch (orderStSelectCommonValue) {
                case "PS0":
                    return (order.type === 'orderdt' && order.orderStatus === "OS09") ||
                        (order.type === 'payinfo' && order.payStatus === "PS0");
                case "PS1":
                    return (order.type === 'orderdt' && order.orderStatus === "OS10") ||
                        (order.type === 'payinfo' && order.payStatus === "PS1");
                case "PS2":
                    return (order.type === 'orderdt' && order.orderStatus === "OS11") ||
                        (order.type === 'payinfo' && order.payStatus === "PS2");
                case "PS3":
                    return (order.type === 'orderdt' && order.orderStatus === "OS12") ||
                        (order.type === 'payinfo' && order.payStatus === "PS3");
                case "PS4":
                    return (order.type === 'orderdt' && order.orderStatus === "OS13") ||
                        (order.type === 'payinfo' && order.payStatus === "PS4");
                case "PS5":
                    return (order.type === 'orderdt' && order.orderStatus === "OS14") ||
                        (order.type === 'payinfo' && order.payStatus === "PS5");
                default:
                    return (order.type === 'orderdt' && order.orderStatus === orderStSelectCommonValue) ||
                        (order.type === 'payinfo' && order.payStatus === orderStSelectCommonValue);
            }
        }

        const filterShipCo = (order) => {
            if (selectedShipCompany === "배송사선택") return true;
            switch (selectedShipCompany) {
                case "FedEx":
                    return (order.type === 'orderdt' && order.shipco === "FedEx") ||
                        (order.type === 'payinfo' && order.dvType === "D01");
                case "UPS":
                    return (order.type === 'orderdt' && order.shipco === "UPS") ||
                        (order.type === 'payinfo' && order.dvType === "D11");
                case "EMS":
                    return (order.type === 'orderdt' && order.shipco === "EMS") ||
                        (order.type === 'payinfo' && order.dvType === "D02");
                case "SHIP":
                    return (order.type === 'orderdt' && order.shipco === "SHIP") ||
                        (order.type === 'payinfo' && order.dvType === "D03");
                case "국내배송":
                    return (order.type === 'orderdt' && order.shipco === "국내배송") ||
                        (order.type === 'payinfo' && order.dvType === "D00");
                // case "DHL":
                //     return (order.type === 'orderdt' && order.shipco === "DHL") ||
                //         (order.type === 'payinfo' && order.dvType === "D04");
                case "K-Packet":
                    return (order.type === 'orderdt' && order.shipco === "K-Packet") ||
                        (order.type === 'payinfo' && order.dvType === "D013");
                case "Small-Packet":
                    return (order.type === 'orderdt' && order.shipco === "Small-Packet") ||
                        (order.type === 'payinfo' && order.dvType === "D14");
                default:
                    return (order.type === 'orderdt' && order.shipco === selectedShipCompany) ||
                        (order.type === 'payinfo' && convertToShipco(order.dvType, order.orderdtDTO?.shipco) === selectedShipCompany);
            }
        }

        const matchesSearch = (value) => {
            const searchString = searchInput.toLowerCase().trim();
            if (typeof value === 'number') {
                const prefixedValue = `BT${value}`;
                return searchString === prefixedValue.toLowerCase();
            }
            return value?.toString().toLowerCase().includes(searchString);
        };

        const newList = combined.filter((order) => {
            if (!filterStatus(order)) return false;
            if (!filterShipCo(order)) return false;
            if (!searchInput.trim()) return true;

            if (order.type === 'orderdt') {
                return (
                    matchesSearch(order.orderNumber) ||
                    matchesSearch(order.shipco) ||
                    matchesSearch(order.memberDTO?.mbNum) ||
                    matchesSearch(order.memberDTO?.userName) ||
                    matchesSearch(order.recinfoDTO?.recname) ||
                    matchesSearch(order.recinfoDTO?.countryDTO?.countryEngNm) ||
                    matchesSearch(order.recinfoDTO?.countryDTO?.countryNm) ||
                    matchesSearch(order.recinfoDTO?.country) ||
                    matchesSearch('주문서') ||
                    order.productsDTO?.some((prod) => matchesSearch(prod?.option)) ||
                    order.productsDTO?.some((prod) => matchesSearch(prod?.name))
                );
            } else if (order.type === 'payinfo') {
                return (
                    matchesSearch(order.orderdtDTO?.orderNumber) ||
                    matchesSearch(convertToShipco(order.dvType, order.orderdtDTO.shipco)) ||
                    matchesSearch(order.orderdtDTO?.memberDTO?.mbNum) ||
                    matchesSearch(order.orderdtDTO?.memberDTO?.userName) ||
                    matchesSearch(order.appRecDTO?.recname) ||
                    matchesSearch(order.appRecDTO?.countryDTO?.countryEngNm) ||
                    matchesSearch(order.appRecDTO?.countryDTO?.countryNm) ||
                    matchesSearch(order.appRecDTO?.country) ||
                    matchesSearch(order.appOrderMemoDTO?.ordDtCont) ||
                    matchesSearch('배송료') ||
                    order.packListDTO?.productsDTO?.some((prod) => matchesSearch(prod?.option)) ||
                    order.packListDTO?.productsDTO?.some((prod) => matchesSearch(prod?.name))
                );
            }
        });

        setFilteredList(newList);
    }, [ordData, payinfoList, searchInput, orderStSelectCommonValue, selectedShipCompany]);


    const orderContentRef = useRef(null);
    const scrollToOrderContent = () => {
        if (orderContentRef.current) {
            orderContentRef?.current.scrollIntoView({behavior: 'smooth'});
        }
    };

    const [selectedProcessSt, setSelectedProcessSt] = useState('');
    const selectNoDateTotalOrderList = ((orderSt) => {
        if (orderSt === "OS01" || orderSt === "PS0" || orderSt === "PS3") {
            setChecked([]);
        }
        setSelectedProcessSt(orderSt);
        axios({
            method: 'get',
            url: '/order/selectAllMainData',
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => {
                    return new Date(a.modiTime) - new Date(b.modiTime);
                });
                setOrdData(sortedData);

                const ProdList = response.data.map(order => {
                    const productMap = new Map();
                    order.productsDTO.forEach(product => {
                        if (!productMap.has(product.seqnum)) {
                            productMap.set(product.seqnum, product);
                        }
                    });
                    return Array.from(productMap.values());
                });
                setProductsList(ProdList);

                const payList = response.data.flatMap(order => order.payInfoDTO);
                setPayinfoList(payList);

                const recList = response.data.map(order => order.recinfoDTO);
                setRecinfoList(recList);

                scrollToOrderContent();
                setOrderStSelectCommonValue(orderSt);
                // 추후 개월수 로직 완료되면 개월값을 default로 변경예정
                // setPeriodSelectCommonValue("전체")

            })
            .catch((error) => {
                console.log("노데이트 주문조회실패 : ", error)
            })
    })

    //region orderModal

    const infoDTPg = (cData) => {
        navigate(`/btcorpad/orderdtmodi/${cData.orderNumber}`);
    };

    const infoPayDTPg = (cData) => {
        setPaySeqnum(cData);
        navigate(`/btcorpad/orderPayDt/${cData.seqNum}`);
    };

    const openRegModal = (rowData, dataType) => {
        const combinedData = {
            ...rowData,
            dataType,
        };

        localStorage.setItem('orderData', JSON.stringify(combinedData));
        navigate('/btcorpad/btad-PayregM', { state: { rowData } });
    };

    const openModalMerge = (rowData) => {
        localStorage.setItem('orderData', JSON.stringify(rowData));
        navigate('/btcorpad/btad-MergeM', { state: { rowData } });
    };

    const openModalDivide = (rowData) => {
        localStorage.setItem('orderData', JSON.stringify(rowData));
        navigate('/btcorpad/btad-DivideM', { state: { rowData } });
    };

    //endregion

    const [paySeqnum, setPaySeqnum] = useState(null);
    const [deliveryDetailSt, setDeliveryDetailSt] = useState(false);
    const deliveryDetailFalseFn = (() => {
        setDeliveryDetailSt(false);
    })
    const deliveryDetailTrueFn = ((e) => {
        setPaySeqnum(e);
        setDeliveryDetailSt(true);
    })

    // 현재 관리자 브라우저위치
    const pathComponent = window.location.pathname;

    const updateAppRecinfo = (updatedPayinfo) => {
        setPayinfoList((prevPayinfoList) => {
            return prevPayinfoList.map((payinfo) => {
                if (payinfo.appRecDTO && payinfo.appRecDTO.seqnum === updatedPayinfo.seqnum) {
                    return {...payinfo, appRecDTO: updatedPayinfo};
                }
                return payinfo;
            });
        });
    };

    const updatePayinfoList = (updatedPayinfo) => {
        setPayinfoList((prevPayinfoList) => {
            return prevPayinfoList.map((payinfo) =>
                payinfo.seqNum === updatedPayinfo.seqNum ? updatedPayinfo : payinfo,
            );
        });
    };

    // 장바구니 상품상태변경 시작
    const [isOpenCartItemDt, setIsOpenCartItemDt] = useState({});

    // const toggleMenuDTcartFn = (cDataIndex, prodQy) => {
    //     if (prodQy > 0) {
    //         setIsOpenCartItemDt(prevState => ({
    //             ...prevState,
    //             [cDataIndex]: !prevState[cDataIndex]
    //         }));
    //     }
    // };

    const toggleMenuDTcartFn = (cDataIndex, prodQy) => {
        setIsOpenCartItemDt(prevState => ({
            ...prevState,
            [cDataIndex]: prodQy > 0 ? !prevState[cDataIndex] : false
        }));
    };

    const [allCartProdStChangeShow, setAllCartProdStChangeShow] = useState(false);
    const closeAllCartProdStChangeShow = (() => {
        setAllCartProdStChangeShow(false);
    })
    const [allCartProdStNonSeqnumShow, setAllCartProdStNonSeqnumShow] = useState(false);
    const closeAllCartProdStNonSeqNumModalShow = (() => {
        setAllCartProdStNonSeqnumShow(false);
    })

    const [cartProdStShow, setCartProdStShow] = useState({});
    const cartProdStRef = useRef([]);

    const toggleCartProdStShow = (cDataIndex, productIndex) => {
        const key = `${cDataIndex}-${productIndex}`;
        setCartProdStShow(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    useEffect(() => {
        function handleClickOutside(event) {
            // Find the key of the currently open dropdown
            const openDropdownKey = Object.keys(cartProdStShow).find(key => cartProdStShow[key]);
            if (openDropdownKey !== undefined) {
                // Retrieve the ref of the open dropdown
                const openDropdownRef = cartProdStRef.current[openDropdownKey];

                // If the click was outside the open dropdown, close it
                if (openDropdownRef && !openDropdownRef.contains(event.target)) {
                    setCartProdStShow(prevState => ({
                        ...prevState,
                        [openDropdownKey]: false
                    }));
                }
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [cartProdStShow]);

    const [selectedAllCartProdSt, setSelectedAllCartProdSt] = useState({});
    const [allCartProdStShow, setAllCartProdStShow] = useState({});
    const allCartProdStRefs = useRef({});

    const toggleAllCartProdStShow = (cDataIndex) => {
        setAllCartProdStShow(prevState => ({
            ...prevState,
            [cDataIndex]: !prevState[cDataIndex]
        }));
    };

    useEffect(() => {
        function handleClickOutside(event) {
            const openDropdownKey = Object.keys(allCartProdStShow).find(key => allCartProdStShow[key]);
            const openDropdownRef = allCartProdStRefs.current[openDropdownKey];

            if (openDropdownRef && !openDropdownRef.contains(event.target)) {
                setAllCartProdStShow(prevState => ({
                    ...prevState,
                    [openDropdownKey]: false
                }));
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [allCartProdStShow, allCartProdStRefs]);
    // 장바구니 상품상태변경 종료

    // 구매대행 상품상태변경 시작
    const [isOpenOrderItemDt, setIsOpenOrderItemDt] = useState({});

    const toggleMenuDTorderFn = (cDataIndex, prodQy) => {
        setIsOpenOrderItemDt(prevState => ({
            ...prevState,
            [cDataIndex]: prodQy > 0 ? !prevState[cDataIndex] : false
        }));
    };

    const [allOrderProdStChangeShow, setAllOrderProdStChangeShow] = useState(false);
    const closeAllOrderProdStChangeShow = (() => {
        setAllOrderProdStChangeShow(false);
    })
    const [allOrderProdStNonSeqnumShow, setAllOrderProdStNonSeqnumShow] = useState(false);
    const closeAllOrderProdStNonSeqNumModalShow = (() => {
        setAllOrderProdStNonSeqnumShow(false);
    })

    const [orderProdStShow, setOrderProdStShow] = useState({});
    const orderProdStRef = useRef([]);

    const toggleOrderProdStShow = (cDataIndex, productIndex) => {
        const key = `${cDataIndex}-${productIndex}`;
        setOrderProdStShow(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    useEffect(() => {
        function handleClickOutside(event) {
            // Find the key of the currently open dropdown
            const openDropdownKey = Object.keys(orderProdStShow).find(key => orderProdStShow[key]);
            if (openDropdownKey !== undefined) {
                // Retrieve the ref of the open dropdown
                const openDropdownRef = orderProdStRef.current[openDropdownKey];

                // If the click was outside the open dropdown, close it
                if (openDropdownRef && !openDropdownRef.contains(event.target)) {
                    setOrderProdStShow(prevState => ({
                        ...prevState,
                        [openDropdownKey]: false
                    }));
                }
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [orderProdStShow]);

    const [selectedAllOrderProdSt, setSelectedAllOrderProdSt] = useState({});
    const [allOrderProdStShow, setAllOrderProdStShow] = useState({});
    const allOrderProdStRefs = useRef({});

    const toggleAllOrderProdStShow = (cDataIndex) => {
        setAllOrderProdStShow(prevState => ({
            ...prevState,
            [cDataIndex]: !prevState[cDataIndex]
        }));
    };

    useEffect(() => {
        function handleClickOutside(event) {
            const openDropdownKey = Object.keys(allOrderProdStShow).find(key => allOrderProdStShow[key]);
            const openDropdownRef = allOrderProdStRefs.current[openDropdownKey];

            if (openDropdownRef && !openDropdownRef.contains(event.target)) {
                setAllOrderProdStShow(prevState => ({
                    ...prevState,
                    [openDropdownKey]: false
                }));
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [allOrderProdStShow, allOrderProdStRefs]);
    // 구매대행 상품상태변경 종료

    // 오프라인 상품상태변경 시작
    const [isOpenOfflineItemDt, setIsOpenOfflineItemDt] = useState({});

    // const toggleMenuDTofflineFn = (cDataIndex, prodQy) => {
    //     if (prodQy > 0) {
    //         setIsOpenOfflineItemDt(prevState => ({
    //             ...prevState,
    //             [cDataIndex]: !prevState[cDataIndex]
    //         }));
    //     }
    // };

    const toggleMenuDTofflineFn = (cDataIndex, prodQy) => {
        setIsOpenOfflineItemDt(prevState => ({
            ...prevState,
            [cDataIndex]: prodQy > 0 ? !prevState[cDataIndex] : false
        }));
    };

    const [allOfflineProdStChangeShow, setAllOfflineProdStChangeShow] = useState(false);
    const closeAllOfflineProdStChangeShow = (() => {
        setAllOfflineProdStChangeShow(false);
    })
    const [allOfflineProdStNonSeqnumShow, setAllOfflineProdStNonSeqnumShow] = useState(false);
    const closeAllOfflineProdStNonSeqNumModalShow = (() => {
        setAllOfflineProdStNonSeqnumShow(false);
    })

    const [offlineProdStShow, setOfflineProdStShow] = useState({});
    const offlineProdStRef = useRef([]);

    const toggleOfflineProdStShow = (cDataIndex, productIndex) => {
        const key = `${cDataIndex}-${productIndex}`;
        setOfflineProdStShow(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    useEffect(() => {
        function handleClickOutside(event) {
            // Find the key of the currently open dropdown
            const openDropdownKey = Object.keys(offlineProdStShow).find(key => offlineProdStShow[key]);
            if (openDropdownKey !== undefined) {
                // Retrieve the ref of the open dropdown
                const openDropdownRef = offlineProdStRef.current[openDropdownKey];

                // If the click was outside the open dropdown, close it
                if (openDropdownRef && !openDropdownRef.contains(event.target)) {
                    setOfflineProdStShow(prevState => ({
                        ...prevState,
                        [openDropdownKey]: false
                    }));
                }
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [offlineProdStShow]);

    const [selectedAllOfflineProdSt, setSelectedAllOfflineProdSt] = useState({});
    const [allOfflineProdStShow, setAllOfflineProdStShow] = useState({});
    const allOfflineProdStRefs = useRef({});

    const toggleAllOfflineProdStShow = (cDataIndex) => {
        setAllOfflineProdStShow(prevState => ({
            ...prevState,
            [cDataIndex]: !prevState[cDataIndex]
        }));
    };

    useEffect(() => {
        function handleClickOutside(event) {
            const openDropdownKey = Object.keys(allOfflineProdStShow).find(key => allOfflineProdStShow[key]);
            const openDropdownRef = allOfflineProdStRefs.current[openDropdownKey];

            if (openDropdownRef && !openDropdownRef.contains(event.target)) {
                setAllOfflineProdStShow(prevState => ({
                    ...prevState,
                    [openDropdownKey]: false
                }));
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [allOfflineProdStShow, allOfflineProdStRefs]);
    // 오프라인 상품상태변경 종료

    // 이체대행 상품상태변경 시작
    const [isOpenTransferItemDt, setIsOpenTransferItemDt] = useState({});

    // const toggleMenuDTtransferFn = (cDataIndex, prodQy) => {
    //     if (prodQy > 0) {
    //         setIsOpenTransferItemDt(prevState => ({
    //             ...prevState,
    //             [cDataIndex]: !prevState[cDataIndex]
    //         }));
    //     }
    // };

    const toggleMenuDTtransferFn = (cDataIndex, prodQy) => {
        setIsOpenTransferItemDt(prevState => ({
            ...prevState,
            [cDataIndex]: prodQy > 0 ? !prevState[cDataIndex] : false
        }));
    };

    const [allTransferProdStChangeShow, setAllTransferProdStChangeShow] = useState(false);
    const closeAllTransferProdStChangeShow = (() => {
        setAllTransferProdStChangeShow(false);
    })
    const [allTransferProdStNonSeqnumShow, setAllTransferProdStNonSeqnumShow] = useState(false);
    const closeAllTransferProdStNonSeqNumModalShow = (() => {
        setAllTransferProdStNonSeqnumShow(false);
    })

    const [transferProdStShow, setTransferProdStShow] = useState({});
    const transferProdStRef = useRef([]);

    const toggleTransferProdStShow = (cDataIndex, productIndex) => {
        const key = `${cDataIndex}-${productIndex}`;
        setTransferProdStShow(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    useEffect(() => {
        function handleClickOutside(event) {
            // Find the key of the currently open dropdown
            const openDropdownKey = Object.keys(transferProdStShow).find(key => transferProdStShow[key]);
            if (openDropdownKey !== undefined) {
                // Retrieve the ref of the open dropdown
                const openDropdownRef = transferProdStRef.current[openDropdownKey];

                // If the click was outside the open dropdown, close it
                if (openDropdownRef && !openDropdownRef.contains(event.target)) {
                    setTransferProdStShow(prevState => ({
                        ...prevState,
                        [openDropdownKey]: false
                    }));
                }
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [transferProdStShow]);

    const [selectedAllTransferProdSt, setSelectedAllTransferProdSt] = useState({});
    const [allTransferProdStShow, setAllTransferProdStShow] = useState({});
    const allTransferProdStRefs = useRef({});

    const toggleAllTransferProdStShow = (cDataIndex) => {
        setAllTransferProdStShow(prevState => ({
            ...prevState,
            [cDataIndex]: !prevState[cDataIndex]
        }));
    };

    useEffect(() => {
        function handleClickOutside(event) {
            const openDropdownKey = Object.keys(allTransferProdStShow).find(key => allTransferProdStShow[key]);
            const openDropdownRef = allTransferProdStRefs.current[openDropdownKey];

            if (openDropdownRef && !openDropdownRef.contains(event.target)) {
                setAllTransferProdStShow(prevState => ({
                    ...prevState,
                    [openDropdownKey]: false
                }));
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [allTransferProdStShow, allTransferProdStRefs]);
    // 이체대행 상품상태변경 종료

    // 국내구매 상품상태변경 시작
    const [isOpenDomesticItemDt, setIsOpenDomesticItemDt] = useState({});

    // const toggleMenuDTdomesticFn = (cDataIndex, prodQy) => {
    //     if (prodQy > 0) {
    //         setIsOpenDomesticItemDt(prevState => ({
    //             ...prevState,
    //             [cDataIndex]: !prevState[cDataIndex]
    //         }));
    //     }
    // };

    const toggleMenuDTdomesticFn = (cDataIndex, prodQy) => {
        setIsOpenDomesticItemDt(prevState => ({
            ...prevState,
            [cDataIndex]: prodQy > 0 ? !prevState[cDataIndex] : false
        }));
    };

    const [allDomesticProdStChangeShow, setAllDomesticProdStChangeShow] = useState(false);
    const closeAllDomesticProdStChangeShow = (() => {
        setAllDomesticProdStChangeShow(false);
    })
    const [allDomesticProdStNonSeqnumShow, setAllDomesticProdStNonSeqnumShow] = useState(false);
    const closeAllDomesticProdStNonSeqNumModalShow = (() => {
        setAllDomesticProdStNonSeqnumShow(false);
    })

    const [domesticProdStShow, setDomesticProdStShow] = useState({});
    const domesticProdStRef = useRef([]);

    const toggleDomesticProdStShow = (cDataIndex, productIndex) => {
        const key = `${cDataIndex}-${productIndex}`;
        setDomesticProdStShow(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    useEffect(() => {
        function handleClickOutside(event) {
            // Find the key of the currently open dropdown
            const openDropdownKey = Object.keys(domesticProdStShow).find(key => domesticProdStShow[key]);
            if (openDropdownKey !== undefined) {
                // Retrieve the ref of the open dropdown
                const openDropdownRef = domesticProdStRef.current[openDropdownKey];

                // If the click was outside the open dropdown, close it
                if (openDropdownRef && !openDropdownRef.contains(event.target)) {
                    setDomesticProdStShow(prevState => ({
                        ...prevState,
                        [openDropdownKey]: false
                    }));
                }
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [domesticProdStShow]);

    const [selectedAllDomesticProdSt, setSelectedAllDomesticProdSt] = useState({});
    const [allDomesticProdStShow, setAllDomesticProdStShow] = useState({});
    const allDomesticProdStRefs = useRef({});

    const toggleAllDomesticProdStShow = (cDataIndex) => {
        setAllDomesticProdStShow(prevState => ({
            ...prevState,
            [cDataIndex]: !prevState[cDataIndex]
        }));
    };

    useEffect(() => {
        function handleClickOutside(event) {
            const openDropdownKey = Object.keys(allDomesticProdStShow).find(key => allDomesticProdStShow[key]);
            const openDropdownRef = allDomesticProdStRefs.current[openDropdownKey];

            if (openDropdownRef && !openDropdownRef.contains(event.target)) {
                setAllDomesticProdStShow(prevState => ({
                    ...prevState,
                    [openDropdownKey]: false
                }));
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [allDomesticProdStShow, allDomesticProdStRefs]);
    // 국내구매 상품상태변경 종료

    // 배송대행 상품상태변경 시작
    const [isOpenDeliveryItemDt, setIsOpenDeliveryItemDt] = useState({});

    // const toggleMenuDTdeliveryFn = (cDataIndex, prodQy) => {
    //     if (prodQy > 0) {
    //         setIsOpenDeliveryItemDt(prevState => ({
    //             ...prevState,
    //             [cDataIndex]: !prevState[cDataIndex]
    //         }));
    //     }
    // };

    const toggleMenuDTdeliveryFn = (cDataIndex, prodQy) => {
        setIsOpenDeliveryItemDt(prevState => ({
            ...prevState,
            [cDataIndex]: prodQy > 0 ? !prevState[cDataIndex] : false
        }));
    };

    const [allDeliveryProdStChangeShow, setAllDeliveryProdStChangeShow] = useState(false);
    const closeAllDeliveryProdStChangeShow = (() => {
        setAllDeliveryProdStChangeShow(false);
    })
    const [allDeliveryProdStNonSeqnumShow, setAllDeliveryProdStNonSeqnumShow] = useState(false);
    const closeAllDeliveryProdStNonSeqNumModalShow = (() => {
        setAllDeliveryProdStNonSeqnumShow(false);
    })

    const [deliveryProdStShow, setDeliveryProdStShow] = useState({});
    const deliveryProdStRef = useRef([]);

    const toggleDeliveryProdStShow = (cDataIndex, productIndex) => {
        const key = `${cDataIndex}-${productIndex}`;
        setDeliveryProdStShow(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    useEffect(() => {
        function handleClickOutside(event) {
            const openDropdownKey = Object.keys(deliveryProdStShow).find(key => deliveryProdStShow[key]);
            if (openDropdownKey !== undefined) {
                const openDropdownRef = deliveryProdStRef.current[openDropdownKey];

                if (openDropdownRef && !openDropdownRef.contains(event.target)) {
                    setDeliveryProdStShow(prevState => ({
                        ...prevState,
                        [openDropdownKey]: false
                    }));
                }
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [deliveryProdStShow]);

    const [selectedAllDeliveryProdSt, setSelectedAllDeliveryProdSt] = useState({});
    const [allDeliveryProdStShow, setAllDeliveryProdStShow] = useState({});
    const allDeliveryProdStRefs = useRef({});

    const toggleAllDeliveryProdStShow = (cDataIndex) => {
        setAllDeliveryProdStShow(prevState => ({
            ...prevState,
            [cDataIndex]: !prevState[cDataIndex]
        }));
    };

    useEffect(() => {
        function handleClickOutside(event) {
            const openDropdownKey = Object.keys(allDeliveryProdStShow).find(key => allDeliveryProdStShow[key]);
            const openDropdownRef = allDeliveryProdStRefs.current[openDropdownKey];

            if (openDropdownRef && !openDropdownRef.contains(event.target)) {
                setAllDeliveryProdStShow(prevState => ({
                    ...prevState,
                    [openDropdownKey]: false
                }));
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [allDeliveryProdStShow, allDeliveryProdStRefs]);
    // 배송대행 상품상태변경 종료

    // common function
    const allProdStChangeMsg1 = "선택한 상품의 상태들을 변경하시겠습니까?";
    const allProdStChangeMsg2 = "변경하실 상품상태를 한 번더 확인해주세요";
    const allProdStNonSeqnumMsg = "전체변경하실 상품을 선택해주세요";

    const [selectedFormTy, setSelectedFormTy] = useState('');
    const [selectedCdataIndex, setSelectedCdataIndex] = useState(0);
    const [selectedProdSt, setSelectedProdSt] = useState({});
    const [selectedItems, setSelectedItems] = useState({
        cart: {},
        order: {},
        offline: {},
        transfer: {},
        domestic: {},
        delivery: {}
    });

    const handleCheckAll = (formType, cDataIndex, isChecked, productsDTO) => {
        let prodStCode = null;
        if (formType === 'cart') {
            prodStCode = "P00";
        } else if (formType === 'order') {
            prodStCode = "P01";
        } else if (formType === 'offline') {
            prodStCode = "P02";
        } else if (formType === 'transfer') {
            prodStCode = "P03";
        } else if (formType === 'domestic') {
            prodStCode = "P04";
        } else if (formType === 'delivery') {
            prodStCode = "P05";
        }

        setSelectedItems(prevState => {
            const updatedState = { ...prevState };
            updatedState[formType][cDataIndex] = isChecked ? productsDTO.filter(prod => prod.prodTy === prodStCode).map(prod => prod.seqnum) : [];
            return updatedState;
        });
    };

    // 개별 아이템 선택 처리
    const handleCheckItem = (formType, cDataIndex, seqNum, isChecked) => {
        setSelectedItems(prevState => {
            const updatedState = { ...prevState };
            if (isChecked) {
                updatedState[formType][cDataIndex] = [...(updatedState[formType][cDataIndex] || []), seqNum];
            } else {
                updatedState[formType][cDataIndex] = (updatedState[formType][cDataIndex] || []).filter(num => num !== seqNum);
            }
            return updatedState;
        });
    };

    const handleProdStChange = (formType, prodSt, seqNum, cDataIndex, productIndex) => {
        // 셀렉트 label 업뎃
        const newSelectedProdSt = {
            ...selectedProdSt,
            [`${formType}-${cDataIndex}-${productIndex}`]: prodSt
        };
        setSelectedProdSt(newSelectedProdSt);

        // 주문서 index 및 prod index에 따라 드랍다운 닫기
        if (formType === "cart") {
            setCartProdStShow({});
        } else if (formType === "order") {
            setOrderProdStShow({});
        } else if (formType === "offline") {
            setOfflineProdStShow({});
        } else if (formType === "transfer") {
            setTransferProdStShow({});
        } else if (formType === "domestic") {
            setDomesticProdStShow({});
        } else if (formType === "delivery") {
            setDeliveryProdStShow({});
        }

        modifyProdSt(prodSt, seqNum);
    };

    const handleAllProdStChange = (formType, cDataIndex, allProdSt) => {
        setSelectedFormTy(formType);
        setSelectedCdataIndex(cDataIndex);
        let selectedItemsDataFl = selectedItems[formType][cDataIndex] && selectedItems[formType][cDataIndex].length > 0;
        let selectedItemsNoDataFl = !selectedItems[formType][cDataIndex] || selectedItems[formType][cDataIndex].length === 0;

        if (formType === 'cart') {
            setSelectedAllCartProdSt(prevState => ({
                ...prevState,
                [cDataIndex]: allProdSt
            }));
            setAllCartProdStShow(prevState => ({
                ...prevState,
                [cDataIndex]: false
            }));
            setAllCartProdStChangeShow(selectedItemsDataFl);
            setAllCartProdStNonSeqnumShow(selectedItemsNoDataFl);
        } else if (formType === 'order') {
            setSelectedAllOrderProdSt(prevState => ({
                ...prevState,
                [cDataIndex]: allProdSt
            }));
            setAllOrderProdStShow(prevState => ({
                ...prevState,
                [cDataIndex]: false
            }));
            setAllOrderProdStChangeShow(selectedItemsDataFl);
            setAllOrderProdStNonSeqnumShow(selectedItemsNoDataFl);
        } else if (formType === 'offline') {
            setSelectedAllOfflineProdSt(prevState => ({
                ...prevState,
                [cDataIndex]: allProdSt
            }));
            setAllOfflineProdStShow(prevState => ({
                ...prevState,
                [cDataIndex]: false
            }));
            setAllOfflineProdStChangeShow(selectedItemsDataFl);
            setAllOfflineProdStNonSeqnumShow(selectedItemsNoDataFl);
        } else if (formType === 'transfer') {
            setSelectedAllTransferProdSt(prevState => ({
                ...prevState,
                [cDataIndex]: allProdSt
            }));
            setAllTransferProdStShow(prevState => ({
                ...prevState,
                [cDataIndex]: false
            }));
            setAllTransferProdStChangeShow(selectedItemsDataFl);
            setAllTransferProdStNonSeqnumShow(selectedItemsNoDataFl);
        } else if (formType === 'domestic') {
            setSelectedAllDomesticProdSt(prevState => ({
                ...prevState,
                [cDataIndex]: allProdSt
            }));
            setAllDomesticProdStShow(prevState => ({
                ...prevState,
                [cDataIndex]: false
            }));
            setAllDomesticProdStChangeShow(selectedItemsDataFl);
            setAllDomesticProdStNonSeqnumShow(selectedItemsNoDataFl);
        } else if (formType === 'delivery') {
            setSelectedAllDeliveryProdSt(prevState => ({
                ...prevState,
                [cDataIndex]: allProdSt
            }));
            setAllDeliveryProdStShow(prevState => ({
                ...prevState,
                [cDataIndex]: false
            }));
            setAllDeliveryProdStChangeShow(selectedItemsDataFl);
            setAllDeliveryProdStNonSeqnumShow(selectedItemsNoDataFl);
        }
    };

    const [dontTrackingShipcoSt, setDontTrackingShipcoSt] = useState(false);
    const closeDontTrackingShipco = (() => {
        setDontTrackingShipcoSt(false);
    });

    const dbSelectedDeliveryTracking = ((selectedDomestic, dsTrackNum) => {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const width = 1200;
        const height = 800;
        const left = (screenWidth / 2) - (width / 2);
        const top = (screenHeight / 2) - (height / 2);

        if (selectedDomestic === "CJ대한통운") {
            const url = `https://trace.cjlogistics.com/next/tracking.html?wblNo=${dsTrackNum}`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "로젠택배") {
            const url = `https://www.ilogen.com/web/personal/trace/${dsTrackNum}`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "우체국") {
            const url = `https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?sid1=${dsTrackNum}&displayHeader=N`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "한진택배") {
            const url = `https://www.hanjin.com/kor/CMS/DeliveryMgr/WaybillResult.do?mCode=MN038&schLang=KR&wblnumText2=${dsTrackNum}`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "롯데택배") {
            const url = `https://www.lotteglogis.com/open/tracking?invno=${dsTrackNum}`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "경동택배") {
            const url = `https://kdexp.com/newDeliverySearch.kd?barcode=${dsTrackNum}`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else {
            setDontTrackingShipcoSt(true);
        }
    })

    const modifyProdSt = (prodSt, seqNums) => {

        let params = new URLSearchParams();
        params.append('prodValue', prodSt.value);
        params.append('adminInfo', adminInfo.mbNum);

        if (!Array.isArray(seqNums)) {
            seqNums = [seqNums];
        }
        seqNums.forEach(num => params.append('prodSeqNum', num));

        axios({
            method: 'post',
            url: '/order/modifyProdSt',
            params: params,
        })
            .then((response) => {

                const updatedOrder = response.data;

                setFilteredList(currentList => {
                    return currentList.map(cData => {
                        if (cData.type === 'orderdt' && cData.seqnum === updatedOrder.seqnum) {
                            const updatedProductsDTO = cData.productsDTO.map(prod => {
                                const updatedProduct = updatedOrder.productsDTO.find(upProd => upProd.seqnum === prod.seqnum);
                                return updatedProduct ? updatedProduct : prod;
                            });

                            // 전체 orderdt와 업데이트된 product데이터만 업뎃
                            Object.assign(cData, updatedOrder);
                            // 업데이트된 productsDTO로 설정
                            cData.productsDTO = updatedProductsDTO;
                            // 반환데이터.type을 orderdt로 분류(안그럼 메인에 출력안됨)
                            cData.type = 'orderdt';
                        }
                        return cData;
                    });
                });

                realTimeChangeProdSt(updatedOrder.productsDTO);

                setSelectedItems({
                    cart: {},
                    order: {},
                    offline: {},
                    transfer: {},
                    domestic: {},
                    delivery: {}
                });

                seletecdProdWithOrderSeqNum(response.data.seqnum, response.data.orderStatus);

            })
            .catch((error) => {
                console.log("상품상태 변경실패 : ", error);
            });
    };

    const realTimeChangeProdSt = async (prodList) => {
        if (stompClient) {
            await stompClient.send("/app/prodSt/adminProdStUpdate", {}, JSON.stringify(prodList));
        } else {
            console.error("Stomp client is not connected.");
        }
    }

    const seletecdProdWithOrderSeqNum = ((orderSeqNum, orderSt) => {
        axios({
            method: 'post',
            url: '/order/seletecdProdWithOrderSeqNum',
            headers: {
                Authorization: LCAT
            },
            params: {
                orderSeqNum: orderSeqNum
            }
        })
            .then((response) => {
                // 중앙에 stompClient 연결데이터, product List, orderSeqNum, orderSt 전송
                orderStatusUpdate(stompClient, response.data, orderSeqNum);
            })
            .catch((error) => {
                console.log("orderdt 반환실패 : ", error)
            })
    })

    const orderStReceived = ((payload) => {
        let data = JSON.parse(payload.body);
        setFilteredList(currentList => {
            return currentList.map(cData => {
                if (cData.type === 'orderdt' && cData.seqnum === data.orderSeqNum) {
                    cData.orderStatus = data.orderSt; // OS 실시간변경
                }
                return cData;
            });
        });
        selectAlarmData();
        selectedAllProcessCount();
    })

    const [prodStList, setProdStList] = useState([]);
    const selectedProdStList = (() => {
        axios({
            method: 'get',
            url: '/order/selectedProdStList',
            data: {
                selectPayinfo: checked
            },
        })
            .then((response) => {
                const newProdStList = response.data.map(item => ({
                    value: item.stValue,
                    label: item.stLabel
                }));
                setProdStList(newProdStList);
            })
            .catch((error) => {
                console.log("상품상태 리스트 조회실패 : ", error)
            })
    })

    useEffect(() => {
        selectedProdStList();
        selectedAllProcessCount();
    }, [])

    const [processData, setProcessData] = useState([]);
    const selectedAllProcessCount = (() => {
        axios({
            method: 'get',
            url: '/order/selectedAllProcessCount',
        })
            .then((response) => {
                setProcessData(response.data);
            })
            .catch((error) => {
                console.log("프로세스수량 조회실패 : ", error)
            })
    })

    const ordDtMemoSave = (seqnum, ordDtCont, memoType) => {
        axios.post('/order/ordMemoDtSave', { seqnum, ordDtCont, memoType }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': LCAT
            }
        })
            .then(() => {
                setSavePopupMsg("저장 완료");
                setSaveMsgSt(true);
                setTimeout(() => setSaveMsgSt(false), 1000);
            })
            .catch(error => {
                setSavePopupMsg("저장 실패");
                setSaveMsgSt(true);
                setTimeout(() => setSaveMsgSt(false), 1000);
            });
    };

    //관리자 예치금/적립금결제 시작
    const [orderdtDepositPaySt, setOrderdtDepositPaySt] = useState(false);
    const [orderdtPointPaySt, setOrderdtPointPaySt] = useState(false);
    const orderdtDepositPayMsg1 = "주문서의 미결제금액을 예치금으로 결제하시겠습니까?";
    const orderdtDepositPayMsg2 = "결제 전 체크해주시기 바랍니다";
    const orderdtPointPayMsg1 = "주문서의 미결제금액을 적립금으로 결제하시겠습니까?";
    const orderdtPointPayMsg2 = "결제 전 체크해주시기 바랍니다";

    // 선택된 데이터가 null 일 경우
    const [dpPayNullSt, setDpPayNullSt] = useState(false);
    const closeDpPayNullSt = (() => {
        setDpPayNullSt(false);
    })

    const openOrderdtDepositPaySt = (() => {
        // 선택된 체크박스 데이터 없으면 orderdtDepositPayNullSt true
        if (!checked || checked.length === 0) {
            setDpPayNullSt(true);
        } else {
            setOrderdtDepositPaySt(true);
        }
    })
    const closeOrderdtDepositPaySt = (() => {
        setOrderdtDepositPaySt(false);
    })

    const openOrderdtPointPaySt = (() => {
        // 선택된 체크박스 데이터 없으면 setOrderdtPointPaySt true
        if (!checked || checked.length === 0) {
            setDpPayNullSt(true);
        } else {
            setOrderdtPointPaySt(true);
        }
    })
    const closeOrderdtPointPaySt = (() => {
        setOrderdtPointPaySt(false);
    })

    const orderdtAdminDpPay = ((type) => {
        axios({
            method: 'post',
            url: '/order/orderdtAdminDpPay',
            headers: {
                'Content-Type': 'application/json',
            },
            data : {
                seqNum: checked,
                type: type
            }
        })
            .then(async (response) => {
                for (const item of response.data) {
                    saveOrderAlarmData(response.data);
                }
                if (type === "deposit") {
                    closeOrderdtDepositPaySt();
                } else {
                    closeOrderdtPointPaySt();
                }
                selectedAllProcessCount();
                selectNoDateTotalOrderList(selectedProcessSt);
            })
            .catch((error) => {
                console.log("예치금결제 실패 : ", error)
            })
    })

    // payinfo 관리자 예치금/적립금결제
    const [payinfoDepositPaySt, setPayinfoDepositPaySt] = useState(false);
    const [payinfoPointPaySt, setPayinfoPointPaySt] = useState(false);
    const payinfoDepositPayMsg1 = "선택된 해외배송비를 예치금으로 결제하시겠습니까?";
    const payinfoDepositPayMsg2 = "결제 전 체크해주시기 바랍니다";
    const payinfoPointPayMsg1 = "선택된 해외배송비를 적립금으로 결제하시겠습니까?";
    const payinfoPointPayMsg2 = "결제 전 체크해주시기 바랍니다";
    const [payinfoSelectOrderErrSt, setPayinfoSelectOrderErrSt] = useState(false);
    const payinfoSelectOrderErrMsg = "배송료타입만 결제가 가능합니다";

    const openPayinfoDepositPaySt = (() => {
        // orderdt 주문서 유형이 선택되었다면 결제제한
        if (orderCheckedCount === 0) {
            // 선택된 체크박스 데이터 없으면 payinfoDepositPayNullSt true
            if (!checked || checked.length === 0) {
                setDpPayNullSt(true);
            } else {
                setPayinfoDepositPaySt(true);
            }
        } else {
            setPayinfoSelectOrderErrSt(true);
        }
    })
    const closePayinfoDepositPaySt = (() => {
        setPayinfoDepositPaySt(false);
    })

    const closePayinfoSelectOrderErr = (() => {
        setPayinfoSelectOrderErrSt(false);
    })

    const openPayinfoPointPaySt = (() => {
        // orderdt 주문서 유형이 선택되었다면 결제제한
        if (orderCheckedCount === 0) {
            // 선택된 체크박스 데이터 없으면 setPayinfoPointPaySt true
            if (!checked || checked.length === 0) {
                setDpPayNullSt(true);
            } else {
                setPayinfoPointPaySt(true);
            }
        } else {
            setPayinfoSelectOrderErrSt(true);
        }
    })
    const closePayinfoPointPaySt = (() => {
        setPayinfoPointPaySt(false);
    })

    const payinfoAdminDpPay = ((type) => {
        axios({
            method: 'post',
            url: '/order/payinfoAdminDpPay',
            headers: {
                'Content-Type': 'application/json',
            },
            data : {
                seqNum: checked,
                type: type
            }
        })
            .then(async (response) => {
                for (const item of response.data) {
                    saveOrderAlarmData(response.data);
                }
                if (type === "deposit") {
                    closePayinfoDepositPaySt();
                } else {
                    closePayinfoPointPaySt();
                }
                selectedAllProcessCount();
                selectNoDateTotalOrderList(selectedProcessSt);
            })
            .catch((error) => {
                console.log("예치금결제 실패 : ", error)
            })
    })

    const saveOrderAlarmData = ((orderData) => {
        axios({
            method: 'post',
            url: '/order/saveOrderData',
            headers: {
                Authorization: LCAT,
                'Content-Type': 'application/json',
            },
            data: orderData
        })
            .then(async () => {
                await stompClient.send("/app/sendOrderAlarm", {}, JSON.stringify({sendFl:true}));
            })
            .catch((error) => {
                console.log("알림데이터 저장실패 : ", error)
            })
    })

    //국내반송/해외반송 시작
    const [adminOsReturnSt, setAdminOsReturnSt] = useState(false);
    const [adminDsReturnSt, setAdminDsReturnSt] = useState(false);
    const osReturnMsg1 = "해외반송으로 처리하시겠습니까?";
    const osReturnMsg2 = "진행 후 복구가 불가합니다";
    const dsReturnMsg1 = "국내반송으로 처리하시겠습니까?";
    const dsReturnMsg2 = "진행 후 복구가 불가합니다";

    // 선택된 데이터가 null 일 경우
    const [returnNullSt, setReturnNullSt] = useState(false);
    const closeReturnNullSt = (() => {
        setReturnNullSt(false);
    })

    const openOsReturnSt = (() => {
        if (orderCheckedCount === 0) {
            // 선택된 체크박스 데이터 없으면 orderdtDepositPayNullSt true
            if (!checked || checked.length === 0) {
                setReturnNullSt(true);
            } else {
                setAdminOsReturnSt(true);
            }
        } else {
            setPayinfoSelectOrderErrSt(true);
        }
    })
    const closeOsReturnSt = (() => {
        setAdminOsReturnSt(false);
    })

    const openDsReturnSt = (() => {
        if (orderCheckedCount === 0) {
            // 선택된 체크박스 데이터 없으면 setOrderdtPointPaySt true
            if (!checked || checked.length === 0) {
                setDpPayNullSt(true);
            } else {
                setAdminDsReturnSt(true);
            }
        } else {
            setPayinfoSelectOrderErrSt(true);
        }
    })
    const closeDsReturnSt = (() => {
        setAdminDsReturnSt(false);
    })

    const deliveryReturn = ((type) => {
        axios({
            method: 'post',
            url: '/order/deliveryReturn',
            headers: {
                'Content-Type': 'application/json',
                Authorization: LCAT
            },
            data : {
                seqNum: checked,
                type: type
            }
        })
            .then(async (response) => {
                for (const item of response.data) {
                    await stompClient.send("/app/updateOrderList", {}, JSON.stringify({userMbNum: item}));
                }
                if (type === "overseas") {
                    closeOsReturnSt();
                } else {
                    closeDsReturnSt();
                }
                selectedAllProcessCount();
                selectNoDateTotalOrderList(selectedProcessSt);
            })
            .catch((error) => {
                console.log("리턴처리실패 : ", error)
            })
    })

    // 국내반송/해외반송 종료
    const [selectedData, setSelectedData] = useState(null);

    const expRegDelete = async (cData) => {
        if (!cData || cData.type !== 'payinfo') {
            console.log('dataType 확인요망:', cData);
            return;
        }
        if (!cData) return;
        await axios({
            method: 'post',
            url: '/ordPay/deletePayinfoList/',
            data: {
                customsSeqnum: cData?.customsDTO?.seqNum,
                appReqSeqnum: cData?.appReqDTO?.seqNum,
                appRecSeqnum: cData?.appRecDTO?.seqnum,
                shipInfoSeqnum: cData?.shipInfoDTO?.seqNum,
                payConSeqnum: cData?.payConDTOList?.map(item => item.seqNum),
                packListSeqnum: cData?.packListDTO?.seqnum,
                payinfoSeqnum: cData?.seqNum,
                memoSeqnum: cData?.appOrderMemoDTO ? cData.appOrderMemoDTO.amSeqNum : null
            },
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                // 삭제된 결제리스트 payinfo 필터
                const updatedSortedPayinfoList = payinfoList.filter((item) => item.seqNum !== cData.seqNum);
                setPayinfoList(updatedSortedPayinfoList);
                selectedAllProcessCount();
                // selectAllData();
            })
            .catch((error) => {
                console.log("결제리스트 삭제실패 : ", error)
            })
    };

    const totalProdQyDv = ((cData) => {
        // 상품총수량(payinfo)
        return cData?.packListDTO?.productsDTO?.reduce((totalSum, item) => {
            if (item) {
                return totalSum + item.quantity;
            }
            return totalSum;
        }, 0);
    })

    // ● ● ● 셀렉트 팝업상태들
    const [ordPerPgPopup, setOrdPerPgPopup] = useState(false);
    const togglePopup = () => {
        setOrdPerPgPopup(!ordPerPgPopup);
    };
    const [ordDatePopup, setOrdDatePopup] = useState(false);
    const [selectedDateRange, setSelectedDateRange] = useState('1개월');
    const handleDateRangeClick = (range) => {
        setSelectedDateRange(range);
        setOrdDatePopup(false);
    };
    const [ordShipcoPopup, setOrdShipcoPopup] = useState(false);
    const handleShipCompanyClick = (company) => {
        setSelectedShipCompany(company);
        setOrdShipcoPopup(false);
    };
    const [ordStPopup, setOrdStPopup] = useState(false);

    const handleOrderStatusClick = (status) => {
        setOrderStSelectCommonValue(status);
        setOrdStPopup(false);
    };

    /** endregion event */

    const [isOpen, setMenu] = useState(false);

    const toggleMenu = (id) => {
        setMenu(isOpen => !isOpen);
        setOrdData((prevData) => {
            return prevData.map((cData) => {
                if (cData.seqnum === id) {
                    return { ...cData, isOpen: !cData.isOpen };
                }
                return cData;
            });
        });
    };

    return (
        <div id="m-a-dashboard-pg-container">
            <div className="m-a-main-slider">
                <div className="m-a-slider-wrapper">
                    <div className="m-a-card m-a-dash-progress-card m-a-card-slider">
                        <div className="m-a-p-card-frame">
                            <div className="m-a-dash-top-imgBox m-0-a">
                                <img className="m-a-dash-top-imgBox-img" alt="" src={mypgOrder}/>
                            </div>
                            <div className="m-a-p-text font-t-cont dark-font wd-100-pc">
                                <div className="m-a-p-t-border display-flex flex-row text-active" onClick={()=> selectNoDateTotalOrderList("OS00")}
                                     style={{border: selectedProcessSt === "OS00" ? "solid 2px #FFAF34" : null, borderRadius: selectedProcessSt === "OS00" ? "4px" : null}}>
                                    <div className="wd-70pc">
                                        <p>입고대기</p>
                                    </div>
                                    <div className="wd-30pc">
                                        <b className="font-c-btorage">{processData.OS00 || 0}</b>
                                    </div>
                                </div>
                                <div className="m-a-p-t-border display-flex flex-row text-active" onClick={()=> selectNoDateTotalOrderList("OS01")}
                                     style={{border: selectedProcessSt === "OS01" ? "solid 2px #FFAF34" : null, borderRadius: selectedProcessSt === "OS01" ? "4px" : null}}>
                                    <div className="wd-70pc">
                                        <p>결제대기</p>
                                    </div>
                                    <div className="wd-30pc">
                                        <b className="font-c-btorage">{processData.OS01 || 0}</b>
                                    </div>
                                </div>
                                <div className="m-a-p-t-border display-flex flex-row text-active" onClick={()=> selectNoDateTotalOrderList("OS02")}
                                     style={{border: selectedProcessSt === "OS02" ? "solid 2px #FFAF34" : null, borderRadius: selectedProcessSt === "OS02" ? "4px" : null}}>
                                    <div className="wd-70pc">
                                        <p>결제중</p>
                                    </div>
                                    <div className="wd-30pc">
                                        <b className="font-c-btorage">{processData.OS02 || 0}</b>
                                    </div>
                                </div>
                                <div className="m-a-p-t-border display-flex flex-row text-active" onClick={()=> selectNoDateTotalOrderList("OS03")}
                                     style={{border: selectedProcessSt === "OS03" ? "solid 2px #FFAF34" : null, borderRadius: selectedProcessSt === "OS03" ? "4px" : null}}>
                                    <div className="wd-70pc">
                                        <p>결제완료</p>
                                    </div>
                                    <div className="wd-30pc">
                                        <b className="font-c-btorage">{processData.OS03 || 0}</b>
                                    </div>
                                </div>
                                <div className="m-a-p-t-border display-flex flex-row text-active" onClick={()=> selectNoDateTotalOrderList("OS04")}
                                     style={{border: selectedProcessSt === "OS04" ? "solid 2px #FFAF34" : null, borderRadius: selectedProcessSt === "OS04" ? "4px" : null}}>
                                    <div className="wd-70pc">
                                        <p>구매중</p>
                                    </div>
                                    <div className="wd-30pc">
                                        <b className="font-c-btorage">{processData.OS04 || 0}</b>
                                    </div>
                                </div>
                                <div className="m-a-p-t-border display-flex flex-row text-active" onClick={()=> selectNoDateTotalOrderList("OS05")}
                                     style={{border: selectedProcessSt === "OS05" ? "solid 2px #FFAF34" : null, borderRadius: selectedProcessSt === "OS05" ? "4px" : null}}>
                                    <div className="wd-70pc">
                                        <p>구매완료</p>
                                    </div>
                                    <div className="wd-30pc">
                                        <b className="font-c-btorage">{processData.OS05 || 0}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="m-a-p-card-frame m-b-15">
                            <div className="m-a-dash-top-imgBox m-0-a">
                                <img className="m-a-dash-top-imgBox-img" alt="" src={mypgDelivery}/>
                            </div>
                            <div className="m-a-p-text font-t-cont dark-font wd-100-pc">
                                <div className="m-a-p-t-border display-flex flex-row text-active" onClick={()=> selectNoDateTotalOrderList("OS06")}
                                     style={{border: selectedProcessSt === "OS06" ? "solid 2px #FFAF34" : null, borderRadius: selectedProcessSt === "OS06" ? "4px" : null}}>
                                    <div className="wd-70pc">
                                        <p>입고중</p>
                                    </div>
                                    <div className="wd-30pc">
                                        <b className="font-c-btorage">{processData.OS06 || 0}</b>
                                    </div>
                                </div>
                                <div className="m-a-p-t-border display-flex flex-row text-active" onClick={()=> selectNoDateTotalOrderList("OS07")}
                                     style={{border: selectedProcessSt === "OS07" ? "solid 2px #FFAF34" : null, borderRadius: selectedProcessSt === "OS07" ? "4px" : null}}>
                                    <div className="wd-70pc">
                                        <p>센터도착</p>
                                    </div>
                                    <div className="wd-30pc">
                                        <b className="font-c-btorage">{processData.OS07 || 0}</b>
                                    </div>
                                </div>
                                <div className="m-a-p-t-border display-flex flex-row text-active" onClick={()=> selectNoDateTotalOrderList("OS08")}
                                     style={{border: selectedProcessSt === "OS08" ? "solid 2px #FFAF34" : null, borderRadius: selectedProcessSt === "OS08" ? "4px" : null}}>
                                    <div className="wd-70pc">
                                        <p>패킹중</p>
                                    </div>
                                    <div className="wd-30pc">
                                        <b className="font-c-btorage">{processData.OS08 || 0}</b>
                                    </div>
                                </div>
                                <div className="m-a-p-t-border display-flex flex-row text-active" onClick={()=> selectNoDateTotalOrderList("PS0")}
                                     style={{border: selectedProcessSt === "PS0" ? "solid 2px #FFAF34" : null, borderRadius: selectedProcessSt === "PS0" ? "4px" : null}}>
                                    <div className="wd-70pc">
                                        <p>결제대기</p>
                                    </div>
                                    <div className="wd-30pc">
                                        <b className="font-c-btorage">{processData.OS09 + processData.PS0 || 0}</b>
                                    </div>
                                </div>
                                <div className="m-a-p-t-border display-flex flex-row text-active" onClick={()=> selectNoDateTotalOrderList("PS1")}
                                     style={{border: selectedProcessSt === "PS1" ? "solid 2px #FFAF34" : null, borderRadius: selectedProcessSt === "PS1" ? "4px" : null}}>
                                    <div className="wd-70pc">
                                        <p>결제중</p>
                                    </div>
                                    <div className="wd-30pc">
                                        <b className="font-c-btorage">{processData.OS10 + processData.PS1 || 0}</b>
                                    </div>
                                </div>
                                <div className="m-a-p-t-border display-flex flex-row text-active" onClick={()=> selectNoDateTotalOrderList("PS2")}
                                     style={{border: selectedProcessSt === "PS2" ? "solid 2px #FFAF34" : null, borderRadius: selectedProcessSt === "PS2" ? "4px" : null}}>
                                    <div className="wd-70pc">
                                        <p>결제완료</p>
                                    </div>
                                    <div className="wd-30pc">
                                        <b className="font-c-btorage">{processData.OS11 + processData.PS2 || 0}</b>
                                    </div>
                                </div>
                                <div className="m-a-p-t-border display-flex flex-row text-active" onClick={()=> selectNoDateTotalOrderList("PS3")}
                                     style={{border: selectedProcessSt === "PS3" ? "solid 2px #FFAF34" : null, borderRadius: selectedProcessSt === "PS3" ? "4px" : null}}>
                                    <div className="wd-70pc">
                                        <p>출고완료</p>
                                    </div>
                                    <div className="wd-30pc">
                                        <b className="font-c-btorage">{processData.OS12 + processData.PS3 || 0}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="m-a-p-card-frame">
                            <div className="m-a-dash-top-imgBox m-0-a">
                                <img className="m-a-dash-top-imgBox-img" alt="" src={mypgReturn}/>
                            </div>
                            <div className="m-a-p-text font-t-cont dark-font wd-100pc">
                                <div className="m-a-p-t-border display-flex flex-row text-active" onClick={()=> selectNoDateTotalOrderList("PS4")}
                                     style={{border: selectedProcessSt === "PS4" ? "solid 2px #FFAF34" : null, borderRadius: selectedProcessSt === "PS4" ? "4px" : null}}>
                                    <div className="wd-70pc">
                                        <p>반송중</p>
                                    </div>
                                    <div className="wd-30pc">
                                        <b className="font-c-default">{processData.OS13 + processData.PS4 || 0}</b>
                                    </div>
                                </div>
                                <div className="m-a-p-t-border display-flex flex-row text-active" onClick={()=> selectNoDateTotalOrderList("PS5")}
                                     style={{border: selectedProcessSt === "PS5" ? "solid 2px #FFAF34" : null, borderRadius: selectedProcessSt === "PS5" ? "4px" : null}}>
                                    <div className="wd-70pc">
                                        <p>취소</p>
                                    </div>
                                    <div className="wd-30pc">
                                        <b className="font-c-default">{processData.OS14 + processData.PS5 || 0}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="m-a-card m-a-dash-progress-card m-a-card-slider user-count-frame">
                        <div>
                            <div>
                                <img className="wd-50 ht-55" alt="" src={totalImg}/>
                            </div>
                            <p className="font-t-cont font-c-default font-s-15">Total</p>
                            <p className="font-t-head font-c-default font-s-20">{convertCount(userCountTotalData)}</p>
                        </div>
                        <div>
                            <div className="p-t-6">
                                <img className="wd-50 ht-50" alt="" src={followImg}/>
                            </div>
                            <p className="font-t-cont font-c-default font-s-15">Follow</p>
                            <p className="font-t-head font-c-default font-s-20">{convertCount(userCountFlwData)}</p>
                        </div>
                        <div>
                            <div className="p-t-6">
                                <img className="wd-50 ht-50" alt="" src={lossImg}/>
                            </div>
                            <p className="font-t-cont font-c-default font-s-15">Loss</p>
                            <p className="font-t-head font-c-default font-s-20">{convertCount(userCountLossData)}</p>
                        </div>
                        <div>
                            <div>
                                <img className="wd-50 ht-55" alt="" src={todayImg}/>
                            </div>
                            <p className="font-t-cont font-c-default font-s-15">Today</p>
                            <p className="font-t-head font-c-default font-s-20">{convertCount(userCountTodayData)}</p>
                        </div>
                    </div>
                    {/*<div className="m-a-card m-a-dash-progress-card m-a-card-slider">*/}
                    {/*    Empty*/}
                    {/*</div>*/}
                </div>
                <div className="m-a-indicators">
                    <span className="m-a-indicator"></span>
                    <span className="m-a-indicator"></span>
                    {/*<span className="m-a-indicator"></span>*/}
                </div>
            </div>
            <div className="m-a-card m-a-dash-o-progress-card m-t-15">
                <p className="font-t-title font-s-18 font-c-default">Order</p>
                <div className="m-a-o-p-text font-t-cont dark-font m-t-15">
                    <div>
                        <p className="text-active" onClick={()=> moveToAlarmListPg('cart', lcCartProd.length)}>장바구니</p>
                        <div className="m-a-value-badge"><p>{lcCartProd.length || 0}</p></div>
                        <p className="text-active" onClick={()=> moveToAlarmListPg('order', lcOrderProd.length)}>구매대행</p>
                        <div className="m-a-value-badge"><p>{lcOrderProd.length || 0}</p></div>
                        <p className="text-active" onClick={()=> moveToAlarmListPg('delivery', lcDeliveryProd.length)}>배송대행</p>
                        <div className="m-a-value-badge"><p>{lcDeliveryProd.length || 0}</p></div>
                    </div>
                </div>
                <div className="m-a-o-p-text font-t-cont m-t-5 dark-font">
                    <div>
                        <p className="text-active" onClick={()=> moveToAlarmListPg('transfer', lcTransferProd.length)}>이채대행</p>
                        <div className="m-a-value-badge"><p>{lcTransferProd.length || 0}</p></div>
                        <p className="text-active" onClick={()=> moveToAlarmListPg('offline', lcOfflineProd.length)}>오프라인</p>
                        <div className="m-a-value-badge"><p>{lcOfflineProd.length || 0}</p></div>
                        <p className="text-active" onClick={()=> moveToAlarmListPg('domestic', lcDomesticProd.length)}>국내배송</p>
                        <div className="m-a-value-badge"><p>{lcDomesticProd.length || 0}</p></div>
                    </div>
                </div>
            </div>
            <div className="m-a-card m-a-dash-o-progress-card m-t-15">
                <p className="font-t-title font-s-18 font-c-default">Pay & Riview</p>
                <div className="m-a-o-p-text font-t-cont dark-font m-t-15">
                    <div>
                        <p className="text-active" onClick={()=> moveToAlarmListPg('pay', lcPayData.length)}>Pay</p>
                        <div className="m-a-value-badge"><p>{lcPayData.length || 0}</p></div>
                        <p className="text-active" onClick={()=> moveToAlarmListPg('deposit', lcDepositData.length)}>예치금신청</p>
                        <div className="m-a-value-badge"><p>{lcDepositData.length || 0}</p></div>
                        <p className="text-active" onClick={()=> moveToAlarmListPg('review', lcReviewData.length)}>이용후기</p>
                        <div className="m-a-value-badge"><p>{lcReviewData.length || 0}</p></div>
                    </div>
                </div>
            </div>
            <div className="m-a-card m-a-dash-o-progress-card m-t-15">
                <p className="font-t-title font-s-18 font-c-default">Packing & Receipt</p>
                <div className="m-a-o-p-text font-t-cont dark-font m-t-15">
                    <div>
                        <p className="font-s-17 p-t-7">Packing</p>
                        <p className={processData.OS07 === 0 ? "font-c-default font-s-23 font-b p-t-3" : "real-text font-s-23 font-b p-t-3"}
                           onClick={() => selectNoDateTotalOrderList("OS07")}>{processData.OS07 || 0}</p>
                        <p className="font-s-17 p-t-7">Receipt</p>
                        <p className="font-b font-s-23" style={{color: "#FFAF34"}}>{ordReceiptCountData}</p>
                    </div>
                </div>
            </div>
            <div className="m-a-dash-select-frame m-t-40">
                <div className="m-a-d-s-f-gbn-1">
                    <div className="wd-20pc">
                        <div onClick={()=> setOrdPerPgPopup(true)} className="m-a-combo font-t-cont font-c-default wd-100pc ht-45 font-s-14 m-a-flex-jc-start m-r-10">
                            <div className="font-t-cont">{perPg}</div>
                        </div>
                    </div>
                    {ordPerPgPopup ?
                        <MAdminPageSmallEvent perPg={perPg}  handleSelectChange={handleSelectChange} togglePopup={togglePopup}
                                              onClick={(e) => { e.stopPropagation(); setOrdPerPgPopup(false);}} /> : null
                    }
                    <MAdminYmdEvent startDate={startDate} endDate={endDate} startHandle={startHandle} endHandle={endHandle}/>
                </div>
                <div className="m-a-d-s-f-gbn-2 font-s-14">
                    <div className="wd-20pc">
                        <div className="m-a-combo wd-100pc ht-45 font-s-15 m-a-flex-jc-start"  onClick={()=> setOrdDatePopup(true)}>
                            {selectedDateRange}
                            {ordDatePopup && (
                                <div className="m-a-page-popup-container" onClick={(e) => { e.stopPropagation(); setOrdDatePopup(false); }}>
                                    <div className="m-a-page-popup-frame" onClick={(e) => e.stopPropagation()}>
                                        <div className="m-a-page-popup-header-frame">
                                            <div className="font-t-title font-s-18 m-t-4">날짜선택</div>
                                        </div>
                                        <div className="m-a-page-popup-body-frame">
                                            <div className={`m-a-card m-t-10 m-b-10 ${selectedDateRange === "1개월" ? "m-a-selected-option" : ""}`}
                                                 onClick={() => handleDateRangeClick("1개월")}>
                                                <p>1개월</p>
                                            </div>
                                            <div className={`m-a-card m-t-10 m-b-10 ${selectedDateRange === "3개월" ? "m-a-selected-option" : ""}`}
                                                 onClick={() => handleDateRangeClick("3개월")}>
                                                <p>3개월</p>
                                            </div>
                                            <div className={`m-a-card m-t-10 m-b-10 ${selectedDateRange === "6개월" ? "m-a-selected-option" : ""}`}
                                                 onClick={() => handleDateRangeClick("6개월")}>
                                                <p>6개월</p>
                                            </div>
                                            <div className={`m-a-card m-t-10 m-b-10 ${selectedDateRange === "1년" ? "m-a-selected-option" : ""}`}
                                                 onClick={() => handleDateRangeClick("1년")}>
                                                <p>1년</p>
                                            </div>
                                        </div>
                                        <div className="m-a-page-popup-footer-frame">
                                            <div className="m-a-file-bt-container">
                                                <button className="m-a-colorBt ht-40 m-b-5 wd-80" onClick={(e) => { e.stopPropagation(); setOrdDatePopup(false); }}>
                                                    <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="display-flex flex-row wd-80pc just-cont-sb p-l-5">
                        <div className="m-a-combo wd-49pc ht-45 font-s-15 m-a-flex-jc-start" onClick={()=> setOrdShipcoPopup(true)}>
                            {selectedShipCompany}
                            {ordShipcoPopup && (
                                <div className="m-a-page-popup-container" onClick={(e) => { e.stopPropagation(); setOrdShipcoPopup(false); }}>
                                    <div className="m-a-page-popup-frame" onClick={(e) => e.stopPropagation()}>
                                        <div className="m-a-page-popup-header-frame">
                                            <div className="font-t-title font-s-18 m-t-4">배송사선택</div>
                                        </div>
                                        <div className="m-a-page-popup-body-frame">
                                            <div onClick={() => handleShipCompanyClick("배송사선택")}
                                                 className={`m-a-card m-t-10 m-b-10 ${selectedShipCompany === "배송사선택" ? "m-a-selected-option" : ""}`}><p>배송사선택</p></div>
                                            <div onClick={() => handleShipCompanyClick("FedEx")}
                                                 className={`m-a-card m-t-10 m-b-10 ${selectedShipCompany === "FedEx" ? "m-a-selected-option" : ""}`}><p>FedEx</p></div>
                                            <div onClick={() => handleShipCompanyClick("UPS")}
                                                 className={`m-a-card m-t-10 m-b-10 ${selectedShipCompany === "UPS" ? "m-a-selected-option" : ""}`}><p>UPS</p></div>
                                            <div onClick={() => handleShipCompanyClick("EMS")}
                                                 className={`m-a-card m-t-10 m-b-10 ${selectedShipCompany === "EMS" ? "m-a-selected-option" : ""}`}><p>EMS</p></div>
                                            <div onClick={() => handleShipCompanyClick("K-Packet")}
                                                 className={`m-a-card m-t-10 m-b-10 ${selectedShipCompany === "K-Packet" ? "m-a-selected-option" : ""}`}><p>K-Packet</p></div>
                                            <div onClick={() => handleShipCompanyClick("항공소형")}
                                                 className={`m-a-card m-t-10 m-b-10 ${selectedShipCompany === "항공소형" ? "m-a-selected-option" : ""}`}><p>항공소형</p></div>
                                            <div onClick={() => handleShipCompanyClick("SHIP")}
                                                 className={`m-a-card m-t-10 m-b-10 ${selectedShipCompany === "SHIP" ? "m-a-selected-option" : ""}`}><p>SHIP</p></div>
                                            <div onClick={() => handleShipCompanyClick("국내배송")}
                                                 className={`m-a-card m-t-10 m-b-10 ${selectedShipCompany === "국내배송" ? "m-a-selected-option" : ""}`}><p>국내배송</p></div>
                                            {/*<div onClick={() => handleShipCompanyClick("DHL")}*/}
                                            {/*     className={`m-a-card m-t-10 m-b-10 ${selectedShipCompany === "DHL" ? "m-a-selected-option" : ""}`}><p>DHL</p></div>*/}
                                        </div>
                                        <div className="m-a-page-popup-footer-frame">
                                            <div className="m-a-file-bt-container">
                                                <button className="m-a-colorBt ht-40 m-b-5 wd-80" onClick={(e) => { e.stopPropagation(); setOrdShipcoPopup(false); }}>
                                                    <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="m-a-combo wd-49pc ht-45 font-s-15 m-a-flex-jc-start" onClick={()=> setOrdStPopup(true)}>
                            {orderStSelectCommonValue}
                            {ordStPopup && (
                                <div className="m-a-page-popup-container" onClick={(e) => { e.stopPropagation(); setOrdStPopup(false); }}>
                                    <div className="m-a-page-popup-frame" onClick={(e) => e.stopPropagation()}>
                                        <div className="m-a-page-popup-header-frame">
                                            <div className="font-t-title font-s-18 m-t-4">상태구분</div>
                                        </div>
                                        <div className="m-a-page-popup-body-frame">
                                            {['상태(전체)'
                                                , '입고대기'
                                                , '결제대기(상품)'
                                                , '결제중(상품)'
                                                , '결제완료(상품)'
                                                , '구매중'
                                                , '구매완료'
                                                , '입고중'
                                                , '센터도착'
                                                , '패킹중'
                                                , '결제대기(배송)'
                                                , '결제중(배송)'
                                                , '결제완료(배송)'
                                                , '출고완료'
                                                , '반송중'
                                                , '취소'].map((status) => (
                                                <div key={status} onClick={() => handleOrderStatusClick(status)}
                                                    className={`m-a-card m-t-10 m-b-10 ${orderStSelectCommonValue === status ? 'm-a-selected-option' : ''}`}>
                                                    <p>{status}</p>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="m-a-page-popup-footer-frame">
                                            <div className="m-a-file-bt-container">
                                                <button className="m-a-colorBt ht-40 m-b-5 wd-80" onClick={(e) => { e.stopPropagation(); setOrdStPopup(false); }}>
                                                    <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="m-a-d-s-f-search m-t-3 wd-100pc">
                    <div className="m-a-input-frame ht-45 font-t-cont font-c-default">
                        <input className="m-a-input" placeholder="Search" value={searchInput} onChange={handleSearchInputChange}/>
                        <IoSearchOutline className="font-s-24 m-r-7" style={{position: "absolute", right: "19px"}}/>
                    </div>
                </div>
            </div>
            {filteredList.slice(indexOfFirst, indexOfLast).map((cData, i) => {
                const key = i;
                let data1 = 0, data2 = 0, data3 = 0, data4 = 0;
                let totalProdQy = 0

                if (cData.type === 'orderdt') {
                    // 상품미결제 + 관리자가 등록한 상품누락금 미결제에 추가
                    data1 = cData.productsDTO?.reduce((sum, item) => {
                        return (item.prodSt === "IS01" || item.prodSt === "IS02") && item.price > 0 ? sum + item.price + item.mallShipPrice + item.fee : sum;
                    }, 0) || 0;
                    data1 += cData.payInfoDTO?.reduce((sum, item) => {
                        return (item.payStatus === "PS0" || item.payStatus === "PS1") && item.payClass === '1'
                        && item.ttProdExpense > 0 ? sum + item.ttProdExpense : sum;
                    }, 0) || 0;
                    // 상품총결제 + 관리자가 등록한 상품누락금 결제완료에 추가
                    data2 = cData.productsDTO?.reduce((sum, item) => {
                        return item.prodSt !== "IS01" && item.prodSt !== "IS02" && item.prodSt !== "IS12" && item.price > 0 ? sum + item.price + item.mallShipPrice + item.fee : sum;
                    }, 0) || 0;
                    data2 += cData.payInfoDTO?.reduce((sum, item) => {
                        return item.payStatus !== "PS0" && item.payStatus !== "PS1" && item.payClass === '1'
                        && item.ttProdExpense > 0 ? sum + item.ttProdExpense : sum;
                    }, 0) || 0;
                    // 기타미결제
                    data3 = cData.payInfoDTO?.reduce((sum, item) => {
                        if ((item.payStatus === "PS0" || item.payStatus === "PS1") && item.payClass === '1') {
                            // payStatus가 PS0 또는 PS1일 때 ttEtcPrice와 ttEtcFee(있을 경우)를 더함
                            const etcPrice = item.ttEtcPrice > 0 ? item.ttEtcPrice : 0;
                            const etcFee = item.ttEtcFee > 0 ? item.ttEtcFee : 0;
                            return sum + etcPrice + etcFee;
                        }
                        return sum;
                    }, 0) || 0;
                    // 기타총결제
                    data4 = cData?.payInfoDTO?.reduce((sum, item) => {
                        if ((item.payStatus !== "PS0" && item.payStatus !== "PS1") && item.payClass === '1') {
                            // payStatus가 PS0 또는 PS1일 때 ttEtcPrice와 ttEtcFee(있을 경우)를 더함
                            const etcPrice = item.ttEtcPrice > 0 ? item.ttEtcPrice : 0;
                            const etcFee = item.ttEtcFee > 0 ? item.ttEtcFee : 0;
                            return sum + etcPrice + etcFee;
                        }
                        return sum;
                    }, 0) || 0;
                    // 상품총수량(orderdt)
                    totalProdQy = cData?.productsDTO?.reduce((sum, item) => {
                        return sum + item.quantity;
                    }, 0) || 0;
                }
                return cData.type === 'orderdt' ? (
                    <div key={key} className="m-a-order-container m-a-card m-t-15">
                        <div className="m-a-order-frame font-t-cont">
                            <div className="m-a-order-info">
                                <div className="m-a-o-i-1 m-b-2 dark-font">
                                    <p className="font-b font-s-17"><img className="wd-17 ht-17 m-b-4 m-r-3" alt="" src={orderIMG}/>{cData.orderNumber}</p>
                                    <p className="font-s-14 m-t-3">{cData.regDate.slice(0, 4) + '-' + cData.regDate.slice(4, 6) + '-' + cData.regDate.slice(6, 8)}</p>
                                </div>
                                <div className="m-a-o-i-2 dark-font">
                                    <p className="font-b font-s-14">{cData.recinfoDTO.recname}</p>
                                    <p className="font-s-14">{cData.recinfoDTO.countryDTO.countryNm + "[" + cData.recinfoDTO.countryDTO.countryCode + "]"}</p>
                                    <p className="font-s-16 font-b">{cData.shipco}</p>
                                </div>
                                <div className="m-a-o-i-3 font-s-14 dark-font">
                                    <p className="font-s-14">{cData.memberDTO.userName}</p>
                                    <p>
                                        <img className="wd-15 ht-15 m-b-2 m-r-2" alt="deposit" src={deposit}/>
                                        {cData.memberDTO.deposit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </p>
                                    <p>
                                        <img className="wd-15 ht-15 m-b-2 m-r-2" alt="deposit" src={pointPay}/>
                                        {cData.memberDTO.point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </p>
                                </div>
                                <div className="m-a-o-i-4">
                                    <div className="m-a-order-status">
                                        <p className="m-0-a font-s-16 font-b">{orderStPrint(cData.orderStatus)}</p>
                                    </div>
                                    <button onClick={() => toggleMenu(cData.seqnum)} className="m-a-commonBt wd-60 ht-30">
                                        {cData.isOpen ?
                                            <FiMinus className="font-s-16 font-c-default"/> : <FiPlus className="font-s-16 font-c-default"/>
                                        }
                                    </button>
                                </div>
                                <div className="m-a-o-i-5 m-t-5">
                                    <div className="m-a-input-frame wd-100pc ht-45 font-t-cont font-c-default">
                                        <input className="m-a-input" type="text" maxLength="300" placeholder="관리자메모"
                                               onKeyDown={(e) => onKeyPress(e, cData.seqnum, 'orderMemoDTO')}
                                               value={memoModiData[`${cData.seqnum}-orderMemoDTO`] || cData?.orderMemoDTO?.ordDtCont || ''}
                                               onChange={(e) => handleInputMemoChange(e, cData.seqnum, 'orderMemoDTO')}
                                               ref={(el) => (inputRefs.current[cData.seqnum] = el)}
                                        />
                                    </div>
                                </div>
                            </div>
                            {cData.isOpen && (
                                <div className="m-a-order-dt-info">
                                {/*장바구니시작*/}
                                {cData.productsDTO.filter(prod => prod.prodTy === "P00").length > 0 && (
                                    <div>
                                        <div className="m-a-order-prod-type">
                                            <div className="wd-10pc">
                                                <input className="m-a-admin-input-chkBox m-0-a vertical-align" type="checkbox"
                                                       onChange={e => handleCheckAll('cart', i, e.target.checked, cData.productsDTO)}
                                                       checked={selectedItems.cart[i] ? selectedItems.cart[i].length === cData.productsDTO.filter(prod => prod.prodTy === "P00").length : false}/>
                                            </div>
                                            <div className="wd-55pc text-left p-l-5" style={{display: "flex", flexDirection: "row"}}
                                                 onClick={()=> toggleMenuDTcartFn(i, cData.productsDTO.filter(prod => prod.prodTy === "P00").length)}>
                                                <p className="font-s-16 font-b dark-font">장바구니</p>
                                                <a className="font-s-16 m-l-5"
                                                   style={{color: cData.productsDTO.filter(prod => prod.prodTy === "P00").length === 0 ? "#5B5B5B" : "#FFAF34"}}>
                                                    {cData.productsDTO.filter(prod => prod.prodTy === "P00").length}
                                                </a>
                                            </div>
                                            <div className="display-flex wd-35pc just-cont-fend">
                                                <div ref={el => allCartProdStRefs.current[`${i}`] = el}>
                                                    <div onClick={() => toggleAllCartProdStShow(i)} className="m-a-combo wd-100 ht-30 font-s-12 m-a-flex-jc-start">
                                                        <div className="font-t-cont">{selectedAllCartProdSt[i]?.label || '전체변경'}</div>
                                                    </div>
                                                    {allCartProdStShow[i] ?
                                                        <div className="m-a-common-popup-container" onClick={() => toggleAllCartProdStShow(i)}>
                                                            <div className="m-a-common-popup-frame" onClick={(e) => e.stopPropagation()}>
                                                                <div className="m-a-common-popup-header-frame">
                                                                    <div className="m-a-common-popup-header-text font-t-cont">전체변경</div>
                                                                </div>
                                                                <div className="m-a-common-popup-body-frame">
                                                                    {prodStList.map((aps, idx) => (
                                                                        <div key={idx} className="m-a-common-popup-body-data" onClick={() => handleAllProdStChange('cart', i, aps)}>
                                                                            {aps.label}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className="m-a-common-popup-footer-frame">
                                                                    <div className="m-a-file-bt-container">
                                                                        <button onClick={() => toggleAllCartProdStShow(i)} className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                            <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {cData.productsDTO.filter(prod => prod.prodTy === "P00").sort((a,b) => a.seqnum - b.seqnum).map((cart, index) => (
                                            <div key={index} className="m-a-order-prod-type-cont">
                                                <div className="m-a-order-p-c-frame-h">
                                                    <div className="wd-10pc">
                                                        <input className="m-a-admin-input-chkBox vertical-align m-t-70" type="checkbox"
                                                               onChange={e => handleCheckItem('cart', i, cart.seqnum, e.target.checked)}
                                                               checked={selectedItems.cart[i] ? selectedItems.cart[i].includes(cart.seqnum) : false}/>
                                                    </div>
                                                    <div className="m-a-prod-cont wd-55pc text-left p-l-5 dark-font">
                                                        <a className="font-b" href={cart.url} target="_blank">{cart.name}</a>
                                                        <p>{cart.option}</p>
                                                        <p>{cart.quantity}</p>
                                                        <p>{cart.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                        <p>{cart?.mallShipPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                        <div className="m-a-input-frame ht-45 wd-100pc">
                                                            <textarea className="m-a-input font font-t-cont" style={{resize: "none"}} cols="14" rows="2"
                                                                      value={adminProdMemos[cart.seqnum] || ''}
                                                                      onChange={(e) => handleProdMemoChange(cart.seqnum, e.target.value)}
                                                                      onKeyDown={(e) => saveProdMemo(e, cart.seqnum)}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="display-flex flex-column wd-35pc just-cont-center">
                                                        <div className="display-flex wd-100pc just-cont-fend p-r-10">
                                                            <a className="font-t-cont font-s-14">{prodStPrint(cart?.prodSt)}</a>
                                                        </div>
                                                        <div className="display-flex wd-100pc just-cont-fend" ref={el => cartProdStRef.current[`${i}-${index}`] = el}>
                                                            <div onClick={() => toggleCartProdStShow(i, index)} className="m-a-combo wd-100 ht-30 font-s-12 m-a-flex-jc-start">
                                                                <div className="font-t-cont">{selectedProdSt[`${'cart'}-${i}-${index}`]?.label || '개별변경'}</div>
                                                            </div>
                                                            {cartProdStShow[`${i}-${index}`] ?
                                                                <div className="m-a-common-popup-container" onClick={() => toggleCartProdStShow(i, index)}>
                                                                    <div className="m-a-common-popup-frame" onClick={(e) => e.stopPropagation()}>
                                                                        <div className="m-a-common-popup-header-frame">
                                                                            <div className="m-a-common-popup-header-text font-t-cont">개별변경</div>
                                                                        </div>
                                                                        <div className="m-a-common-popup-body-frame">
                                                                            {prodStList.map((ps, idx) => (
                                                                                <div key={idx} className="m-a-common-popup-body-data" onClick={() => handleProdStChange('cart', ps, cart.seqnum, i, index)}>
                                                                                    {ps.label}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        <div className="m-a-common-popup-footer-frame">
                                                                            <div className="m-a-file-bt-container">
                                                                                <button onClick={() => toggleCartProdStShow(i, index)} className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                                    <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {/*장바구니종료*/}
                                    </div>
                                )}
                                {/*구매대행시작*/}
                                {cData.productsDTO.filter(prod => prod.prodTy === "P01").length > 0 && (
                                    <div>
                                        <div className="m-a-order-prod-type">
                                            <div className="wd-10pc">
                                                <input className="m-a-admin-input-chkBox m-0-a vertical-align" type="checkbox"
                                                       onChange={e => handleCheckAll('order', i, e.target.checked, cData.productsDTO)}
                                                       checked={selectedItems.order[i] ? selectedItems.order[i].length === cData.productsDTO.filter(prod => prod.prodTy === "P01").length : false}
                                                />
                                            </div>
                                            <div className="wd-55pc text-left p-l-5" style={{display: "flex", flexDirection: "row"}}
                                                 onClick={()=> toggleMenuDTcartFn(i, cData.productsDTO.filter(prod => prod.prodTy === "P01").length)}>
                                                <p className="font-s-16 font-b dark-font">구매대행</p>
                                                <a className="font-s-16 m-l-5"
                                                   style={{color: cData.productsDTO.filter(prod => prod.prodTy === "P01").length === 0 ? "#5B5B5B" : "#FFAF34"}}>
                                                    {cData.productsDTO.filter(prod => prod.prodTy === "P01").length}
                                                </a>
                                            </div>
                                            <div className="display-flex wd-35pc just-cont-fend">
                                                <div ref={el => allOrderProdStRefs.current[`${i}`] = el}>
                                                    <div onClick={() => toggleAllOrderProdStShow(i)} className="m-a-combo wd-100 ht-30 font-s-12 m-a-flex-jc-start">
                                                        <div className="font-t-cont">{selectedAllOrderProdSt[i]?.label || '전체변경'}</div>
                                                    </div>
                                                    {allOrderProdStShow[i] ?
                                                        <div className="m-a-common-popup-container" onClick={() => toggleAllOrderProdStShow(i)}>
                                                            <div className="m-a-common-popup-frame" onClick={(e) => e.stopPropagation()}>
                                                                <div className="m-a-common-popup-header-frame">
                                                                    <div className="m-a-common-popup-header-text font-t-cont">전체변경</div>
                                                                </div>
                                                                <div className="m-a-common-popup-body-frame">
                                                                    {prodStList.map((aps, idx) => (
                                                                        <div key={idx} className="m-a-common-popup-body-data" onClick={() => handleAllProdStChange('order', i, aps)}>
                                                                            {aps.label}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className="m-a-common-popup-footer-frame">
                                                                    <div className="m-a-file-bt-container">
                                                                            <button onClick={() => toggleAllOrderProdStShow(i)} className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                                <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                                            </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {cData.productsDTO.filter(prod => prod.prodTy === "P01").sort((a,b) => a.seqnum - b.seqnum).map((order, index) => (
                                            <div key={index} className="m-a-order-prod-type-cont">
                                                <div className="m-a-order-p-c-frame-h">
                                                    <div className="wd-10pc">
                                                        <input className="m-a-admin-input-chkBox vertical-align m-t-65" type="checkbox"
                                                            onChange={e => handleCheckItem('order', i, order.seqnum, e.target.checked)}
                                                            checked={selectedItems.order[i] ? selectedItems.order[i].includes(order.seqnum) : false}/>
                                                    </div>
                                                    <div className="m-a-prod-cont wd-55pc text-left p-l-5 p-r-5 dark-font">
                                                        <a className="font-b" href={order.url} target="_blank">{order.name}</a>
                                                        <p>{order.option}</p>
                                                        <p>{order.quantity}</p>
                                                        <p>{order.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                        <p>{order?.mallShipPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                        <div className="m-a-input-frame ht-45 wd-100pc">
                                                            <textarea className="m-a-input font font-t-cont" style={{resize: "none"}} cols="14" rows="2"
                                                                      value={adminProdMemos[order.seqnum] || ''}
                                                                      onChange={(e) => handleProdMemoChange(order.seqnum, e.target.value)}
                                                                      onKeyDown={(e) => saveProdMemo(e, order.seqnum)}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="display-flex flex-column wd-35pc just-cont-center p-t-40">
                                                        <div className="display-flex wd-100pc just-cont-fend p-r-10">
                                                            <a className="font-t-cont font-s-14">{prodStPrint(order?.prodSt)}</a>
                                                        </div>
                                                        <div className="display-flex wd-100pc just-cont-fend" ref={el => orderProdStRef.current[`${i}-${index}`] = el}>
                                                            <div onClick={() => toggleOrderProdStShow(i, index)} className="m-a-combo wd-100 ht-30 font-s-12 m-a-flex-jc-start">
                                                                <div className="font-t-cont">{selectedProdSt[`${'order'}-${i}-${index}`]?.label || '개별변경'}</div>
                                                            </div>
                                                            {orderProdStShow[`${i}-${index}`] ?
                                                                <div className="m-a-common-popup-container" onClick={() => toggleOrderProdStShow(i, index)}>
                                                                    <div className="m-a-common-popup-frame" onClick={(e) => e.stopPropagation()}>
                                                                        <div className="m-a-common-popup-header-frame">
                                                                            <div className="m-a-common-popup-header-text font-t-cont">개별변경</div>
                                                                        </div>
                                                                        <div className="m-a-common-popup-body-frame">
                                                                            {prodStList.map((ps, idx) => (
                                                                                <div key={idx} className="m-a-common-popup-body-data" onClick={() => handleProdStChange('order', ps, order.seqnum, i, index)}>
                                                                                    {ps.label}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        <div className="m-a-common-popup-footer-frame">
                                                                            <div className="m-a-file-bt-container">
                                                                                <button onClick={() => toggleOrderProdStShow(i, index)} className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                                    <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {/*구매대행종료*/}
                                    </div>
                                )}
                                {/*오프라인시작*/}
                                {cData.productsDTO.filter(prod => prod.prodTy === "P02").length > 0 && (
                                    <div>
                                        <div className="m-a-order-prod-type">
                                            <div className="wd-10pc">
                                                <input className="m-a-admin-input-chkBox m-0-a vertical-align" type="checkbox"
                                                       onChange={e => handleCheckAll('offline', i, e.target.checked, cData.productsDTO)}
                                                       checked={selectedItems.offline[i] ? selectedItems.offline[i].length === cData.productsDTO.filter(prod => prod.prodTy === "P02").length : false}
                                                />
                                            </div>
                                            <div className="wd-55pc text-left p-l-5" style={{display: "flex", flexDirection: "row"}}
                                                 onClick={()=> toggleMenuDTcartFn(i, cData.productsDTO.filter(prod => prod.prodTy === "P02").length)}>
                                                <p className="font-s-16 font-b dark-font">오프라인</p>
                                                <a className="font-s-16 m-l-5"
                                                   style={{color: cData.productsDTO.filter(prod => prod.prodTy === "P02").length === 0 ? "#5B5B5B" : "#FFAF34"}}>
                                                    {cData.productsDTO.filter(prod => prod.prodTy === "P02").length}
                                                </a>
                                            </div>
                                            <div className="display-flex wd-35pc just-cont-fend">
                                                <div ref={el => allOfflineProdStRefs.current[`${i}`] = el}>
                                                    <div onClick={() => toggleAllOfflineProdStShow(i)} className="m-a-combo wd-100 ht-30 font-s-12 m-a-flex-jc-start">
                                                        <div className="font-t-cont">{selectedAllOfflineProdSt[i]?.label || '전체변경'}</div>
                                                    </div>
                                                    {allOfflineProdStShow[i] ?
                                                        <div className="m-a-common-popup-container" onClick={() => toggleAllOfflineProdStShow(i)}>
                                                            <div className="m-a-common-popup-frame" onClick={(e) => e.stopPropagation()}>
                                                                <div className="m-a-common-popup-header-frame">
                                                                    <div className="m-a-common-popup-header-text font-t-cont">전체변경</div>
                                                                </div>
                                                                <div className="m-a-common-popup-body-frame">
                                                                    {prodStList.map((aps, idx) => (
                                                                        <div key={idx} className="m-a-common-popup-body-data" onClick={() => handleAllProdStChange('offline', i, aps)}>
                                                                            {aps.label}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className="m-a-common-popup-footer-frame">
                                                                    <div className="m-a-file-bt-container">
                                                                        <button onClick={() => toggleAllOfflineProdStShow(i)} className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                            <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {cData.productsDTO.filter(prod => prod.prodTy === "P02").sort((a,b) => a.seqnum - b.seqnum).map((offline, index) => (
                                            <div key={index} className="m-a-order-prod-type-cont">
                                                <div className="m-a-order-p-c-frame-h">
                                                    <div className="wd-10pc">
                                                        <input className="m-a-admin-input-chkBox vertical-align m-t-70" type="checkbox"
                                                               onChange={e => handleCheckItem('offline', i, offline.seqnum, e.target.checked)}
                                                               checked={selectedItems.offline[i] ? selectedItems.offline[i].includes(offline.seqnum) : false}/>
                                                    </div>
                                                    <div className="m-a-prod-cont wd-60pc text-left p-l-5 dark-font">
                                                        <a className="font-b" href={offline.url} target="_blank">{offline.name}</a>
                                                        <p>{offline.option}</p>
                                                        <p>{offline.quantity}</p>
                                                        <p>{offline.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                        <p>{offline.fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                        <div className="m-a-input-frame ht-45 wd-100pc">
                                                            <textarea className="m-a-input font font-t-cont" style={{resize: "none"}} cols="15" rows="2"
                                                                      value={adminProdMemos[offline.seqnum] || ''}
                                                                      onChange={(e) => handleProdMemoChange(offline.seqnum, e.target.value)}></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="display-flex flex-column wd-35pc just-cont-center">
                                                        <div className="display-flex wd-100pc just-cont-fend p-r-10">
                                                            <a className="font-t-cont font-s-14">{prodStPrint(offline?.prodSt)}</a>
                                                        </div>
                                                        <div className="display-flex wd-100pc just-cont-fend" ref={el => offlineProdStRef.current[`${i}-${index}`] = el}>
                                                            <div onClick={() => toggleOfflineProdStShow(i, index)} className="m-a-combo wd-100 ht-30 font-s-12 m-a-flex-jc-start">
                                                                <div className="font-t-cont">{selectedProdSt[`${'offline'}-${i}-${index}`]?.label || '개별변경'}</div>
                                                            </div>
                                                            {offlineProdStShow[`${i}-${index}`] ?
                                                                <div className="m-a-common-popup-container" onClick={() => toggleOfflineProdStShow(i, index)}>
                                                                    <div className="m-a-common-popup-frame" onClick={(e) => e.stopPropagation()}>
                                                                        <div className="m-a-common-popup-header-frame">
                                                                            <div className="m-a-common-popup-header-text font-t-cont">개별변경</div>
                                                                        </div>
                                                                        <div className="m-a-common-popup-body-frame">
                                                                            {prodStList.map((ps, idx) => (
                                                                                <div key={idx} className="m-a-common-popup-body-data" onClick={() => handleProdStChange('offline', ps, offline.seqnum, i, index)}>
                                                                                    {ps.label}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        <div className="m-a-common-popup-footer-frame">
                                                                            <div className="m-a-file-bt-container">
                                                                                <button onClick={() => toggleOfflineProdStShow(i, index)} className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                                    <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {/*오프라인종료*/}
                                    </div>
                                )}
                                {/*이체대행시작*/}
                                {cData.productsDTO.filter(prod => prod.prodTy === "P03").length > 0 && (
                                    <div>
                                        <div className="m-a-order-prod-type">
                                            <div className="wd-10pc">
                                                <input className="m-a-admin-input-chkBox m-0-a vertical-align" type="checkbox"
                                                       onChange={e => handleCheckAll('transfer', i, e.target.checked, cData.productsDTO)}
                                                       checked={selectedItems.transfer[i] ? selectedItems.transfer[i].length === cData.productsDTO.filter(prod => prod.prodTy === "P03").length : false}/>
                                            </div>
                                            <div className="wd-55pc text-left p-l-5" style={{display: "flex", flexDirection: "row"}}
                                                 onClick={()=> toggleMenuDTcartFn(i, cData.productsDTO.filter(prod => prod.prodTy === "P03").length)}>
                                                <p className="font-s-16 font-b dark-font">이체대행</p>
                                                <a className="font-s-16 m-l-5"
                                                   style={{color: cData.productsDTO.filter(prod => prod.prodTy === "P03").length === 0 ? "#5B5B5B" : "#FFAF34"}}>
                                                    {cData.productsDTO.filter(prod => prod.prodTy === "P03").length}
                                                </a>
                                            </div>
                                            <div className="display-flex wd-35pc just-cont-fend">
                                                <div ref={el => allTransferProdStRefs.current[`${i}`] = el}>
                                                    <div onClick={() => toggleAllTransferProdStShow(i)} className="m-a-combo wd-100 ht-30 font-s-12 m-a-flex-jc-start">
                                                        <div className="font-t-cont">{selectedAllTransferProdSt[i]?.label || '전체변경'}</div>
                                                    </div>
                                                    {allTransferProdStShow[i] ?
                                                        <div className="m-a-common-popup-container" onClick={() => toggleAllTransferProdStShow(i)}>
                                                            <div className="m-a-common-popup-frame" onClick={(e) => e.stopPropagation()}>
                                                                <div className="m-a-common-popup-header-frame">
                                                                    <div className="m-a-common-popup-header-text font-t-cont">전체변경</div>
                                                                </div>
                                                                <div className="m-a-common-popup-body-frame">
                                                                    {prodStList.map((aps, idx) => (
                                                                        <div key={idx} className="m-a-common-popup-body-data" onClick={() => handleAllProdStChange('transfer', i, aps)}>
                                                                            {aps.label}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className="m-a-common-popup-footer-frame">
                                                                    <div className="m-a-file-bt-container">
                                                                        <button onClick={() => toggleAllTransferProdStShow(i)} className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                            <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {cData.productsDTO.filter(prod => prod.prodTy === "P03").sort((a,b) => a.seqnum - b.seqnum).map((transfer, index) => (
                                            <div key={index} className="m-a-order-prod-type-cont">
                                                <div className="m-a-order-p-c-frame-h">
                                                    <div className="wd-10pc">
                                                        <input className="m-a-admin-input-chkBox vertical-align m-t-35" type="checkbox"
                                                            onChange={e => handleCheckItem('transfer', i, transfer.seqnum, e.target.checked)}
                                                            checked={selectedItems.transfer[i] ? selectedItems.transfer[i].includes(transfer.seqnum) : false}/>
                                                    </div>
                                                    <div className="m-a-prod-cont wd-55pc text-left p-l-5 dark-font">
                                                        {/*<a>{transfer.name}</a>*/}
                                                        {/*<a>{transfer.option}</a>*/}
                                                        {/*<a>{transfer.quantity}</a>*/}
                                                        <p>{transfer.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                        <p>{transfer.fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                        <div className="m-a-input-frame ht-45 wd-100pc">
                                                            <textarea className="m-a-input font font-t-cont" style={{resize: "none"}} cols="15" rows="2"
                                                                      value={adminProdMemos[transfer.seqnum] || ''}
                                                                      onChange={(e) => handleProdMemoChange(transfer.seqnum, e.target.value)}
                                                                      onKeyDown={(e) => saveProdMemo(e, transfer.seqnum)}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="display-flex flex-column wd-35pc just-cont-center">
                                                        <div className="display-flex wd-100pc just-cont-fend p-r-10">
                                                            <a className="font-t-cont font-s-14">{prodStPrint(transfer?.prodSt)}</a>
                                                        </div>
                                                        <div className="display-flex wd-100pc just-cont-fend" ref={el => transferProdStRef.current[`${i}-${index}`] = el}>
                                                            <div onClick={() => toggleTransferProdStShow(i, index)} className="m-a-combo wd-100 ht-30 font-s-12 m-a-flex-jc-start">
                                                                <div className="font-t-cont">{selectedProdSt[`${'transfer'}-${i}-${index}`]?.label || '개별변경'}</div>
                                                            </div>
                                                            {transferProdStShow[`${i}-${index}`] ?
                                                                <div className="m-a-common-popup-container" onClick={() => toggleTransferProdStShow(i, index)}>
                                                                    <div className="m-a-common-popup-frame" onClick={(e) => e.stopPropagation()}>
                                                                        <div className="m-a-common-popup-header-frame">
                                                                            <div className="m-a-common-popup-header-text font-t-cont">개별변경</div>
                                                                        </div>
                                                                        <div className="m-a-common-popup-body-frame">
                                                                            {prodStList.map((ps, idx) => (
                                                                                <div key={idx} className="m-a-common-popup-body-data" onClick={() => handleProdStChange('transfer', ps, transfer.seqnum, i, index)}>
                                                                                    {ps.label}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        <div className="m-a-common-popup-footer-frame">
                                                                            <div className="m-a-file-bt-container">
                                                                                <button onClick={() => toggleTransferProdStShow(i, index)} className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                                    <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {/*이체대행종료*/}
                                    </div>
                                )}
                                {/*국내배송시작*/}
                                {cData.productsDTO.filter(prod => prod.prodTy === "P04").length > 0 && (
                                    <div>
                                        <div className="m-a-order-prod-type">
                                            <div className="wd-10pc">
                                                <input className="m-a-admin-input-chkBox m-0-a vertical-align" type="checkbox"
                                                       onChange={e => handleCheckAll('domestic', i, e.target.checked, cData.productsDTO)}
                                                       checked={selectedItems.domestic[i] ? selectedItems.domestic[i].length === cData.productsDTO.filter(prod => prod.prodTy === "P04").length : false}
                                                />
                                            </div>
                                            <div className="wd-55pc text-left p-l-5" style={{display: "flex", flexDirection: "row"}}
                                                 onClick={()=> toggleMenuDTcartFn(i, cData.productsDTO.filter(prod => prod.prodTy === "P04").length)}>
                                                <p className="font-s-16 font-b dark-font">국내배송</p>
                                                <a className="font-s-16 m-l-5"
                                                   style={{color: cData.productsDTO.filter(prod => prod.prodTy === "P04").length === 0 ? "#5B5B5B" : "#FFAF34"}}>
                                                    {cData.productsDTO.filter(prod => prod.prodTy === "P04").length}
                                                </a>
                                            </div>
                                            <div className="display-flex wd-35pc just-cont-fend">
                                                <div ref={el => allDomesticProdStRefs.current[`${i}`] = el}>
                                                    <div onClick={() => toggleAllDomesticProdStShow(i)} className="m-a-combo wd-100 ht-30 font-s-12 m-a-flex-jc-start">
                                                        <div className="font-t-cont">{selectedAllDomesticProdSt[i]?.label || '전체변경'}</div>
                                                    </div>
                                                    {allDomesticProdStShow[i] ?
                                                        <div className="m-a-common-popup-container" onClick={() => toggleAllDomesticProdStShow(i)}>
                                                            <div className="m-a-common-popup-frame" onClick={(e) => e.stopPropagation()}>
                                                                <div className="m-a-common-popup-header-frame">
                                                                    <div className="m-a-common-popup-header-text font-t-cont">전체변경</div>
                                                                </div>
                                                                <div className="m-a-common-popup-body-frame">
                                                                    {prodStList.map((aps, idx) => (
                                                                        <div key={idx} className="m-a-common-popup-body-data" onClick={() => handleAllProdStChange('domestic', i, aps)}>
                                                                            {aps.label}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className="m-a-common-popup-footer-frame">
                                                                    <div className="m-a-file-bt-container">
                                                                        <button onClick={() => toggleAllDomesticProdStShow(i)} className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                            <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {cData.productsDTO.filter(prod => prod.prodTy === "P04").sort((a,b) => a.seqnum - b.seqnum).map((domestic, index) => (
                                            <div key={index} className="m-a-order-prod-type-cont">
                                                <div className="m-a-order-p-c-frame-h">
                                                    <div className="wd-10pc">
                                                        <input className="m-a-admin-input-chkBox vertical-align m-t-70" type="checkbox"
                                                            onChange={e => handleCheckItem('domestic', i, domestic.seqnum, e.target.checked)}
                                                            checked={selectedItems.domestic[i] ? selectedItems.domestic[i].includes(domestic.seqnum) : false}/>
                                                    </div>
                                                    <div className="m-a-prod-cont wd-55pc text-left p-l-5 dark-font">
                                                        <p>{domestic.name}</p>
                                                        <p>{domestic.option}</p>
                                                        <p>{domestic.quantity}</p>
                                                        <p>{domestic.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                        <p>{domestic.fee.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                                        <div className="m-a-input-frame ht-45 wd-100pc">
                                                            <textarea className="m-a-input font font-t-cont" style={{resize: "none"}} cols="15" rows="2"
                                                                      value={adminProdMemos[domestic.seqnum] || ''}
                                                                      onChange={(e) => handleProdMemoChange(domestic.seqnum, e.target.value)}
                                                                      onKeyDown={(e) => saveProdMemo(e, domestic.seqnum)}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="display-flex flex-column wd-35pc just-cont-center">
                                                        <div className="display-flex wd-100pc just-cont-fend p-r-10">
                                                            <a className="font-t-cont font-s-14">{prodStPrint(domestic?.prodSt)}</a>
                                                        </div>
                                                        <div className="display-flex wd-100pc just-cont-fend" ref={el => domesticProdStRef.current[`${i}-${index}`] = el}>
                                                            <div onClick={() => toggleDomesticProdStShow(i, index)} className="m-a-combo wd-100 ht-30 font-s-12 m-a-flex-jc-start">
                                                                <div className="font-t-cont">{selectedProdSt[`${'domestic'}-${i}-${index}`]?.label || '개별변경'}</div>
                                                            </div>
                                                            {domesticProdStShow[`${i}-${index}`] ?
                                                                <div className="m-a-common-popup-container" onClick={() => toggleDomesticProdStShow(i, index)}>
                                                                    <div className="m-a-common-popup-frame" onClick={(e) => e.stopPropagation()}>
                                                                        <div className="m-a-common-popup-header-frame">
                                                                            <div className="m-a-common-popup-header-text font-t-cont">개별변경</div>
                                                                        </div>
                                                                        <div className="m-a-common-popup-body-frame">
                                                                            {prodStList.map((ps, idx) => (
                                                                                <div key={idx} className="m-a-common-popup-body-data" onClick={() => handleProdStChange('domestic', ps, domestic.seqnum, i, index)}>
                                                                                    {ps.label}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        <div className="m-a-common-popup-footer-frame">
                                                                            <div className="m-a-file-bt-container">
                                                                                <button onClick={() => toggleDomesticProdStShow(i, index)} className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                                    <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {/*국내배송종료*/}
                                    </div>
                                )}
                                {/*배송대행시작*/}
                                {cData.productsDTO.filter(prod => prod.prodTy === "P05").length > 0 && (
                                    <div>
                                        <div className="m-a-order-prod-type">
                                            <div className="wd-10pc">
                                                <input className="m-a-admin-input-chkBox m-0-a vertical-align" type="checkbox"
                                                       onChange={e => handleCheckAll('delivery', i, e.target.checked, cData.productsDTO)}
                                                       checked={selectedItems.delivery[i] ? selectedItems.delivery[i].length === cData.productsDTO.filter(prod => prod.prodTy === "P05").length : false}
                                                />
                                            </div>
                                            <div className="wd-55pc text-left p-l-5" style={{display: "flex", flexDirection: "row"}}
                                                 onClick={()=> toggleMenuDTcartFn(i, cData.productsDTO.filter(prod => prod.prodTy === "P05").length)}>
                                                <p className="font-s-16 font-b dark-font">배송대행</p>
                                                <a className="font-s-16 m-l-5"
                                                   style={{color: cData.productsDTO.filter(prod => prod.prodTy === "P05").length === 0 ? "#5B5B5B" : "#FFAF34"}}>
                                                    {cData.productsDTO.filter(prod => prod.prodTy === "P05").length}
                                                </a>
                                            </div>
                                            <div className="display-flex wd-35pc just-cont-fend">
                                                <div ref={el => allDeliveryProdStRefs.current[`${i}`] = el}>
                                                    <div onClick={() => toggleAllDeliveryProdStShow(i)} className="m-a-combo wd-100 ht-30 font-s-12 m-a-flex-jc-start">
                                                        <div className="font-t-cont">{selectedAllDeliveryProdSt[i]?.label || '전체변경'}</div>
                                                    </div>
                                                    {allDeliveryProdStShow[i] ?
                                                        <div className="m-a-common-popup-container" onClick={() => toggleAllDeliveryProdStShow(i)}>
                                                            <div className="m-a-common-popup-frame" onClick={(e) => e.stopPropagation()}>
                                                                <div className="m-a-common-popup-header-frame">
                                                                    <div className="m-a-common-popup-header-text font-t-cont">전체변경</div>
                                                                </div>
                                                                <div className="m-a-common-popup-body-frame">
                                                                    {prodStList.map((aps, idx) => (
                                                                        <div key={idx} className="m-a-common-popup-body-data" onClick={() => handleAllProdStChange('delivery', i, aps)}>
                                                                            {aps.label}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <div className="m-a-common-popup-footer-frame">
                                                                    <div className="m-a-file-bt-container">
                                                                        <button onClick={() => toggleAllDeliveryProdStShow(i)} className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                            <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {cData.productsDTO.filter(prod => prod.prodTy === "P05").sort((a,b) => a.seqnum - b.seqnum).map((delivery, index) => (
                                            <div key={index} className="m-a-order-prod-type-cont">
                                                <div className="m-a-order-p-c-frame-h">
                                                    <div className="wd-10pc">
                                                        <input className="m-a-admin-input-chkBox vertical-align m-t-70" type="checkbox"
                                                            onChange={e => handleCheckItem('delivery', i, delivery.seqnum, e.target.checked)}
                                                            checked={selectedItems.delivery[i] ? selectedItems.delivery[i].includes(delivery.seqnum) : false}/>
                                                    </div>
                                                    <div className="m-a-prod-cont wd-55pc text-left p-l-5 dark-font">
                                                        <p>{delivery.domShipco}</p>
                                                        <div>
                                                            <a onClick={() => dbSelectedDeliveryTracking(delivery.domShipco, delivery.inWaynum)} style={{color: "#4C93E5"}}
                                                               className="m-r-3 font-b c-point text-underline">{delivery?.inWaynum || ""}
                                                            </a>
                                                            {!copiedIndexes.includes(index) && (
                                                                <RxCopy className="m-l-3 font-s-20  btn-click-event font-c-default" style={{opacity: "0.65"}}
                                                                        onClick={() => handleCopy(delivery.inWaynum.trim(), index)}/>
                                                            )}
                                                            {copiedIndexes.includes(index) && <RxCheck className="m-l-3 font-s-18 font-c-btorage" style={{opacity: "0.65"}} />}
                                                        </div>
                                                        <p>{delivery.name}</p>
                                                        <p>{delivery.option}</p>
                                                        <p>{delivery.quantity}</p>
                                                        <div className="m-a-input-frame ht-45 wd-100pc">
                                                            <textarea className="m-a-input font font-t-cont" style={{resize: "none"}} cols="15" rows="2"
                                                                      value={adminProdMemos[delivery.seqnum] || ''}
                                                                      onChange={(e) => handleProdMemoChange(delivery.seqnum, e.target.value)}
                                                                      onKeyDown={(e) => saveProdMemo(e, delivery.seqnum)}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="display-flex flex-column wd-35pc just-cont-center">
                                                        <div className="display-flex wd-100pc just-cont-fend p-r-10">
                                                            <a className="font-t-cont font-s-14">{prodStPrint(delivery?.prodSt)}</a>
                                                        </div>
                                                        <div className="display-flex wd-100pc just-cont-fend"ref={el => deliveryProdStRef.current[`${i}-${index}`] = el}>
                                                            <div onClick={() => toggleDeliveryProdStShow(i, index)} className="m-a-combo wd-100 ht-30 font-s-12 m-a-flex-jc-start">
                                                                <div className="font-t-cont">{selectedProdSt[`${'delivery'}-${i}-${index}`]?.label || '개별변경'}</div>
                                                            </div>
                                                            {deliveryProdStShow[`${i}-${index}`] ?
                                                                <div className="m-a-common-popup-container" onClick={() => toggleDeliveryProdStShow(i, index)}>
                                                                    <div className="m-a-common-popup-frame" onClick={(e) => e.stopPropagation()}>
                                                                        <div className="m-a-common-popup-header-frame">
                                                                            <div className="m-a-common-popup-header-text font-t-cont">개별변경</div>
                                                                        </div>
                                                                        <div className="m-a-common-popup-body-frame">
                                                                            {prodStList.map((ps, idx) => (
                                                                                <div key={idx} className="m-a-common-popup-body-data" onClick={() => handleProdStChange('delivery', ps, delivery.seqnum, i, index)}>
                                                                                    {ps.label}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        <div className="m-a-common-popup-footer-frame">
                                                                            <div className="m-a-file-bt-container">
                                                                                <button onClick={() => toggleDeliveryProdStShow(i, index)} className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                                    <div className="m-a-colorBt-text font-t-cont">Close</div>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {/*배송대행종료*/}
                                    </div>
                                )}
                            </div>
                            )}
                            <div className="m-a-order-bt-frame m-t-12">
                                <div className="m-a-order-bt">
                                    <button className="m-a-commonBt wd-70 ht-35 font-c-default font-s-15" onClick={() => infoDTPg(cData)}>상세</button>
                                    <button className="m-a-colorBt-B wd-70 ht-35" onClick={() => openModalDivide(cData)}>분리</button>
                                    <button className="m-a-colorBt-B wd-70 ht-35" onClick={() => openModalMerge(cData)}>통합</button>
                                    {cData.type === "orderdt" &&
                                        <button className="m-a-colorBt-B m-a-colorBt-text-B wd-70 ht-35" onClick={() => openRegModal(cData, 'orderdt')}>등록</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    ) :
                    // payinfo map
                    cData.type === 'payinfo' && (cData.payType === "PT1") ? (
                        <div key={key} className="m-a-order-container m-a-card m-t-15">
                            <div className="m-a-order-frame font-t-cont">
                                <div className="m-a-order-info">
                                    <div className="m-a-o-i-1 m-b-2 dark-font">
                                        <p className="font-b font-s-15"><img className="wd-17 ht-17 m-b-4 m-r-3" alt="" src={payIMG}/>{cData.orderdtDTO.orderNumber}-{cData.shipInfoDTO.boxNum}</p>
                                        <p className="font-s-12 m-t-3">{cData.regDate.slice(0, 4) + '-' + cData.regDate.slice(4, 6) + '-' + cData.regDate.slice(6, 8)}</p>
                                    </div>
                                    <div className="m-a-o-i-2 dark-font">
                                        <p className="font-b font-s-14">{cData.appRecDTO.recname}</p>
                                        <p className="font-s-14">{cData.appRecDTO.countryDTO.countryNm + "[" + cData.appRecDTO.countryDTO.countryCode + "]"}</p>
                                        <p className="font-s-16 font-b">{convertToShipco(cData.dvType, cData.orderdtDTO.shipco)}</p>
                                    </div>
                                    <div className="m-a-o-i-3 font-s-14 ht-30 dark-font">
                                        <p className="font-s-14">{cData.orderdtDTO.memberDTO.userName}</p>
                                        <div>
                                            <a className="m-r-5 font-b c-point text-underline" style={{color: "#276ab7"}}>{cData.shipInfoDTO?.outWayNum || ""}</a>
                                            {cData.shipInfoDTO?.outWayNum !== "" && cData.shipInfoDTO?.outWayNum !== 0 && cData.shipInfoDTO?.outWayNum !== null &&
                                                <RxCopy className="font-s-20 c-point btorage-hover btn-click-event" style={{opacity: "0.65"}}
                                                        onClick={() => handleCopy(cData.shipInfoDTO?.outWayNum)}/>
                                            }
                                        </div>
                                    </div>
                                    <div className="m-a-o-i-4">
                                        <div className="m-a-order-status-B">
                                            <p className="m-0-a font-s-16 font-b">
                                                {orderStPrint(
                                                    cData.payStatus === "PS0" ? "OS09" :
                                                        cData.payStatus === "PS1" ? "OS10" :
                                                            cData.payStatus === "PS2" ? "OS11" :
                                                                cData.payStatus === "PS3" ? "OS12" :
                                                                    cData.payStatus === "PS4" ? "OS13" :
                                                                        cData.payStatus === "PS5" ? "OS14" : null)}
                                            </p>
                                        </div>
                                        {/*<button className="m-a-commonBt wd-60 ht-30"><BsPlusLg className="font-s-16 font-c-default"/></button>*/}
                                    </div>
                                </div>
                                <div className="m-a-order-dt-info wd-100pc">
                                    <div>
                                        { cData.ttFedexPrice > 0 || cData.ttEmsPrice > 0 || cData.ttSeaPrice > 0 || cData.ttUpsPrice > 0 || cData.ttKPackPrice > 0 || cData.ttAirSPrice > 0 ?
                                            <div className="m-a-order-prod-type-p font-s-14 dark-font gap5">
                                                <div>
                                                    <p className="m-r-5">FedEx</p>
                                                    <a style={{color:cData?.ttFedexPrice > 0 ? "#FFAF34" : "#949494", fontWeight:cData?.ttFedexPrice > 0 ? "bold" : "normal"}}>
                                                        &#8361; {cData?.ttFedexPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                    </a>
                                                </div>
                                                <div>
                                                    <p className="m-r-5">UPS</p>
                                                    <a style={{color:cData?.ttUpsPrice > 0 ? "#FFAF34" : "#949494", fontWeight:cData?.ttUpsPrice > 0 ? "bold" : "normal"}}>
                                                        &#8361; {cData?.ttUpsPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                    </a>
                                                </div>
                                                <div>
                                                    <p className="m-r-5">EMS</p>
                                                    <a style={{color:cData?.ttEmsPrice > 0 ? "#FFAF34" : "#949494", fontWeight:cData?.ttEmsPrice > 0 ? "bold" : "normal"}}>
                                                        &#8361; {cData?.ttEmsPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                    </a>
                                                </div>
                                                <div>
                                                    <p className="m-r-5">Sea</p>
                                                    <a style={{color:cData?.ttSeaPrice > 0 ? "#FFAF34" : "#949494", fontWeight:cData?.ttSeaPrice > 0 ? "bold" : "normal"}}>
                                                        &#8361; {cData?.ttSeaPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                    </a>
                                                </div>
                                                <div>
                                                    <p className="m-r-5">K-Packet</p>
                                                    <a style={{color:cData?.ttKPackPrice > 0 ? "#FFAF34" : "#949494", fontWeight:cData?.ttKPackPrice > 0 ? "bold" : "normal"}}>
                                                        &#8361; {cData?.ttKPackPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                    </a>
                                                </div>
                                                <div>
                                                    <p className="m-r-5">항공소형</p>
                                                    <a style={{color:cData?.ttAirSPrice > 0 ? "#FFAF34" : "#949494", fontWeight:cData?.ttAirSPrice > 0 ? "bold" : "normal"}}>
                                                        &#8361; {cData?.ttAirSPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                    </a>
                                                </div>
                                            </div>
                                            :
                                            cData.ttDomPrice > 0 ?
                                                <div className="display-flex just-cont-fend">
                                                    <a className="m-r-5">국내배송</a>
                                                    <a style={{color:cData?.ttDomPrice > 0 && cData?.payStatus === "PS0" ? "#FFAF34" : "#5B5B5B", fontWeight:"bold"}}>
                                                        &#8361; {cData?.ttDomPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                    </a>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                                <div className="m-a-order-bt-frame m-t-12">
                                    <div className="m-a-order-bt">
                                        {/*<div className='m-a-user-my-page-order-sheet-button'*/}
                                        {/*     onClick={() => deliveryDetailTrueFn(order.seqNum, order.orderdtDTO.shipco)}>*/}
                                        {/*    {t(`myDt`)}onClick={() => deliveryDetailTrueFn(order.seqNum, order.orderdtDTO.shipco)}*/}
                                        {/*</div>*/}
                                        <button className="m-a-commonBt wd-70 ht-35 font-c-default font-s-15" onClick={() => deliveryDetailTrueFn(cData.seqNum)}>상세</button>
                                        {/*<button className="m-a-commonBt wd-70 ht-35 font-c-default font-s-15" onClick={() => infoPayDTPg(cData)}>상세</button>*/}
                                        {cData.type === "orderdt" &&
                                            <button className="m-a-colorBt-B wd-70 ht-35 font-s-15" onClick={() => openRegModal(cData, 'orderdt')}>등록</button>
                                        }
                                        {cData.type === "payinfo" && cData.payStatus !== "PS3" &&
                                            <button className="m-a-colorBt-B wd-70 ht-35 font-s-15" onClick={() => expRegDelete(cData)}>등록취소</button>
                                        }
                                        {cData.type === "payinfo" && cData.payStatus === "PS3" &&
                                            <button className="m-a-colorBt-B wd-70 ht-35 font-s-15">출고취소</button>
                                        }
                                        {cData.type === "payinfo" && cData.payStatus !== "PS3" &&
                                            <button className="m-a-colorBt-B wd-70 ht-35 font-s-15" onClick={() => openRegModal(cData, 'payinfo')}>비용수정</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        ) : null
            })}
            {allCartProdStChangeShow ?
                <MsgSelectModal allCartProdStChangeShow={allCartProdStChangeShow} closeAllCartProdStChangeShow={closeAllCartProdStChangeShow}
                                allProdStChangeMsg1={allProdStChangeMsg1} allProdStChangeMsg2={allProdStChangeMsg2} modifyProdSt={modifyProdSt}
                                selectedAllCartProdSt={selectedAllCartProdSt} selectedItems={selectedItems} selectedCdataIndex={selectedCdataIndex} selectedFormTy={selectedFormTy}/>
                :
                allCartProdStNonSeqnumShow ?
                <MsgConfirmModal allCartProdStNonSeqnumShow={allCartProdStNonSeqnumShow} closeAllCartProdStNonSeqNumModalShow={closeAllCartProdStNonSeqNumModalShow}
                                 allProdStNonSeqnumMsg={allProdStNonSeqnumMsg}/>
                :
                allOrderProdStChangeShow ?
                <MsgSelectModal allOrderProdStChangeShow={allOrderProdStChangeShow} closeAllOrderProdStChangeShow={closeAllOrderProdStChangeShow}
                                allProdStChangeMsg1={allProdStChangeMsg1} allProdStChangeMsg2={allProdStChangeMsg2} modifyProdSt={modifyProdSt}
                                selectedAllOrderProdSt={selectedAllOrderProdSt} selectedItems={selectedItems} selectedCdataIndex={selectedCdataIndex} selectedFormTy={selectedFormTy}/>
                :
                allOrderProdStNonSeqnumShow ?
                <MsgConfirmModal allOrderProdStNonSeqnumShow={allOrderProdStNonSeqnumShow} closeAllOrderProdStNonSeqNumModalShow={closeAllOrderProdStNonSeqNumModalShow}
                                 allProdStNonSeqnumMsg={allProdStNonSeqnumMsg}/>
                :
                allOfflineProdStChangeShow ?
                <MsgSelectModal allOfflineProdStChangeShow={allOfflineProdStChangeShow} closeAllOfflineProdStChangeShow={closeAllOfflineProdStChangeShow}
                                allProdStChangeMsg1={allProdStChangeMsg1} allProdStChangeMsg2={allProdStChangeMsg2} modifyProdSt={modifyProdSt}
                                selectedAllOfflineProdSt={selectedAllOfflineProdSt} selectedItems={selectedItems} selectedCdataIndex={selectedCdataIndex} selectedFormTy={selectedFormTy}/>
                :
                allOfflineProdStNonSeqnumShow ?
                <MsgConfirmModal allOfflineProdStNonSeqnumShow={allOfflineProdStNonSeqnumShow} closeAllOfflineProdStNonSeqNumModalShow={closeAllOfflineProdStNonSeqNumModalShow}
                                 allProdStNonSeqnumMsg={allProdStNonSeqnumMsg}/>
                :
                allTransferProdStChangeShow ?
                <MsgSelectModal allTransferProdStChangeShow={allTransferProdStChangeShow} closeAllTransferProdStChangeShow={closeAllTransferProdStChangeShow}
                                allProdStChangeMsg1={allProdStChangeMsg1} allProdStChangeMsg2={allProdStChangeMsg2} modifyProdSt={modifyProdSt}
                                selectedAllTransferProdSt={selectedAllTransferProdSt} selectedItems={selectedItems} selectedCdataIndex={selectedCdataIndex} selectedFormTy={selectedFormTy}/>
                :
                allTransferProdStNonSeqnumShow ?
                <MsgConfirmModal allTransferProdStNonSeqnumShow={allTransferProdStNonSeqnumShow} closeAllTransferProdStNonSeqNumModalShow={closeAllTransferProdStNonSeqNumModalShow}
                                 allProdStNonSeqnumMsg={allProdStNonSeqnumMsg}/>
                :
                allDomesticProdStChangeShow ?
                <MsgSelectModal allDomesticProdStChangeShow={allDomesticProdStChangeShow} closeAllDomesticProdStChangeShow={closeAllDomesticProdStChangeShow}
                                allProdStChangeMsg1={allProdStChangeMsg1} allProdStChangeMsg2={allProdStChangeMsg2} modifyProdSt={modifyProdSt}
                                selectedAllDomesticProdSt={selectedAllDomesticProdSt} selectedItems={selectedItems} selectedCdataIndex={selectedCdataIndex} selectedFormTy={selectedFormTy}/>
                :
                allDomesticProdStNonSeqnumShow ?
                <MsgConfirmModal allDomesticProdStNonSeqnumShow={allDomesticProdStNonSeqnumShow} closeAllDomesticProdStNonSeqNumModalShow={closeAllDomesticProdStNonSeqNumModalShow}
                                 allProdStNonSeqnumMsg={allProdStNonSeqnumMsg}/>
                :
                allDeliveryProdStChangeShow ?
                <MsgSelectModal allDeliveryProdStChangeShow={allDeliveryProdStChangeShow} closeAllDeliveryProdStChangeShow={closeAllDeliveryProdStChangeShow}
                                allProdStChangeMsg1={allProdStChangeMsg1} allProdStChangeMsg2={allProdStChangeMsg2} modifyProdSt={modifyProdSt}
                                selectedAllDeliveryProdSt={selectedAllDeliveryProdSt} selectedItems={selectedItems} selectedCdataIndex={selectedCdataIndex} selectedFormTy={selectedFormTy}/>
                :
                allDeliveryProdStNonSeqnumShow ?
                <MsgConfirmModal allDeliveryProdStNonSeqnumShow={allDeliveryProdStNonSeqnumShow} closeAllDeliveryProdStNonSeqNumModalShow={closeAllDeliveryProdStNonSeqNumModalShow}
                                 allProdStNonSeqnumMsg={allProdStNonSeqnumMsg}/>
                : null
            }
            {deliveryDetailSt ?
                <MDeliveryPriceDetail deliveryDetailSt={deliveryDetailSt} deliveryDetailFalseFn={deliveryDetailFalseFn}
                                     paySeqnum={paySeqnum} updateAppRecinfo={updateAppRecinfo}
                                     updatePayinfoList={updatePayinfoList} pathComponent={pathComponent}/> : null
            }
        </div>
    );
}

export default MAdminDashBoardPg
