import "../../css/mobile/MLoginPg.css";
import Logo from "../../assets/img/HeaderLogo.png";
import {useLocation, useNavigate} from "react-router-dom";
import Naver from "../../assets/img/naverLogo.png";
import Google from "../../assets/img/googleLogo.png";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import axios from "axios";
import i18n from "../../i18n";
import MsgCheckModal from "../../components/web/modal/MsgCheckModal";
import SelectLangModal from "../../components/web/modal/SelectLangModal";
import MCommonTermsPopup from "../../components/mobile/MCommonTermsPopup";

const MLoginPg = () => {

    const {t} = useTranslation();
    const navigate = useNavigate()
    const location = useLocation();

    const {state} = useLocation();
    const [id, setId] = useState("");
    const [password, setPassword] = useState("");
    const [loginErrorMsg, setLoginErrorMsg] = useState(false);
    const [googleLoginErrorMsg, setGoogleLoginErrorMsg] = useState(false);
    const [autoLogin, setAutoLogin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isNaverLoading, setIsNaverLoading] = useState(false);
    const [isGoogleLoading, setIsGoogleLoading] = useState(false);

    const handleChange_email = (e) => {
        setId(e.target.value);
        setLoginErrorMsg(false);
    }
    const handleChange_password = (e) => {
        setPassword(e.target.value);
        setLoginErrorMsg(false);
    }

    // 체크하면 LCAT도 받고 로그인 유지
    const handleAutoLogin = (e) => {
        if (e.target.checked)
            setAutoLogin(true)
        else setAutoLogin(false)
    }

    // 로그인 비밀번호 양식에서 엔터누르면 로그인 버튼 클릭
    const handleOnKeyPress = e => {
        if (e.key === 'Enter') {
            LoginCheck().then();
        }
    };

    // "2022-12-28T21:15:07.109Z"  // 좌측이 몇번째 자리까지 가져올건지, 우측이 시작위치
    // 앞에서 네글자 가져오기 이런식으로 년/월/일 가져오고, 중간에 하이픈 및 : 없애기
    const dateToString = JSON.stringify(new Date())
    const year = dateToString.substring(1, 5) * 60 * 60 * 24 * 365; // 2022년
    const month = dateToString.substring(8, 6) * 60 * 60 * 24 * 30; // 12월
    const day = dateToString.substring(11, 9) * 60 * 60 * 24; // 29일
    const hours = dateToString.substring(14, 12) * 60 * 60; // 07시
    const minutes = dateToString.substring(17, 15) * 60; // 39분
    const seconds = dateToString.substring(20, 18) * 1; // 20초
    const atTime = year + month + day + hours + minutes + seconds; // 2022-12-29 // 07:39:20

    // ■■■■■■■■■■■■■■ 비토리지 로그인
    async function LoginCheck() {
        if (id === "" || password === "") {
            setLoginErrorMsg(true);
            setIsLoading(false);
            return;
        }
        setIsLoading(true);
        try {
            const response = await axios.get("/member/userOutFl/" + id, {
                params: {
                    id: id,
                    password: password
                }
            });
            login(response.data);
        } catch (error) {
            setIsLoading(false);
            setLoginErrorMsg(true);
        }
    }

    const sendAccessInfo = async (deviceType, id) => {
        try {
            const response = await axios.post('/init/accessInfo', {
                userId: id,
                accessTime: new Date().toISOString(),
                machFl: deviceType
            });
        } catch (error) {
            console.error(error);
        }
    };

    const determineMachFl = () => {
        if (window.navigator.userAgent.includes('ReactNative')) {
            return 'A'; //App
        } else if (window.navigator.userAgent.includes('Mobi')) {
            return 'M'; //웹앱
        } else {
            return 'P'; //PC
        }
    };

    const login = async (res) => {
        if (res === "Y") {
            setLoginErrorMsg(true);
            setIsLoading(false);
        } else if (res === "N") {
            try {
                const response = await axios.post("/member/login", {
                    id: id,
                    password: password,
                    loginPath: location.pathname
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });

                const deviceType = determineMachFl();
                await sendAccessInfo(deviceType, id);

                const currentTime = new Date();
                // if (autoLogin) {
                //   localStorage.setItem('AToken', response.headers.get('Authorization'));
                //   localStorage.setItem('lastLoginTime', currentTime);
                // } else {
                //   sessionStorage.setItem('AToken', response.headers.get('Authorization'));
                //   sessionStorage.setItem('lastLoginTime', currentTime);
                // }
                localStorage.setItem('AToken', response.headers.get('Authorization'));
                localStorage.setItem('lastLoginTime', currentTime);
                let navigateBackTo = location.state?.from || "/";
                navigate(navigateBackTo);
            } catch (error) {
                setIsLoading(false);
                setLoginErrorMsg(true);
            }
        }
    }

    // 언어선택
    const [selectLangSt, setSelectLangSt] = useState(false);
    const selectLangTrueFn = (() => {
        setSelectLangSt(true);
    })
    const selectLangFalseFn = (() => {
        setSelectLangSt(false);
    })
    const handleSelectLangChange = (lang) => {
        setSelectLangSt(false);
        // 로컬스토리지 언어변경 + i18next 언어변경 code
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', lang);
    }

    useEffect(() => {
        selectedGoogleId();
        selectedNaverId();
    }, [])

    const [googleId, setGoogleId] = useState(null);
    const selectedGoogleId = () => axios({
        method: 'get',
        url: '/credentials/secretManager/googleId',
    })
        .then((response) => {
            setGoogleId(response.data);
        })

    // ■■■■■■■■■■■■■■ 구글로그인
    const googleLogin = () => {
        setIsGoogleLoading(true);
        let popup = null;

        try {
            const codeClient = window.google.accounts.oauth2.initCodeClient({
                client_id: googleId,
                scope: 'openid email profile',
                ux_mode: 'popup',
                callback: async (response, error) => {
                    if (error) {
                        setIsGoogleLoading(false);
                        return;
                    }
                    try {
                        const res = await axios.post('/externalApi/googleLogin', {code: response.code});
                        const currentTime = new Date();
                        localStorage.setItem('AToken', res.headers['authorization']);
                        localStorage.setItem('lastLoginTime', currentTime);
                        const userEmail = res.data.email;
                        const deviceType = determineMachFl();
                        await sendAccessInfo(deviceType, userEmail);

                        let navigateBackTo = location.state?.from || "/";
                        navigate(navigateBackTo);
                    } catch (error) {
                        googleLoginErrorTrue();
                    }
                    setIsGoogleLoading(false);
                },
            });

            popup = codeClient.requestCode();

            const checkPopupClosed = setInterval(() => {
                if (!popup || popup.closed) {
                    clearInterval(checkPopupClosed);
                    setIsGoogleLoading(false);
                }
            }, 1000);
        } catch (e) {
            setIsGoogleLoading(false);
            console.error('Google login failed:', e);
        }
    };

    const googleLoginErrorTrue = (() => {
        setGoogleLoginErrorMsg(true);
    })
    const googleLoginErrorClose = (() => {
        setGoogleLoginErrorMsg(false);
    })

    // ■■■■■■■■■■■■■■ 네이버로그인
    const [naverId, setNaverId] = useState(null);
    const selectedNaverId = () => axios({
        method: 'get',
        url: '/credentials/secretManager/naverId',
    })
        .then((response) => {
            setNaverId(response.data);
        })

    function generateRandomString(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    async function naverLogin() {
        setIsNaverLoading(true);
        try {
            const clientId = naverId;
            const redirectUri = "https://btorage.com/NaverLoginLoading"; // 운영
            // const redirectUri = "http://localhost:3000/NaverLoginLoading"; // 개발
            const state = generateRandomString(16);
            const url = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&state=${state}`;

            checkLoginPopup(url);

            const deviceType = determineMachFl();
            await sendAccessInfo(deviceType, naverId);

        } catch (error) {
            setIsNaverLoading(false);
            console.error('Naver login failed:', error);
        }
    }

    function checkLoginPopup(url) {
        // url로 네이버창 띄우고 로그인되면 redirectUri로 전송
        // NaverLoginLoading으로 code, state 전달됨
        window.open(url, '_self')
    }

    const moveToIdPwFindPg = (() => {
        navigate("/UserIdPwFindPg");
    })

    const moveToJoinPg = (() => {
        navigate("/UserJoinPg");
    })

    const [termsOfUsePopup, setTermsOfUsePopup] = useState(false);
    const closeTermsOfUsePopup = (() => {
        setTermsOfUsePopup(false);
    })

    const [privacyPolicyPopup, setPrivacyPolicyPopup] = useState(false);
    const closePrivacyPolicyPopup = (() => {
        setPrivacyPolicyPopup(false);
    })

    return (
        <div className="m-a-login-pg-container font-t-cont">
            <div className="wd-100pc text-center" style={{marginBottom: "12%"}}>
                <img className="m-a-login-pg-logo m-b-20" src={Logo} alt="BTORAGE" onClick={() => navigate("/")}/>
            </div>
            <div className="display-flex flex-column just-cont-center text-center wd-100pc gap15" style={{marginBottom: "18%"}}>
                <div><p className="font-s-12 font-c-default">앱에서 더욱 간편하게 로그인하고 이용해 보세요!</p></div>
                <button className="bt-btn-B-B wd-100pc ht-45 font-s-16">{t(`mobileLoginApp`)}</button> {/*앱으로 로그인하기*/}
            </div>
            <input className="bt-basic-input wd-100pc ht-45 m-b-5 font-s-13 m-b-10" type={'text'} placeholder={t(`loginLabel1`)} minLength="4" maxLength="36"
                   onChange={handleChange_email} onKeyDown={handleOnKeyPress}/>
            <input className="bt-basic-input wd-100pc ht-45 m-b-5 font-s-13" placeholder={t(`loginLabel2`)} type="password" minLength="8" maxLength="20"
                   onChange={handleChange_password} onKeyDown={handleOnKeyPress}/>
            {loginErrorMsg ?
                <div className="m-a-login-pg-error-message">{t(`loginErr1`)}</div> : null
            }
            <div className="m-a-login-pg-text-button-frame m-t-20 m-b-10 font-c-default">
                <div className='m-a-login-pg-text-button' onClick={moveToIdPwFindPg}>{t(`loginRePw`)}</div>
                <div className='m-a-login-pg-vertical-divider'/>
                <div className='m-a-login-pg-text-button' onClick={moveToJoinPg}>{t(`loginJoin`)}</div>
            </div>
            <button className="bt-btn-A wd-100pc ht-45 font-s-16 m-b-10" onClick={LoginCheck}>
                {isLoading ? (
                    <div className="spinner m-0-a"></div>
                ) : (
                    <p>{t(`loginBt1`)}</p>
                )}
            </button>
            <div className="display-flex flex-row wd-100pc gap10">
                <button className="bt-btn-C wd-50pc ht-45 position-relative" onClick={naverLogin}>
                    <img className="wd-20 ht-20" src={Naver} alt='Logo'/>
                    {isNaverLoading ? (
                        <div className="blackSpinner position-absolute" style={{left: "50%"}}></div>
                    ) : (
                        // <div className='m-a-login-pg-sns-text'>{t(`loginBt2`)}</div>
                        <p></p>
                    )}
                </button>
                <button className="bt-btn-C wd-50pc ht-45 position-relative" onClick={googleLogin}>
                    <img className="wd-23 ht-23" src={Google} alt='Logo'/>
                    {isGoogleLoading ? (
                        <div className="blackSpinner position-absolute" style={{left: "50%"}}></div>
                    ) : (
                        // <div className='m-a-login-pg-sns-text'>{t(`loginBt3`)}</div>
                        <p></p>
                    )}
                </button>
            </div>
            {googleLoginErrorMsg &&
                <MsgCheckModal googleLoginErrorMsg={googleLoginErrorMsg} googleLoginErrorClose={googleLoginErrorClose}/>
            }
            <div className="m-a-login-pg-footer font-c-default">
                <div className='m-a-login-pg-footer-text' onClick={selectLangTrueFn}>{t(`lang`)}</div>
                <div className='m-a-login-pg-footer-text' onClick={() => setTermsOfUsePopup(true)}>{t(`UseInfo`)}</div>
                <div className='m-a-login-pg-footer-text' onClick={() => setPrivacyPolicyPopup(true)}>{t(`infoPolicy`)}</div>
            </div>
            {selectLangSt ?
                <SelectLangModal selectLangTrueFn={selectLangTrueFn} selectLangFalseFn={selectLangFalseFn}
                                 handleSelectLangChange={handleSelectLangChange}/>
                :
                termsOfUsePopup ?
                    <MCommonTermsPopup termsOfUsePopup={termsOfUsePopup} closeTermsOfUsePopup={closeTermsOfUsePopup}/>
                    :
                    privacyPolicyPopup ?
                        <MCommonTermsPopup privacyPolicyPopup={privacyPolicyPopup}
                                           closePrivacyPolicyPopup={closePrivacyPolicyPopup}/>
                        :
                        null
            }
        </div>
    )
}

export default MLoginPg;
