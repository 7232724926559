import React, {useCallback, useEffect, useRef, useState} from 'react';
import '../../../css/web/AdminReceiptList.css';
import {RiArrowDownSFill, RiArrowUpSFill} from "react-icons/ri";
import axios from "axios";
import AdminPageEvent from "./AdminPageEvent";
import MsgSelectModal from "../modal/MsgSelectModal";
import {connect} from "../../../services/WebsocketService";
import MsgCheckModal from "../modal/MsgCheckModal";
import {useWebSocket} from "../../../services/WebsocketContext";
import excel from "exceljs";
import CommonLoading from "../modal/CommonLoading";

function AdminReceiptList() {

    /** region DataTable */

    const [ordReceiptData, setOrdReceiptData] = useState([]);
    const [selectedShipCompany, setSelectedShipCompany] = useState('');

    /** endregion */

    /** region Function */

    const LCAT = localStorage.getItem('adminToken');
    const [prodToggleSt, setProdToggleSt] = useState({});
    const [recToggleSt, setRecToggleSt] = useState({});
    const menuRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const [adminInfo, setAdminInfo] = useState([]);
    const selectAdminInfo = (() => {
        axios({
            method: 'get',
            url: '/member/modiInfo',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response)=> {
                setAdminInfo(response.data[0])
            })
            .catch(()=> {
                console.log("멤버조회 실패")
            })
    })

    useEffect(() => {
        selectAdminInfo();
    }, [])


    // 웹소켓
    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!adminInfo.mbNum || isSubscribedRef.current) return;

            if (!stompClient && !isConnected) {
                await websocketConnect(adminInfo.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                isSubscribedRef.current = false;
            }
        };

    }, [adminInfo.mbNum, isConnected, stompClient]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setRecToggleSt({});
                setProdToggleSt({});
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleMenu = (clickedSeqNum, menuType) => {
        if (menuType === 'rec') {
            setRecToggleSt(prevRecStates => {
                let newStates = { ...prevRecStates };
                Object.keys(newStates).forEach(key => {
                    newStates[key] = false;
                });
                newStates[clickedSeqNum] = !prevRecStates[clickedSeqNum];
                return newStates;
            });
            setProdToggleSt({});
        } else if (menuType === 'prod') {
            setProdToggleSt(prevProdStates => {
                let newStates = { ...prevProdStates };
                Object.keys(newStates).forEach(key => {
                    newStates[key] = false;
                });
                newStates[clickedSeqNum] = !prevProdStates[clickedSeqNum];
                return newStates;
            });
            setRecToggleSt({});
        }
    };

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(20);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const pageChange = (currentPage) => {
        setCurrentPage(currentPage);
    };
    const handleSelectChange = (e) => {
        setperPg(parseInt(e.target.value));
        setCurrentPage(1);
    };

    //endregion

    //region CheckBox

    const [checked, setChecked] = useState([]);
    const [shipcoChecked, setShipcoChecked] = useState([]);

    const checkBoxChange = (id, dvType) => {
        setChecked((prevSelectedData) => {
            if (prevSelectedData.includes(id)) {
                setShipcoChecked(prev => prev.filter(item => item.id !== id));
                return prevSelectedData.filter(item => item !== id);
            } else {
                setShipcoChecked(prev => [...prev, { id, dvType }]);
                return [...prevSelectedData, id];
            }
        });
    };

    const allcheckBoxChange = (e) => {
        if (e.target.checked) {
            const allIds = ordReceiptData.map(item => item.seqNum);
            const allTypes = ordReceiptData.map(item => ({ id: item.seqNum, dvType: item.dvType }));
            setChecked(allIds);
            setShipcoChecked(allTypes);
        } else {
            setChecked([]);
            setShipcoChecked([]);
        }
    };

    //endregion

    // region Alert

    const [alertShow, setAlertShow] = useState(false);
    const [alertShowEms, setAlertShowEms] = useState(false);

    const [nonSelectOrderSt, setNonSelectOrderSt] = useState(false);
    const [nonEmsDataSt, setNonEmsDataSt] = useState(false);

    const msgConfirmData1 = "주문정보를 출고처리 하시겠습니까??";
    const msgConfirmData2 = "선택된 주문정보가 출고완료 됩니다.";
    const msgConfirmEmsData1 = "EMS 주문정보를 출고처리 하시겠습니까??";
    const msgConfirmEmsData2 = "선택된 주문정보가 출고완료 됩니다.";

    const alertTariff = (() => {
        if (checked.length === 0) {
            setNonSelectOrderSt(true);
            return;
        }
        openTariffModal();
    })

    const alertWaybill = (() => {
        if (checked.length === 0) {
            setNonSelectOrderSt(true);
            return;
        }
        setAlertShow(true)
    })

    const alertPostOfficeApi = () => {
        if (checked.length === 0) {
            setNonSelectOrderSt(true);
            return;
        }

        const hasNonEmsData = shipcoChecked.some(item => (item.dvType !== "D02" && item.dvType !== "D11" && item.dvType !== "D13" && item.dvType !== "D14" ));

        if (hasNonEmsData) {
            setNonEmsDataSt(true);
        } else {
            setAlertShowEms(true);
        }
    };

    const closeNonSelectOrderSt = (() => {
        setNonSelectOrderSt(false);
    })
    const closeNonEmsDataSt = (() => {
        setNonEmsDataSt(false);
    })

    const modalCancel1 = useCallback(() => {
        setAlertShow(false);
    })
    const modalCancelEms = useCallback(() => {
        setAlertShowEms(false);
    })

    // endregion

    useEffect(() => {
        window.scrollTo(0, 910);
        return () => {
            window.scrollTo(0, 0);
        };
    }, []);

    const handleInputWaybillChange = (e, seqNum) => {
        const updatedData = ordReceiptData.map(data => {
            if (data.seqNum === seqNum) {
                return { ...data, shipInfoDTO: { ...data.shipInfoDTO, outWayNum: e.target.value }};
            }
            return data;
        });
        setOrdReceiptData(updatedData);
    };

    const NumberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const handleInputPriceChange = (e, seqNum) => {
        const value = e.target.value.replace(/,/g, '');
        const formattedValue = NumberWithCommas(value);

        const updatedData = ordReceiptData.map(data => {
            if (data.seqNum === seqNum) {
                return { ...data, shipInfoDTO: { ...data.shipInfoDTO, shipPrice: formattedValue }};
            }
            return data;
        });
        setOrdReceiptData(updatedData);
    };

    useEffect(() => {
        ordReceiptSelect();
    }, []);

    let WayModal;
    const width = 850;
    const height = 900;
    const left = (window.innerWidth / 2) - (width / 2);
    const top = (window.innerHeight / 2) - (height / 2);

    const openTariffModal = () => {
        if (checked.length === 0) {
            alert('주문을 선택하세요.');
            return;
        }

        const selectedData = ordReceiptData.filter(row => checked.includes(row.seqNum));
        localStorage.setItem('ordReceiptData', JSON.stringify(selectedData));

        if (WayModal && !WayModal.closed) {
            WayModal.location.href = '/btcorpad/btad-TariffReg';
            WayModal.focus();
        } else {
            WayModal = window.open('/btcorpad/btad-TariffReg', '_blank', `width=${width},height=${height},left=${left},top= ${top}, scrollbars=yes`);
        }
    };

    /** endregion */

    /** region Event */

    const ordReceiptSelect = (() => {
        axios({
            method: 'get',
            url: '/order/selectOrdReceiptList',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setOrdReceiptData(response.data);
            })
            .catch((error) => {
                console.log("실패: ", error);
            })
    })

    // const shipInfoData = ordReceiptData.filter((chkData) => checked.includes(chkData.seqNum)).map(entry => ({
    //     seqNum: entry.shipInfoDTO.seqNum,
    //     outWayNum: entry.shipInfoDTO.outWayNum,
    //     shipPrice: parseInt(entry.shipInfoDTO.shipPrice.replace(/,/g, ''), 10),
    //     // shipPrice: entry.shipInfoDTO.shipPrice,
    //     inputValue: entry.inputValue
    // }));

    const shipInfoData = ordReceiptData
        .filter((chkData) => checked.includes(chkData.seqNum))
        .map((entry) => ({
            seqNum: entry.shipInfoDTO.seqNum,
            outWayNum: entry.shipInfoDTO.outWayNum,
            shipPrice: parseInt((entry.shipInfoDTO.shipPrice || '0').replace(/,/g, ''), 10),
            inputValue: entry.inputValue,
        }));

    const orderdtData = ordReceiptData.filter((chkData) => checked.includes(chkData.seqNum)).map(data => ({
        seqnum: data.orderdtDTO.seqnum
    }));

    const payInfoData = ordReceiptData.filter((chkData) => checked.includes(chkData.seqNum)).map(data => ({
        seqNum: data.seqNum
    }));

    const outWaybillUpdate = () => {
        if (isLoading) return;

        setIsLoading(true);

        if (checked.length === 0) {
            setAlertShow(false);
            alert('주문을 선택하세요.');
            return;
        }
        const updateRequest = {
            shipInfoDTOList: shipInfoData,
            orderdtDTOList: orderdtData,
            payinfoDTOList: payInfoData
        };
        axios.post('/order/ordWaybillUpdate', updateRequest, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization' : LCAT
            }
        })
            .then(async (response) => {
                const data = response.data;
                for (const payinfo of data) {
                    await sendMessage(payinfo);
                    await stompClient.send("/app/updateOrderList", {}, JSON.stringify({userMbNum: payinfo.orderdtDTO.memberDTO.mbNum}));
                }
                setAlertShow(false);
                // 재조회가 아닌 접수완료 후 출고완료된 payinfo.seqNum들 반환해서 ordReceiptData에서 필터
                ordReceiptSelect();
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Error:", error);
            });
    };

    const isInsuranceOverZero = ordReceiptData.emsInsrFee > 0;
    const boyn = isInsuranceOverZero ? 'Y' : 'N';

    // const regData = ordReceiptData.filter((apiData) => checked.includes(apiData.seqNum)).map((apiData) => ({

    const regData = ordReceiptData.filter((apiData) => checked.includes(apiData.seqNum)).map((apiData) => {
        let { address1, address2, address3 } = apiData.appRecDTO;

        // if (!address2 && !address3) {
        //     address3 = address1.slice(-20); //뒤에서부터 20자
        //     address2 = address1.slice(-40, -20); //그 앞의 20자
        //     address1 = address1.slice(0, -40); //나머지
        // } else if (!address3) {
        //     address3 = address2.slice(-20); //뒤에서부터 20자
        //     address2 = address2.slice(0, -20); //나머지
        // }

        if (!address2 && !address3) {
            address3 = address1.slice(-1); // 뒤에서부터 1자
            address2 = address1.slice(-2, -1); // 그 앞의 1자
            address1 = address1.slice(0, -2); // 나머지
        } else if (!address3) {
            address3 = address2.slice(-1); // 뒤에서부터 1자
            address2 = address2.slice(0, -1); // 나머지
        }

        const premiumcd = apiData.dvType === "D02" ? '31' : apiData.dvType === "D11" ? '32' : '14';
        const division = (apiData.dvType === "D02" || apiData.dvType === "D11") ? 'em' : 'rl'

        return {
            custno: '0004794247',
            apprno: '40326H0123',
            premiumcd: premiumcd,
            em_ee: division,
            countrycd: apiData.appRecDTO.countryDTO.countryCode,
            totweight: 3000,
            boyn: boyn,
            boprc: apiData.emsInsrFee,
            // nextdayreserveyn: 'Y',
            // reqhhmi: 1159,
            orderno: apiData.orderdtDTO.orderNumber,
            // premiumexportyn: 'N',
            // cdremark: '15156',

            sender: 'Btorage',
            senderzipcode: '12722',
            senderaddr1: '42beon-gill, 1floor, Gonjiam-eup 32-15',
            senderaddr2: 'Yeongok-gil,',
            senderaddr3: 'Gwangju-si, Gyeonggi-do',
            sendertelno1: '82',
            sendertelno2: '031',
            sendertelno3: '722',
            sendertelno4: '2210',
            sendermobile1: '82',
            sendermobile2: '010',
            sendermobile3: '3338',
            sendermobile4: '9354',
            senderemail: 'help@btorage.com',
            // snd_message: '',

            receivename: apiData.appRecDTO.recname,
            receivezipcode: apiData.appRecDTO.zipcode,
            // receiveaddr1: apiData.appRecDTO.address1,
            // receiveaddr2: apiData.appRecDTO.address2,
            // receiveaddr3: apiData.appRecDTO.address3,
            receiveaddr1: address1 || '',
            receiveaddr2: address2 || '',
            receiveaddr3: address3 || '',
            // buildnm: '',
            // receivetelno1: '81',
            // receivetelno2: apiData.appRecDTO.telnum.substring(0, 4),
            // receivetelno3: apiData.appRecDTO.telnum.substring(4, 8),
            // receivetelno4: apiData.appRecDTO.telnum.substring(8, 12),
            receivetelno: apiData.appRecDTO.telnum, //전체
            receivemail: apiData.orderdtDTO.memberDTO.id,

            orderprsnzipcd: '12722',
            orderprsnaddr1: '42beon-gill, 1floor, Gonjiam-eup 32-15, Yeongok-gil',
            orderprsnaddr2: 'Gwangju, Gyeonggi-do,',
            orderprsnnm: "Btorage",
            orderprsntelnno: '82',
            // orderprsntelfno: apiData.appRecDTO.telnum,
            // orderprsntelmno: apiData.appRecDTO.telnum,
            // orderprsntellno: apiData.appRecDTO.telnum,
            orderprsntelno: '010-3338-9354', //전체
            orderprsnhtelfno: '10',
            orderprsnhtelmno: '3338',
            orderprsnhtellno: '9354', //전체
            orderprsnhtelno: '010-3338-9354',
            orderprsnemailid: 'help@btorage.com',

            // EM_gubun: apiData.customsDTO.customItemDTO.hsCodeNm, //상품구분
            EM_gubun: "Gift", //상품구분 동적으로 맵핑해야함
            contents: apiData.customsDTO.custContent, //내용품명
            number: apiData.customsDTO.custQty, //개수
            weight: Math.round(apiData.shipInfoDTO.weight * 1000), //순중량
            value: apiData.customsDTO.custPrice, //가격
            hs_code: apiData.customsDTO.customItemDTO.hsCode, //hscode
            origin: 'KR', //생산지
            // modelno: apiData.customsDTO.custContent, // 규격

            // ecommerceyn: 'Y',
            // exportsendprsnnm: 'IM EUN WOO',
            // exportsendprsnaddr: '42beon-gill, 1floor, Gonjiam-eup 32-15',
            // bizregno: '8616100177',
            // xprtnoyn: 'N',
            // xprtno1: '',
            // xprtno2: '',
            // xprtno3: '',
            // xprtno4: '',
            // totdivsendyn1: 'N',
            // totdivsendyn2: 'N',
            // totdivsendyn3: 'N',
            // totdivsendyn4: 'N',
            // wrapcnt1: '',
            // wrapcnt2: '',
            // wrapcnt3: '',
            // wrapcnt4: '',
            //
            // skustockmgmtno: '',
            // paytypecd: '',
            // currunit: '',
            // paypprno: '',
            // dutypayprsncd: '',
            // dutypayamt: '',
            // dutypaycurr: '',
            boxlength: apiData.shipInfoDTO.wid,
            boxwidth: apiData.shipInfoDTO.ver,
            boxheight: apiData.shipInfoDTO.hei,
            currunitcd: 'USD',
            paySeqNum: apiData.seqNum, // payinfo 출고처리를 위한 필드(백엔드에서 삭제하고 시작)
    // }));
        };
    });

    const emsConnectApi = async () => {
        await axios({
            method: 'post',
            url: '/externalApi/emsReceipt',
            data: regData,
            headers: {
                'Content-Type': 'application/json',
                Authorization: LCAT
            }
        })
            .then(async (response) => {
                const data = response.data;
                for (const payinfo of data) {
                    await sendMessage(payinfo);
                    await stompClient.send("/app/updateOrderList", {}, JSON.stringify({userMbNum: payinfo.orderdtDTO.memberDTO.mbNum}));
                }
                // 재조회가 아닌 접수완료 후 출고완료된 payinfo.seqNum들 반환해서 ordReceiptData에서 필터
                // ordReceiptSelect();
                // setAlertShowEms(false);
            })
            .catch(error => {
                console.error('Error occurred:', error.response || error);
            });
    }

    const myPgLink = `https://btorage.com/UserMyPg`;
    // ${myPgLink}

    const getMessage = (lang, userName, shipco) => {
        switch (lang) {
            case "Korean":
                return `안녕하세요 ${userName}님^^*\n주문해주신 상품이 ${shipco === "국내배송" ? "국내" : "해외"}로 출발하였습니다.\n무사히 수령하실 때까지 도움드릴 일 있으시면 언제든지 문의주세요 :)\n\n저희 Btorage를 믿고 이용해주셔서 진심으로 감사드립니다 !`;
            case "Chinese":
                return `您好，${userName}^^*\n您订购的商品已${shipco === "국내배송" ? "在国内" : "在国际"}发出。\n在您安全收到商品前，如需任何帮助，请随时联系我们 :)\n\n感谢您信任并使用Btorage！`;
            case "Japanese":
                return `こんにちは、${userName}さん^^*\nご注文いただいた商品が${shipco === "국내배송" ? "国内" : "海外"}に発送されました。\n無事にお受け取りいただけるまで、何かお手伝いが必要な際はいつでもご連絡ください :)\n\nBtorageをご利用いただき、本当にありがとうございます！`;
            case "Indonesian":
                return `Halo ${userName}^^*\nBarang pesanan Anda telah dikirim ${shipco === "국내배송" ? "di dalam negeri" : "ke luar negeri"}.\nJika Anda membutuhkan bantuan sampai barang diterima dengan aman, jangan ragu untuk menghubungi kami kapan saja :)\n\nTerima kasih telah mempercayai dan menggunakan Btorage!`;
            default:
                return `Hello ${userName}^^*\nYour ordered items have been shipped ${shipco === "국내배송" ? "domestically" : "internationally"}.\nIf you need any assistance until you safely receive them, please feel free to contact us anytime :)\n\nThank you sincerely for trusting and using Btorage!`;
        }
    };

    // <a href="${myPgLink}">마이페이지 바로가기</a>
    const getHtmlMessage = (lang, userName, shipco) => {
        switch (lang) {
            case "Korean":
                return `안녕하세요 ${userName}님^^*<br>주문해주신 상품이 ${shipco === "국내배송" ? "국내" : "해외"}로 출발하였습니다.<br>무사히 수령하실 때까지 도움드릴 일 있으시면 언제든지 문의주세요 :)<br><br>저희 비토리지를 믿고 이용해주셔서 진심으로 감사드립니다 !`;

            case "Chinese":
                return `您好，${userName}^^*<br>您订购的商品已${shipco === "국내배송" ? "在国内" : "在国际"}发出。<br>在您安全收到商品前，如需任何帮助，请随时联系我们 :)<br><br>感谢您信任并使用Btorage！`;
            case "Japanese":
                return `こんにちは、${userName}さん^^*<br>ご注文いただいた商品が${shipco === "국내배송" ? "国内" : "海外"}に発送されました。<br>無事にお受け取りいただけるまで、何かお手伝いが必要な際はいつでもご連絡ください :)<br><br>Btorageをご利用いただき、本当にありがとうございます！`;
            case "Indonesian":
                return `Halo ${userName}^^*<br>Barang pesanan Anda telah dikirim ${shipco === "국내배송" ? "di dalam negeri" : "ke luar negeri"}.<br>Jika Anda membutuhkan bantuan sampai barang diterima dengan aman, jangan ragu untuk menghubungi kami kapan saja :)<br><br>Terima kasih telah mempercayai dan menggunakan Btorage!`;
            default:
                return `Hello ${userName}^^*<br>Your ordered items have been shipped ${shipco === "국내배송" ? "domestically" : "internationally"}.<br>If you need any assistance until you safely receive them, please feel free to contact us anytime :)<br><br>Thank you sincerely for trusting and using Btorage!`;
        }
    };

    const sendMessage = async (data) => {
        const userName = data?.orderdtDTO?.memberDTO?.userName;
        const lang = data?.orderdtDTO?.memberDTO?.lang;
        const shipco = data?.orderdtDTO.shipco;
        const chatSendLang = getMessage(lang, userName, shipco);
        const emailSendLang = getHtmlMessage(lang, userName, shipco);

        if (stompClient && chatSendLang.trim() !== '' && emailSendLang.trim() !== '') {
            const chatMessage = {
                chatKey: chatSendLang,
                fileNm: null,
                chatOrdNum: Number(data?.orderdtDTO.orderNumber),
                mbNum: adminInfo.mbNum,
                mstMbNum: data?.orderdtDTO?.memberDTO?.mbNum,
                mstUserNm: userName,
                email: data?.orderdtDTO?.memberDTO?.id,
                sendMailFl: true,
                autoChatFl: true,
                lang: lang,
                process: "PS3",
                emailKey: emailSendLang
            };
            await stompClient.send("/app/chat/adminMessage", {}, JSON.stringify(chatMessage));
        } else {
            console.log("메시지 전송실패");
        }
    }

    // region Excel

    const date = new Date();
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const DateViewValue = year + month + day;

    const excelDown = (excelType) => {
        try {
            const selectedRows = ordReceiptData.filter((item) => checked.includes(item.seqNum));

            const isAllRowsMatch = selectedRows.every(item => item.orderdtDTO.shipco === excelType);
            if (!isAllRowsMatch) {
                alert(`[${excelType}] ※선택된 배송사를 확인해주세요.`);
                return;
            }

            const excel = require('exceljs');
            const workbook = new excel.Workbook();
            workbook.created = new Date();
            workbook.modified = new Date();

            function mapDataToExcelRowDHL(item) {
                return {
                    'Transaction ID*': item.orderdtDTO?.orderNumber,
                    'Reference Notes / Order ID* (35)': item.orderdtDTO.orderNumber,
                    'Pre-allocate tracking number': '',
                    'Recipient Name* (35)': item.appRecDTO?.recname,
                    'Country Code* (2)': item.appRecDTO?.countryDTO?.countryCode
                };
            }

            function mapDataToExcelRowFEDEX(item) {
                let serviceType;
                const countryCode = item.appRecDTO.countryDTO.countryCode;
                if (countryCode === 'US' || countryCode === 'CA' || countryCode === 'MX') {
                    serviceType = '2P';
                } else {
                    serviceType = 'EC';
                }

                let packingType;
                const fedexPack = item.shipInfoDTO.fedexPack;
                if (fedexPack === '1') {
                    packingType = '2';
                } else {
                    packingType = '1';
                }

                let address1 = item.appRecDTO.address1.substring(0, 35);
                let address2 = item.appRecDTO.address2.substring(0, 35);
                let address3 = item.appRecDTO.address3.substring(0, 35);
                const city = item.appRecDTO.city.substring(0, 35);

                if (item.appRecDTO.address1.length > 35) {
                    const remainingChars = 35 - address1.length;
                    address1 = address1 + item.appRecDTO.address1.substring(35, 35 + remainingChars);
                }
                if (item.appRecDTO.address2.length > 35) {
                    const remainingChars = 35 - address2.length;
                    address2 = address2 + item.appRecDTO.address2.substring(35, 35 + remainingChars);
                }
                if (item.appRecDTO.address3.length > 35) {
                    const remainingChars = 35 - address3.length;
                    address3 = address3 + item.appRecDTO.address3.substring(35, 35 + remainingChars);
                }

                return {
                    '주문번호키': item.orderdtDTO?.orderNumber,
                    '주문번호': item.orderdtDTO?.orderNumber,
                    'TaxID': item.appRecDTO.taxid,
                    '수취인성함': item.appRecDTO.recname,
                    '수취인주소1': address1,
                    '수취인주소2': address2,
                    '상세주소': address3,
                    '도시명': city,
                    '국가코드': item.appRecDTO.countryDTO.countryCode,
                    '우편번호': item.appRecDTO.zipcode,
                    '연락처': item.appRecDTO.telnum,
                    '이메일': item.orderdtDTO.memberDTO.id,  //소진이는 이메일 값 지우기 ★★★
                    '내용품명': item.customsDTO.custContent,
                    'hscode': item.customsDTO.customItemDTO.hsCode,
                    '수량': item.customsDTO.custQty,
                    'KR': 'KR',
                    '금액': item.customsDTO.custPrice,
                    '무게1': item.shipInfoDTO.weight,
                    'PCS': 'PCS',
                    '1carton': '1',
                    '무게': item.shipInfoDTO.weight,
                    '길이': item.shipInfoDTO.ver,
                    '가로': item.shipInfoDTO.wid,
                    '세로': item.shipInfoDTO.hei,
                    '서비스종류': serviceType,
                    '포장재종류': packingType
                };
            }

            const specialHeaders = [
                'Transaction ID*'
                , 'Reference Notes / Order ID* (35)'
                , '수취인성함* (35)'
                , '수취인주소 1* (35)'
                , '수취인주소 2* (35)'
                , '도시명* (35)'
                , '국가코드* (2)'
                , '우편번호* (10)'
                , '전화번호* (15)'
                , '내용품명* (148)'
                , '수량* (7)'
                , '제조국가* (2)'
                , '전화번호* (15)'
                , '단가* (15)'
                , '단위* (3)'
                , '패키지수* (4)'
                , '택배무게* (13)'
                , '"서비스종류*\n' +
                '1) IP\n' +
                '3) IE\n' +
                '6) Int\'l First\n' +
                '17) IED\n' +
                '18) IPD\n' +
                '70) IPF\n' +
                '84) IDF\n' +
                '86) IEF"'
                , '"포장재*\n' +
                '01 - Other\n' +
                '02 - FedEx Pak\n' +
                '03 - FedEx Box\n' +
                '04 - FedEx Tube\n' +
                '06 - Envelope "'
            ];

            const specialHeaderStyle = {
                fill: {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{ argb:'FFFF0000' }
                }
            };

            const sheetConfigs = {
                'DHL': {
                    name: 'DHL',
                    columns: ['DHL'],
                },
                'FedEx': {
                    name: 'FedEx',
                    columns: [
                        {header: 'Transaction ID*', key: '주문번호키', hidden: false, height: 15,  width: 22},
                        {header: 'Reference Notes / Order ID* (35)', key: '주문번호', width: 22},
                        {header: 'Preassign Tracking Number', key: '', width: 0},
                        {header: 'Booking Number', key: '', width: 0},
                        {header: 'Recipient IRS/EIN Number', key: 'TaxID', width: 20},
                        {header: '수취인성함* (35)', key: '수취인성함', width: 20},
                        {header: 'Recipient Company Name (35)', key: '', width: 0},
                        {header: '수취인주소 1* (35)', key: '수취인주소1', width: 30},
                        {header: '수취인주소 2* (35)', key: '수취인주소2', width: 30},
                        {header: 'Recipient Address Line 3 (35) ) - v13', key: '상세주소', width: 30},
                        {header: '도시명* (35)', key: '도시명', width: 15},
                        {header: 'Shipping Contents / Product Code (20)', key: '', width: 0},
                        {header: '국가코드* (2)', key: '국가코드', width: 12},
                        {header: '우편번호* (10)', key: '우편번호', width: 12},
                        {header: '전화번호* (15)', key: '연락처', width: 15},
                        {header: 'Recipient Tel Ext. (6) - v13', key: '', width: 0},
                        {header: 'Recipient Email (60)', key: '이메일', width: 22},
                        {header: '내용품명* (148)', key: '내용품명', width: 25},
                        {header: 'HS CODE (20)', key: 'hscode', width: 13},
                        {header: '수량* (7)', key: '수량', width: 12},
                        {header: '제조국가* (2)', key: 'KR', width: 12},
                        {header: '단가* (15)', key: '금액', width: 10},
                        {header: 'Commodity Unit Weight (16)', key: '무게1', width: 12},
                        {header: 'Invoice Number (20)', key: '', width: 0},
                        {header: 'CI Comments (300)', key: '', width: 0},
                        {header: 'CI Freight Charge (11)', key: '', width: 0},
                        {header: '단위* (3)', key: 'PCS', width: 9},
                        {header: '패키지수* (4)', key: '1carton', width: 12},
                        {header: '택배무게* (13)', key: '무게', width: 12},
                        {header: 'length', key: '길이', width: 12},
                        {header: 'width', key: '가로', width: 12},
                        {header: 'height', key: '세로', width: 12},
                        {header: '"서비스종류*\n' +
                                '1) IP\n' +
                                '3) IE\n' +
                                '6) Int\'l First\n' +
                                '17) IED\n' +
                                '18) IPD\n' +
                                '70) IPF\n' +
                                '84) IDF\n' +
                                '86) IEF"', key: '서비스종류', width: 20},
                        {header: '"포장재*\n' +
                                '01 - Other\n' +
                                '02 - FedEx Pak\n' +
                                '03 - FedEx Box\n' +
                                '04 - FedEx Tube\n' +
                                '06 - Envelope "', key: '포장재종류', width: 20}
                    ],
                }
            };

            const borderHeaderStyle = {
                top: { style: 'thin', color: { argb: 'FF000000' } },
                left: { style: 'thin', color: { argb: 'FF000000' } },
                bottom: { style: 'thin', color: { argb: 'FF000000' } },
                right: { style: 'thin', color: { argb: 'FF000000' } }
            };
            const borderBodyStyle = {
                top: { style: 'thin', color: { argb: 'FF000000' } },
                left: { style: 'thin', color: { argb: 'FF000000' } },
                bottom: { style: 'thin', color: { argb: 'FF000000' } },
                right: { style: 'thin', color: { argb: 'FF000000' } }
            };

            const applyBordersToRow = (row, border) => {
                row.eachCell({ includeEmpty: true }, (cell) => {
                    cell.border = border;
                });
            };

            const applyStylesToHeader = (sheet, height) => {
                const headerRow = sheet.getRow(1);
                headerRow.font = { name: '맑은 고딕', bold: true, size: 10 };
                headerRow.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
                headerRow.height = height;


                headerRow.eachCell((cell, number) => {
                    if (specialHeaders.includes(sheet.columns[number - 1].header)) {
                        cell.style = {...cell.style, ...specialHeaderStyle};
                    }
                });
                applyBordersToRow(headerRow, borderHeaderStyle);
            };

            const createSheet = (name, columns) => {
                const sheet = workbook.addWorksheet(name);
                sheet.columns = columns;
                applyStylesToHeader(sheet, 80);
                return sheet;
            };

            const addRowsToSheet = (sheet, rows, border) => {
                rows.forEach((item, index) => {
                    const row = sheet.addRow(item);
                    for (let i = 1; i <= sheet.columns.length; i++) {
                        row.getCell(i).font = { name: '맑은 고딕', size: 10 };
                    }
                    applyBordersToRow(row, border);
                });
            };

            let transformedSelectedRows;
            switch (excelType) {
                case 'DHL':
                    transformedSelectedRows = selectedRows.map(mapDataToExcelRowDHL);
                    break;
                case 'FedEx':
                    transformedSelectedRows = selectedRows.map(mapDataToExcelRowFEDEX);
                    break;
                default:
                    throw new Error(`지원하지 않는 배송사: ${excelType}`);
            }

            if (sheetConfigs[excelType]) {
                const sheet = createSheet(sheetConfigs[excelType].name, sheetConfigs[excelType].columns);
                applyStylesToHeader(sheet, 80, excelType);
                addRowsToSheet(sheet, transformedSelectedRows, borderBodyStyle);
            }

            let fileName = `${excelType}_receipt_${DateViewValue}.xlsx`;
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = fileName;
                anchor.click();
                window.URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.error(error);
        }
    };

    const excelDownPostOffice = (excelType) => {
        try {
            const selectedRows = ordReceiptData.filter((item) => checked.includes(item.seqNum));

            const isAllRowsMatch = selectedRows.every(item => item.orderdtDTO.shipco === excelType);
            if (!isAllRowsMatch) {
                alert(`[${excelType}] ※선택된 배송사를 확인해주세요.`);
                return;
            }

            const excel = require('exceljs');
            const workbook = new excel.Workbook();
            workbook.created = new Date();
            workbook.modified = new Date();

            function mapDataToExcelRowEMS(item) {

                const maxLengthAddress1 = 200;

                let address1 = item.appRecDTO.address1.substring(0, maxLengthAddress1);

                let address2 = '';
                let address3 = '';

                if (item.appRecDTO.address1.length > maxLengthAddress1) {
                    const remainingChars = item.appRecDTO.address1.length - maxLengthAddress1;
                    address2 = item.appRecDTO.address1.substring(maxLengthAddress1, maxLengthAddress1 + remainingChars);
                    address1 = address1.substring(0, maxLengthAddress1);
                }

                // let address1 = item.appRecDTO.address1.substring(0, 200);
                // let address2 = item.appRecDTO.address2.substring(0, 50);
                // let address3 = item.appRecDTO.address3.substring(0, 50);
                const city = item.appRecDTO.city.substring(0, 50);
                //
                // if (item.appRecDTO.address2.length > 50) {
                //     const remainingChars = 50 - address2.length;
                //     address3 = item.appRecDTO.address2.substring(50, 50 + remainingChars) + address3;
                //     address2 = address2 + item.appRecDTO.address2.substring(0, remainingChars);
                // }

                return {
                    '상품구분': 'Gift',
                    '수취인명': item.appRecDTO.recname,
                    '수취인전화': item.appRecDTO.telnum,
                    '수취인국가코드': item.appRecDTO.countryDTO.countryCode,
                    '수취인우편번호': item.appRecDTO.zipcode,
                    '수취인상세주소': address1,
                    '수취인주소1': address2,
                    '수취인주소2': address3 + city,
                    '실중량1': Math.round(item.shipInfoDTO.weight * 1000),
                    '내용품명': item.customsDTO.custContent,
                    '개수': item.customsDTO.custQty,
                    '실중량2': Math.round(item.shipInfoDTO.weight * 1000),
                    '가격': item.customsDTO.custPrice,
                    '통화단위': 'USD',
                    'HSCODE': item.customsDTO.customItemDTO.hsCode,
                    '생산지': 'KR',
                    'E': 'E',
                    'em': 'em',
                    '가로': item.shipInfoDTO.ver,
                    '세로': item.shipInfoDTO.wid,
                    '높이': item.shipInfoDTO.hei,
                };
            }

            const specialHeaders = [
                '상품구분\n' +
                '(Sample-샘플)\n' +
                '(Gift-선물)\n' +
                '(Merchandise-상품)\n' +
                '(Document-서류)'
                , '수취인명\n' +
                '(35자이내 공백포함)'
                , '수취인국가코드'
                , '수취인우편번호\n' +
                '(숫자,문자 가능\n' +
                '17자이내)'
                , '수취인 상세주소\n' +
                '(200byte 이내)'
                , '수취인\n' +
                '시/군/구(예시) 주소\n' +
                '(50byte 이내)'
                , '수취인\n' +
                '주/도/시(예시) 주소\n' +
                '(50byte 이내)'
                , '박스포장\n' +
                '실 중량(g)'
                , '내용품명\n' +
                '(영문 32자이내 \n' +
                '공백포함)'
                , 'EMS : E\n' +
                'EMS 프리미엄 : P\n' +
                'K-Packet : K\n' +
                '등기소형 :R'
                , 'EMS 비서류 : em,    \n' +
                ' EMS 서류 : ee,\n' +
                ' K-Packet : rl, \n' +
                '소형포장물 : re'
                , '가로(cm)'
                , '세로(cm)'
                , '높이(cm)'
            ];

            const specialHeaderStyle = {
                fill: {
                    type: 'pattern',
                    pattern:'solid',
                    fgColor:{ argb:'FFFF0000' }
                }
            };

            const sheetConfigs = {
                'EMS': {
                    name: 'EMS',
                    columns: [
                        {header: '상품구분\n' +
                                '(Sample-샘플)\n' +
                                '(Gift-선물)\n' +
                                '(Merchandise-상품)\n' +
                                '(Document-서류)', key: '상품구분', hidden: false, width: 20},
                        {header: '수취인명\n' +
                                '(35자이내 공백포함)', key: '수취인명', width: 33},
                        {header: '수취인EMAIL', key: '', width: 0},
                        {header: '수취인전화\n' +
                                '국가번호\n' +
                                '(숫자4자리)', key: '', width: 0},
                        {header: '수취인전화\n' +
                                '지역번호\n' +
                                '(숫자4자리)', key: '', width: 0},
                        {header: '수취인전화\n' +
                                '국번\n' +
                                '(숫자4자리)', key: '', width: 0},
                        {header: '수취인전화\n' +
                                '전화번호\n' +
                                '(숫자4자리)', key: '', width: 0},
                        {header: '수취인전화\n' +
                                '(숫자, - 허용)', key: '수취인전화', width: 14},
                        {header: '수취인국가코드', key: '수취인국가코드', width: 14},
                        {header: '수취인국가명\n' +
                                '(영문국가명 국가코드 확실\n' +
                                '시  생략가능) ', key: '', width: 0},
                        {header: '수취인우편번호\n' +
                                '(숫자,문자 가능\n' +
                                '17자이내)', key: '수취인우편번호', width: 14},
                        {header: '수취인 상세주소\n' +
                                '(200byte 이내)', key: '수취인상세주소', width: 38},
                        {header: '수취인\n' +
                                '시/군/구(예시) 주소\n' +
                                '(50byte 이내)', key: '수취인주소1', width: 35},
                        {header: '수취인\n' +
                                '주/도/시(예시) 주소\n' +
                                '(50byte 이내)', key: '수취인주소2', width: 35},
                        {header: '수취인 건물명\n' +
                                '미국행 K-Packet 이용할 경우만 \n' +
                                '입력가능\n' +
                                '( 영문 공백포함 90자 이내 )', key: '', width: 0},
                        {header: '박스포장\n' +
                                '실 중량(g)', key: '실중량1', width: 11},
                        {header: '내용품명\n' +
                                '(영문 32자이내 \n' +
                                '공백포함)', key: '내용품명', width: 18},
                        {header: '개수', key: '개수', width: 8},
                        {header: '순중량(g)', key: '실중량2', width: 11},
                        {header: '가격', key: '가격', width: 8},
                        {header: '통화단위', key: '통화단위', width: 10},
                        {header: 'HSCODE\n' +
                                '(숫자만 10자리)', key: 'HSCODE', width: 15},
                        {header: '생산지', key: '생산지', width: 8},
                        {header: '규격', key: '우편번호', width: 0},
                        {header: '보험가입여부\n' +
                                '(Y/N)', key: '우편번호', width: 12},
                        {header: '보험가입금액\n' +
                                '(물품가액을\n' +
                                ' 기입 원\\)', key: '우편번호', width: 12},
                        {header: 'EMS : E\n' +
                                'EMS 프리미엄 : P\n' +
                                'K-Packet : K\n' +
                                '등기소형 :R', key: 'E', width: 15},
                        {header: 'EMS 비서류 : em,    \n' +
                                ' EMS 서류 : ee,\n' +
                                ' K-Packet : rl, \n' +
                                '소형포장물 : re', key: 'em', width: 15},
                        {header: '1', key: '', width: 0},
                        {header: '2', key: '', width: 0},
                        {header: '3', key: '', width: 0},
                        {header: '4', key: '', width: 0},
                        {header: '5', key: '', width: 0},
                        {header: '6', key: '', width: 0},
                        {header: '7', key: '', width: 0},
                        {header: '8', key: '', width: 0},
                        {header: '9', key: '', width: 0},
                        {header: '10', key: '', width: 0},
                        {header: '11', key: '', width: 0},
                        {header: '12', key: '', width: 0},
                        {header: '13', key: '', width: 0},
                        {header: '14', key: '', width: 0},
                        {header: '15', key: '', width: 0},
                        {header: '16', key: '', width: 0},
                        {header: '17', key: '', width: 0},
                        {header: '18', key: '', width: 0},
                        {header: '19', key: '', width: 0},
                        {header: '20', key: '', width: 0},
                        {header: '21', key: '', width: 0},
                        {header: '22', key: '', width: 0},
                        {header: '23', key: '', width: 0},
                        {header: '24', key: '', width: 0},
                        {header: '25', key: '', width: 0},
                        {header: '26', key: '', width: 0},
                        {header: '27', key: '', width: 0},
                        {header: '28', key: '', width: 0},
                        {header: '29', key: '', width: 0},
                        {header: '30', key: '', width: 0},
                        {header: '31', key: '', width: 0},
                        {header: '32', key: '', width: 0},
                        {header: '33', key: '', width: 0},
                        {header: '34', key: '', width: 0},
                        {header: '가로(cm)', key: '가로', width: 10},
                        {header: '세로(cm)', key: '세로', width: 10},
                        {header: '높이(cm)', key: '높이', width: 10},
                    ],
                }
            };

            const borderHeaderStyle = {
                top: { style: 'thin', color: { argb: 'FF000000' } },
                left: { style: 'thin', color: { argb: 'FF000000' } },
                bottom: { style: 'thin', color: { argb: 'FF000000' } },
                right: { style: 'thin', color: { argb: 'FF000000' } }
            };

            const applyBordersToRow = (row, border) => {
                row.eachCell({ includeEmpty: true }, (cell) => {
                    cell.border = border;
                });
            };

            const applyStylesToHeader = (sheet, height) => {
                for (let i = 2; i <= 5; i++) {
                    const invisibleRow = sheet.getRow(i);
                    invisibleRow.height = 0.5;
                }

                const headerRow = sheet.getRow(1);
                headerRow.font = { name: '맑은 고딕', bold: true, size: 10 };
                headerRow.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
                headerRow.height = height;

                headerRow.eachCell((cell, number) => {
                    if (specialHeaders.includes(sheet.columns[number - 1].header)) {
                        cell.style = { ...cell.style, ...specialHeaderStyle };
                    }
                });
                applyBordersToRow(headerRow, borderHeaderStyle);
            };

            const createSheet = (name, columns) => {
                const sheet = workbook.addWorksheet(name);
                sheet.columns = columns;
                applyStylesToHeader(sheet, 80);
                return sheet;
            };

            const addRowsToSheet = (sheet, rows, border) => {
                rows.forEach((item, index) => {
                    const row = sheet.addRow(item);
                    for (let i = 1; i <= sheet.columns.length; i++) {
                        row.getCell(i).font = { name: '맑은 고딕', size: 10 };
                    }
                    applyBordersToRow(row, border);
                });
            };

            let transformedSelectedRows;
            transformedSelectedRows = selectedRows.map(mapDataToExcelRowEMS);

            if (sheetConfigs) {
                const sheet = createSheet(sheetConfigs['EMS'].name, sheetConfigs['EMS'].columns);
                applyStylesToHeader(sheet, 80);
                addRowsToSheet(sheet, transformedSelectedRows, borderHeaderStyle);
            }

            let fileName = `EMS_receipt_${DateViewValue}.xlsx`;
            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = fileName;
                anchor.click();
                window.URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.error(error);
        }
    };

    // endregion

    /** endregion */

    return (
        <div className="AdminReceiptList font-t-cont">
            <div className="admin-wrap-card text-center">
                <div className="Receipt-title"><p className="font-c-default font-t-title font-s-25">Receipt List</p></div>
                <div style={{padding: "0 1rem 1rem 1rem"}}>
                    <div className="admin-event-wrap m-b-15">
                        <div className="admin-event-wrap-btn">
                            <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                            <div style={{marginRight: "15px"}}>
                                <select className="admin-combo p-l-7 font-s-14" style={{ width: "110px", height: "1.78rem"}} onChange={(e) => setSelectedShipCompany(e.target.value)}>
                                    <option value="">배송사선택</option>
                                    <option value="D01">FedEx</option>
                                    <option value="D02">EMS</option>
                                    <option value="D03">Sea</option>
                                    <option value="D04">DHL</option>
                                    <option value="D11">UPS</option>
                                    <option value="D13">K-Packet</option>
                                    <option value="D14">Small-Packet</option>
                                    <option value="D00">국내배송</option>
                                </select>
                            </div>
                        </div>
                        <div className="admin-event-wrap-btn gap7">
                            <label className="font-s-13 m-t-8 m-r-3">출고접수 양식 다운로드</label>
                            <button className="admin-btn-B wd-100 ht-30 font-s-13 btn-click-event" onClick={() => excelDown('FedEx')} >EXCEL[FedEx]</button>
                            <button className="admin-btn-B wd-100 ht-30 font-s-13 btn-click-event" onClick={() => excelDownPostOffice('UPS')} >EXCEL[UPS]</button>
                            <button className="admin-btn-B wd-100 ht-30 font-s-13 btn-click-event" onClick={() => excelDownPostOffice('EMS')} >EXCEL[EMS]</button>
                        </div>
                    </div>
                    <div className="div-tb m-b-20">
                        <div className="div-tb-head ht-90">
                            <div style={{width: "45px", textAlign: "right", paddingRight: "5px"}}>
                                <input className="admin-input-chkBox m-0-a" type={"checkbox"} checked={checked.length === ordReceiptData.length} onChange={allcheckBoxChange}/>
                            </div>
                            <div style={{width: "215px"}}><p>주문번호</p></div>
                            <div style={{width: "170px"}}>
                                <p>고객명</p>
                                <p>수취인명</p>
                            </div>
                            <div style={{width: "120px"}}>
                                <p>국가</p>
                                <p>상세주소</p>
                            </div>
                            <div style={{width: "100px"}}>
                                <p>총상품수량</p>
                                <p>상품상세</p>
                            </div>
                            <div style={{width: "120px"}}><p>총상품금액</p></div>
                            <div style={{width: "150px"}}>
                                <p>배송사</p>
                                <p>운송장번호</p>
                                <p>운임료</p>
                            </div>
                            <div style={{width: "250px"}}>
                                <p>세관신고금액</p>
                                <p>내용품명</p>
                            </div>
                            <div style={{width: "180px", paddingTop: "7px"}}>
                                <p>부피중량</p>
                                <p>적용중량</p>
                                <p>배송비용(&#8361;)</p>
                            </div>
                        </div>
                        {ordReceiptData.filter((cData) => selectedShipCompany === '' || cData.dvType === selectedShipCompany).map((rData, i) => {
                            let totalQty = 0;
                            let totalPrice = 0;

                            if (rData.packListDTO && rData.packListDTO.productsDTO) {
                                rData.packListDTO.productsDTO.forEach(product => {
                                    totalQty += product.quantity;
                                    totalPrice += (product.price + product.mallShipPrice);
                                });
                            }

                            return (
                                <div key={i} className="div-tb-body ht-110" style={{position: "relative"}}>
                                    <div style={{width: "45px", textAlign: "right", paddingRight: "5px"}}>
                                        <input className="admin-input-chkBox m-0-a" type={"checkbox"} checked={checked.includes(rData.seqNum)} onChange={() => checkBoxChange(rData.seqNum, rData.dvType)}/>
                                    </div>
                                    <div style={{width: "215px"}}><p className="font-b">{rData.orderdtDTO.orderNumber} - {rData.shipInfoDTO.boxNum}</p></div>
                                    <div style={{width: "170px"}}>
                                        <p>{rData.orderdtDTO.memberDTO.userName}</p>
                                        <p className="font-b">{rData.appRecDTO.recname}</p>
                                    </div>
                                    <div style={{width: "120px"}}>
                                        <p>{rData.appRecDTO.countryDTO.countryNm + " [" + rData.appRecDTO.countryDTO.countryCode + "]"}</p>
                                        <p>
                                            <button onClick={() => toggleMenu(rData.seqNum, 'rec')} className="admin-btn-basic wd-40 ht-25 m-t-3"
                                                    style={{position: "relative", backgroundColor: recToggleSt[rData.seqNum] ? "rgb(255,203,109)" : "", border: recToggleSt[rData.seqNum] ? "none" : ""}}>
                                                {recToggleSt[rData.seqNum] ?
                                                    <RiArrowUpSFill style={{fontSize: "20px", marginBottom: "1px"}}/> :
                                                    <RiArrowDownSFill style={{fontSize: "20px", marginBottom: "1px"}}/>
                                                }
                                            </button>
                                            {recToggleSt[rData.seqNum] && (
                                                <div ref={menuRef} style={{
                                                    position: "absolute",
                                                    width: "550px",
                                                    top: "-148%",
                                                    left: 280,
                                                    height: "auto",
                                                    backgroundColor: "#ffffff",
                                                    padding: "7px",
                                                    zIndex: 10,
                                                    border: "solid 1px #dee2e6",
                                                    borderRadius: "7px",
                                                    boxShadow: "2px 2px 10px -5px"
                                                }}>
                                                    <div className="dt-box-cont">
                                                        <div style={{display: "flex", flexDirection: "row", height: "35px"}}>
                                                            <div style={{width: "20%", backgroundColor: "#FFF7EA"}}><p className="font-b">우편번호</p></div>
                                                            <div style={{width: "30%"}}><p className="font-b">{rData.appRecDTO.zipcode}</p></div>
                                                            <div style={{width: "20%", backgroundColor: "#FFF7EA"}}><p className="font-b">도시명</p></div>
                                                            <div style={{width: "30%"}}><p>{rData.appRecDTO.city}</p></div>
                                                        </div>
                                                        <div style={{display: "flex", flexDirection: "row", height: "35px"}}>
                                                            <div style={{width: "20%", backgroundColor: "#FFF7EA",}}><p className="font-b">주소1</p></div>
                                                            <div style={{width: "80%"}}><p  className="font-b" style={{marginLeft: "15px"}}>{rData.appRecDTO.address1}</p></div>
                                                        </div>
                                                        <div style={{display: "flex", flexDirection: "row", height: "35px"}}>
                                                            <div style={{width: "20%", backgroundColor: "#FFF7EA"}}><p className="font-b">주소2</p></div>
                                                            <div style={{width: "80%"}}><p  className="font-b" style={{marginLeft: "15px"}}>{rData.appRecDTO.address2}</p></div>
                                                        </div>
                                                        <div style={{display: "flex", flexDirection: "row", height: "35px"}}>
                                                            <div style={{width: "20%", backgroundColor: "#FFF7EA"}}><p className="font-b">주소3</p></div>
                                                            <div style={{width: "80%"}}><p  className="font-b" style={{marginLeft: "15px"}}>{rData.appRecDTO.address3}</p></div>
                                                        </div>
                                                        <div style={{display: "flex", flexDirection: "row", height: "35px"}}>
                                                            <div style={{width: "20%", backgroundColor: "#FFF7EA"}}><p className="font-b">연락처</p></div>
                                                            <div style={{width: "30%"}}><p>{rData.appRecDTO.telnum}</p></div>
                                                            <div style={{width: "20%", backgroundColor: "#FFF7EA"}}><p className="font-b">Tax ID</p></div>
                                                            <div style={{width: "30%"}}><p>{rData.appRecDTO.taxid}</p></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </p>
                                    </div>
                                    <div style={{width: "100px"}}>
                                        <p>{totalQty}개</p>
                                        <p>
                                            <button onClick={() => toggleMenu(rData.seqNum, 'prod')} className="admin-btn-basic wd-40 ht-25 m-t-3"
                                                    style={{position: "relative", backgroundColor: prodToggleSt[rData.seqNum] ? "rgb(255,203,109)" : "", border: prodToggleSt[rData.seqNum] ? "none" : ""}}>
                                                {prodToggleSt[rData.seqNum] ?
                                                    <RiArrowUpSFill style={{fontSize: "20px", marginBottom: "1px"}}/> :
                                                    <RiArrowDownSFill style={{fontSize: "20px", marginBottom: "1px"}}/>
                                                }
                                            </button>
                                            {prodToggleSt[rData.seqNum] && (
                                                <div ref={menuRef} style={{
                                                    position: "absolute",
                                                    width: "500px",
                                                    top: "-178%",
                                                    left: 440,
                                                    height: "223px",
                                                    backgroundColor: "#ffffff",
                                                    padding: "7px",
                                                    zIndex: 10,
                                                    border: "solid 1px #dee2e6",
                                                    borderRadius: "7px",
                                                    boxShadow: "1px 2px 10px -5px"
                                                }}>
                                                    <div className="dt-box-cont" style={{display: "table", height: "222px"}}>
                                                        <div style={{display: "flex", flexDirection: "row", height: "35px", borderTopRightRadius: "5px", borderTopLeftRadius: "5px", backgroundColor: "#FFF7EA"}}>
                                                            <div style={{width: "85%"}}><p className="font-b">상품명</p></div>
                                                            <div style={{width: "15%"}}><p className="font-b">수량</p></div>
                                                        </div>
                                                        <div className="sub-scroll" style={{height: "170px", overflowY: "auto"}}>
                                                        {rData.packListDTO.productsDTO.map((product, i) => (
                                                            <div key={i} style={{display: "flex", flexDirection: "row"}}>
                                                                <div className="wd-85% ht-100pc"><p>{product.name}</p></div>
                                                                <div style={{width: "15%", height: "35px"}}><p>{product.quantity}</p></div>
                                                            </div>
                                                        ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </p>
                                    </div>
                                    <div style={{width: "120px"}}><p>{'\u20A9'} {totalPrice.toLocaleString()}</p></div>
                                    <div style={{width: "150px"}}>
                                        <p className="font-b">
                                            {rData.dvType === "D00" ? "국내배송" :
                                                rData.dvType === "D01" ? "FedEx" :
                                                rData.dvType === "D02" ? "EMS" :
                                                    rData.dvType === "D03" ? "Sea" :
                                                        rData.dvType === "D04" ? "DHL" :
                                                            rData.dvType === "D11" ? "UPS" :
                                                                rData.dvType === "D13" ? "K-Packet" :
                                                                    rData.dvType === "D14" ? "Small-Packet" :
                                                            "N/A"}
                                        </p>
                                        <p>
                                            <input className="admin-input-line wd-120 ht-25 font-b font-c-progress font-s-12 text-center m-t-3 m-b-5" type="text" maxLength="16"
                                                   value={rData.shipInfoDTO.outWayNum || ''} placeholder="운송장번호"
                                                   onChange={(e) => handleInputWaybillChange(e, rData.seqNum)}
                                            />
                                        </p>
                                        <p>
                                            <input key={i} className="admin-input-line wd-120 ht-25 text-right p-r-5" type="text" placeholder="0" maxLength="8"
                                                value={rData.shipInfoDTO.shipPrice || ''}
                                                onChange={(e) => handleInputPriceChange(e, rData.seqNum)}
                                            />
                                        </p>
                                    </div>
                                    <div style={{width: "250px"}}>
                                        {/*<p>{rData.customsDTO.custPrice}$</p>*/}
                                        <p style={{ color: rData.customsDTO.custPrice > 800 ? "red" : ""}}>{rData.customsDTO.custPrice}$</p>
                                        <p>{rData.customsDTO.custContent}</p>
                                    </div>
                                    <div style={{width: "180px"}}>
                                        <p>{rData.shipInfoDTO.wid} x {rData.shipInfoDTO.ver} x {rData.shipInfoDTO.hei} - {rData.shipInfoDTO.weight}kg</p>
                                        <p className="font-b" style={{color: "#276AB7"}}>
                                            {rData.dvType === "D00" ? rData.shipInfoDTO.domeApyWeight :
                                                rData.dvType === "D01" ? rData.shipInfoDTO.fedexApyWeight :
                                                    rData.dvType === "D02" ? rData.shipInfoDTO.emsApyWeight :
                                                        rData.dvType === "D03" ? rData.shipInfoDTO.seaApyWeight :
                                                                rData.dvType === "D11" ? rData.shipInfoDTO.upsApyWeight :
                                                                    rData.dvType === "D13" ? rData.shipInfoDTO.kpackApyWeight :
                                                                        rData.dvType === "D14" ? rData.shipInfoDTO.airSApyWeight :
                                                                            0}

                                            {rData.shipInfoDTO.domeApyWeights}kg
                                        </p>
                                        <p className="font-b" style={{color: "#FFAF34"}}>
                                            {'\u20A9'} {rData.dvType === "D00" ? rData.ttDomPrice.toLocaleString() :
                                                rData.dvType === "D01" ? rData.ttFedexPrice.toLocaleString() :
                                                rData.dvType === "D02" ? rData.ttEmsPrice.toLocaleString() :
                                                    rData.dvType === "D03" ? rData.ttSeaPrice.toLocaleString() :
                                                            rData.dvType === "D11" ? rData.ttUpsPrice.toLocaleString() :
                                                                rData.dvType === "D13" ? rData.ttKPackPrice.toLocaleString() :
                                                                    rData.dvType === "D14" ? rData.ttAirSPrice.toLocaleString() :
                                                        0}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="text-right">
                        <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event m-r-7" onClick={alertTariff}>세관내용수정</button>
                        <button className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event m-r-7" onClick={alertWaybill}>수동출고</button>
                        <button className="admin-btn-C wd-100 ht-30 font-s-13 btn-click-event m-r-7" onClick={alertPostOfficeApi}>EMS API</button>
                        <button className="admin-btn-C wd-100 ht-30 font-s-13 btn-click-event m-r-7" onClick={alertPostOfficeApi}>UPS API</button>
                        <button className="admin-btn-C wd-100 ht-30 font-s-13 btn-click-event" onClick={alertPostOfficeApi}>K-P API</button>
                    </div>
                </div>
            </div>
            {isLoading && <CommonLoading />}
            {alertShow === true ? <MsgSelectModal alertShow={alertShow} eventHandle1={modalCancel1} eventHandle2={outWaybillUpdate} msgData1={msgConfirmData1} msgData2={msgConfirmData2}/> : null}
            {alertShowEms === true ? <MsgSelectModal alertShow={alertShowEms} eventHandle1={modalCancelEms} eventHandle2={emsConnectApi} msgData1={msgConfirmEmsData1} msgData2={msgConfirmEmsData2}/> : null}
            {nonEmsDataSt ? <MsgCheckModal nonEmsDataSt={nonEmsDataSt} closeNonEmsDataSt={closeNonEmsDataSt}/>:null}
            {nonSelectOrderSt ? <MsgCheckModal nonSelectOrderSt={nonSelectOrderSt} closeNonSelectOrderSt={closeNonSelectOrderSt}/>:null}
        </div>
    );
}

export default AdminReceiptList
