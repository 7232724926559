import React from 'react';
import footerCenterLogo from '../../../assets/img/footerCenterLogo.png'
import '../../../css/web/UserFooterMain.css';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {usePopup} from "../../contextApi/FooterOpenChatContext";
import HeaderLogo from "../../../assets/img/HeaderLogo.png";
import Kpacket from "../../../assets/img/Kpacket.png";
import emsGrey from "../../../assets/img/emsgrey.png";
import fedexGrey from "../../../assets/img/fedexgrey.png";
import upsGrey from "../../../assets/img/upsgrey.png";

function UserFooterMain({ openTermsPopup, openPrivacyPopup }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { setIsPopupOpen } = usePopup();

    return (
        <div className="u-f-main-container ht-300 display-flex align-center just-cont-sb font-t-cont c-def">
            <div className="display-flex flex-column just-cont-sb wd-25pc ht-100pc">
                <div className="ht-15pc">
                    <img className="wd-125 ht-28 m-a-opc-08" alt="mainLogo" src={HeaderLogo}/>
                </div>
                <div className="ht-25pc font-c-lightgrey p-b-10">
                    <p className="font-s-14 m-b-3">{t(`fmCompNm`)}</p> {/*비토리지 주식회사*/}
                    <p className="font-s-12">경기도 광주시 곤지암읍 연곡길42번길 32-15</p>
                </div>
                {/*제휴사 이미지 8개까지 보여짐*/}
                <div className="display-flex flex-column wd-100pc ht-60pc font-c-lightgrey border-top-bt">
                    <div className="display-flex flex-row just-cont-sb wd-auto ht-100pc">
                        <div className="wd-55 ht-auto">
                            <img className="grayscale-img wd-100pc ht-100pc" style={{objectFit: "contain"}} alt="" src={fedexGrey}/>
                        </div>
                        <div className="wd-55 ht-auto text-center align-center-c">
                            <img className="grayscale-img wd-40 ht-40" style={{objectFit: "contain"}} alt="" src={upsGrey}/>
                        </div>
                        <div className="wd-55 ht-auto">
                            <img className="grayscale-img wd-100pc ht-100pc" style={{objectFit: "contain"}} alt="" src={emsGrey}/>
                        </div>
                        <div className="wd-55 ht-auto">
                            <img className="grayscale-img wd-100pc ht-100pc" style={{objectFit: "contain"}} alt="" src={Kpacket}/>
                        </div>
                    </div>
                    <div className="display-flex flex-row just-cont-sb wd-auto ht-100pc">
                        <div className="wd-55 ht-auto">

                        </div>
                        <div className="wd-55 ht-auto">

                        </div>
                        <div className="wd-55 ht-auto">

                        </div>
                        <div className="wd-55 ht-auto">

                        </div>
                    </div>
                </div>
            </div>
            <div className="display-flex flex-row just-cont-center wd-45pc ht-100pc">
                <div className="display-flex flex-column just-cont-ar wd-50pc ht-100pc p-l-30 font-c-lightgrey font-s-15">
                    <p className="footerCenterMenu" onClick={() => navigate('/UserUsageGuidePg')}>{t(`fmSmallMenu5`)}</p> {/*이용가이드*/}
                    <p className="footerCenterMenu" onClick={openTermsPopup}>{t(`fmSmallMenu2`)}</p> {/*이용약관*/}
                    <p className="footerCenterMenu" onClick={openPrivacyPopup}>{t(`fmSmallMenu3`)}</p> {/*개인정보처리방침*/}
                    <p className="footerCenterMenu">{t(`fmSmallMenu4`)}</p> {/*제휴문의*/}
                </div>
                <div className="display-flex flex-column just-cont-ar wd-50pc ht-100pc p-l-10 font-c-lightgrey font-s-12">
                    <p>{t(`fmCompCeo`)}</p>
                    <p>{t(`fmCompInfoAdmin`)}</p>
                    <p>{t(`fmCompNum`)}</p>
                    <p>{t(`fmCompMail`)}</p>
                    <p>{t(`fmCompSalesNum`)}</p>
                    <p>© 2022 Btorage Corp. All rights reserved.</p>
                </div>
            </div>
            <div className="display-flex flex-column just-cont-sb wd-25pc ht-100pc gap10">
                <div className="ht-15pc">
                    <p className="font-c-default font-s-15 font-b">{t(`fmCt`)}</p> {/*고객센터*/}
                </div>
                <div className="display-flex flex-column just-cont-ar wd-100pc ht-55pc align-center-c">
                    <div className="display-flex flex-row just-cont-fstart">
                        <img className="wd-50 ht-50 m-a-opc-05" src={footerCenterLogo} alt="centerLogo"/>
                        <div className="display-flex flex-column just-cont-sb ht-50 font-c-lightgrey font-s-14 m-l-20">
                            <p>{t(`fmCs`)}</p> {/*상담*/}
                            <p>{t(`fmLunch`)}</p> {/*점심*/}
                        </div>
                    </div>
                    {/*이용 가능한 SNS 및 컨텐츠 흑백로고*/}
                    <div className="display-flex flex-row just-cont-fstart wd-auto ht-100pc align-center-c">
                        <div className="wd-30 ht-30 c-point">

                        </div>
                        <div className="wd-30 ht-30 c-point">

                        </div>
                        <div className="wd-30 ht-30 c-point">

                        </div>
                        <div className="wd-30 ht-30 c-point">

                        </div>
                        <div className="wd-30 ht-30 c-point">

                        </div>
                    </div>
                </div>
                <div className="display-flex just-cont-ar ht-30pc">
                    <div className="wd-50pc ht-100pc align-center-c">
                        {/*// 비동기 특성상 unreadCount가 0으로 리셋이 안 되서 contextApi 사용*/}
                        <button onClick={()=> setIsPopupOpen(true)} className="bt-btn-C font-s-13 wd-100 ht-40" tabIndex="0">{t(`fmChatBt`)}</button> {/*1:1 채팅*/}
                    </div>
                    <div className="wd-50pc ht-100pc align-center-c">
                        <button onClick={()=> navigate("/UserMailInquiryPg")} className="bt-btn-C font-s-13 wd-100 ht-40" tabIndex="0">{t(`fmContBt`)}</button> {/*메일문의*/}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserFooterMain
