import '../../css/mobile/MCustomInfoPg.css';
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";
import commonHeader from "../../assets/img/commonHeader.png";
import MHeader from "../../components/mobile/MHeader";
import notice from "../../assets/img/notice.png";
import mainNotice from "../../assets/img/mainNotice.png";
import Expand from "../../assets/img/commonBottom.png";
import MsgCheckModal from "../../components/web/modal/MsgCheckModal";
import MMsgCheckModal from "../../components/mobile/MMsgCheckModal";
import withAuth from "../../auth/UserRouteAuth";
import Back from "../../assets/img/commonBack.png";
import back from "../../assets/img/commonBack.png";
import {useNavigate} from "react-router-dom";
import {BiCaretDown} from "react-icons/bi";


const MCustomInfoPg = () => {
    const {t} = useTranslation();
    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');

    const [emsTab, setEmsTab] = useState(true);
    const [premiumTab, setPremiumTab] = useState(false);

    const [caution, setCaution] = useState(true);
    const [prohibition, setProhibition] = useState(false);
    const [significant, setSignificant] = useState(false);
    const [customs, setCustoms] = useState(false);

    // 여기서부터 커스텀 셀렉트
    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({value: '', label: t(`ordCrLabel`)});
    const [countryShow, setCountryShow] = useState(false);
    const [filteredCountryList, setFilteredCountryList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [crCode, setCrCode] = useState('')
    const countryRef = useRef(null);

    const toggleCountryShow = () => {
        setCountryShow(prev => !prev);
    };

    const handleCountryChange = (country) => {
        setCrCode(country.value);
        setSelectedCountry(country);
        setCountryShow(true);
        setSearchKeyword('');
    };

    const countryNameMapping = {
        'USA': 'United States of America',
    };

    const handleCountrySearch = (event) => {
        const keyword = event.target.value;
        setSearchKeyword(keyword);

        const mappedKeyword = countryNameMapping[keyword.toUpperCase()] || keyword;

        const filteredCountries = countryList.filter(country =>
            country.label.toLowerCase().includes(mappedKeyword.toLowerCase())
        );

        setFilteredCountryList(filteredCountries);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (countryRef.current && !countryRef.current.contains(event.target)) {
                setCountryShow(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // 여기까지 커스텀 셀렉트

    const selectAllCountry = () => {
        axios.get('/order/selectCountry')
            .then(response => {
                const countries = response.data.map(country => ({
                    value: country.countryCode,
                    label: `${country.countryNm}(${country.countryEngNm})`
                })).sort((a, b) => a.label.localeCompare(b.label));

                setCountryList(countries);
                setFilteredCountryList(countries);
            })
            .catch(error => {
                console.log(error);
            });
    };

    useEffect(() => {
        selectAllCountry();
    }, [])

    const [data, setData] = useState(null);
    const [premiumcd, setPremiumcd] = useState('31');
    const emsCountries = ["AU", "US", "CA", "JP", "FR", "DE", "GB", "IT", "CN", "KR", "SG", "TH", "VN", "NZ",
        "MY", "PH", "ID", "IN", "BR", "MX", "ZA", "RU", "ES", "SE", "NL", "CH", "DK", "NO", "FI", "BE", "PT", "GR"];
    const emsCountry = emsCountries.includes(crCode); // 위 emsCountries에 해당하는 국가코드가 하나라도 있으면 true, 없으면 false 반환
    const [selectedShipCrError, setSelectedShipCrError] = useState(false);
    const closeSelectedShipCrError = (() => {
        setSelectedShipCrError(false);
    })

    useEffect(() => {
        if (crCode && emsTab && !emsCountry) {
            setSelectedShipCrError(true);
        } else if ((crCode && emsTab && emsCountry) || (crCode && premiumTab)) {
            const regkey = 'c316db8184d8c7fd41571380612488';
            axios({
                method: 'get',
                url: `/externalApi/api.EmsApplyGoCondition.ems?regkey=${regkey}&nation=${crCode}&premiumcd=${premiumcd}`,
            })
                .then(response => {
                    if (response.status === 200) {

                        const xml = response.data;
                        const parser = new DOMParser();
                        const xmlDoc = parser.parseFromString(xml, "text/xml");

                        const nationcd = xmlDoc.querySelector("nationcd").textContent;
                        const prcapplyareacd = xmlDoc.querySelector("prcapplyareacd").textContent;
                        const limitwght = xmlDoc.querySelector("limitwght").textContent;
                        const maxstdcd = xmlDoc.querySelector("maxstdcd").textContent;
                        const maxstdcdnm = xmlDoc.querySelector("maxstdcdnm").textContent;
                        const fixtimedivcd = xmlDoc.querySelector("fixtimedivcd").textContent;
                        const delivnotictreatcd = xmlDoc.querySelector("delivnotictreatcd").textContent;
                        const delivseq = xmlDoc.querySelector("delivseq").textContent;
                        const keepddcnt = xmlDoc.querySelector("keepddcnt").textContent;
                        const customrprtdocmakestdcd = xmlDoc.querySelector("customrprtdocmakestdcd").textContent;
                        const etcdocwritecd = xmlDoc.querySelector("etcdocwritecd").textContent;
                        const writechractcont = xmlDoc.querySelector("writechractcont").textContent;
                        const delivrequirddcnt = xmlDoc.querySelector("delivrequirddcnt").textContent;
                        const delivnonableweekday = xmlDoc.querySelector("delivnonableweekday").textContent;
                        const recprsntreatcd = xmlDoc.querySelector("recprsntreatcd").textContent;
                        const areanm = xmlDoc.querySelector("EmsApplyGetServiceArea > areanm").textContent;


                        const sendcondnotes = Array.from(xmlDoc.querySelectorAll("sendcondnote"))
                            .map(note => note.textContent);

                        // Use these parsed values to update your state or render them
                        setData({
                            nationcd,
                            prcapplyareacd,
                            limitwght,
                            maxstdcd,
                            maxstdcdnm,
                            fixtimedivcd,
                            delivnotictreatcd,
                            delivseq,
                            keepddcnt,
                            customrprtdocmakestdcd,
                            etcdocwritecd,
                            writechractcont,
                            delivrequirddcnt,
                            delivnonableweekday,
                            recprsntreatcd,
                            areanm,

                            sendcondnotes
                        });

                    } else {
                        console.error('Failed to fetch data');
                    }
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [crCode]);

    const handleMainTab = ((tab) => {
        if (tab === "Ems") {
            setEmsTab(true);
            setPremiumcd('31');
            setPremiumTab(false);
        } else {
            setEmsTab(false);
            setPremiumcd('32');
            setPremiumTab(true);
        }
    })

    const handleTab = ((tab) => {
        if (tab === "caution") {
            setCaution(true);
            setProhibition(false);
            setSignificant(false);
            setCustoms(false);
        } else if (tab === "prohibition") {
            setCaution(false);
            setProhibition(true);
            setSignificant(false);
            setCustoms(false);
        } else if (tab === "significant") {
            setCaution(false);
            setProhibition(false);
            setSignificant(true);
            setCustoms(false);
        } else {
            setCaution(false);
            setProhibition(false);
            setSignificant(false);
            setCustoms(true);
        }
    })

    const deliveryItemInfoWindow = () => {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const width = 1200;
        const height = 900;
        const left = (screenWidth / 2) - (width / 2);
        const top = (screenHeight / 2) - (height / 2);
        window.open('https://ems.epost.go.kr/front.Introduction04pop.postal', '_blank', `width=${width},height=${height},left=${left},top=${top}`);
    }
    const countryCommonInfoWindow = () => {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const width = 800;
        const height = 600;
        const left = (screenWidth / 2) - (width / 2);
        const top = (screenHeight / 2) - (height / 2);
        window.open('https://ems.epost.go.kr/ems/front/survey/pafao04b09.jsp', '_blank', `width=${width},height=${height},left=${left},top=${top}`);
    }

    const prohibitionEpWindow = () => {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const width = 800;
        const height = 700;
        const left = (screenWidth / 2) - (width / 2);
        const top = (screenHeight / 2) - (height / 2);
        window.open('https://ems.epost.go.kr/ems/front/survey/pafao04b07.jsp', '_blank', `width=${width},height=${height},left=${left},top=${top}`);
    }
    const limitItemEpWindow = () => {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const width = 800;
        const height = 400;
        const left = (screenWidth / 2) - (width / 2);
        const top = (screenHeight / 2) - (height / 2);
        window.open('https://ems.epost.go.kr/ems/front/survey/pafao04b08.jsp', '_blank', `width=${width},height=${height},left=${left},top=${top}`);
    }
    const countryCommonEpWiondow = () => {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const width = 900;
        const height = 700;
        const left = (screenWidth / 2) - (width / 2);
        const top = (screenHeight / 2) - (height / 2);
        window.open('https://ems.epost.go.kr/ems/front/survey/pafao04b09.jsp', '_blank', `width=${width},height=${height},left=${left},top=${top}`);
    }

    const navigate = useNavigate();

    return (
        <div className="m-a-custom-info-pg-container font-t-cont">
            <MHeader title={t(`ciHeader1`)} url={"/"}/> {/*국가별통관정보*/}
            <div className="m-a-custom-info-sub-title">
                <img className="m-a-custom-info-sub-title-icon" src={commonHeader} alt=''/>
                <p className="font-t-title font-s-15 p-t-5"> {t(`ciHeader1`)}</p> {/*국가별통관정보*/}
            </div>
            <div className='m-a-custom-info-divider'/>
            <div className='m-a-custom-info-row'>
                <div className={`m-a-custom-info-row-box ${emsTab && 'selected'}`} onClick={() => handleMainTab('Ems')}>EMS</div>
                <div className={`m-a-custom-info-row-box ${premiumTab && 'selected'}`} onClick={() => handleMainTab('Premium')}>EMS Premium</div>
            </div>
            {emsTab && (
                <div className="m-a-custom-info-frame border">
                    <div className="m-a-custom-info-frame-grid margin-bottom">
                        <div className='m-a-custom-info-frame-grid-label'> {t(`ciSmallMenu1`)}</div>
                        <div className="ht-40 wd-100pc position-relative custom-info-col-3" ref={countryRef} onClick={toggleCountryShow}>
                            <div className="order-rec-country-input display-flex flex-row wd-100pc ht-100pc">
                                {selectedCountry.value ? (
                                    <div className="wd-15pc text-center p-l-5 p-t-10">
                                        <div className={`font-s-20 fi fi-${selectedCountry.value.toLowerCase()}`} />
                                    </div>
                                ) : (
                                    <div className="wd-20pc text-center p-l-5 p-t-10">
                                        <div className="wd-29 ht-22 text-center p-t-1 font-s-12 country-select-flag">?</div>
                                    </div>
                                )}
                                {/*국가명 입력 또는 선택*/}
                                <input className="country-select-in-input wd-75pc font-s-12 p-l-13"
                                       type="text" placeholder={t(`modSmallMenu3-1`)}
                                       value={searchKeyword || (selectedCountry.value ? selectedCountry.label : '')}
                                       onChange={handleCountrySearch} />
                                <i className="wd-13pc text-center align-center-c font-s-18 p-b-2 font-c-darkgrey">
                                    <BiCaretDown />
                                </i>
                            </div>
                            {countryShow && (
                                <div className="country-select-data wd-100pc position-absolute m-t-5">
                                    {filteredCountryList.map((country, index) => (
                                        <div className="selectChildBt display-flex p-t-10 p-b-10 p-l-20" key={index}
                                             onClick={() => handleCountryChange(country)} style={{zIndex: 99 }}>
                                            <div className={`font-s-15 m-r-8 fi fi-${country.value.toLowerCase()}`}></div>
                                            <p className="font-s-13">{country.label}</p>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="m-a-custom-info-frame-grid">
                        <div className='m-a-custom-info-frame-grid-label'> {t(`ciSmallMenu2`)}</div>
                        <div className='m-a-custom-info-frame-grid-value'> {data && data.nationcd}</div>
                        <div className='m-a-custom-info-frame-grid-label'> {t(`ciSmallMenu3`)}</div>
                        <div className='m-a-custom-info-frame-grid-value'> {data && data.fixtimedivcd}</div>
                    </div>
                    <div className='m-a-custom-info-frame-grid'>
                        <div className='m-a-custom-info-frame-grid-label'> {t(`ciSmallMenu4`)}</div>
                        <div className='m-a-custom-info-frame-grid-value'> {data && data.delivnotictreatcd}</div>
                        <div className='m-a-custom-info-frame-grid-label'> {t(`ciSmallMenu5`)}</div>
                        <div className='m-a-custom-info-frame-grid-value'> {data && data.maxstdcdnm}</div>
                    </div>
                    <div className='m-a-custom-info-frame-grid'>
                        <div className='m-a-custom-info-frame-grid-label'> {t(`ciSmallMenu6`)}</div>
                        <div className='m-a-custom-info-frame-grid-value custom-info-col-3'> {data && data.limitwght}</div>
                    </div>
                    <div className='m-a-custom-info-frame-grid'>
                        <div className='m-a-custom-info-frame-grid-label'> {t(`ciSmallMenu7`)}</div>
                        <div className='m-a-custom-info-frame-grid-value'> {data && data.delivseq}</div>
                        <div className='m-a-custom-info-frame-grid-label'> {t(`ciSmallMenu8`)}</div>
                        <div className='m-a-custom-info-frame-grid-value'> {data && data.delivrequirddcnt}</div>
                    </div>
                    <div className='m-a-custom-info-frame-grid'>
                        <div className='m-a-custom-info-frame-grid-label'> {t(`ciSmallMenu9`)}</div>
                        <div className='m-a-custom-info-frame-grid-value'> {data && data.keepddcnt}</div>
                        <div className='m-a-custom-info-frame-grid-label'> {t(`ciSmallMenu10`)}</div>
                        <div className='m-a-custom-info-frame-grid-value'> {data && data.delivnonableweekday}</div>
                    </div>
                    <div className='m-a-custom-info-frame-grid'>
                        <div className='m-a-custom-info-frame-grid-label'> {t(`ciSmallMenu11`)}</div>
                        <div className='m-a-custom-info-frame-grid-value'> {data && data.customrprtdocmakestdcd}</div>
                        <div className='m-a-custom-info-frame-grid-label'> {t(`ciSmallMenu12`)}</div>
                        <div className='m-a-custom-info-frame-grid-value'> {}</div>
                    </div>
                    <div className='m-a-custom-info-description'>
                        ※ {t(`ciNotice1`)} <br/>
                        ※ {t(`ciNotice2`)} <br/>
                        ※ {t(`ciNotice3`)}
                    </div>
                    <div className='m-a-custom-info-sub-title'>
                        <img className='m-a-custom-info-sub-title-icon' src={notice} alt=''/>
                        <div className='m-a-custom-info-sub-title-text black'>{t(`ciHeader2`)}</div> {/*긴급공지*/}
                    </div>
                    <div className="m-a-custom-info-row">
                        <button className="bt-btn-C font-s-12 wd-50pc" onClick={deliveryItemInfoWindow}> {t(`ciNoticeBt1`)}</button>
                        <button className="bt-btn-C font-s-12 wd-50pc" onClick={countryCommonInfoWindow}> {t(`ciNoticeBt2`)}</button>
                    </div>
                    <div className='m-a-custom-info-description background'>
                        {data && data.areanm.split('\n').map((text, index) =>
                            <p key={index} style={{fontSize: "13px"}}>{text}</p>
                        )}
                    </div>
                    <div className='m-a-custom-info-sub-title'>
                        <img className='m-a-custom-info-sub-title-icon' src={mainNotice} alt=''/>
                        <div className='m-a-custom-info-sub-title-text black'>{t(`ciHeader3`)}</div> {/*주요안내사항*/}
                    </div>
                    <div className="m-a-custom-info-row m-b-8">
                        <button className={`bt-btn-C font-s-12 wd-50pc ${caution && 'selected'}`} onClick={() => handleTab('caution')}> {t(`ciGuideBt1`)}</button>
                        <button className={`bt-btn-C font-s-12 wd-50pc ${prohibition && 'selected'}`} onClick={() => handleTab('prohibition')}> {t(`ciGuideBt2`)}</button>
                    </div>
                    <div className="m-a-custom-info-row">
                        <button className={`bt-btn-C font-s-12 wd-50pc ${significant && 'selected'}`} onClick={() => handleTab('significant')}> {t(`ciGuideBt3`)}</button>
                        <button className={`bt-btn-C font-s-12 wd-50pc ${customs && 'selected'}`} onClick={() => handleTab('customs')}> {t(`ciGuideBt4`)}</button>
                    </div>
                    <div className='m-a-custom-info-description background'>
                        {caution ?
                            <div>
                                {data && data.sendcondnotes[0].split('\n').map((text, index) =>
                                    <p key={index} style={{margin: "0", fontSize: "13px"}}>{text}</p>
                                )}
                            </div> :
                            prohibition ?
                                <div>
                                    {data && (data.sendcondnotes[1] + '\n' + data.sendcondnotes[2]).split('\n').map((text, index) =>
                                        <p key={index} style={{margin: "0", fontSize: "13px"}}>{text}</p>
                                    )}
                                </div> :
                                significant ?
                                    <div>
                                        {data && data.sendcondnotes[3].split('\n').map((text, index) =>
                                            <p key={index} style={{margin: "0", fontSize: "13px"}}>{text}</p>
                                        )}
                                    </div> :
                                    customs ?
                                        <div>
                                            {data && data.sendcondnotes[4] && data.sendcondnotes[4].split('\n').map((text, index) =>
                                                <p key={index} style={{margin: "0", fontSize: "13px"}}>{text}</p>
                                            )}
                                        </div>
                                        :
                                        null
                        }
                    </div>
                </div>
            )}
            {premiumTab && (
                <div className={'m-a-custom-info-frame border'}>
                    <div className='m-a-custom-info-frame-grid margin-bottom'>
                        <div className='m-a-custom-info-frame-grid-label'> {t('ciSmallMenu1')}</div>
                        <div className="ht-40 wd-100pc position-relative custom-info-col-3" ref={countryRef} onClick={toggleCountryShow}>
                            <div className="order-rec-country-input display-flex flex-row wd-100pc ht-100pc">
                                {selectedCountry.value ? (
                                    <div className="wd-15pc text-center p-l-5 p-t-10">
                                        <div className={`font-s-20 fi fi-${selectedCountry.value.toLowerCase()}`} />
                                    </div>
                                ) : (
                                    <div className="wd-20pc text-center p-l-5 p-t-10">
                                        <div className="wd-29 ht-22 text-center p-t-1 font-s-12 country-select-flag">?</div>
                                    </div>
                                )}
                                {/*국가명 입력 또는 선택*/}
                                <input className="country-select-in-input wd-75pc font-s-12 p-l-13"
                                       type="text" placeholder={t(`modSmallMenu3-1`)}
                                       value={searchKeyword || (selectedCountry.value ? selectedCountry.label : '')}
                                       onChange={handleCountrySearch} />
                                <i className="wd-13pc text-center align-center-c font-s-18 p-b-2 font-c-darkgrey">
                                    <BiCaretDown />
                                </i>
                            </div>
                            {countryShow && (
                                <div className="country-select-data wd-100pc position-absolute m-t-5">
                                    {filteredCountryList.map((country, index) => (
                                        <div className="selectChildBt display-flex p-t-10 p-b-10 p-l-20" key={index}
                                             onClick={() => handleCountryChange(country)} style={{zIndex: 99 }}>
                                            <div className={`font-s-15 m-r-8 fi fi-${country.value.toLowerCase()}`}></div>
                                            <p className="font-s-13">{country.label}</p>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='m-a-custom-info-frame-grid '>
                        <div className='m-a-custom-info-frame-grid-label'> {t(`ciSmallMenu2`)}</div>
                        <div className='m-a-custom-info-frame-grid-value'> {data && data.prcapplyareacd}</div>
                        <div className='m-a-custom-info-frame-grid-label'> {t(`ciSmallMenu13`)}</div>
                        <div className='m-a-custom-info-frame-grid-value'> {data && data.delivnotictreatcd}</div>
                    </div>
                    <div className='m-a-custom-info-frame-grid '>
                        <div className='m-a-custom-info-frame-grid-label'> {t(`ciSmallMenu4`)}</div>
                        <div className='m-a-custom-info-frame-grid-value'> {data && data.delivnotictreatcd}</div>
                        <div className='m-a-custom-info-frame-grid-label'> {t(`ciSmallMenu5`)}</div>
                        <div className='m-a-custom-info-frame-grid-value'> {data && data.maxstdcdnm}</div>
                    </div>
                    <div className='m-a-custom-info-frame-grid '>
                        <div className='m-a-custom-info-frame-grid-label'> {t(`ciSmallMenu6`)}</div>
                        <div className='m-a-custom-info-frame-grid-value custom-info-col-3'> {data && data.limitwght}</div>
                    </div>
                    <div className='m-a-custom-info-frame-grid '>
                        <div className='m-a-custom-info-frame-grid-label'> {t(`ciSmallMenu7`)}</div>
                        <div className='m-a-custom-info-frame-grid-value'> {data && data.delivseq}</div>
                        <div className='m-a-custom-info-frame-grid-label'> {t(`ciSmallMenu8`)}</div>
                        <div className='m-a-custom-info-frame-grid-value'> {data && data.delivrequirddcnt}</div>
                    </div>
                    <div className='m-a-custom-info-frame-grid '>
                        <div className='m-a-custom-info-frame-grid-label'> {t(`ciSmallMenu14`)}</div>
                        <div className='m-a-custom-info-frame-grid-value'> {data && data.keepddcnt}</div>
                        <div className='m-a-custom-info-frame-grid-label'> {t(`ciSmallMenu10`)}</div>
                        <div className='m-a-custom-info-frame-grid-value'> {data && data.delivnonableweekday}</div>
                    </div>
                    <div className='m-a-custom-info-description'>
                        ※ {t(`ciNotice1`)} <br/>
                        ※ {t(`ciNotice3`)}
                    </div>
                    <div className='m-a-custom-info-row'>
                        <button className="bt-btn-C font-s-12 wd-50pc" onClick={prohibitionEpWindow}> {t(`ciSmallMenu15`)}</button>
                        <button className="bt-btn-C font-s-12 wd-50pc" onClick={limitItemEpWindow}> {t(`ciSmallMenu16`)}</button>
                        <button className="bt-btn-C font-s-12 wd-50pc" onClick={countryCommonEpWiondow}> {t(`ciNoticeBt2`)}</button>
                    </div>
                    <div className='m-a-custom-info-sub-title'>
                        <img className='m-a-custom-info-sub-title-icon' src={notice} alt=''/>
                        <div className='m-a-custom-info-sub-title-text black'>{t(`ciHeader2`)}</div> {/*긴급공지*/}
                    </div>
                    <div className="m-a-custom-info-description background">
                        {data && data.areanm.split('\n').map((text, index) =>
                            <p key={index}>
                                {text}
                            </p>
                        )}
                    </div>
                    <div className='m-a-custom-info-sub-title'>
                        <img className='m-a-custom-info-sub-title-icon' src={mainNotice} alt=''/>
                        <div className='m-a-custom-info-sub-title-text black'>{t(`ciHeader3`)}</div> {/*주요안내사항*/}
                    </div>
                    <div className='m-a-custom-info-row m-b-8'>
                        <button className={`bt-btn-C font-s-12 wd-50pc ${caution && 'selected'}`} onClick={() => handleTab('caution')}> {t(`ciGuideBt1`)}</button>
                        <button className={`bt-btn-C font-s-12 wd-50pc ${prohibition && 'selected'}`} onClick={() => handleTab('prohibition')}> {t(`ciGuideBt2`)}</button>
                    </div>
                    <div className='m-a-custom-info-row'>
                        <button className={`bt-btn-C font-s-12 wd-50pc ${significant && 'selected'}`} onClick={() => handleTab('significant')}> {t(`ciGuideBt3`)}</button>
                        <button className={`bt-btn-C font-s-12 wd-50pc ${customs && 'selected'}`} onClick={() => handleTab('customs')}> {t(`ciGuideBt4`)}</button>
                    </div>
                    <div className='m-a-custom-info-description background'>
                        {caution ?
                            <div>
                                {data && data.sendcondnotes[0].split('\n').map((text, index) =>
                                    <p key={index}
                                    >{text}</p>
                                )}
                            </div> :
                            prohibition ?
                                <div>
                                    {data && (data.sendcondnotes[1] + '\n' + data.sendcondnotes[2])
                                        .split('\n').map((text, index) =>
                                            <p key={index}
                                            >{text}</p>
                                        )}
                                </div> :
                                significant ?
                                    <div>
                                        {data && data.sendcondnotes[3].split('\n').map((text, index) =>
                                            <p key={index}
                                            >{text}</p>
                                        )}
                                    </div> :
                                    customs ?
                                        <div>
                                            {data && data.sendcondnotes[4] && data.sendcondnotes[4].split('\n').map((text, index) =>
                                                <p key={index}
                                                >{text}</p>
                                            )}
                                        </div>
                                        :
                                        null
                        }
                    </div>
                </div>
            )}

            { selectedShipCrError ?
                <MMsgCheckModal selectedShipCrError={selectedShipCrError} closeSelectedShipCrError={closeSelectedShipCrError}/>
                :
                null
            }
        </div>
    )

}

export default withAuth(MCustomInfoPg);
