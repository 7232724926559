import '../../css/mobile/MUserInfoModiPg.css';
import React, {useCallback, useEffect, useRef, useState} from "react";
import changePw from "../../assets/img/changePw.png";
import ToggleOn from "../../assets/img/toggleOnIcon.svg";
import ToggleOff from "../../assets/img/toggleOffIcon.svg";
import Logo from "../../assets/img/HeaderLogo.png";
import MHeader from "../../components/mobile/MHeader";
import Expand from "../../assets/img/commonBottom.png";
import Close from '../../assets/img/commonClose.png'
import Close2 from '../../assets/img/commonClose2.png'
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import profileImage from "../../assets/img/profileDefaultImg.png";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import MsgConfirmModal from "../../components/web/modal/MsgConfirmModal";
import UserAddrBook from "../../components/web/modal/UserAddrBook";
import withAuth from "../../auth/UserRouteAuth";
import MAddrBookPg from "./MAddrBookPg";

const MUserInfoModiPg = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [nowPassword, setNowPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [lang, setLang] = useState("");

    const [nowPasswordError, setNowPasswordError] = useState(false);
    const [nowPasswordMatchError, setNowPasswordMatchError] = useState(false);
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [samePasswordError, setSamePasswordError] = useState(false);
    const [confirmNewPasswordError, setConfirmNewPasswordError] = useState(false);
    const [langError, setLangError] = useState(false);
    const [changePwFl, setChangePwFl] = useState(false);

    const SSAT = sessionStorage.getItem('AToken');
    const LCAT = localStorage.getItem('AToken');

    const onChangeNowPassword = (e) => { /*영문+숫자+특수문자 8~20자리*/
        const nowPassworRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()])(?=.*[0-9]).{8,20}$/;
        if ((!e.target.value || (nowPassworRegex.test(e.target.value)))) setNowPasswordError(false);
        else setNowPasswordError(true);
        setNowPassword(e.target.value);
    };

    const onChangeNewPassword = (e) => { /*영문+숫자+특수문자 8~20자리*/
        const newPasswordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()])(?=.*[0-9]).{8,20}$/;
        if ((!e.target.value || (newPasswordRegex.test(e.target.value)))) setNewPasswordError(false);
        else setNewPasswordError(true);

        if (e.target.value === nowPassword) setSamePasswordError(true);
        else setSamePasswordError(false);

        if (!confirmNewPassword || e.target.value === confirmNewPassword) setConfirmNewPasswordError(false);
        else setConfirmNewPasswordError(true);
        setNewPassword(e.target.value);
    };

    const onChangeConfirmNewPassword = (e) => { /*비밀번호 확인(비밀번호와 다르면 에러메시지)*/
        if (newPassword === e.target.value) setConfirmNewPasswordError(false);
        else setConfirmNewPasswordError(true);
        setConfirmNewPassword(e.target.value);
    };

    const onChangeLang = (e) => {
        const langRegex = /^[a-zA-Z-ㄱ-힣]{2,25}/; /*영문 또는 한글 2~25자*/
        if ((!e.target.value || (langRegex.test(e.target.value)))) setLangError(false);
        else setLangError(true);
        setLang(e.target.value);
    };

    const validation = () => {
        let isValue = true;
        if (!socialJoinFl && changePwFl) {
            if (!nowPassword && nowPasswordMatchError) {
                setNowPasswordMatchError(true);
                isValue = false;
            }
            if (!newPassword && newPasswordError) {
                setNewPasswordError(true);
                isValue = false;
            }
            if (!confirmNewPassword || (confirmNewPassword !== newPassword) || confirmNewPasswordError) {
                setConfirmNewPasswordError(true);
                isValue = false;
            }
            if (nowPasswordMatchError || newPasswordError || confirmNewPasswordError || langError) {
                isValue = false;
            }
        }
        if (!langError && (!lang || lang === "none")) {
            setLangError(true);
            isValue = false;
        }
        return isValue;
    };

    const errorAndSave = (e) => {
        if (validation()) {
            userInfoModify(e);
        }
    }

    // 회원가입 소통언어 직접입력 인풋 Start
    // 소통언어 양식 셀렉트&인풋 전환 기능
    const langOptions = [
        {value: 'none', label: 'Language'},
        {value: 'English', label: 'English'},
        {value: 'Japanese', label: 'Japanese'},
        {value: 'Chinese', label: 'Chinese'},
        {value: 'Indonesian', label: 'Indonesian'},
        {value: 'Korean', label: 'Korean'},
        {value: '', label: t(`mdSfIp`)},
    ];

    const [langChoiceReturn, setLangChoiceReturn] = useState(true);
    const LangHandle = useCallback(() => {
        setLangChoiceReturn(true);
        setLangSelectedOption(langOptions[0]);
    }, []);

    const [langSelectedOption, setLangSelectedOption] = useState(langOptions[0]);
    const [langIsOpen, setLangIsOpen] = useState(false);
    const [langFocusedIndex, setLangFocusedIndex] = useState(0);
    const langDropdownRef = useRef(null);


    useEffect(() => {
        const handleClickOutside = (e) => {
            if (langDropdownRef.current && !langDropdownRef.current.contains(e.target)) {
                setLangIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const langHandleSelect = (option, index) => {
        if (option.value !== '' || option.value !== "none") {
            setLangError(false);
        }
        setLangSelectedOption(option);
        setLangFocusedIndex(index);
        setLangIsOpen(false);
        setLang(option.value);
        if (option.value === '') setLangChoiceReturn(false);
        else setLangChoiceReturn(true);
    };

    const langHandleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setLangFocusedIndex((prevIndex) => {
                const newIndex = (prevIndex + 1) % langOptions.length;
                setLangSelectedOption(langOptions[newIndex]);
                setLang(langOptions[newIndex].value);
                if (langOptions[newIndex].value === '') setLangChoiceReturn(false);
                else setLangChoiceReturn(true);
                return newIndex;
            });
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setLangFocusedIndex((prevIndex) => {
                const newIndex = (prevIndex - 1 + langOptions.length) % langOptions.length;
                setLangSelectedOption(langOptions[newIndex]);
                setLang(langOptions[newIndex].value);
                if (langOptions[newIndex].value === '') setLangChoiceReturn(false);
                else setLangChoiceReturn(true);
                return newIndex;
            });
        } else if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            langHandleSelect(langOptions[langFocusedIndex], langFocusedIndex);
        } else if (e.key === 'Escape') {
            setLangIsOpen(false);
        }
    };

    const langHandleOnFocus = () => {
        if (langFocusedIndex === -1) {
            setLangFocusedIndex(langOptions.findIndex((option) => option.value === langSelectedOption.value));
        }
    };

    const [modiPgId, setModiPgId] = useState('')
    const [modiPgUsername, setModiPgUsername] = useState("")
    const [modiPgMailRecFl, setModiPgMailRecFl] = useState("")
    const [modiPgMbNum, setModiPgMbNum] = useState("")
    const [modiPgSeqNum, setModiPgSeqNum] = useState("")
    const [modiPgInfoGatAgree, setModiPgInfoGatAgree] = useState("");
    const [modiPgUseTermsAgree, setModiPgUseTermsAgree] = useState("");
    const [modiPgUserOutFl, setModiPgUserOutFl] = useState("");
    const [modiPgImgFile, setModiPgImgFile] = useState("");

    const [socialJoinFl, setSocialJoinFl] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            axios({
                method: 'get',
                url: '/member/modiInfo',
                headers: {
                    Authorization: LCAT ? LCAT : SSAT
                }
            })
                .then((response) => {
                    setLangSelectedOption({value: response.data[0].lang, label: response.data[0].lang});
                    setModiPgId(response.data[0].id)
                    setModiPgUsername(response.data[0].userName)
                    setLang(response.data[0].lang)
                    setModiPgMailRecFl(response.data[0].mailRecFl)
                    setModiPgSeqNum(response.data[0].seqNum)
                    setModiPgMbNum(response.data[0].mbNum)
                    setModiPgInfoGatAgree(response.data[0].infoGatAgree)
                    setModiPgUseTermsAgree(response.data[0].useTermsAgree)
                    setModiPgUserOutFl(response.data[0].userOutFl)
                    setModiPgImgFile(response.data[0].profileDTO)
                    setSocialJoinFl(response.data[0].socialJoinFl);

                    if (response.data[0].profileDTO === null) {
                        setProfilePhotoLine(profileImage)
                    } else {
                        setProfilePhotoLine("https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + response.data[0].profileDTO.filename)
                    }
                })
        },);
    }, [])

    // 메일수신여부 On/Off 버튼
    const ToggleChecked = ((e) => {
        if (modiPgMailRecFl === "Y") {
            setModiPgMailRecFl("N");
        } else if (modiPgMailRecFl === "N") {
            setModiPgMailRecFl("Y")
        }
    })

    const modiUserDelete = () => axios({
        method: 'put',
        url: '/member/userOut',
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        }
    })
        .then(() => {
            setAlertShow(false);
            setConfirmShow1(true);
        })

    const confirm = (() => {
        navigate("/UserLoginPg")
        if (SSAT) {
            sessionStorage.removeItem('AToken');
            sessionStorage.removeItem('oneAtTime');
            sessionStorage.removeItem('twoAtTime');
        } else {
            localStorage.removeItem('AToken');
            localStorage.removeItem('oneAtTime');
            localStorage.removeItem('twoAtTime');
        }
    })

    const userInfoModify = () => axios({
        method: 'post',
        url: '/member/infoModify',
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        },
        params: {
            nowPassword: nowPassword
        },
        data: {
            id: modiPgId,
            userName: modiPgUsername,
            password: newPassword,
            passwordConfirm: confirmNewPassword,
            lang: lang,
            mailRecFl: modiPgMailRecFl,
            seqNum: modiPgSeqNum,
            mbNum: modiPgMbNum,
            infoGatAgree: modiPgInfoGatAgree,
            useTermsAgree: modiPgUseTermsAgree,
            userOutFl: modiPgUserOutFl,
            socialJoinFl: socialJoinFl,
            changePwFl: changePwFl
        }
    })
        .then(() => {
            setConfirmShow2(true);
        })
        .catch(() => {
            setNowPasswordError(true);
        })

    // 모달관련 스테이트
    const [alertShow, setAlertShow] = useState(false);
    const [confirmShow1, setConfirmShow1] = useState(false);
    const [confirmShow2, setConfirmShow2] = useState(false);
    const [confirmShow3, setConfirmShow3] = useState(false);
    const [confirmShow4, setConfirmShow4] = useState(false);
    const [photoDeleteSelectSt, setPhotoDeleteSelectSt] = useState(false);
    const [photoDeleteConfirmSt, setPhotoDeleteConfirmSt] = useState(false);
    const [deliAddrBookSt, setDeliAddrBookSt] = useState(false);

    const userOutSelect = (() => {
        setAlertShow(true);
    })

    const modimodalCancel = useCallback(() => {
        setAlertShow(false);
    })

    const photoDeleteCancelFn = (() => {
        setPhotoDeleteSelectSt(false);
    })

    const photoDeleteConfirmFn = (() => {
        setPhotoDeleteConfirmSt(false);
    })

    const deliAddrBookFn = (() => {
        setDeliAddrBookSt(true);
    })

    const addrBookCloseFn = (() => {
        setDeliAddrBookSt(false);
    })

    const msgConfirmData1 = t("mdModal1");
    const msgConfirmData2 = t("mdModal2");
    const msgConfirmData3 = t("mdModal3");
    const msgConfirmData4 = t("mdModal4");
    const MsgConfirmData5 = t("mdModal5");
    const MsgConfirmData6 = t("mdModal6");
    const MsgConfirmData7 = t("mdModal7");

    const confirm2 = (() => {
        navigate("/")
    })
    const confirm3 = (() => {
        setConfirmShow3(false);
    })
    const confirm4 = (() => {
        setConfirmShow4(false);
        window.location.reload();
    })

    const [profilePhotoLine, setProfilePhotoLine] = useState(profileImage);

    const photoDeleteButton = (() => {
        setPhotoDeleteSelectSt(true);
    })

    const profileDelete = () => axios({
        method: 'post',
        url: '/member/profileDelete',
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        },
        params: {
            id: modiPgImgFile.seqnum
        },
    })
        .then(() => {
            setPhotoDeleteSelectSt(false);
            setProfilePhotoLine(profileImage);
            setPhotoDeleteConfirmSt(true);
        })
        .catch(() => {
            console.log("실패")
        })

    const formData = new FormData();
    const PhotoChangeHandler = (e) => {
        const file = e.target.files[0];
        formData.append('file', file);
        setProfilePhotoLine(URL.createObjectURL(e.target.files[0]))
    }

    const profileSave = async () => await axios({
        method: 'post',
        url: '/member/profileSave',
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: LCAT ? LCAT : SSAT
        },
        params: {
            email: modiPgId
        },
        data: formData
    })
        .then(() => {
            // 등록 후 바로 사진삭제할 수도 있어서 여기에 ProfileVO도 데이터 업뎃해줘야함
            setConfirmShow4(true);
        })
        .catch(() => {
            console.log("실패")
        })

    const photoChangeAndSave = (e) => {
        PhotoChangeHandler(e);
        profileSave(e).then();
    }

    const selectFile = useRef(null);
    return (
        <>
            <div className='m-a-user-info-modi-pg-container'>
                <MHeader title={t(`hdBt1`)} url={'/'}/>
                <img className='m-a-user-info-modi-pg-user-profile m-t-20' src={profilePhotoLine} alt='profilePhoto'/>
                <div className={'m-a-user-info-modi-pg-user-profile-buttons'}>
                    <div className={'m-a-user-info-modi-pg-user-profile-button gray'} onClick={photoDeleteButton}>{t(`mdBt1`)}</div> {/*사진제거*/}
                    <input style={{display: "none"}} type="file" name="file" accept="image/*" ref={selectFile} onChange={photoChangeAndSave}/>
                    <div className={'m-a-user-info-modi-pg-user-profile-button'} onClick={() => selectFile.current.click()}>{t(`mdBt2`)}</div> {/*사진등록*/}
                </div>
                <div className={'m-a-user-info-modi-divider'}/>
                <div className='m-a-user-info-modi-pg-col'>
                    {/*<div className={'m-a-user-info-modi-pg-label'}>{t(`mdSmallMenu1`)}</div>*/}
                    <div className={'m-a-user-info-modi-pg-box'}>{modiPgId}</div>
                </div>
                <div className='m-a-user-info-modi-pg-col'>
                    {/*<div className={'m-a-user-info-modi-pg-label'}>{t(`mdSmallMenu2`)}</div>*/}
                    <div className={'m-a-user-info-modi-pg-box'}>{modiPgUsername}</div>
                </div>
                {!socialJoinFl && (
                    <>
                        {changePwFl ? (
                            <div className='m-a-user-info-modi-pg-password-change-frame'>
                                <div className={'m-a-user-info-modi-pg-password-change-close-frame'}>
                                    <img className='m-a-user-info-modi-pg-password-change-close-icon' src={Close}
                                         alt='Close' onClick={() => setChangePwFl(false)}/>
                                </div>
                                <div className='m-a-user-info-modi-pg-input-frame border'>
                                    <input className='m-a-user-info-modi-pg-box without-border'
                                           placeholder={t(`mdLabel1`)} type="password"
                                           value={nowPassword} onChange={onChangeNowPassword}
                                           minLength="8" maxLength="20"/>
                                </div>
                                {nowPasswordError ?
                                    <div className='m-a-user-info-modi-pg-error'>
                                        {t(`mdErr1`)}
                                    </div> : nowPasswordMatchError ?
                                        <div className='m-a-user-info-modi-pg-error'>
                                            {t(`mdErr2`)}
                                        </div> : null
                                }
                                <div className='m-a-user-info-modi-pg-input-frame border'>
                                    <input className='m-a-user-info-modi-pg-box without-border'
                                           placeholder={t(`mdLabel2`)} type="password"
                                           value={newPassword} onChange={onChangeNewPassword}
                                           minLength="8" maxLength="20"
                                    />
                                </div>
                                {newPasswordError && <div className='m-a-user-info-modi-pg-error'>
                                    {t(`mdErr3`)}</div>}
                                {samePasswordError && <div className='m-a-user-info-modi-pg-error'>
                                    {t(`mdErr3-2`)}</div>}
                                <div className='m-a-user-info-modi-pg-input-frame border'>
                                    <input className='m-a-user-info-modi-pg-box without-border'
                                           placeholder={t(`mdLabel3`)}
                                           type="password" value={confirmNewPassword}
                                           onChange={onChangeConfirmNewPassword}
                                           minLength="8" maxLength="20"/>
                                </div>
                                {confirmNewPasswordError &&
                                    <div className='m-a-user-info-modi-pg-error'>{t(`mdErr4`)}</div>}
                            </div>
                        ) : (
                            <div className='m-a-user-info-button-transparent'
                                 onClick={() => setChangePwFl(true)}>
                                <img className='m-a-user-info-modi-pg-password-change-icon' src={changePw}
                                     alt='changePw'/>
                                {t('mdSmallMenu3')}
                            </div>
                        )}
                    </>
                )}
                {langChoiceReturn && langSelectedOption.value !== "" ? (
                    <div className='m-a-user-info-modi-pg-language-frame'
                         ref={langDropdownRef}
                         tabIndex="0"
                         onKeyDown={langHandleKeyDown}
                         onFocus={langHandleOnFocus}
                         onClick={() => setLangIsOpen(!langIsOpen)}>
                        <div
                            className="m-a-user-info-modi-pg-language">{langSelectedOption.label || t(`mdSmallMenu4`)}</div>
                        <img className={`m-a-user-info-modi-pg-select-icon ${langIsOpen ? 'reversed' : ''}`}
                             src={Expand} alt='Expand'/>
                        {langIsOpen && (
                            <div className='m-a-user-info-modi-pg-language-select'>
                                {langOptions.map((option, index) => (
                                    <div
                                        className={`m-a-user-info-modi-pg-language-select-item
                                        ${option.label === lang && 'selected'}`}
                                        key={option.value}
                                        onClick={() => langHandleSelect(option, index)}>
                                        {option.label}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ) : (
                    <div className={'m-a-user-info-modi-pg-language-input-box'}>
                        <input className='m-a-user-info-modi-pg-language-input'
                               value={lang} type="text" placeholder={t(`mdLabel4`)}
                               onChange={onChangeLang}/>
                        <img className={'m-a-user-info-modi-pg-language-input-close'}
                             src={Close2} alt={'Close'}
                             onClick={LangHandle}/>
                    </div>
                )}
                {langError && <div className='m-a-user-info-modi-pg-error'>{t(`mdErr4`)}</div>}

                <div className='m-a-user-info-modi-pg-mail-receiving-frame'>
                    <div className={'m-a-user-modi-pg-mail-receiving-label'}>{t(`mdSmallMenu6`)} </div>
                    <div className='m-a-user-info-modi-pg-mail-receiving-text'
                         onClick={ToggleChecked}
                    >
                        <img className='m-a-user-info-modi-pg-mail-receiving-toggle'
                             src={modiPgMailRecFl === "Y" ? ToggleOn :
                                 modiPgMailRecFl === "N" ? ToggleOff : null} alt=''/>
                        {modiPgMailRecFl === "Y" ? t(`mdCont3`) :
                            modiPgMailRecFl === "N" ? t(`mdCont4`) : null}
                    </div>
                </div>
                <button className={`m-a-user-info-modi-pg-save`}
                        onClick={errorAndSave}>
                    {t(`mdBt`)}
                </button>
                <div className="m-a-user-info-modi-pg-exit-box m-t-5">
                    <div className='m-a-user-info-modi-pg-exit'
                         onClick={userOutSelect}>
                        {t(`mdOut`)}
                    </div>
                </div>
                {alertShow === true ?

                    <MsgSelectModal alertShow={alertShow} eventHandle1={modimodalCancel} eventHandle2={modiUserDelete}
                                    msgData1={msgConfirmData1}
                                    msgData2={msgConfirmData2}/>
                    :
                    null
                }
                {confirmShow1 === true ?
                    <MsgConfirmModal confirm={confirm} msgData3={msgConfirmData3} confirmShow1={confirmShow1}/>
                    :
                    null
                }
                {confirmShow2 === true ?
                    <MsgConfirmModal confirm2={confirm2} msgData4={msgConfirmData4} confirmShow2={confirmShow2}/>
                    :
                    null
                }
                {confirmShow3 === true ?
                    <MsgConfirmModal confirm3={confirm3} msgData5={MsgConfirmData5} confirmShow3={confirmShow3}/>
                    :
                    null
                }
                {confirmShow4 === true ?
                    // 컨펌4는 컨펌쇼4를 false로 만들어서 앨럿창 사라지게 / data5는 문구 / confirmshow4는 state임
                    <MsgConfirmModal confirm4={confirm4} msgData6={MsgConfirmData5} confirmShow4={confirmShow4}/>
                    :
                    null
                }
                {photoDeleteSelectSt === true ?
                    <MsgSelectModal photoDeleteSelectSt={photoDeleteSelectSt} photoDeleteCancelFn={photoDeleteCancelFn}
                                    photoDeleteExecution={profileDelete} msgData7={MsgConfirmData6}
                    />
                    :
                    null
                }
                {photoDeleteConfirmSt === true ?
                    <MsgConfirmModal photoDeleteConfirmSt={photoDeleteConfirmSt}
                                     photoDeleteConfirmFn={photoDeleteConfirmFn} MsgConfirmData7={MsgConfirmData7}/>
                    :
                    null
                }
                {deliAddrBookSt === true ?
                    <MAddrBookPg addrBookCloseFn={addrBookCloseFn} deliAddrBookSt={deliAddrBookSt}/>
                    :
                    null
                }
            </div>
        </>
    )
}

export default withAuth(MUserInfoModiPg);
