import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {BiCaretDown} from "react-icons/bi";
import weight from "../../assets/img/weight.png";
import {useTranslation} from "react-i18next";

function UserCompareShipPg() {

    const { t } = useTranslation();

    const [countryList, setCountryList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({ value: '', label: t(`ordCrLabel`)});
    const [countryShow, setCountryShow] = useState(false);
    const [filteredCountryList, setFilteredCountryList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const countryRef = useRef(null);

    const toggleCountryShow = () => {
        setCountryShow(prev => !prev);
    };

    const handleCountryChange = (country) => {
        setSelectedCountry(country);
        setSearchKeyword('');
        // setCountryShow(false);
        setCountryShow(true);
    };


    const countryNameMapping = {
        'USA': 'United States of America',
    };

    const handleCountrySearch = (event) => {
        const keyword = event.target.value;
        setSearchKeyword(keyword);

        const mappedKeyword = countryNameMapping[keyword.toUpperCase()] || keyword;

        const filteredCountries = countryList.filter(country =>
            country.label.toLowerCase().includes(mappedKeyword.toLowerCase())
        );

        setFilteredCountryList(filteredCountries);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (countryRef.current && !countryRef.current.contains(event.target)) {
                setCountryShow(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const selectAllCountry = () => {
        axios.get('/order/selectCountry')
            .then(response => {
                const countries = response.data.map(country => ({
                    value: country.countryCode,
                    label: `${country.countryNm} (${country.countryEngNm})`
                })).sort((a, b) => a.label.localeCompare(b.label));

                setCountryList(countries);
                setFilteredCountryList(countries);
            })
            .catch(error => {
                console.error("Error:", error);
            });
    };

    useEffect(() => {
        selectAllCountry();
    }, [])

    const [realWeight, setRealWeight] = useState('');
    const [wid, setWid] = useState('');
    const [len, setLen] = useState('');
    const [hei, setHei] = useState('');

    const [fedexAppWeight, setFedExAppWeight] = useState('');
    const [dhlAppWeight, setDhlAppWeight] = useState('');
    const [emsAppWeight, setEmsAppWeight] = useState('');
    const [upsAppWeight, setUpsAppWeight] = useState('');
    const [kpackAppWeight, setKpackAppWeight] = useState('');

    const [fedexPrice, setFedexPrice] = useState('');
    const [dhlPrice, setDhlPrice] = useState('');
    const [emsPrice, setEmsPrice] = useState('');
    const [upsPrice, setUpsPrice] = useState('');
    const [kpackPrice, setKpackPrice] = useState('');
    const [smallPackPrice, setSmallPackPrice] = useState('');
    const [seaPrice, setSeaPrice] = useState('');

    const initWeight = (() => {
        setRealWeight('');
        setWid('');
        setLen('');
        setHei('');
    })

    const findPrice = (weight, priceList) => {
        const weightInGrams = Math.round(weight * 1000); // kg를 그램으로 변환, 반올림 처리
        const weightKeys = Object.keys(priceList).map(key => parseInt(key.substring(1))); // "w500" -> 500
        const sortedWeights = weightKeys.sort((a, b) => a - b);

        for (let i = 0; i < sortedWeights.length; i++) {
            if (weightInGrams <= sortedWeights[i]) {
                return priceList[`w${sortedWeights[i]}`]; // 해당 무게에 맞는 가격 반환
            }
        }
        return t('cspWtNoSurch'); // 무게구간에 해당하지 않을 경우 '별도문의' 반환
    };

    // 실무게&가로&세로&높이 모두 입력될때만 DB에 조회쿼리
    useEffect(() => {
        if (selectedCountry.value !== '' && realWeight !== '' && wid !== '' && len !== '' && hei !== '') {
            selectedShipFee(selectedCountry.value);
        }
    }, [selectedCountry, realWeight, wid, len, hei])

    const selectedShipFee = ((crCd) => {
        axios({
            method: 'get',
            url: '/shipinfo/selectedShipFee',
            params: {
                crCd: crCd
            }
        })
            .then((response) => {
                const data = response.data;

                const divideBy6000 = wid * len * hei / 6000;
                const divideBy5000 = wid * len * hei / 5000;
                const fedexAppWeight = Math.max(realWeight, divideBy5000);
                const upsAppWeight = Math.max(realWeight, divideBy5000);
                const dhlAppWeight = Math.max(realWeight, divideBy5000);
                const emsAppWeight = Math.max(realWeight, divideBy6000);
                const kpackAppWeight = Math.max(realWeight, divideBy6000);
                const smallPackAppWeight = Math.max(realWeight);
                const seaAppWeight = Math.max(realWeight);
                // 위 두 계산값 모두 소숫점 2자리까지만 출력
                const formattedFedexAppWeight = parseFloat(fedexAppWeight.toFixed(2));
                const formattedUpsAppWeight = parseFloat(upsAppWeight.toFixed(2));
                const formattedDhlAppWeight = parseFloat(dhlAppWeight.toFixed(2));
                const formattedEmsAppWeight = parseFloat(emsAppWeight.toFixed(2));
                const formattedKpackAppWeight = parseFloat(kpackAppWeight.toFixed(2));
                const formattedSmallPackAppWeight = parseFloat(smallPackAppWeight.toFixed(2));
                const formattedSeaAppWeight = parseFloat(seaAppWeight.toFixed(2));

                setFedExAppWeight(formattedFedexAppWeight);
                setUpsAppWeight(formattedUpsAppWeight);
                setDhlAppWeight(formattedDhlAppWeight);
                setEmsAppWeight(formattedEmsAppWeight);
                setKpackAppWeight(formattedKpackAppWeight);

                // 가격찾고 서비스하지 않는 국가로 인해 null이면 '별도문의 반환'
                let fedexPrice = data.exrInfoFEDEXDTO ? findPrice(formattedFedexAppWeight, data.exrInfoFEDEXDTO) : t('cspWtNoSurch');
                let upsPrice = data.exrInfoUPSDTO ? findPrice(formattedUpsAppWeight, data.exrInfoUPSDTO) : t('cspWtNoSurch');
                let dhlPrice = data.exrInfoDHLDTO ? findPrice(formattedDhlAppWeight, data.exrInfoDHLDTO) : t('cspWtNoSurch');
                let emsPrice = data.exrInfoEMSDTO ? findPrice(formattedEmsAppWeight, data.exrInfoEMSDTO) : t('cspWtNoSurch');
                let kpackPrice = data.exrInfoEMSDTO ? findPrice(formattedKpackAppWeight, data.exrInfoEMSDTO) : t('cspWtNoSurch');
                let smallPackPrice = data.exrInfoEMSDTO ? findPrice(realWeight, data.exrInfoEMSDTO) : t('cspWtNoSurch');
                let seaPrice = data.exrInfoSHIPDTO ? findPrice(realWeight, data.exrInfoSHIPDTO) : t('cspWtNoSurch');

                // fedex 유류할증료 합산
                if (typeof fedexPrice === "number" && data.countryDTO && data.countryDTO.fedexFuelSurcharge > 0) {
                    // 백분율을 소수점으로 변환하여 fedexPrice에서 fedexFuelSurcharge값만큼 fedexPrice에 +=
                    const surchargeRate = data.countryDTO.fedexFuelSurcharge / 100;
                    const fuelSurcharge = fedexPrice * surchargeRate;
                    const roundedFuelSurcharge = Math.round(fuelSurcharge);
                    // 소숫점 제외한 전체 결과값인 fuelSurcharge값을 산출
                    fedexPrice += roundedFuelSurcharge;
                    // 전체 결과값에서 10원 단위로 반올림
                    fedexPrice = Math.round(fedexPrice / 10) * 10;
                }
                // fedex 운송수수료 합산
                if (typeof fedexPrice === "number" && data.countryDTO.fedexFee > 0) {
                    const additionalFedexFee = formattedFedexAppWeight * 10 * data.countryDTO.fedexFee;
                    fedexPrice += additionalFedexFee;
                }

                // ups 유류할증료 합산
                if (typeof upsPrice === "number" && data.countryDTO && data.countryDTO.upsFuelSurcharge > 0) {
                    // 백분율을 소수점으로 변환하여 fedexPrice에서 fedexFuelSurcharge값만큼 fedexPrice에 +=
                    const surchargeRate = data.countryDTO.upsFuelSurcharge / 100;
                    const fuelSurcharge = upsPrice * surchargeRate;
                    const roundedFuelSurcharge = Math.round(fuelSurcharge);
                    // 소숫점 제외한 전체 결과값인 fuelSurcharge값을 산출
                    upsPrice += roundedFuelSurcharge;
                    // 전체 결과값에서 10원 단위로 반올림
                    upsPrice = Math.round(upsPrice / 10) * 10;
                }
                // ups 운송수수료 합산
                if (typeof upsPrice === "number" && data.countryDTO.upsFee > 0) {
                    const additionalUpsFee = formattedUpsAppWeight * 10 * data.countryDTO.upsFee;
                    upsPrice += additionalUpsFee;
                }

                // dhl 유류할증료 합산
                if (typeof dhlPrice === "number" && data.countryDTO && data.countryDTO.dhlFuelSurcharge > 0) {
                    // 백분율을 소수점으로 변환하여 dhlPrice에서 dhlFuelSurcharge값만큼 dhlPrice에 +=
                    const surchargeRate = data.countryDTO.dhlFuelSurcharge / 100;
                    const fuelSurcharge = dhlPrice * surchargeRate;
                    const roundedFuelSurcharge = Math.round(fuelSurcharge);
                    // 소숫점 제외한 전체 결과값인 fuelSurcharge값을 산출
                    dhlPrice += roundedFuelSurcharge;
                    // 전체 결과값에서 10원 단위로 반올림
                    dhlPrice = Math.round(dhlPrice / 10) * 10;
                }
                // dhl 운송수수료 합산
                if (typeof dhlPrice === "number" && data.countryDTO.dhlFee > 0) {
                    const additionalDhlFee = formattedFedexAppWeight * 10 * data.countryDTO.dhlFee;
                    dhlPrice += additionalDhlFee;
                }

                // ems 운송수수료 합산
                if (typeof emsPrice === "number" && data.countryDTO.emsFee > 0) {
                    const additionalEmsFee = formattedEmsAppWeight * 10 * data.countryDTO.emsFee;
                    emsPrice += additionalEmsFee;
                }

                // K-Packet 운송수수료 합산
                if (typeof kpackPrice === "number" && data.countryDTO.kpackFee > 0) {
                    const additionalKpackFee = formattedKpackAppWeight * 10 * data.countryDTO.kpackFee;
                    kpackPrice += additionalKpackFee;
                }

                // Small-Packet 운송수수료 합산
                if (typeof smallPackPrice === "number" && data.countryDTO.airSFee > 0) {
                    const additionalSmallPackFee = formattedSmallPackAppWeight * 10 * data.countryDTO.airSFee;
                    smallPackPrice += additionalSmallPackFee;
                }

                // sea 운송수수료 합산
                if (typeof seaPrice === "number" && (data.countryDTO.countryCode === "US" || data.countryDTO.countryCode === "CA")) {
                    const additionalSeaFee = formattedSeaAppWeight * 10 * data.countryDTO.seaFee;
                    seaPrice += additionalSeaFee;
                }

                setFedexPrice(fedexPrice);
                setUpsPrice(upsPrice);
                setDhlPrice(dhlPrice);
                setEmsPrice(emsPrice);
                setKpackPrice(kpackPrice);
                setSmallPackPrice(smallPackPrice);
                setSeaPrice(seaPrice);
            })
            .catch((error) => {
                console.log("배송사별 요금조회 실패 : ", error)
            })
    })

    return (
            <div className="display-flex just-cont-center align-center font-t-cont c-def" style={{minHeight: "700px"}}>
                <div className="">
                    <div className="display-flex ht-40 just-cont-sb align-center">
                        <div className="display-flex just-cont-center">
                            <img className="wd-30 ht-30" src={weight} alt="header"/>
                            <p className="font-t-title m-l-10 font-s-18 m-t-3">{t(`cspHeader`)}</p> {/*배송료비교*/}
                        </div>
                    </div>
                    <div className="wd-500 border-bt border-rd4 pd20">
                        <div className="display-flex align-center border-bottom-bt ht-40 m-b-20">
                            <p className="font-s-16">{t(`cspSmallHeader1`)}</p> {/*무게정보*/}
                        </div>
                        <div className="display-flex flex-row just-cont-sb align-center m-b-20">
                            <div className="wd-30pc"><p className="font-s-14">{t(`modSmallMenu3`)}</p> {/*국가*/}</div>
                            <div className="wd-70pc">
                                <div ref={countryRef} className="ht-45 wd-100pc c-point position-relative" onClick={toggleCountryShow}>
                                    <div className="order-rec-country-input display-flex flex-row wd-100pc ht-100pc">
                                        {selectedCountry.value ? (
                                            <div className="wd-15pc text-center align-center-c">
                                                <div className={`m-l-6 font-s-20 fi fi-${selectedCountry.value.toLowerCase()}`} />
                                            </div>
                                        ) : (
                                            <div className="wd-15pc text-center align-center-c p-l-5">
                                                <div className="wd-29 ht-22 text-center font-s-12 m-0-a country-select-flag">?</div>
                                            </div>
                                        )}
                                        <input className="country-select-in-input wd-100pc ht-100pc c-point font-s-14 p-l-15"
                                               type="text" placeholder={t(`modSmallMenu3-1`)} autoComplete="off"
                                               value={searchKeyword || (selectedCountry.value ? selectedCountry.label : '') || (selectedCountry.value ? selectedCountry.label : '')}
                                               onChange={handleCountrySearch} />
                                        <i className="wd-10pc text-center align-center-c font-s-20 p-b-2 font-c-darkgrey">
                                            <BiCaretDown />
                                        </i>
                                    </div>
                                    {countryShow && (
                                        <div className="country-select-data wd-100pc position-absolute m-t-5">
                                            {filteredCountryList.map((country, index) => (
                                                <div className="selectChildBt display-flex c-point p-t-10 p-b-10 p-l-20" key={index}
                                                     onClick={() => handleCountryChange(country)}>
                                                    <div className={`font-s-17 m-r-8 fi fi-${country.value.toLowerCase()}`}></div>
                                                    <p className="font-s-15">{country.label}</p>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                {/*region 기존*/}
                                {/*<div ref={countryRef} style={{height: "90%", width: "100%", position: "relative", paddingTop:"6px"}}>*/}
                                {/*    <button className="selectParentBt" style={{width: "100%", height: "90%"}}*/}
                                {/*            onClick={toggleCountryShow}>*/}
                                {/*        <p>{selectedCountry.label}</p>*/}
                                {/*        <span style={{position: "absolute", left: "85%", top: "5%", height: "100%", width: "20%"}}>*/}
                                {/*    <i style={{*/}
                                {/*        display: "flex",*/}
                                {/*        justifyContent: "center",*/}
                                {/*        alignItems: "center",*/}
                                {/*        height: "100%"*/}
                                {/*    }}><BiCaretDown/></i>*/}
                                {/*</span>*/}
                                {/*    </button>*/}
                                {/*    {countryShow ?*/}
                                {/*        <div style={{*/}
                                {/*            border: "solid",*/}
                                {/*            height: "400px",*/}
                                {/*            borderWidth: "thin 1px",*/}
                                {/*            borderColor: "lightgray",*/}
                                {/*            marginTop: "5px",*/}
                                {/*            overflowY: "scroll",*/}
                                {/*        }}>*/}
                                {/*            {countryList.map((country, index) => (*/}
                                {/*                <div className="selectChildBt" key={index}*/}
                                {/*                     onClick={() => handleCountryChange(country)} style={{*/}
                                {/*                    display: "flex", padding: "10px", justifyContent: "center",*/}
                                {/*                    alignItems: "center", cursor: "pointer", fontSize: "14px", borderRadius: "4px",*/}
                                {/*                }}>{country.label}</div>*/}
                                {/*            ))}*/}
                                {/*        </div>*/}
                                {/*        :*/}
                                {/*        null*/}
                                {/*    }*/}
                                {/*</div>*/}
                                {/*endregion*/}
                            </div>
                        </div>
                        <div className="display-flex flex-row just-cont-sb align-center m-b-20">
                            <div className="wd-30pc"><p className="font-s-14">{t(`cspRw`)}(kg)</p> {/*무게*/}</div>
                            <div className="wd-70pc">
                                <input type="text" className="border-bt ht-45 wd-100pc p-l-5 border-rd4 font-s-16 font-b"
                                       value={realWeight} maxLength="5" onChange={(e)=> setRealWeight(e.target.value)}/>
                            </div>
                        </div>
                        <div className="display-flex flex-row just-cont-sb align-center m-b-20">
                            <div className="wd-30pc"><p className="font-s-14">{t(`cspV`)}(cm)</p> {/*부피*/}</div>
                            <div className="wd-70pc display-flex flex-row just-cont-sb">
                                <div className="wd-28pc">
                                    <input type="text" className="border-bt wd-100pc ht-45 text-center border-rd4 font-s-16 font-b"
                                           value={wid} maxLength="3" onChange={(e)=> setWid(e.target.value)}/>
                                </div>
                                <div className="align-center"><p className="font-s-18 p-t-10">*</p></div>
                                <div className="wd-28pc">
                                    <input type="text" className="border-bt wd-100pc ht-45 text-center border-rd4 font-s-16 font-b"
                                           value={len} maxLength="3" onChange={(e)=> setLen(e.target.value)}/>
                                </div>
                                <div className="align-center"><p className="font-s-18 p-t-10">*</p></div>
                                <div className="wd-28pc">
                                    <input type="text" className="border-bt wd-100pc ht-45 text-center border-rd4 font-s-16 font-b"
                                           value={hei} maxLength="3" onChange={(e)=> setHei(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        <div className="display-flex flex-row just-cont-sb align-center m-b-20">
                            <div className="wd-30pc"><p className="font-s-14">{t(`cspVw`)}(cm)</p> {/*부피무게*/}</div>
                            {emsAppWeight && fedexAppWeight ?
                                <div className="wd-70pc display-flex flex-row just-cont-fstart" style={{color: "dimgray"}}>
                                    <p>{emsAppWeight}kg</p><p>&nbsp;~&nbsp;</p><p>{fedexAppWeight}kg</p>
                                </div>
                                :
                                null
                            }
                        </div>
                        <div className="m-b-40 text-center">
                            <button className="bt-btn-A wd-40pc ht-45 font-s-14" onClick={initWeight}>{t(`cspBt`)} {/*초기화 버튼*/}</button>
                        </div>
                        <div className="display-flex align-center border-bottom-bt ht-40 m-b-20">
                            <p className="font-s-16">{t(`cspSmallHeader2`)}</p> {/*최종배송료*/}
                        </div>
                        <div className="display-flex flex-column gap3">
                            {/*FedEx 배송료*/}
                            <div className="display-flex ht-35">
                                <div className="wd-70pc display-flex align-center">
                                    <p className="font-s-13">FedEx&nbsp;</p>
                                    { fedexAppWeight ?
                                        <span className="font-s-12" style={{color: "dimgray"}}>({t(`cspAw`)}
                                            <span className="font-c-btorage font-s-12"> {fedexAppWeight}</span>kg)</span>
                                        :
                                        null
                                    }
                                </div>
                                <div className="wd-70pc display-flex just-cont-fend align-center">
                                    {/*추후 FedEx 배송료+유류할증료 연동*/}
                                    <span className="font-s-13" style={{color: !fedexPrice ? "#1f1f1f" : "#FFAF34", fontWeight: fedexPrice ? "bold" : null}}>
                                    &#8361; {fedexPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                </span>
                                </div>
                            </div>
                            {/*UPS 배송료*/}
                            <div className="display-flex ht-35">
                                <div className="wd-70pc display-flex align-center">
                                    <p className="font-s-13">UPS&nbsp;</p>
                                    { upsAppWeight ?
                                        <span className="font-s-12" style={{color: "dimgray"}}>({t(`cspAw`)}
                                            <span className="font-c-btorage font-s-12"> {upsAppWeight}</span>kg)</span>
                                        :
                                        null
                                    }
                                </div>
                                <div className="wd-70pc display-flex just-cont-fend align-center">
                                    <span className="font-s-13" style={{color: !upsPrice ? "#1f1f1f" : "#FFAF34", fontWeight: upsPrice ? "bold" : null}}>
                                    &#8361; {upsPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                </span>
                                </div>
                            </div>
                            {/*EMS 배송료*/}
                            <div style={{height: "35px", display:"flex"}}>
                                <div style={{width:"70%", display:"flex", alignItems:"center"}}>
                                    <p style={{margin:"0", fontSize:"13px", fontWeight:"lighter", display:"flex", alignItems:"center", color:"black"}}>EMS</p>
                                    { emsAppWeight ?
                                        <span style={{fontSize:"12px", color:"dimgray", marginLeft:"5px"}}>({t(`cspAw`)}
                                            <span style={{fontSize:"12px", fontWeight:"bold", color:"darkorange"}}> {emsAppWeight}</span>kg)</span>
                                        :
                                        null
                                    }
                                </div>
                                <div style={{width:"30%", fontSize:"13px", display:"flex", alignItems:"center", justifyContent:"flex-end"}}>
                                    {/*추후 EMS 배송료+코로나수수료 연동*/}
                                    <span style={{color: !emsPrice ? "black" : "orange", fontWeight: emsPrice ? "bold" : null}}>
                                    &#8361; {emsPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                </span>
                                </div>
                            </div>
                            {/*K-P 배송료*/}
                            <div style={{height: "35px", display:"flex"}}>
                                <div style={{width:"70%", display:"flex", alignItems:"center"}}>
                                    <p style={{fontSize:"13px", fontWeight:"lighter", display:"flex", alignItems:"center", color:"black"}}>K-Packet</p>
                                    { kpackAppWeight ?
                                        <span style={{fontSize:"12px", color:"dimgray", marginLeft:"5px"}}>({t(`cspAw`)}
                                            <span style={{fontSize:"12px", fontWeight:"bold", color:"darkorange"}}> {kpackAppWeight}</span>kg)</span>
                                        :
                                        null
                                    }
                                </div>
                                <div style={{width:"30%", fontSize:"13px", display:"flex", alignItems:"center", justifyContent:"flex-end"}}>
                                <span style={{color: !kpackPrice ? "black" : "orange", fontWeight: kpackPrice ? "bold" : null}}>
                                    &#8361; {kpackPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                </span>
                                </div>
                            </div>
                            {/*Small-Packet 배송료*/}
                            <div style={{height: "35px", display:"flex"}}>
                                <div style={{width:"70%", display:"flex", alignItems:"center"}}>
                                    <p style={{fontSize:"13px", fontWeight:"lighter", display:"flex", alignItems:"center", color:"black"}}>Small-Packet</p>
                                    { realWeight ?
                                        <span style={{fontSize:"12px", color:"dimgray", marginLeft:"5px"}}>({t(`cspAw`)}
                                            <span style={{fontSize:"12px", fontWeight:"bold", color:"darkorange"}}> {realWeight}</span>kg)</span>
                                        :
                                        null
                                    }
                                </div>
                                <div style={{width:"30%", fontSize:"13px", display:"flex", alignItems:"center", justifyContent:"flex-end"}}>
                                <span style={{color: !smallPackPrice ? "black" : "orange", fontWeight: smallPackPrice ? "bold" : null}}>
                                    &#8361; {smallPackPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                                </span>
                                </div>
                            </div>
                            {/*선편 배송료*/}
                            <div style={{height: "35px", display:"flex"}}>
                                <div style={{width:"70%", display:"flex", alignItems:"center"}}>
                                    <p style={{fontSize:"13px", fontWeight:"lighter", display:"flex", alignItems:"center", color:"black"}}>Sea Parcel</p>
                                    { realWeight ?
                                        <span style={{fontSize:"12px", color:"dimgray", marginLeft:"5px"}}>({t(`cspAw`)}
                                            <span style={{fontSize:"12px", fontWeight:"bold", color:"darkorange"}}> {realWeight}</span>kg)</span>
                                        :
                                        null
                                    }
                                </div>
                                <div style={{width:"30%", fontSize:"13px", display:"flex", alignItems:"center", justifyContent:"flex-end"}}>
                                    {/*추후 선편배송료 연동*/}
                                    <span style={{color: !seaPrice ? "black" : "orange", fontWeight: seaPrice ? "bold" : null}}>&#8361; {seaPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}</span>
                                </div>
                            </div>
                        </div>

                        <div style={{marginTop:"15px", height:"20px"}}><span style={{fontSize:"12px", color:"dimgray"}}>※ {t('cspNt1')}</span></div>
                        <div><span style={{fontSize:"12px", color:"#4B89DC"}}>※ {t('cspNt2')}</span></div>
                    </div>
                </div>
            </div>
    )
}

export default UserCompareShipPg
