import React, {useEffect, useRef, useState} from 'react';
import '../../../css/web/UserHeaderMain.css'
import HeaderLogo from '../../../assets/img/HeaderLogo.png'
import {useLocation} from "react-router-dom";
import profileImage from '../../../assets/img/profileDefaultImg.png'
import clock from '../../../assets/img/clock.png'
import selectLang from '../../../assets/img/selectLang.png'
import ordChatAlarm from '../../../assets/img/orderChatAlarm.png'
import {useNavigate} from "react-router-dom";
import axios from "axios";
import SelectLangModal from "../modal/SelectLangModal";
import KoreaTime from "../modal/KoreaTime";
import noMessage from "../../../assets/img/noMessage.png"

import i18n from '../../../i18n';
import {useTranslation} from "react-i18next";
import {useWebSocket} from "../../../services/WebsocketContext";

function UserHeaderMain() {
    const location = useLocation();
    const navigate = useNavigate();
    const {t} = useTranslation();

    // 세션스토리지에 저장되어 있는 AT
    const SSAT = sessionStorage.getItem('AToken');
    // 로컬스토리지에 저장되어 있는 AT
    const LCAT = localStorage.getItem('AToken');

    // 로그아웃 axios
    const logout = () => axios({
        method: 'post',
        url: '/member/logout',
        headers: {
            Authorization: LCAT ? LCAT : SSAT
        }
    })
        .then(() => {
            if (SSAT) {
                sessionStorage.removeItem('AToken')
                sessionStorage.removeItem('lastLoginTime')
            } else {
                localStorage.removeItem('AToken');
                localStorage.removeItem('lastLoginTime')
            }
            navigate("/UserLoginPg")
        })
        .catch(() => {
            if (SSAT) {
                sessionStorage.removeItem('AToken')
                sessionStorage.removeItem('lastLoginTime')
            } else {
                localStorage.removeItem('AToken');
                localStorage.removeItem('lastLoginTime')
            }
            navigate("/UserLoginPg")
        })

    //메인메뉴 드롭다운 state
    const [dropMenu, setDropMenu] = useState(0);

    const [profilePhotoLine, setProfilePhotoLine] = useState(profileImage);
    const [profileName, setProfileName] = useState("");
    const [memberDto, setMemberDto] = useState([]);

    const [openImagePop, setOpenImagePop] = useState(false);
    const [imageSrc, setImageSrc] = useState('');

    const openImageP = (imageSrc) => {
        setImageSrc(imageSrc);
        setOpenImagePop(true);
    };

    const closeImageP = () => {
        setOpenImagePop(false);
        setImageSrc('');
    };

    const selectMember = (() => {
        axios({
            method: 'get',
            url: '/member/modiInfo',
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            }
        })
            .then((response) => {
                setMemberDto(response.data[0]);
                setProfileName(response.data[0].userName)

                if (response.data && response.data[0] && response.data[0].profileDTO && response.data[0].profileDTO.filename) {
                    setProfilePhotoLine("https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + response.data[0].profileDTO.filename)
                } else {
                    setProfilePhotoLine(profileImage)
                }
            })
            .catch(() => {
                console.log("실패")
            })
    })

    useEffect(() => {
        if (LCAT || SSAT) {
            selectMember();
        }
    }, [LCAT, SSAT]);

    useEffect(() => {
        if (memberDto && memberDto.mbNum) {
            getChatData(memberDto.mbNum);
        }
    }, [memberDto]);

    const [chatData, setChatData] = useState([]);
    const [totalUnreadCount, setTotalUnreadCount] = useState(0);

    const getChatData = (mbNum) => {
        axios.get(`/order/chatAlarmList/${mbNum}`, {
            headers: {
                Authorization: LCAT ? LCAT : SSAT
            }
        })
            .then(response => {
                let data = response.data;
                data.sort((a, b) => b.seqnum - a.seqnum);
                // data = data.slice(0, 6);
                setChatData(data)
                const unreadCountUpdate = data.reduce((total, chat) => total + (chat.unreadCount || 0), 0);
                setTotalUnreadCount(unreadCountUpdate);
            })
            .catch(error => {
                console.log(error);
            })
    }

    // 웹소켓
    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!memberDto.mbNum || isSubscribedRef.current) return;

            if (!stompClient && !isConnected) {
                await websocketConnect(memberDto.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                // 관리자메시지
                subscribe(`/user/${memberDto.mbNum}/queue/adminSubMessage`, onMessageReceived)
                // 전송메시지를 관리자가 읽을 시 수신
                subscribe(`/user/${memberDto.mbNum}/queue/headerCountInit`, onChatAdminUpdate)
                // 메시지 및 파일챗 변경 시 수신
                subscribe(`/user/${memberDto.mbNum}/queue/adminChatUpdate`, onChatAdminUpdate)
                subscribe(`/user/${memberDto.mbNum}/queue/adminUpdateOrder`, updateOrderList)
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                unsubscribe(`/user/${memberDto.mbNum}/queue/adminSubMessage`)
                unsubscribe(`/user/${memberDto.mbNum}/queue/headerCountInit`)
                unsubscribe(`/user/${memberDto.mbNum}/queue/adminChatUpdate`)
                unsubscribe(`/user/${memberDto.mbNum}/queue/adminUpdateOrder`)
                isSubscribedRef.current = false;
            }
        };

    }, [memberDto.mbNum, isConnected, stompClient]);

    const onMessageReceived = async (payload) => {
        let newMessage = JSON.parse(payload.body);
        if (Number(newMessage.mstMbNum) === Number(memberDto.mbNum) && Number(newMessage.memberDTO.role) === 1) {
            getChatData(memberDto.mbNum);
        }
    }

    const onChatAdminUpdate = ((payload) => {
        getChatData(memberDto.mbNum);
    })

    const updateOrderList = ((payload) => {
        getChatData(memberDto.mbNum);
    })



    const [dropdownSt, setDropdownSt] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setDropdownSt((prev) => !prev);
    };

    const dropdownClose = (() => {
        setDropdownSt(false);
    })

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                dropdownClose();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    const myOrdPgMove = (ordNum) => () => {
        setDropdownSt(false);
        navigate(`/UserMyOrderPg/${ordNum}`)
    };

    // 언어선택
    const [selectLangSt, setSelectLangSt] = useState(false);
    const selectLangTrueFn = (() => {
        setSelectLangSt(true);
    })
    const selectLangFalseFn = (() => {
        setSelectLangSt(false);
    })
    const handleSelectLangChange = (lang) => {
        setSelectLangSt(false);
        // 로컬스토리지 언어변경 + i18next 언어변경 code
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', lang);
    }

    const [koreaTimeSt, setKoreaTimeSt] = useState(false);
    const koreaTimeTrueFn = (() => {
        setKoreaTimeSt(true);
    })
    const koreaTimeFalseFn = (() => {
        setKoreaTimeSt(false);
    })

    const moveToMyPg = (() => {
        navigate("/UserMyPg")
    })

    const convertToLastChatDate = ((regDate) => {
        const regTime = new Date(regDate);
        const currentTime = new Date();
        const timeDifference = currentTime - regTime;

        // 날짜 차이를 분으로 변환
        const minutesDiff = Math.floor(timeDifference / 60000);
        const hoursDiff = Math.floor(minutesDiff / 60);
        const daysDiff = Math.floor(hoursDiff / 24);

        // 날짜 차이가 1일 미만일 때
        if (daysDiff < 1) {
            if (minutesDiff < 1) return t('now');
            if (minutesDiff < 60) return `${minutesDiff}${t('min')}`;
            return `${hoursDiff}${t('hour')}`;
        } else if (daysDiff === 1) {
            return t('yesterday');
        } else if (daysDiff === 2) {
            return t('2daysAgo');
        } else if (daysDiff < 7) {
            return `${daysDiff}${t('daysAgo')}`;
        } else {
            // 7일 이상 차이날 때는 월-일 형식으로 반환
            return `${(regTime.getMonth() + 1).toString().padStart(2, '0')}-${regTime.getDate().toString().padStart(2, '0')}`;
        }
    })

    const handleMenuCart = () => {
        navigate("/UserOrderPg", {state: {cartState: true, headerText: t("hdMenu2")}});
    };
    const handleMenuOrder = () => {
        navigate("/UserOrderPg", {state: {orderState: true, headerText: t("hdSMenu6")}});
    };
    const handleMenuOffline = () => {
        navigate("/UserOrderPg", {state: {offlineState: true, headerText: t("hdSMenu8")}});
    };
    const handleMenuTransfer = () => {
        navigate("/UserOrderPg", {state: {transferState: true, headerText: t("hdSMenu7")}});
    };
    const handleMenuDomestic = () => {
        navigate("/UserOrderPg", {state: {domesticState: true, headerText: t("hdSMenu9")}});
    };
    const handleMenuDelivery = () => {
        navigate("/UserOrderPg", {state: {deliveryState: true, headerText: t("hdMenu4")}});
    };

    const mainRefresh = () => {
        navigate(`/`);
        setSelectedCategory(null);
        setServiceMenu(false);
        setOrderMenu(false);
    }

    const handleMenu = (menu) => {
        setSelectedCategory(menu);
        if (menu === "service") {
            setServiceMenu(true);
            setOrderMenu(false);
        } else if (menu === "order") {
            setServiceMenu(false);
            setOrderMenu(true);
        }
    };

    const [serviceMenu, setServiceMenu] = useState(false);
    const [orderMenu, setOrderMenu] = useState(false);

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [menuState, setMenuState] = useState({service: false, order: false});

    const handleMenuClick = (item) => {
        if (item === "service" || item === "order") {
            handleMenu(item);
        } else {
            setServiceMenu(false);
            setOrderMenu(false);
            setSelectedCategory(item);

            switch (item) {
                case t("hdMenu2"):
                    handleMenuCart();
                    break;
                case t("hdMenu3"):
                    handleMenuOrder();
                    break;
                case t("hdMenu4"):
                    handleMenuDelivery();
                    break;
                case t("hdMenu5"):
                    navigate("/UserReviewPg");
                    break;
                default:
                    break;
            }
        }
    };

    return (
        <div className="pc-bt-main-header wd-100pc position-fixed m-b-100 font-t-cont">
            <div className="display-flex flex-row just-cont-sb wd-1280 m-0-a align-center ht-60">
                {/*로고 & 상위카테고리*/}
                <div className="display-flex flex-row wd-65pc ht-100pc">
                    <div className="pc-bt-main-logo-frame wd-20pc ht-100pc align-center-c">
                        <img className="pc-bt-main-logo c-point" alt="mainLogo" src={HeaderLogo} onClick={mainRefresh}/>
                    </div>
                    <div className="display-flex flex-row just-cont-fstart wd-80pc ht-100pc">
                        <div className="min-wd-100 ht-100pc align-center-c btorage-hover text-center p-t-7">
                            <p className={`font-t-head font-s-17 c-point ${serviceMenu ? "bt-clicked" : ""}`} onClick={() => handleMenuClick("service")}>{t("hdMenu1")}</p> {/*서비스*/}
                        </div>
                        <div className={`min-wd-110 ht-100pc align-center-c btorage-hover text-center p-t-7 ${selectedCategory === t("hdMenu2") ? "bt-clicked" : ""}`}>
                            <p className="font-t-head font-s-17 c-point" onClick={() => handleMenuClick(t("hdMenu2"))}>{t("hdMenu2")}</p> {/*장바구니*/}
                        </div>
                        <div className="min-wd-110 ht-100pc align-center-c btorage-hover text-center p-t-7" >
                            <p className={`font-t-head font-s-17 c-point ${orderMenu ? "bt-clicked" : ""}`}
                               onClick={() => handleMenuClick("order")}>{t("hdMenu3")}</p> {/*구매대행*/}
                        </div>
                        <div className={`min-wd-110 ht-100pc align-center-c btorage-hover text-center p-t-7 ${selectedCategory === t("hdMenu4") ? "bt-clicked" : ""}`}>
                            <p className="font-t-head font-s-17 c-point" onClick={() => handleMenuClick(t("hdMenu4"))}>{t("hdMenu4")}</p> {/*배송대행*/}
                        </div>
                        <div className={`min-wd-110 ht-100pc align-center-c btorage-hover text-center p-t-7 ${selectedCategory === t("hdMenu5") ? "bt-clicked" : ""}`}>
                            <p className="font-t-head font-s-17 c-point" onClick={() => handleMenuClick(t("hdMenu5"))}>{t("hdMenu5")}</p> {/*이용후기*/}
                        </div>
                    </div>
                </div>
                {/*Sign*/}
                <div className="display-flex flex-row just-cont-fend wd-35pc ht-100pc">
                    <div className="display-flex flex-row just-cont-fend wd-100pc ht-100pc align-center-c">
                        <div className="min-wd-30 ht-100pc align-center-c m-r-7">
                            <img className="c-point wd-32 ht-32 m-a-opc-05 img-hover-opc" alt="lang" src={selectLang} onClick={selectLangTrueFn}/>
                        </div>
                        <div className="min-wd-30 ht-100pc align-center-c m-r-7">
                            <img className="c-point wd-32 ht-32 m-a-opc-07 img-hover-opc" alt="clock" src={clock} onClick={koreaTimeTrueFn}/>
                        </div>
                        {LCAT || SSAT ?
                            <div className="display-flex flex-row font-t-cont align-center gap8 font-t-cont m-l-15">
                                <button onClick={moveToMyPg} className="bt-btn-E ht-35 wd-95 font-s-13">{t(`hdMypg`)}</button> {/*마이페이지*/}
                                <div ref={dropdownRef} className="profileImg">
                                    <div className="c-point wd-40 ht-40 border-50pc">
                                        <img className="wd-100pc ht-100pc m-a-opc-08 img-hover-opc border-50pc" alt="" style={{objectFit: "cover"}} src={profilePhotoLine} onClick={toggleDropdown} />
                                    </div>
                                    {totalUnreadCount > 0 ?
                                        <span className="display-flex just-cont-center align-center position-absolute font-c-fff font-s-12 m-l-5 wd-auto ht-16 font-s-11 font-c-fff p-l-4 p-r-4 c-def max-wd-30"
                                              style={{
                                                right: -5.5,
                                                top: -5,
                                                borderRadius: "100%",
                                                backgroundColor: "#FF6324",
                                                padding: "0 5px"
                                        }}>{totalUnreadCount}</span>
                                        :
                                        null
                                    }
                                    {/*마이페이지 열기*/}
                                    {openImagePop && (
                                        <div className="pop-dt-img-container" onClick={closeImageP}>
                                            <div className="pop-dt-img-frame" onClick={(e) => e.stopPropagation()}>
                                                <img className="pop-dt-img" src={imageSrc} alt="profileDelete" />
                                                <button className="pop-dt-close font-t-head" onClick={closeImageP}>X</button>
                                            </div>
                                        </div>
                                    )}
                                    {dropdownSt ?
                                        <div className="c-def wd-350 ht-auto position-absolute border-rd6 font-c-default"
                                             style={{right: 0, backgroundColor: "#FFFFFF", boxShadow: "0 8px 16px 0 rgba(0,0,0,0.2)", zIndex: "3", top: "55px",}}>
                                            <div className="display-flex flex-column just-cont-center wd-100pc ht-auto">
                                                <div className="display-flex just-cont-center wd-100pc ht-100 border-rd4 p-t-10">
                                                    <div className="wd-80 ht-80 display-flex align-center just-cont-center" style={{borderRadius: "50%", overflow: "hidden"}}>
                                                        <img className="display-inline wd-100pc ht-100pc c-point" style={{objectFit: "cover"}} alt="" src={profilePhotoLine}
                                                             onClick={() => openImageP(profilePhotoLine)}/>
                                                    </div>
                                                </div>
                                                <div className="wd-100pc text-center"><p className="font-t-title font-s-17">{profileName}</p></div>
                                                <div className="display-flex flex-column just-cont-center wd-100pc p-l-20 p-r-20 m-t-5 m-b-20">
                                                    <div className="display-flex flex-row just-cont-sb font-s-15">
                                                        <p>{t(`myOrdPc1`)}</p> {/*My사서함번호*/}
                                                        <div className="display-flex">
                                                            <p>BT&nbsp;</p>
                                                            <p className="font-c-btorage">{memberDto.mbNum}</p>
                                                        </div>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb font-s-15">
                                                        <p>{t(`myOrdPc2`)}</p> {/*My예치금*/}
                                                        <p className="font-c-btorage">&#8361; {memberDto && memberDto?.deposit?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || ''}</p>
                                                    </div>
                                                    <div className="display-flex flex-row just-cont-sb font-s-15">
                                                        <p>{t(`myOrdPc3`)}</p> {/*My적립금*/}
                                                        <p className="font-c-btorage">&#8361; {memberDto && memberDto?.point?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || ''}</p>
                                                    </div>
                                                </div>
                                                <div className="display-block ht-auto just-cont-center align-center pd10">
                                                    <div className="display-flex align-center m-b-10">
                                                        <img className="wd-24 ht-24 m-l-8 m-r-8" alt="alarmImg" src={ordChatAlarm}/>
                                                        <p className="font-s-15">{t(`hdChat`)}</p> {/*주문서채팅*/}
                                                    </div>
                                                    {chatData[0] ?
                                                        <div>
                                                            {chatData.map((data, index) => (
                                                                data.unreadCount > 0 && (
                                                                    <div key={index} style={{
                                                                        width: "95%",
                                                                        height: "60px",
                                                                        borderTop: "solid 1px lightgray",
                                                                        borderRadius: "0",
                                                                        justifyContent: "center",
                                                                        alignItems: "center",
                                                                        position: "relative",
                                                                        margin: "auto"
                                                                    }}>
                                                                        {/*관리자로부터 도착한 메시지 // button 클릭 시 주문번호를 가지고 navigate("/UserMyOrderPg")로 이동 */}
                                                                        <button className="chatListBt" style={{
                                                                            width: "100%",
                                                                            height: "100%",
                                                                            display: "flex",
                                                                            border: "none"
                                                                        }} onClick={myOrdPgMove(data.chatOrdNum)}>
                                                <span style={{
                                                    position: "absolute",
                                                    left: "35px",
                                                    top: "6px",
                                                    borderRadius: "100%",
                                                    width: "auto",
                                                    height: "16px",
                                                    backgroundColor: "#FF6324",
                                                    color: "white",
                                                    fontSize: "12px",
                                                    marginLeft: "5px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    padding: "0 5px"
                                                }}>{data.unreadCount}</span>
                                                                            <div style={{
                                                                                borderWidth: "thin 1px",
                                                                                borderColor: "rgba(0, 0, 0, 0.1)",
                                                                                height: "80%",
                                                                                width: "18%",
                                                                                marginRight: "5px",
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                overflow: "hidden",
                                                                                margin: "auto",
                                                                            }}><img style={{
                                                                                width: "45px",
                                                                                height: "45px",
                                                                                display: "block",
                                                                                margin: "auto",
                                                                                borderRadius: "100%",
                                                                            }} alt=""
                                                                                    src={data.memberDTO && data.memberDTO.profileDTO && data.memberDTO.profileDTO.filename ? "https://toeloperfile.s3.ap-northeast-2.amazonaws.com/profileImage/" + data.memberDTO.profileDTO.filename : HeaderLogo}/>
                                                                            </div>
                                                                            <div style={{
                                                                                width: "80%",
                                                                                padding: "0 8px",
                                                                                margin: "auto",
                                                                            }}>
                                                                                <div style={{
                                                                                    height: "50%",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "space-between"
                                                                                }}>
                                                                                    <p style={{
                                                                                        margin: "0",
                                                                                        fontSize: "13px",
                                                                                    }}>{data.chatOrdNum}
                                                                                    </p>
                                                                                    <p style={{
                                                                                        margin: "0",
                                                                                        fontSize: "10px",
                                                                                        color: "darkgray"
                                                                                    }}>{convertToLastChatDate(data.regTime)}</p>
                                                                                </div>
                                                                                <span style={{
                                                                                    height: "50%",
                                                                                    fontSize: "13px",
                                                                                    textAlign: "left",
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    color: "dimgrey",
                                                                                    overflow: "hidden",
                                                                                    textOverflow: "ellipsis",
                                                                                    whiteSpace: "nowrap"
                                                                                }}>{data.chatKey.startsWith("chatFile") ? data.fileNm : data.chatKey}</span>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                )
                                                            ))}
                                                        </div>
                                                        :
                                                        <div style={{
                                                            width: "100%",
                                                            height: "100px",
                                                            borderTop: "solid",
                                                            borderWidth: "thin 1px",
                                                            borderColor: "#EAEAEA"
                                                        }}>
                                                            <div style={{
                                                                height: "60%",
                                                                display: "flex",
                                                                alignItems: "flex-end",
                                                                justifyContent: "center"
                                                            }}>
                                                                <img alt="noData" style={{
                                                                    width: "35px",
                                                                    height: "35px",
                                                                    opacity: "0.6"
                                                                }} src={noMessage}/>
                                                            </div>
                                                            <div style={{
                                                                height: "40%",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                marginTop: "5px"
                                                            }}>
                                                                <p style={{
                                                                    margin: "0",
                                                                    fontSize: "14px",
                                                                    color: "darkgray",
                                                                    fontWeight: "lighter"
                                                                }}>No Message</p>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                {/*드랍다운 버튼*/}
                                                <div className="display-flex just-cont-center ht-60 align-center pd10 gap10">
                                                    <button className="bt-btn-E ht-35 wd-50pc font-s-13" onClick={() => navigate("/UserModiPg")}>{t(`hdBt1`)}</button> {/*정보수정*/}
                                                    <button className="bt-btn-E ht-35 wd-50pc font-s-13" onClick={logout}>{t(`hdBt2`)}</button> {/*로그아웃*/}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            :
                            <div className="display-flex flex-row font-t-cont align-center gap8">
                                <button onClick={() => navigate("/UserLoginPg")} className="bt-btn-E ht-35 wd-80 font-s-13">{t(`hdLogin`)}</button> {/*로그인*/}
                                <button onClick={() => navigate("/UserJoinPg")} className="bt-btn-B ht-35 wd-80 font-s-13">{t(`hdJoin`)}</button> {/*회원가입*/}
                            </div>
                        }
                    </div>
                </div>
            </div>
            {/*하위카테고리*/}
            {serviceMenu ?
                <div className="display-flex just-cont-center font-t-cont">
                    <div className="display-flex align-center" style={{width: "1280px"}}>
                        <div className="display-flex align-center c-point" style={{minWidth: "165px"}}></div>
                        <div className="ht-30 display-flex just-cont-sb m-l-30 font-s-13" style={{minWidth: "100px"}}>
                            <a className="btorage-hover font-t-cont c-point"
                               onClick={() => navigate('/UserUsageGuidePg')}
                               style={{minWidth: "80px", padding: "0 10px"}}>{t(`hdSMenu2`)}</a>
                            <a className="btorage-hover font-t-cont c-point"
                               onClick={() => navigate('/UserCustomInfoPg')}
                               style={{minWidth: "80px", padding: "0 10px"}}>{t(`hdSMenu3`)}</a>
                            <a className="btorage-hover font-t-cont c-point"
                               onClick={() => navigate('/UserCompareShipPg')}
                               style={{minWidth: "80px", padding: "0 10px"}}>{t(`hdSMenu4`)}</a>
                            <a className="btorage-hover font-t-cont c-point" onClick={() => navigate('/UserDepChargPg')}
                               style={{minWidth: "80px", padding: "0 10px"}}>{t(`hdSMenu5`)}</a>
                        </div>
                    </div>
                </div>
                :
                orderMenu ?
                    <div className="display-flex just-cont-center">
                        <div className="display-flex align-center" style={{width: "1280px"}}>
                            <div className="display-flex align-center c-point" style={{minWidth: "165px"}}></div>
                            <div className="ht-30 display-flex just-cont-sb m-l-30 font-s-13"
                                 style={{minWidth: "100px"}}>
                                <a className="btorage-hover font-t-cont c-point" onClick={handleMenuOrder}
                                   style={{minWidth: "80px", padding: "0 10px"}}>{t(`hdSMenu6`)}</a>
                                <a className="btorage-hover font-t-cont c-point" onClick={handleMenuTransfer}
                                   style={{minWidth: "80px", padding: "0 10px"}}>{t(`hdSMenu7`)}</a>
                                <a className="btorage-hover font-t-cont c-point" onClick={handleMenuOffline}
                                   style={{minWidth: "80px", padding: "0 10px"}}>{t(`hdSMenu8`)}</a>
                                <a className="btorage-hover font-t-cont c-point" onClick={handleMenuDomestic}
                                   style={{minWidth: "80px", padding: "0 10px"}}>{t(`hdSMenu9`)}</a>
                            </div>
                        </div>
                    </div>
                    :
                    null
            }
            {selectLangSt ?
                <SelectLangModal selectLangTrueFn={selectLangTrueFn} selectLangFalseFn={selectLangFalseFn}
                                 handleSelectLangChange={handleSelectLangChange}/>
                :
                koreaTimeSt ?
                    <KoreaTime koreaTimeFalseFn={koreaTimeFalseFn}/>
                    :
                    null
            }
        </div>
    )
}

export default UserHeaderMain
