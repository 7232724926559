import React, {useEffect, useState} from 'react';
import axios from "axios";
import i18n from "../../i18n";
import '../../css/web/AdminPopupSetPg.css';
import '../../css/Common.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import {Autoplay, Navigation, Pagination} from "swiper/modules";
import {useTranslation} from "react-i18next";

function MUserMainPopModal() {

    /** region DataSet */

    const [popupData, setPopupData] = useState([]);
    const [isSmallOpen, setIsSmallOpen] = useState(false);

    /** endregion */

    /** region Function */

    const {t} = useTranslation();

    const getFutureDate = (days) => {
        const date = new Date();
        date.setDate(date.getDate() + days);
        return date.toISOString();
    };

    const handlePopupClose = (popupSize, days) => {
        const futureDate = getFutureDate(days);
        localStorage.setItem(`${popupSize}MobilePopupHide`, futureDate);
        if (popupSize === 'small') setIsSmallOpen(false);
    };

    const checkPopupVisibility = () => {
        const now = new Date();
        const smallPopupHide = localStorage.getItem('smallMobilePopupHide');

        if (smallPopupHide) {
            const hideUntilDate = new Date(smallPopupHide);
            if (hideUntilDate > now) {
                setIsSmallOpen(false);
            } else {
                setIsSmallOpen(true);
            }
        } else {
            setIsSmallOpen(true);
        }
    };

    /** endregion */

    useEffect(() => {
        const selectPopupData = (lang) => {
            axios.get(`/popup/selectPopupList?lang=${lang}`)
                .then((response) => {
                    const filteredData = response.data.filter((popup) =>
                        popup.pmachineType === "1" && popup.psizeType === "0"
                    );
                    const popupData = filteredData.map((data) => ({
                        ...data,
                        imageUrl: data?.bannerPopPhotoDTOS?.[0]?.fileNm
                            ? `https://toeloperfile.s3.ap-northeast-2.amazonaws.com/popupImg/${data.bannerPopPhotoDTOS[0].fileNm}`
                            : null
                    }));
                    setPopupData(popupData);
                    if (popupData.some(popup => popup.psizeType === '0')) {
                        checkPopupVisibility();
                    }
                });
        };

        const handleLanguageChange = (lng) => {
            let lang;
            switch (lng) {
                case 'ko':
                    lang = '0';
                    break;
                case 'en':
                    lang = '1';
                    break;
                case 'ja':
                    lang = '2';
                    break;
                case 'zh':
                    lang = '3';
                    break;
                case 'id':
                    lang = '4';
                    break;
                default:
                    lang = '0';
            }
            selectPopupData(lang);
        };
        handleLanguageChange(i18n.language);
        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, []);

    const smallPopups = popupData.filter(popup => popup.psizeType === '0');

    if (!isSmallOpen) return null;

    return (
        <div className="m-a-custom-popup-container font-t-cont">
                <div className="custom-popup-frame m-popup-s-frame">
                    <div className="">
                        <div className="m-popup-s-frame-img c-p-main position-relative">
                            <div className="wd-100pc ht-100pc">
                                {smallPopups.length > 0 && (
                                    <Swiper className="scroll-container"
                                            modules={[Autoplay, Pagination, Navigation]}
                                            slidesPerView={1}
                                            loop={true}
                                            centeredSlides={true}
                                            autoplay={{ delay: 3500, disableOnInteraction: false }}
                                            pagination={{
                                                el: '.popup-custom-pagination',
                                                clickable: true,
                                                dynamicBullets: true,
                                            }}
                                            watchOverflow={true}
                                    >
                                        {smallPopups.map((popup, index) => (
                                            <SwiperSlide key={`popup-slide-${popup.id || index}`}>
                                                <div className="wd-100pc ht-100pc">
                                                    <img className="wd-100pc ht-100pc" src={popup.imageUrl} alt={`Slide ${index}`}
                                                         onClick={() => {
                                                             if (popup.pimgType === '1') {
                                                                 window.open(popup.purl, '_self');
                                                             }
                                                         }}
                                                    />
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                )}
                            </div>
                        </div>
                        <div className=" c-p-footer ht-45 display-flex flex-row just-cont-sb font-s-13">
                            <button className="wd-50pc ht-100pc text-center pop-btn font-c-default p-l-3 p-r-3" onClick={() => handlePopupClose('small', 1)}>{t(`popupFooterToday`)}</button> {/*오늘만 안 볼래요*/}
                            <button className="wd-50pc ht-100pc text-center pop-btn font-c-default" onClick={() => handlePopupClose('small', 0)}>{t(`popupFooterClose`)}</button> {/*닫기*/}
                        </div>
                    </div>
                </div>
        </div>
    );
}

export default MUserMainPopModal
