import React, { useEffect, useRef, useState} from 'react';
import '../../../css/web/SideQuickMenu.css'
import instaLogo from "../../../assets/img/insta_logo.png";
import mainChat from "../../../assets/img/mainChat.png";
import commonClose from "../../../assets/img/commonClose.png";
import mainChatPeople from "../../../assets/img/mainChatPeople.png";
import btorageLogo from "../../../assets/img/BtorageLogo.png";
import chatBot from "../../../assets/img/chatBot.png";
import mainChatUpload from "../../../assets/img/mainChatUpload.png";
import mainChatEmoji from "../../../assets/img/mainChatEmoji.png";
import mainChatSend from "../../../assets/img/mainChatSend.png";
import mainChatTrash from "../../../assets/img/mainChatTrash.png";
import mainChatBack from "../../../assets/img/mainChatBack.png";
import trash from "../../../assets/img/mainChatTrash.png";

import {RiKakaoTalkFill} from 'react-icons/ri';
import {IoChatbubblesSharp, IoLogoWechat} from 'react-icons/io5';
import {SiInstagram} from 'react-icons/si';
import {GoMail} from 'react-icons/go';
import {BsLine} from 'react-icons/bs';
import { useNavigate} from "react-router-dom";
import MsgCheckModal from "../modal/MsgCheckModal";
import axios from "axios";
import DOMPurify from "dompurify";
import Linkify from "react-linkify";
import Picker from "@emoji-mart/react";
import ChatFileUpload from "../modal/ChatFileUpload";
import ChatFileDown from "../modal/ChatFileDown";
import chatFileImg from "../../../assets/img/chatFolderImg.png";
import {useTranslation} from "react-i18next";
import {usePopup} from "../../contextApi/FooterOpenChatContext";
import {useWebSocket} from "../../../services/WebsocketContext";
import i18n from "../../../i18n";

function SideQuickMenu() {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const {isPopupOpen, setIsPopupOpen} = usePopup();

    const msgNum = localStorage.getItem('msgNum');
    const [lcstMsgNum, setLcstMsgNum] = useState(msgNum)

    const [chatOpen, setChatOpen] = useState(false);
    const [chatPopup, setChatPopup] = useState(false);
    const [chatMessages, setChatMessages] = useState([]);

    const [chatShow, setChatShow] = useState(false);
    const [unreadCount, setUnreadCount] = useState(null);

    const [dataDeadlineMessageSt, setDataDeadlineMessageSt] = useState(false);

    const openPopup = (() => {
        setChatPopup(!chatPopup);
        setChatOpen(false);
        resetUnreadChat(chatMessages[chatMessages.length - 1]);
        setDataDeadlineMessageSt(true);
        setTimeout(() => setDataDeadlineMessageSt(false), 10000);
    })

    const closeChatPopup = (() => {
        setChatPopup(false);
        setIsPopupOpen(false);
        setDataDeadlineMessageSt(false);
    })

    const [message, setMessage] = useState('')
    const [nUserChatRemoveSt, setNUserChatRemoveSt] = useState(false);
    const nUserChatRemove = async () => {
        axios({
            method: 'post',
            url: '/member/nUserChatRemove',
            params: {
                lcstMsgNum: lcstMsgNum
            }
        })
            .then(() => {
                setChatMessages([]);
                setNUserChatRemoveSt(false);
                setChatPopup(false);
            })
    };

    useEffect(() => {
        if (chatPopup) {
            const currentLanguage = i18n.language || 'ko';
            let lang;
            switch (currentLanguage) {
                case 'en':
                    lang = '1';
                    break;
                case 'ja':
                    lang = '2';
                    break;
                case 'zh':
                    lang = '3';
                    break;
                case 'id':
                    lang = '4';
                    break;
                default:
                    lang = '0'; // 기본 언어는 ko
            }
            selectAutoResList(lang);
        }
    }, [i18n.language, chatPopup]);

    const selectAutoResList = (lang) => axios({
        method: 'get',
        url: '/member/selectAutoResList/',
        params: {
            lang:lang,
        }
    })
        .then((response) => {
            setAutoResMstList(response.data)
        })

    const GenerateMsgNum = () => axios({
        method: 'get',
        url: '/member/GenerateMsgNum/',
    })
        .then((response) => {
            const data = response.data;
            setLcstMsgNum(data)
            localStorage.setItem('msgNum', data)
        })

    const getChatData = () => {
        axios.get(`/member/chatData/${lcstMsgNum}`, {})
            .then(response => {
                if (response && response.data.length > 0 && response.data[0].unreadCount) {
                    setUnreadCount(response.data[0].unreadCount)
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        if (!lcstMsgNum) {
            GenerateMsgNum();
        }
    }, [])

    useEffect(() => {
        if (lcstMsgNum) {
            getChatData();
        }
    }, [lcstMsgNum])

    useEffect(() => {
        if (isPopupOpen) {
            setChatPopup(true);
            resetUnreadChat(chatMessages[chatMessages.length - 1]);
        }
    }, [isPopupOpen]);

    const [autoResMstList, setAutoResMstList] = useState([]);
    const [autoResDtList, setAutoResDtList] = useState([]);

    const [masterModifyForm, setMasterModifyForm] = useState(true);
    const [detailModifyForm, setDetailModifyForm] = useState(false);

    const selectedMasterBt = ((master, index) => {
        setMasterModifyForm(false);
        setDetailModifyForm(true);
        setAutoResDtList(master.autoResDt);
    })

    const workCloseMessage = t(`sqmMdMsg1`);
    const inquiryConnectMessage = t(`sqmMdMsg2`);
    const counselorConnect = (() => {
        const koreaTime = new Date();
        const day = koreaTime.getDay();
        const hour = koreaTime.getHours();

        if (day >= 1 && day <= 5 && hour >= 9 && hour < 18) {
            sendMessage(inquiryConnectMessage, null, true)
        } else {
            sendMessage(workCloseMessage, null, true)
        }
    })

    const selectedDetailBt = ((detail, index) => {
        sendMessage(detail.content, null, true);
    })

    // 웹소켓
    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!msgNum || isSubscribedRef.current) return;
            if (!stompClient && !isConnected) {
                await websocketConnect(msgNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                subscribe(`/user/${msgNum}/queue/nonUserAdminMessage`, onMessageReceived);
                subscribe(`/user/${msgNum}/queue/nonUserAdminRead`, onReadMessageReceived);
                subscribe(`/user/${msgNum}/queue/adminNonChatUpdate`, onChatAdminUpdate);
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                unsubscribe(`/user/${msgNum}/queue/nonUserAdminMessage`);
                unsubscribe(`/user/${msgNum}/queue/nonUserAdminRead`);
                unsubscribe(`/user/${msgNum}/queue/adminNonChatUpdate`);
                isSubscribedRef.current = false;
            }
        };

    }, [msgNum, isConnected, stompClient]);


    const lcstMsgNumRef = useRef(lcstMsgNum);
    const chatPopupRef = useRef(chatPopup);

    useEffect(() => {
        lcstMsgNumRef.current = lcstMsgNum;
        chatPopupRef.current = chatPopup;
    }, [lcstMsgNum, chatPopup]);

    const onMessageReceived = (payload) => {
        let newMessage = JSON.parse(payload.body);

        if (newMessage.chatKey.startsWith('chatFile')) {
            const filename = newMessage.chatKey.split('/').pop();
            if (isVideo(filename)) {
                newMessage.isVideo = true;
            } else if (isImage(filename)) {
                newMessage.isImage = true;
            } else {
                newMessage.isFile = true;
            }
        }
        setChatMessages((chatMessages) => {
            setUnreadCount(newMessage.unreadCount)
            return [...chatMessages, newMessage];
        });
        // 새로 등록된 메시지의 msgNum과 관리자가 선택한 chatDt.msgNum이 동일 && nonChatShow가 true 두 조건 모두 충족할 경우 자동읽음처리
        if (newMessage.msgNum === lcstMsgNumRef.current && chatPopupRef.current) {
            resetUnreadChat(newMessage);
            stompAdminRead();
        }
    };

    const onChatAdminUpdate = ((payload) => {
        let adminUpdateData = JSON.parse(payload.body);
        console.log("adminUpdateData : ", adminUpdateData)
        if (adminUpdateData !== null) {
            fetchChatMessages(adminUpdateData);
        }
    })

    const isImage = (fileName) => {
        const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "webp", "JPG", "JPEG", "PNG", "GIF", "BMP", "TIFF", "WEBP"];
        const fileExtension = fileName.split('.').pop().toLowerCase();
        return imageExtensions.includes(fileExtension);
    }

    const [chatConnectFailSt, setChatConnectFailSt] = useState(false);
    const sendMessage = async (printFilename, originalFilename, autoFl) => {
        if (!lcstMsgNum) {
            setChatConnectFailSt(true);
            return;
        }
        if (stompClient && printFilename.trim() !== '') {
            const chatMessage = {
                msgNum: lcstMsgNum,
                chatKey: printFilename,
                fileNm: originalFilename,
                role: autoFl ? '0' : '1',
                autoFl: !!autoFl,
                sendMailFl: false
            };
            try {
                await stompClient.send("/app/nonUserChat", {}, JSON.stringify(chatMessage));
                await new Promise(resolve => setTimeout(resolve, 500));
                fetchChatMessages(lcstMsgNumRef.current);
                setChatPopup(true);

                messagesEndRef.current.style.height = '470px'
                chatInputRef.current.style.height = '42px'

                await stompClient.send("/app/nonUserChat/totalCount", {}, JSON.stringify({sendFl: true}));
            } catch (error) {
                console.error("웹소켓 통신실패", error);
                setChatConnectFailSt(true);
            }
        } else {
            console.error("웹소켓 연결실패");
            setChatConnectFailSt(true);
        }
    };

    const fetchChatMessages = async (msgNum) => {
        if (!msgNum) {
            return;
        }
        try {
            const response = await axios({
                method: 'get',
                url: '/member/chatFetch',
                params: {
                    msgNum: msgNum
                }
            });
            if (!response || response.data === false) {
                console.log('데이터없음');
                return;
            }
            const data = response.data;
            if (data && data.length > 0) {
                setUnreadCount(data[data.length - 1].unreadCount)
            }
            console.log("전달된 data : ", data)


            for (let message of data) {
                if (message.chatKey.startsWith('chatFile')) {
                    const filename = message.chatKey.split('/').pop();
                    if (isVideo(filename)) {
                        message.fileUrl = 'https://toeloperfile.s3.ap-northeast-2.amazonaws.com/nUserChatMsg/' + message.chatKey;
                        message.isVideo = true;
                    } else if (isImage(filename)) {
                        message.fileUrl = 'https://toeloperfile.s3.ap-northeast-2.amazonaws.com/nUserChatMsg/' + message.chatKey;
                        message.isImage = true;
                    } else {
                        message.fileUrl = 'file';
                    }
                }
            }
            setChatMessages(data)
            // setChatMessages((prevMessages) => {
            //     const newMessages = data.filter(
            //         (newMessage) => !prevMessages.some((message) => message.seqnum === newMessage.seqnum),
            //     );
            //     return [...prevMessages, ...newMessages];
            // });
        } catch (error) {
            console.error('Error fetching chat messages:', error);
            throw error;
        }
    };

    useEffect(() => {
        if (lcstMsgNum) {
            fetchChatMessages(lcstMsgNum)
        }
    }, [lcstMsgNum]);

    // 관리자 -> 사용자 읽음수신 코드
    const onReadMessageReceived = (payload) => {
        // 관리자가 읽었을 경우 사용자채팅 안읽음수를 '0'으로 초기화
        let adminReadData = JSON.parse(payload.body);
        if (chatPopupRef.current && adminReadData && adminReadData.readerRole === "ADMIN") {
            setChatMessages(currentChatMessages => {
                let updatedMessages = [...currentChatMessages];
                let hasUpdates = false;
                for (let message of updatedMessages) {
                    if ((message.msgNum === String(adminReadData.msgNum)) && message.unreadCount > 0) {
                        message.unreadCount = 0;
                        hasUpdates = true;
                    }
                }
                return updatedMessages;
            })
        }
    };

    const messagesEndRef = useRef(null);
    const chatScrollBottom = (() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollTo({
                top: messagesEndRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    })

    useEffect(() => {
        chatScrollBottom();
    }, [chatMessages, chatPopup]);

    const getDayOfWeek = (date) => {
        const dayOfWeek = new Date(date).getDay(); // 일요일은 0, 월요일은 1, ... 토요일은 6
        return [t('sun'), t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat'),][dayOfWeek];
    };

    const formatDate = (dateString) => {
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);

        // 요일 계산
        const date = new Date(`${year}/${month}/${day}`);
        const dayOfWeek = getDayOfWeek(date);

        return `${year}/${month}/${day} ${dayOfWeek}`;
    };

    function isSameDay(date1, date2) {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    }

    function isFirstMessageOfDay(current, previous) {
        if (!previous) {
            return true; // 이전 메시지가 없다면 현재 메시지는 당연히 첫 번째 메시지입니다.
        }
        // 날짜 문자열을 분리하여 Date 객체를 생성합니다.
        const currentYear = current.regDate.substring(0, 4);
        const currentMonth = current.regDate.substring(4, 6);
        const currentDay = current.regDate.substring(6, 8);
        const currentDate = new Date(`${currentYear}-${currentMonth}-${currentDay}`);

        const previousYear = previous.regDate.substring(0, 4);
        const previousMonth = previous.regDate.substring(4, 6);
        const previousDay = previous.regDate.substring(6, 8);
        const previousDate = new Date(`${previousYear}-${previousMonth}-${previousDay}`);

        return !isSameDay(currentDate, previousDate);
    }

    const formatTime = (timeString) => {
        const parts = timeString.split(/[- :]/);
        const date = new Date(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]);
        return date.toTimeString().split(' ')[0];
    };

    const resetUnreadChat = ((newMessage) => {
        if ((newMessage?.role === "0")) {
            axios({
                method: 'post',
                url: `/member/chat/unread/`,
                params: {
                    msgNum: newMessage.msgNum,
                },
            })
                .then(response => {
                    if (response.status !== 200) {
                        throw new Error("HTTP status " + response.status);
                    } else {
                        setUnreadCount(0);
                        stompAdminRead(newMessage.msgNum);
                    }
                })
                .catch(error => console.error(error));
        }
    })

    // 사용자 -> 관리자 읽음전송
    const stompAdminRead = (async (msgNum) => {
        if (!stompClient || !stompClient.connected) {
            console.log("읽음전송실패")
            return;
        }
        const readChat = {
            msgNum: msgNum,
            readerRole: "USER",
        };
        await stompClient.send("/app/nonUserChat/read", {}, JSON.stringify(readChat));
    })

    const [showEmoji, setShowEmoji] = useState(false);
    const [showChatFile, setShowChatFile] = useState(false);

    const [selectedFileUrl, setSelectedFileUrl] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleEmojiClick = (emoji) => {
        const newMessage = message + emoji.native;
        setMessage(newMessage);
        setShowEmoji(!showEmoji);
    };

    const fileInputRef = useRef();
    const handleFileUpload = (event) => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            setSelectedFile(file);
            setSelectedFileUrl(URL.createObjectURL(file));
        }
    };

    const [fileSizeOverSt, setFileSizeOverSt] = useState(false);
    const closeFileSizeOverSt = (() => {
        setFileSizeOverSt(false);
    })
    const saveChatFile = async () => {
        if (!selectedFile || !selectedFile.size) {
            setShowChatFile(false);
            return;
        }
        const MAX_FILE_SIZE = 128 * 1024 * 1024; // 최대파일크기 128MB(백엔드에 맞추기)
        if (selectedFile.size > MAX_FILE_SIZE) {
            setShowChatFile(false);
            resetFileInput();
            setFileSizeOverSt(true);
            return;
        }
        const formData = new FormData();
        formData.append('file', selectedFile);
        axios({
            method: 'post',
            url: '/member/upload',
            data: formData
        })
            .then((response) => {
                const fileKeys = response.data;
                sendMessage(fileKeys.printFilename, fileKeys.originalFilename);
                setChatShow(true);
                setShowChatFile(false);
                resetFileInput();
            })
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
        setShowChatFile(true);
    };

    const showChatFileClose = (() => {
        setShowChatFile(false);
        resetFileInput();
    })

    const resetFileInput = () => {
        fileInputRef.current.value = "";
        setSelectedFile(null);
        setSelectedFileUrl(null);
    };

    const nonDownloadFile = async (filename, originalFilename) => {
        const response = await axios({
            method: 'get',
            url: `/member/chatFileDownload/${filename}`,
            responseType: 'blob'
        });
        const url = window.URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', originalFilename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        setNonSelectedClickSt(false);
        openDownloadFileCompl();
    };
    const [downloadFileComplSt, setDownloadFileComplSt] = useState(false);
    const openDownloadFileCompl = (() => {
        setDownloadFileComplSt(true);
    })
    const closeDownloadFileCompl = (() => {
        setDownloadFileComplSt(false);
    })


    const isVideo = (filename) => {
        const extension = filename.split('.').pop().toLowerCase();
        return ['mp4', 'webm', 'ogg'].includes(extension);
    };

    const oneMonthFromNow = (deadline) => {
        return deadline.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
    }

    const rows = Math.min(Math.max(Math.floor(message.length / 30) + 1, 1), 10);


    const [nonSelectedClickFile, setNonSelectedClickFile] = useState(null);
    const [nonSelectedClickSt, setNonSelectedClickSt] = useState(false);

    const clickFileDetail = ((file) => {
        setNonSelectedClickFile(file);
        setNonSelectedClickSt(true);
    })
    const nonClickFileClose = (() => {
        setNonSelectedClickSt(false);
    })

    // 채팅메시지 인풋조절
    // const chatInputRef = React.useRef(null);
    // const chatBodyRef = React.useRef(null);
    //
    // const resizeTextArea = () => {
    //     const textArea = chatInputRef.current;
    //     // 최소 높이 설정 후 스크롤 높이에 맞게 늘어나도록 함
    //     textArea.style.height = '42px';
    //     textArea.style.height = textArea.scrollHeight + "px";
    //     textArea.scrollTop = 0;
    // };
    // const handleChatInput = (e) => {
    //     setMessage(e.target.value);
    //     resizeTextArea();
    // };

    const chatInputRef = React.useRef(null);
    const resizeTextArea = () => {
        const textArea = chatInputRef.current;
        const chatBody = messagesEndRef.current; // 채팅 바디의 ref를 추가합니다.
        const initialChatBodyHeight = 470; // 초기 채팅 바디 높이를 설정합니다.

        textArea.style.height = '42px';
        textArea.style.height = `${textArea.scrollHeight}px`;
        textArea.scrollTop = 0;

        // 채팅 바디의 높이를 조정합니다.
        const textAreaHeight = textArea.clientHeight;
        const chatBodyHeight = initialChatBodyHeight - (textAreaHeight - 42);
        chatBody.style.height = `${chatBodyHeight}px`;
    };

    const handleChatInput = (e) => {
        setMessage(e.target.value);
        resizeTextArea();
    };

    const moveToMailPg = () => {
        setChatOpen(!chatOpen);
        navigate("/UserMailInquiryPg");
    };

    return (
        <div className={`SideQuickMenu ${chatOpen ? "" : "closed"}`}>
            <div className="quickMenu">
                <ul style={{listStyle: "none"}}>
                    <div className={`font-t-cont chat-list ${chatOpen ? "open" : ""}`}>
                        <li className="position-relative">
                            <div onClick={openPopup} className="side-ctg c-point">
                                <i className="icon"><IoChatbubblesSharp className="i-logo font-s-27" style={{margin: '11px 0 0 0', color: '#ffffff'}}/></i>
                                <span>{t(`sqmTx1`)}</span>
                            </div>
                            {unreadCount > 0 && chatMessages?.length > 0 && chatMessages[chatMessages.length - 1]?.role === "0" ?
                                <p className="position-absolute font-c-fff font-s-14" style={{
                                    top: "0",
                                    right: "0",
                                    margin: "0",
                                    borderRadius: "100%",
                                    backgroundColor: "#FF6324",
                                    padding: "0 6px"
                                }}>{unreadCount}</p>
                                :
                                null
                            }
                        </li>
                        <li>
                            <a className="side-ctg a-point" href="https://line.me/ko/" target="_blank">
                                <i className="icon">
                                    <BsLine className="i-logo"
                                            style={{margin: '11px 0 0 0', fontSize: '25px', color: '#ffffff'}}/>
                                </i>
                                <span>LINE</span>
                            </a>
                        </li>
                        <li>
                            <a className="side-ctg c-point insta-ctg" href="https://www.instagram.com/lfamily0212/"
                               target="_blank">
                                <i className="icon">
                                    <img className="i-logo insta-logo-img"
                                         style={{marginTop: "12px", width: "29px", height: "26px"}} src={instaLogo}
                                         alt="Instagram"/>
                                    <SiInstagram className="i-logo insta-logo-icon"
                                                 style={{margin: '11px 0 0 0', fontSize: '24px', color: '#ffffff'}}/>
                                </i>
                                <span>DM</span>
                            </a>
                        </li>
                        <li>
                            <a className="side-ctg c-point" href="https://pf.kakao.com/_zTxllC/chat" target="_blank">
                                <i className="icon">
                                    <RiKakaoTalkFill className="i-logo" style={{
                                        margin: '10px 0 0 0',
                                        fontSize: '30px',
                                        color: '#3A1D1D'
                                    }}/>
                                </i>
                                <span style={{color: "#3A1D1D"}}>KaKao</span>
                            </a>
                        </li>
                        <li>
                            <a className="side-ctg c-point" href="https://www.wechat.com/ko/" target="_blank">
                                <i className="icon">
                                    <IoLogoWechat className="i-logo"
                                                  style={{margin: '9px 0 0 0', fontSize: '30px', color: '#ffffff'}}/>
                                </i>
                                <span>WeChat</span>
                            </a>
                        </li>
                        <li>
                            <div className="side-ctg c-point" onClick={moveToMailPg}>
                                <i className="icon">
                                    <GoMail className="i-logo"
                                            style={{margin: '11px 0 0 0', fontSize: '27px', color: '#ffffff'}}/>
                                </i>
                                <span>{t(`sqmTx2`)}</span>
                            </div>
                        </li>
                    </div>
                    {/*비회원 실시간채팅*/}
                    {chatPopup ?
                        <div style={{
                            width: "400px",
                            position: "fixed",
                            right: "100px",
                            bottom: "3%",
                            transition: "0.2s",
                            zIndex: 1000
                        }}>
                            {/*채팅헤더*/}
                            <div style={{
                                height: "70px",
                                backgroundColor: "#FFE5B6",
                                display: "flex",
                                alignItems: "center",
                                padding: "0 10px 0 10px"
                            }}>
                                <div style={{
                                    width: "10%",
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <img style={{width: "30px", height: "30px"}} src={mainChatPeople} alt=""/>
                                </div>
                                <div className="font-t-title"
                                     style={{width: "80%", height: "100%", display: "flex", alignItems: "center"}}>
                                    <p className="font-c-btorage">Btorage 1:1 Chat</p>
                                </div>
                                <div onClick={closeChatPopup} style={{
                                    width: "10%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer"
                                }}>
                                    <img style={{width: "40px", height: "40px", opacity: "0.6"}} src={commonClose}
                                         alt=""/>
                                </div>
                            </div>
                            {/*채팅바디*/}
                            <div className="font-t-cont" style={{
                                height: "470px",
                                backgroundColor: "#FFF5E4",
                                overflowY: "scroll",
                            }} ref={messagesEndRef}>


                                {/*데이터 만료기한 안내문구*/}
                                { dataDeadlineMessageSt ?
                                <div className="chat-data-deadline-notice-container">
                                    <div className="chat-data-deadline-notice-border-frame">
                                        <div className="chat-data-deadline-notice-message-frame">
                                            <p className="font-t-cont font-s-12">{t('ncmNotice')}</p>
                                        </div>
                                    </div>
                                </div>
                                    :
                                    null
                                }


                                {/*자동응답*/}
                                {masterModifyForm ?
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginTop: "8px"
                                    }}>
                                        <div style={{
                                            width: "180px",
                                            padding: "3px",
                                            backgroundColor: "#F6F6F6",
                                            borderRadius: "6px"
                                        }}>
                                            {/*마스터버튼*/}
                                            {autoResMstList.map((master, index) => (
                                                <div onClick={() => selectedMasterBt(master, index)} key={index}
                                                     className="autoResBt">
                                                    <span style={{
                                                        textAlign: "center",
                                                        fontSize: "11px",
                                                        fontWeight: "bold",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        wordBreak: "break-all",
                                                        width: "180px"
                                                    }}>{master && master.title}</span>
                                                </div>
                                            ))}
                                            <div onClick={counselorConnect} className="autoResBt">
                                                <span style={{
                                                    margin: "0",
                                                    fontSize: "11px",
                                                    fontWeight: "bold"
                                                }}>{t(`sqmTx3`)}</span>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    detailModifyForm ?
                                        <div style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: "8px"
                                        }}>
                                            <div style={{
                                                width: "180px",
                                                padding: "3px",
                                                backgroundColor: "#F6F6F6",
                                                borderRadius: "6px"
                                            }}>
                                                <div style={{
                                                    height: "20px",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    margin: "4px",
                                                    paddingLeft: "5px"
                                                }}>
                                                    <p style={{
                                                        margin: "0",
                                                        fontSize: "12px",
                                                        color: "dimgray",
                                                        width: "50%"
                                                    }}>#{t(`sqmTx4`)}</p>
                                                    <div style={{
                                                        width: "50%",
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                        marginRight: "5px"
                                                    }}>
                                                        <img onClick={() => {
                                                            setDetailModifyForm(false);
                                                            setMasterModifyForm(true);
                                                        }}
                                                             alt=''
                                                             style={{width: "17px", height: "17px", cursor: "pointer"}}
                                                             src={mainChatBack}/>
                                                    </div>
                                                </div>
                                                {/*디테일버튼*/}
                                                {autoResDtList.map((detail, index) => (
                                                    <div onClick={() => selectedDetailBt(detail, index)} key={index}
                                                         className="autoResBt">
                                                        <span style={{
                                                            textAlign: "center",
                                                            fontSize: "11px",
                                                            fontWeight: "bold",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                            wordBreak: "break-all",
                                                            width: "180px"
                                                        }}>{detail && detail.title}</span>
                                                    </div>
                                                ))}
                                                <div onClick={counselorConnect} className="autoResBt">
                                                    <span style={{
                                                        margin: "0",
                                                        fontSize: "11px",
                                                        fontWeight: "bold"
                                                    }}>{t(`sqmTx3`)}</span>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                                {chatMessages.map((message, index) => (
                                    <div key={index}>
                                        {(isFirstMessageOfDay(message, chatMessages[index - 1]) &&
                                            <div style={{
                                                display: "flex",
                                                alignItems: "center",
                                                marginBottom: "10px",
                                                justifyContent: "center",
                                                marginTop: "20px"
                                            }}>
                                                <p style={{
                                                    margin: "0",
                                                    fontSize: "10px"
                                                }}>{formatDate(message.regDate)}</p>
                                            </div>
                                        )}
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: message && message?.role === '0' ? "flex-start" : "flex-end"
                                        }}>
                                            {message && message?.role === '0' ?
                                                // 관리자채팅
                                                <div style={{minHeight: "45px", display: "flex"}}>
                                                    <div style={{
                                                        width: "35px",
                                                        height: "35px",
                                                        marginLeft: "10px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        borderRadius:"100%",
                                                        marginRight:"8px",
                                                        backgroundColor:"white",
                                                        boxShadow: "0 5px 8px 0 rgba(0,0,0,0.2)"
                                                    }}>
                                                        <img alt='' style={{width: "25px", height: "25px", objectFit:"contain"}}
                                                             src={!message.autoFl ? btorageLogo : chatBot}/>
                                                    </div>
                                                    <div style={{display: "flex"}}>
                                                        {message.chatKey.startsWith("chatFile") ?
                                                            <div
                                                                onClick={!message.removeFl ? () => clickFileDetail(message) : null}
                                                                style={{marginLeft: "3px", cursor: "pointer"}}>
                                                                {isImage(message.chatKey) ?
                                                                    <div>
                                                                        {!message.removeFl ?
                                                                            // 이미지
                                                                            <p style={{
                                                                                margin: "3px",
                                                                                maxWidth: "290px",
                                                                                width: "fit-content",
                                                                                wordBreak: "keep-all",
                                                                                textAlign: "left",
                                                                                fontWeight: "lighter"
                                                                            }}><img
                                                                                style={{
                                                                                    maxWidth: "260px",
                                                                                    maxHeight: "500px"
                                                                                }}
                                                                                src={message.fileUrl}
                                                                                alt="chatKey"/></p>
                                                                            :
                                                                            <div style={{
                                                                                padding: "10px",
                                                                                textAlign: "center"
                                                                            }}>
                                                                                <img style={{
                                                                                    width: "40px",
                                                                                    height: "40px",
                                                                                    opacity: "0.6"
                                                                                }} src={trash} alt="remove"/>
                                                                                <p style={{
                                                                                    margin: "0",
                                                                                    fontSize: "11px",
                                                                                    marginTop: "5px",
                                                                                    color: "darkgray"
                                                                                }}>({t('deadline')})</p>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    // 관리자동영상
                                                                    isVideo(message.chatKey) ?
                                                                        <div>
                                                                            {!message.removeFl ?
                                                                                <div style={{
                                                                                    margin: "0",
                                                                                    padding: "0"
                                                                                }}>
                                                                                    <video width="260" height="230"
                                                                                           controls>
                                                                                        <source src={message.fileUrl}
                                                                                                type="video/mp4"/>
                                                                                    </video>
                                                                                </div>
                                                                                :
                                                                                <div style={{
                                                                                    padding: "10px",
                                                                                    textAlign: "center"
                                                                                }}>
                                                                                    <img style={{
                                                                                        width: "40px",
                                                                                        height: "40px",
                                                                                        opacity: "0.6"
                                                                                    }} src={trash} alt="remove"/>
                                                                                    <p style={{
                                                                                        margin: "0",
                                                                                        fontSize: "11px",
                                                                                        marginTop: "5px",
                                                                                        color: "darkgray"
                                                                                    }}>({t('deadline')})</p>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        :
                                                                        // 관리자파일
                                                                        <div>
                                                                            {!message.removeFl ?
                                                                                <div style={{
                                                                                    width: "150px",
                                                                                    height: "130px",
                                                                                    backgroundColor: "white",
                                                                                    borderRadius: "6px"
                                                                                }}>
                                                                                    <div style={{
                                                                                        height: "60%",
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                        overflow: "hidden"
                                                                                    }}>
                                                                                        <img style={{
                                                                                            width: "50%",
                                                                                            height: "90%",
                                                                                            paddingTop: "6px"
                                                                                        }} src={chatFileImg}
                                                                                             alt="fileImg"/>
                                                                                    </div>
                                                                                    <div className="font-t-cont"
                                                                                         style={{
                                                                                             height: "40%",
                                                                                         }}>
                                                                                        <div
                                                                                            className="user-center wd-100pc m-l-5">
                                                                                            <div
                                                                                                className="m-a-chatKey-fileNm-text wd-140 user-center">
                                                                                                {message.fileNm}
                                                                                            </div>
                                                                                        </div>
                                                                                        <p style={{
                                                                                            width: "100%",
                                                                                            height: "50%",
                                                                                            display: "flex",
                                                                                            justifyContent: "center",
                                                                                            margin: "0",
                                                                                            color: "dimgrey",
                                                                                            fontSize: "12px",
                                                                                            paddingBottom: "5px"
                                                                                        }}> ~ {oneMonthFromNow(message.deadline)}{t(`sqmTx5`)}</p>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div style={{
                                                                                    padding: "10px",
                                                                                    textAlign: "center"
                                                                                }}>
                                                                                    <img style={{
                                                                                        width: "40px",
                                                                                        height: "40px",
                                                                                        opacity: "0.6"
                                                                                    }} src={trash} alt="remove"/>
                                                                                    <p style={{
                                                                                        margin: "0",
                                                                                        fontSize: "11px",
                                                                                        marginTop: "5px",
                                                                                        color: "darkgray"
                                                                                    }}>({t('deadline')})</p>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                }
                                                            </div>
                                                            :
                                                            <div style={{
                                                                backgroundColor: "white",
                                                                margin: "6px 0 6px 0",
                                                                borderRadius: "4px",
                                                            }}>
                                                                <Linkify
                                                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                        <a style={{color: "orangered"}} target="blank"
                                                                           href={decoratedHref} key={key}>
                                                                            {decoratedText}
                                                                        </a>
                                                                    )}
                                                                >
                                                                    <p style={{
                                                                        maxWidth: "280px",
                                                                        width: "fit-content",
                                                                        wordBreak: "break-word",
                                                                        whiteSpace: "pre-wrap",
                                                                        margin: "0",
                                                                        padding: "8px",
                                                                        fontSize: "12px",
                                                                        color: "black",
                                                                        fontWeight: "lighter"
                                                                        // 텍스트 채팅메시지
                                                                    }}>{DOMPurify.sanitize(message.chatKey)}</p>
                                                                </Linkify>
                                                            </div>
                                                        }
                                                        {!message.autoFl ?
                                                            <div style={{
                                                                display: "flex",
                                                                alignItems: "flex-end",
                                                                // marginLeft: "5px",
                                                                padding: "6px"
                                                            }}>
                                                                {!message.removeFl ?
                                                                    <span style={{
                                                                        margin: "0",
                                                                        fontSize: "9px",
                                                                        marginLeft: "-2px"
                                                                    }}>{formatTime(message.regTime)}</span>
                                                                    :
                                                                    null
                                                                }
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                // 사용자채팅
                                                <div style={{display: "flex", alignItems: "center", margin: "5px"}}>
                                                    <div style={{display: "flex"}}>

                                                        <div style={{
                                                            display: "flex",
                                                            alignItems: "flex-end",
                                                            marginRight: "5px"
                                                        }}>
                                                            {message && message.unreadCount > 0 ?
                                                                <p style={{
                                                                    margin: "0",
                                                                    color: "darkorange",
                                                                    fontSize: "11px",
                                                                    marginRight: "5px"
                                                                }}>1</p>
                                                                :
                                                                null
                                                            }

                                                            {!message.removeFl ?
                                                                <p style={{
                                                                    margin: "0",
                                                                    fontSize: "10px"
                                                                }}>{formatTime(message.regTime)}</p>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        {message.chatKey.startsWith("chatFile") ?
                                                            <div
                                                                onClick={!message.removeFl ? () => clickFileDetail(message) : null}
                                                                style={{marginLeft: "3px", cursor: "pointer"}}>
                                                                {/*사용자 이미지채팅*/}
                                                                {isImage(message.chatKey) ?
                                                                    <div>
                                                                        {!message.removeFl ?
                                                                            <img
                                                                                style={{
                                                                                    maxWidth: "260px",
                                                                                    maxHeight: "500px"
                                                                                }}
                                                                                src={message.fileUrl}
                                                                                alt="chatKey"/>
                                                                            :
                                                                            <div style={{
                                                                                padding: "10px",
                                                                                textAlign: "center"
                                                                            }}>
                                                                                <img style={{
                                                                                    width: "40px",
                                                                                    height: "40px",
                                                                                    opacity: "0.6"
                                                                                }} src={trash} alt="remove"/>
                                                                                <p style={{
                                                                                    margin: "0",
                                                                                    fontSize: "11px",
                                                                                    marginTop: "5px",
                                                                                    color: "darkgray"
                                                                                }}>({t('deadline')})</p>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    // 사용자 동영상채팅
                                                                    isVideo(message.chatKey) ?
                                                                        <div>
                                                                            {!message.removeFl ?
                                                                                <div style={{
                                                                                    margin: "0",
                                                                                    padding: "0"
                                                                                }}>
                                                                                    <video width="260" height="230"
                                                                                           controls>
                                                                                        <source src={message.fileUrl}
                                                                                                type="video/mp4"/>
                                                                                    </video>
                                                                                </div>
                                                                                :
                                                                                <div style={{
                                                                                    padding: "10px",
                                                                                    textAlign: "center"
                                                                                }}>
                                                                                    <img style={{
                                                                                        width: "40px",
                                                                                        height: "40px",
                                                                                        opacity: "0.6"
                                                                                    }} src={trash} alt="remove"/>
                                                                                    <p style={{
                                                                                        margin: "0",
                                                                                        fontSize: "11px",
                                                                                        marginTop: "5px",
                                                                                        color: "darkgray"
                                                                                    }}>({t('deadline')})</p>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            {!message.removeFl ?
                                                                                // 외 파일
                                                                                <div style={{
                                                                                    width: "150px",
                                                                                    height: "130px",
                                                                                    backgroundColor: "white",
                                                                                    borderRadius: "6px"
                                                                                }}>
                                                                                    <div style={{
                                                                                        height: "60%",
                                                                                        display: "flex",
                                                                                        justifyContent: "center",
                                                                                        alignItems: "center",
                                                                                        overflow: "hidden"
                                                                                    }}>
                                                                                        <img style={{
                                                                                            width: "50%",
                                                                                            height: "90%",
                                                                                            paddingTop: "6px"
                                                                                        }} src={chatFileImg}
                                                                                             alt="fileImg"/>
                                                                                    </div>
                                                                                    <div className="font-t-cont"
                                                                                         style={{
                                                                                             height: "40%",
                                                                                         }}>
                                                                                        <div
                                                                                            className="user-center wd-100pc m-l-5">
                                                                                            <div
                                                                                                className="m-a-chatKey-fileNm-text wd-140">
                                                                                                {message.fileNm}
                                                                                            </div>
                                                                                        </div>
                                                                                        <p style={{
                                                                                            width: "100%",
                                                                                            height: "50%",
                                                                                            display: "flex",
                                                                                            justifyContent: "center",
                                                                                            margin: "0",
                                                                                            color: "dimgrey",
                                                                                            fontSize: "12px",
                                                                                            paddingBottom: "5px"
                                                                                        }}> ~ {oneMonthFromNow(message.deadline)}{t(`sqmTx5`)}</p>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <div style={{
                                                                                    padding: "10px",
                                                                                    textAlign: "center"
                                                                                }}>
                                                                                    <img style={{
                                                                                        width: "40px",
                                                                                        height: "40px",
                                                                                        opacity: "0.6"
                                                                                    }} src={trash} alt="remove"/>
                                                                                    <p style={{
                                                                                        margin: "0",
                                                                                        fontSize: "11px",
                                                                                        marginTop: "5px",
                                                                                        color: "darkgray"
                                                                                    }}>({t('deadline')})</p>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                }
                                                            </div>
                                                            :
                                                            <div style={{
                                                                backgroundColor: "#FFAF34",
                                                                borderRadius: "15px",
                                                            }}>
                                                                <Linkify
                                                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                                                        <a style={{color: "orangered"}} target="blank"
                                                                           href={decoratedHref} key={key}>
                                                                            {decoratedText}
                                                                        </a>
                                                                    )}
                                                                >
                                                                    <p style={{
                                                                        maxWidth: "300px",
                                                                        width: "fit-content",
                                                                        wordBreak: "break-word",
                                                                        whiteSpace: "pre-wrap",
                                                                        margin: "0",
                                                                        padding: "8px",
                                                                        color: "white",
                                                                        fontSize: "12px",
                                                                        fontWeight: "lighter"
                                                                        // 텍스트 채팅메시지
                                                                    }}>{DOMPurify.sanitize(message.chatKey)}</p>
                                                                </Linkify>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {/*채팅푸터*/}
                            <div style={{backgroundColor: "#F6F6F6"}}>
                                <div style={{padding: "10px", borderRadius: "4px"}}>
                                    {/*채팅메시지*/}
                                    <div style={{backgroundColor: "white", display: "flex"}}>
                                        <div style={{
                                            width: "90%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}>
                                            {/*채팅인풋*/}
                                            <textarea
                                                ref={chatInputRef}
                                                placeholder=""
                                                spellCheck="false"
                                                value={message}
                                                style={{
                                                    borderRadius: "6px",
                                                    width: "100%",
                                                    resize: "none",
                                                    height: "42px",
                                                    maxHeight: "200px",
                                                    overflow: "auto", // Changed from "auto" to "hidden"
                                                    outline: "none",
                                                    padding: "10px",
                                                    fontSize: "14px",
                                                    border: "1px solid transparent", // Transparent border instead of none
                                                    boxSizing: "border-box" // Ensures padding and border are included in height calculations
                                                }}
                                                onChange={handleChatInput}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter' && !e.shiftKey) {
                                                        e.preventDefault();
                                                        sendMessage(message);
                                                        setMessage('');
                                                        chatInputRef.current.style.height = '42px'; // Reset to default height
                                                    }
                                                }}
                                            />
                                        </div>
                                        <button disabled={message.trim() === ''} onClick={() => {
                                            sendMessage(message);
                                            setMessage('');
                                        }} style={{
                                            width: "10%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                            outline: "none",
                                            border: "none"
                                        }}>
                                            <img alt='' style={{
                                                width: "25px",
                                                height: "25px",
                                                opacity: message.length > 0 ? "1" : "0.6"
                                            }} src={mainChatSend}/>
                                        </button>
                                    </div>
                                    {/*emoji & upload & remove*/}
                                    <div style={{
                                        height: "50%",
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: "10px",
                                        justifyContent: "space-between"
                                    }}>
                                        <div style={{display: "flex"}}>
                                            <div onClick={() => setShowEmoji(!showEmoji)} style={{
                                                width: "30px",
                                                height: "30px",
                                                cursor: "pointer",
                                                paddingTop: "1px"
                                            }}>
                                                <img alt='' style={{width: "25px", height: "25px"}}
                                                     src={mainChatEmoji}/>
                                            </div>
                                            {/*이모티콘*/}
                                            {showEmoji ?
                                                <div style={{position: "relative"}}>
                                                    <div style={{
                                                        position: "absolute",
                                                        bottom: "-10px",
                                                        right: "50px",
                                                    }}>
                                                        <Picker
                                                            onEmojiSelect={handleEmojiClick}
                                                            previewPosition="none"
                                                            searchPosition="none"
                                                            skinTonePosition="none"
                                                            locale="kr"
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                null
                                            }
                                            {/*파일업로드*/}
                                            <div style={{
                                                width: "30px",
                                                height: "30px",
                                                marginLeft: "1px",
                                                cursor: "pointer"
                                            }}>
                                                <input
                                                    type="file"
                                                    ref={fileInputRef}
                                                    style={{display: "none"}}
                                                    onChange={handleFileUpload}
                                                />
                                                <button style={{
                                                    width: "30px",
                                                    height: "30px",
                                                    border: "none",
                                                    display: "flex",
                                                    overflow: "hidden",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }} onClick={triggerFileInput}>
                                                    <img alt='' style={{width: "25px", height: "25px"}}
                                                         src={mainChatUpload}/>
                                                </button>
                                            </div>
                                        </div>

                                        {/*채팅메시지 제거*/}
                                        <div onClick={() => setNUserChatRemoveSt(true)} style={{
                                            width: "30px",
                                            height: "30px",
                                            marginLeft: "3px",
                                            cursor: "pointer"
                                        }}>
                                            <img alt='' style={{width: "30px", height: "25px"}} src={mainChatTrash}/>
                                        </div>

                                        {nUserChatRemoveSt ?
                                            <MsgCheckModal nUserChatRemoveSt={nUserChatRemoveSt}
                                                           nUserChatRemoveClose={setNUserChatRemoveSt}
                                                           nUserChatRemove={nUserChatRemove}/>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    <li className="main-chat" style={{position: "relative", cursor: "pointer"}}>
                        <div
                            onClick={() => setChatOpen(!chatOpen)}
                            style={{
                                width: "74px",
                                height: "74px",
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <img alt='chat'
                                 style={{
                                     width: "100%",
                                     height: "100%",
                                     display: "flex",
                                     justifyContent: "center",
                                     alignItems: "center",
                                     opacity: chatOpen ? '0.6' : "1",
                                     transition: "0.6s"
                                 }}
                                 src={mainChat}
                            />
                        </div>
                        {unreadCount > 0 && chatMessages && chatMessages[chatMessages.length - 1]?.role === "0" ?
                            <p style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                margin: "0",
                                borderRadius: "100%",
                                backgroundColor: "#FF6324",
                                color: "white",
                                fontSize: "14px",
                                padding: "0 6px"
                            }}>{unreadCount}</p>
                            :
                            null
                        }
                    </li>
                </ul>
            </div>
            {showChatFile ?
                <ChatFileUpload showChatFileClose={showChatFileClose} selectedFile={selectedFile}
                                selectedFileUrl={selectedFileUrl} saveChatFile={saveChatFile}/>
                :
                nonSelectedClickSt ?
                    <ChatFileDown nonSelectedClickSt={nonSelectedClickSt} nonClickFileClose={nonClickFileClose}
                                  nonSelectedClickFile={nonSelectedClickFile} nonDownloadFile={nonDownloadFile}/>
                    :
                    chatConnectFailSt ?
                        <MsgCheckModal chatConnectFailSt={chatConnectFailSt}
                                       setChatConnectFailSt={setChatConnectFailSt}/>
                        :
                        fileSizeOverSt ?
                            <MsgCheckModal fileSizeOverSt={fileSizeOverSt} closeFileSizeOverSt={closeFileSizeOverSt}/>
                            :
                            downloadFileComplSt ?
                                <MsgCheckModal downloadFileComplSt={downloadFileComplSt}
                                               closeDownloadFileCompl={closeDownloadFileCompl}/>
                                :
                                null
            }
        </div>
    )
}

export default SideQuickMenu
