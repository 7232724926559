import React from "react";
import '../../css/mobile/MMsgSelectModal.css'
import warnIcon from "../../assets/img/warnIcon.png";
import complDown from '../../assets/img/complete.png'
import {useTranslation} from "react-i18next";

function MMsgCheckModal({
                            regiWaitingFn,
                            regiWaitingSt,
                            regiWaitingMsg,
                            lgWaitingSt,
                            confirmModalCloseFn,
                            lgRegiWaitingMsg,
                            googleLoginErrorMsg,
                            googleLoginErrorClose,
                            naverLoginErrorMsg,
                            naverLoginErrorClose,
                            editMstDeleteModal,
                            deleteMaster,
                            editDtDeleteModal,
                            deleteDetail,
                            deleteMstClose,
                            deleteDtClose,
                            nUserChatRemoveSt,
                            nUserChatRemoveClose,
                            nUserChatRemove,
                            chatConnectFailSt,
                            setChatConnectFailSt,
                            deleteMacroMaster,
                            deleteMacroDetail,
                            deleteMacroDtClose,
                            deleteMacroMstClose,
                            editMacroMstDeleteModal,
                            editMacroDtDeleteModal,
                            dontTrackingShipcoSt,
                            closeDontTrackingShipco,
                            downNoSelectSt,
                            closeDownNoSelect,
                            orderDivideMergeSt,
                            moveToMyPg,
                            dpPayNullSt,
                            closeDpPayNullSt,
                            returnNullSt,
                            closeReturnNullSt,
                            allUpdateProdNullSt,
                            closeAllUpdateProdNullSt,
                            payinfoSelectOrderErrSt,
                            closePayinfoSelectOrderErr,
                            payinfoSelectOrderErrMsg,
                            nonEmsDataSt,
                            closeNonEmsDataSt,
                            nonSelectOrderSt,
                            closeNonSelectOrderSt,
                            biggerCpPriceSt,
                            closeBiggerCpPriceSt,
                            commonError,
                            closeCommonError,
                            fileSizeOverSt,
                            closeFileSizeOverSt,
                            agreeCheckBoxError,
                            setAgreeCheckBoxError,
                            payMethodError,
                            setPayMethodError,
                            pointPayError,
                            setPointPayError,
                            etcPayError,
                            setEtcPayError,
                            pointSelectErr,
                            setPointSelectErr,
                            depositSelectErr,
                            setDepositSelectErr,
                            downloadFileComplSt,
                            closeDownloadFileCompl,
                            selectedShipCrError,
                            closeSelectedShipCrError
                        }) {

    const {t} = useTranslation();

    return (
        <div className="m-a-msgck-modal-container">
            <div className="modalFrame-lang-price-err border-rd6">
                <div className="display-flex flex-row just-cont-fstart ht-50">
                    <div className="m-a-msgck-icon-container">
                        <img alt='' src={downloadFileComplSt ? complDown : warnIcon} className="m-a-msgck-icon"/>
                    </div>
                    <div className="m-a-msgck-head-text font-t-title">
                        {regiWaitingSt ?
                            <div className="font-t-title font-s-18">Waiting</div>
                            :
                            lgWaitingSt ?
                                <div className="font-t-title font-s-16">Login Error</div>
                                :
                                googleLoginErrorMsg || naverLoginErrorMsg ?
                                    <div className="font-t-title font-s-16">{t(`mcmHeader1`)}</div>
                                    :
                                    editMstDeleteModal ?
                                        <div className="font-t-title font-s-16">Delete Master</div>
                                        :
                                        editDtDeleteModal ?
                                            <div className="font-t-title font-s-16">Delete Detail</div>
                                            :
                                            editMacroMstDeleteModal ?
                                                <div className="font-t-title font-s-16">Delete Master</div>
                                                :
                                                editMacroDtDeleteModal ?
                                                    <div className="font-t-title font-s-16">Delete Detail</div>
                                                    :
                                                    nUserChatRemoveSt ?
                                                        <div className="font-t-title font-s-16">{t(`mcmHeader2`)}</div>
                                                        :
                                                        chatConnectFailSt ?
                                                            <div
                                                                className="font-t-title font-s-16">{t(`mcmHeader3`)}</div>
                                                            :
                                                            dontTrackingShipcoSt ?
                                                                <div
                                                                    className="font-t-title font-s-16">{t(`mcmHeader4`)}</div>
                                                                :
                                                                downNoSelectSt ?
                                                                    <div
                                                                        className="font-t-title font-s-16">{t(`mcmHeader5`)}</div>
                                                                    :
                                                                    orderDivideMergeSt ?
                                                                        <div
                                                                            className="font-t-title font-s-16">{t(`modMdHd1`)}</div>
                                                                        :
                                                                        dpPayNullSt ?
                                                                            <div className="font-t-title font-s-16">Data
                                                                                Null Error</div>
                                                                            :
                                                                            returnNullSt ?
                                                                                <div
                                                                                    className="font-t-title font-s-16">Data
                                                                                    Null Error</div>
                                                                                :
                                                                                allUpdateProdNullSt ?
                                                                                    <div
                                                                                        className="font-t-title font-s-16">Data
                                                                                        Null Error</div>
                                                                                    :
                                                                                    payinfoSelectOrderErrSt ?
                                                                                        <div
                                                                                            className="font-t-title font-s-16">Selection
                                                                                            Error</div>
                                                                                        :
                                                                                        nonEmsDataSt ?
                                                                                            <div
                                                                                                className="font-t-title font-s-16">Select
                                                                                                Data Error</div>
                                                                                            :
                                                                                            nonSelectOrderSt ?
                                                                                                <div
                                                                                                    className="font-t-title font-s-16">No
                                                                                                    Select Error</div>
                                                                                                :
                                                                                                biggerCpPriceSt ?
                                                                                                    <div
                                                                                                        className="font-t-title font-s-16">{t('modCUseBtErr1')}</div>
                                                                                                    :
                                                                                                    commonError ?
                                                                                                        <div className="font-t-title font-s-16">Data Null Error</div>
                                                                                                        :
                                                                                                        agreeCheckBoxError ?
                                                                                                            <div
                                                                                                                className="font-t-title font-s-16">Agree Error</div>
                                                                                                            :
                                                                                                            payMethodError ?
                                                                                                                <div
                                                                                                                    className="font-t-title font-s-16">Pay Error</div>
                                                                                                                :
                                                                                                                pointPayError ?
                                                                                                                    <div
                                                                                                                        className="font-t-title font-s-16">Pay Error</div>
                                                                                                                    :
                                                                                                                    etcPayError ?
                                                                                                                        <div
                                                                                                                            className="font-t-title font-s-16">Pay Error</div>
                                                                                                                        :
                                                                                                                        pointSelectErr ?
                                                                                                                            <div className="font-t-title font-s-16">Pay Error</div>
                                                                                                                            :
                                                                                                                            depositSelectErr ?
                                                                                                                                <div
                                                                                                                                    className="font-t-title font-s-16">Pay Error</div>
                                                                                                                                :
                                                                                                                                fileSizeOverSt ?
                                                                                                                                    <div
                                                                                                                                        className="font-t-title font-s-16">{t('uploadSizeOverHd')}</div>
                                                                                                                                    :
                                                                                                                                    downloadFileComplSt ?
                                                                                                                                        <div
                                                                                                                                            className="font-t-title font-s-16">{t('downloadComplHd')}</div>
                                                                                                                                        :
                                                                                                                                        selectedShipCrError ?
                                                                                                                                            <div
                                                                                                                                                className="font-t-title font-s-16">{t('ciModalHd')}</div>
                                                                                                                                            :
                                                                                                                                    null
                        }
                    </div>
                </div>
                <div className="font-t-cont text-center pd15 m-b-10">
                    {regiWaitingSt ?
                        <div className="font-t-title font-s-16">관리자승인 후 로그인이 가능합니다</div>
                        :
                        lgWaitingSt ?
                            <div className="font-t-title font-s-16">가입승인이 완료되지 않은 계정입니다</div>
                            :
                            googleLoginErrorMsg || naverLoginErrorMsg ?
                                <div className="font-t-title font-s-16">{t(`mcmCont1`)}</div>
                                :
                                editMstDeleteModal ?
                                    <div className="font-t-title font-s-16">하위 Detail 데이터들까지 삭제됩니다</div>
                                    :
                                    editDtDeleteModal ?
                                        <div className="font-t-title font-s-16">삭제 시 복구가 불가합니다</div>
                                        :
                                        editMacroMstDeleteModal ?
                                            <div className="font-t-title font-s-16">하위 Detail 데이터들까지 삭제됩니다</div>
                                            :
                                            editMacroDtDeleteModal ?
                                                <div className="font-t-title font-s-16">삭제 시 복구가 불가합니다</div>
                                                :
                                                nUserChatRemoveSt ?
                                                    <div className="font-t-title font-s-16">{t(`mcmCont2`)}</div>
                                                    :
                                                    chatConnectFailSt ?
                                                        <div className="font-t-title font-s-16">{t(`mcmCont3`)}</div>
                                                        :
                                                        dontTrackingShipcoSt ?
                                                            <div
                                                                className="font-t-title font-s-16">{t(`mcmCont4`)}</div>
                                                            :
                                                            downNoSelectSt ?
                                                                <div
                                                                    className="font-t-title font-s-16">{t(`mcmCont5`)}</div>
                                                                :
                                                                orderDivideMergeSt ?
                                                                    <div
                                                                        className="font-t-title font-s-16">{t(`modMdMsg1`)}</div>
                                                                    :
                                                                    dpPayNullSt ?
                                                                        <div className="font-t-title font-s-16">결제하실
                                                                            데이터를 선택해주세요</div>
                                                                        :
                                                                        allUpdateProdNullSt ?
                                                                            <div
                                                                                className="font-t-title font-s-16">입고처리하실
                                                                                데이터를 선택해주세요</div>
                                                                            :
                                                                            payinfoSelectOrderErrSt ?
                                                                                <div
                                                                                    className="font-t-title font-s-16">배송료타입만
                                                                                    선택해주세요</div>
                                                                                :
                                                                                nonEmsDataSt ?
                                                                                    <div
                                                                                        className="font-t-title font-s-16">EMS
                                                                                        데이터만 선택해주세요</div>
                                                                                    :
                                                                                    nonSelectOrderSt ?
                                                                                        <div
                                                                                            className="font-t-title font-s-16">데이터를
                                                                                            선택해주세요</div>
                                                                                        :
                                                                                        biggerCpPriceSt ?
                                                                                            <div
                                                                                                className="font-t-title font-s-16">{t('modCUseBtErr2')}</div>
                                                                                            :
                                                                                            commonError ?
                                                                                                <div
                                                                                                    className="font-t-title font-s-16">{t('recInfoNullErr')}</div>
                                                                                                :
                                                                                                agreeCheckBoxError ?
                                                                                                    <div
                                                                                                        className="font-t-title font-s-16">{t('modPiErr4')}</div>
                                                                                                    :
                                                                                                    payMethodError ?
                                                                                                        <div
                                                                                                            className="font-t-title font-s-16">{t('modPiErr1')}</div>
                                                                                                        :
                                                                                                        pointPayError ?
                                                                                                            <div
                                                                                                                className="font-t-title font-s-16">{t('modPiErr2')}</div>
                                                                                                            :
                                                                                                            etcPayError ?
                                                                                                                <div
                                                                                                                    className="font-t-title font-s-16">{t('modPiErr3')}</div>
                                                                                                                :
                                                                                                                pointSelectErr ?
                                                                                                                    <div className="font-t-title font-s-16">{t('depositErr')}</div>
                                                                                                                    :
                                                                                                                    depositSelectErr ?
                                                                                                                        <div
                                                                                                                            className="font-t-title font-s-16">{t('pointErr')}</div>
                                                                                                                        :
                                                                                                                        fileSizeOverSt ?
                                                                                                                            <div
                                                                                                                                className="font-t-title font-s-16">{t('uploadSizeOverCont')}</div>
                                                                                                                            :
                                                                                                                            downloadFileComplSt ?
                                                                                                                                <div
                                                                                                                                    className="font-t-title font-s-16">{t('downloadComplCont')}</div>
                                                                                                                                :
                                                                                                                                selectedShipCrError ?
                                                                                                                                    <div
                                                                                                                                        className="font-t-title font-s-16">{t('ciModalCont')}</div>
                                                                                                                                    :
                                                                                                                            null
                    }
                </div>
                <div className="m-a-msgck-bt-container">
                    {regiWaitingSt ?
                        <button onClick={regiWaitingFn} className="m-a-colorBt ht-40 m-b-5 wd-80">
                            <div className="m-a-colorBt-text font-t-cont">Ok</div>
                        </button>
                        :
                        lgWaitingSt ?
                            <button onClick={confirmModalCloseFn} className="m-a-colorBt ht-40 m-b-5 wd-80">
                                <div className="m-a-colorBt-text font-t-cont">Ok</div>
                            </button>
                            :
                            googleLoginErrorMsg ?
                                <button onClick={googleLoginErrorClose} className="m-a-colorBt ht-40 m-b-5 wd-80">
                                    <div className="m-a-colorBt-text font-t-cont">{t(`st6`)}</div>
                                </button>
                                :
                                naverLoginErrorMsg ?
                                    <button onClick={naverLoginErrorClose} className="m-a-colorBt ht-40 m-b-5 wd-80">
                                        <div className="m-a-colorBt-text font-t-cont">Ok</div>
                                    </button>
                                    :
                                    nUserChatRemoveSt ?
                                        <div className="m-a-msgck-bt-container">
                                            {/*조건부렌더링*/}
                                            <button onClick={() => nUserChatRemoveClose(false)} className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                <div className="m-a-gr-Bt-text font-t-cont">{t(`st3`)}</div>
                                            </button>
                                            <button onClick={nUserChatRemove} className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                <div className="m-a-colorBt-text font-t-cont">{t(`st4`)}</div>
                                            </button>
                                        </div>
                                        :
                                        chatConnectFailSt ?
                                            <div className="m-a-msgck-bt-container">
                                                {/*조건부렌더링*/}
                                                <button onClick={() => setChatConnectFailSt(false)} className="m-a-gr-Bt ht-40 m-b-5 wd-80">
                                                    <div className="m-a-gr-Bt-text font-t-cont">{t(`st3`)}</div>
                                                </button>
                                                <button onClick={nUserChatRemove} className="m-a-colorBt ht-40 m-b-5 wd-80 m-l-5">
                                                    <div className="m-a-colorBt-text font-t-cont">{t(`st5`)}</div>
                                                </button>
                                            </div>
                                            :
                                            dontTrackingShipcoSt ?
                                                <button onClick={closeDontTrackingShipco}
                                                        className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                    <div className="m-a-colorBt-text font-t-cont">{t(`st6`)}</div>
                                                </button>
                                                :
                                                downNoSelectSt ?
                                                    <button onClick={closeDownNoSelect}
                                                            className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                        <div className="m-a-colorBt-text font-t-cont">{t(`st6`)}</div>
                                                    </button>
                                                    :
                                                    orderDivideMergeSt ?
                                                        <button onClick={moveToMyPg}
                                                                className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                            <div
                                                                className="m-a-colorBt-text font-t-cont">{t(`st6`)}</div>
                                                        </button>
                                                        :
                                                        dpPayNullSt ?
                                                            <button onClick={closeDpPayNullSt}
                                                                    className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                <div className="m-a-colorBt-text font-t-cont">Ok
                                                                </div>
                                                            </button>
                                                            :
                                                            dpPayNullSt ?
                                                                <button onClick={closeReturnNullSt}
                                                                        className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                    <div
                                                                        className="m-a-colorBt-text font-t-cont">Ok
                                                                    </div>
                                                                </button>
                                                                :
                                                                allUpdateProdNullSt ?
                                                                    <button onClick={closeAllUpdateProdNullSt}
                                                                            className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                        <div
                                                                            className="m-a-colorBt-text font-t-cont">Ok
                                                                        </div>
                                                                    </button>
                                                                    :
                                                                    payinfoSelectOrderErrSt ?
                                                                        <button onClick={closePayinfoSelectOrderErr}
                                                                                className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                            <div
                                                                                className="m-a-colorBt-text font-t-cont">Ok
                                                                            </div>
                                                                        </button>
                                                                        :
                                                                        nonEmsDataSt ?
                                                                            <button onClick={closeNonEmsDataSt}
                                                                                    className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                                <div
                                                                                    className="m-a-colorBt-text font-t-cont">Ok
                                                                                </div>
                                                                            </button>
                                                                            :
                                                                            nonSelectOrderSt ?
                                                                                <button onClick={closeNonSelectOrderSt}
                                                                                        className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                                    <div
                                                                                        className="m-a-colorBt-text font-t-cont">Ok
                                                                                    </div>
                                                                                </button>
                                                                                :
                                                                                biggerCpPriceSt ?
                                                                                    <button
                                                                                        onClick={closeBiggerCpPriceSt}
                                                                                        className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                                        <div
                                                                                            className="m-a-colorBt-text font-t-cont">Ok
                                                                                        </div>
                                                                                    </button>
                                                                                    :
                                                                                    commonError ?
                                                                                        <button
                                                                                            onClick={closeCommonError}
                                                                                            className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                                            <div
                                                                                                className="m-a-colorBt-text font-t-cont">{t(`st6`)}</div>
                                                                                        </button>
                                                                                        :
                                                                                        agreeCheckBoxError ?
                                                                                            <button
                                                                                                onClick={()=> setAgreeCheckBoxError(false)}
                                                                                                className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                                                <div
                                                                                                    className="m-a-colorBt-text font-t-cont">{t(`st6`)}</div>
                                                                                            </button>
                                                                                            :
                                                                                            payMethodError ?
                                                                                                <button
                                                                                                    onClick={()=> setPayMethodError(false)}
                                                                                                    className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                                                    <div
                                                                                                        className="m-a-colorBt-text font-t-cont">{t(`st6`)}</div>
                                                                                                </button>
                                                                                                :
                                                                                                pointPayError ?
                                                                                                    <button
                                                                                                        onClick={()=> setPointPayError(false)}
                                                                                                        className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                                                        <div
                                                                                                            className="m-a-colorBt-text font-t-cont">{t(`st6`)}</div>
                                                                                                    </button>
                                                                                                    :
                                                                                                    etcPayError ?
                                                                                                        <button
                                                                                                            onClick={()=> setEtcPayError(false)}
                                                                                                            className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                                                            <div
                                                                                                                className="m-a-colorBt-text font-t-cont">{t(`st6`)}</div>
                                                                                                        </button>
                                                                                                        :
                                                                                                        pointSelectErr ?
                                                                                                            <button
                                                                                                                onClick={()=> setPointSelectErr(false)}
                                                                                                                className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                                                                <div
                                                                                                                    className="m-a-colorBt-text font-t-cont">{t(`st6`)}</div>
                                                                                                            </button>
                                                                                                            :
                                                                                                            depositSelectErr ?
                                                                                                                <button
                                                                                                                    onClick={()=> setDepositSelectErr(false)}
                                                                                                                    className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                                                                    <div
                                                                                                                        className="m-a-colorBt-text font-t-cont">{t(`st6`)}</div>
                                                                                                                </button>
                                                                                                                :
                                                                                                                fileSizeOverSt ?
                                                                                                                    <button
                                                                                                                        onClick={closeFileSizeOverSt}
                                                                                                                        className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                                                                        <div
                                                                                                                        className="m-a-colorBt-text font-t-cont">{t(`st6`)}</div>
                                                                                                                    </button>
                                                                                                                    :
                                                                                                                    downloadFileComplSt ?
                                                                                                                        <button
                                                                                                                            onClick={closeDownloadFileCompl}
                                                                                                                            className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                                                                            <div
                                                                                                                                className="m-a-colorBt-text font-t-cont">{t(`st6`)}</div>
                                                                                                                        </button>
                                                                                                                        :
                                                                                                                        selectedShipCrError ?
                                                                                                                            <button
                                                                                                                                onClick={closeSelectedShipCrError}
                                                                                                                                className="m-a-colorBt ht-40 m-b-5 wd-80">
                                                                                                                                <div
                                                                                                                                    className="m-a-colorBt-text font-t-cont">{t(`st6`)}</div>
                                                                                                                            </button>
                                                                                                                            :
                                                                                                                    null
                    }
                </div>
            </div>
        </div>
    )
}

export default MMsgCheckModal
