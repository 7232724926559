import React, {useState} from 'react';
import '../../css/web/UserIdPwFindPg.css';
import HeaderLogo from "../../assets/img/HeaderLogo.png";
import {GoMail} from "react-icons/go";
import {Link, useLocation, useNavigate} from "react-router-dom";
import SelectLangModal from "../../components/web/modal/SelectLangModal";
import axios from "axios";
import {AiFillLock} from "react-icons/ai";
import {useTranslation} from "react-i18next";
import i18n from "../../i18n";

function UserIdPwFindPg() {

    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    // 언어선택
    const [selectLangSt, setSelectLangSt] = useState(false);
    const selectLangTrueFn = (() => {
        setSelectLangSt(true);
    })
    const selectLangFalseFn = (() => {
        setSelectLangSt(false);
    })

    const handleSelectLangChange = (lang) => {
        setSelectLangSt(false);
        // 로컬스토리지 언어변경 + i18next 언어변경 code
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', lang);
    }

    const [codeSendSt, setCodeSendSt] = useState(false);
    const [email, setEmail] = useState('');
    const [tempPassword, setTempPassword] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [passwordMatchError, setPasswordMatchError] = useState(false);

    const handleEmail = ((e) => {
        setEmail(e);
        setEmailError(false);
        setNotJoinError(false);
    })

    const handleTempPassword = ((e) => {
        setTempPassword(e);
        setResPassWordError(false);
    })

    const handlePassword = (e) => { /*영문+숫자+특수문자 8~20자리*/
        const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^&*()])(?=.*[0-9]).{8,20}$/;
        if ((!e || (passwordRegex.test(e)))) setPasswordError(false);
        else setPasswordError(true);
        setPassword(e);
    };

    const handleConfirmPassword = (e) => { /*비밀번호 확인(비밀번호와 다르면 에러메시지)*/
        if (password === e) setPasswordMatchError(false);
        else setPasswordMatchError(true);
        setConfirmPassword(e);
    };

    const [resPassword, setResPassword] = useState('');
    const [resPassWordError, setResPassWordError] = useState(false);
    const [notJoinError, setNotJoinError] = useState(false);

    const generateTempPassword = () => {
        setIsLoading(true);
        if (email === '') {
            setEmailError(true);
            setIsLoading(false);
        } else {
        axios({
            method: 'post',
            url: '/member/generateTempPassword',
            params: {
                email: email
            }
        })
            .then((response) => {
                setResPassword(response.data);
                setCodeSendSt(true);
            })
            .catch(() => {
                setNotJoinError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
        }
    }

    const validation = () => {
        let isValid = true;
        if (!password) {
            setPasswordError(true);
            isValid = false;
        }
        if (password !== confirmPassword || !confirmPassword) {
            setPasswordMatchError(true);
            isValid = false;
        }
        if (resPassword !== tempPassword) {
            setResPassWordError(true);
            isValid = false;
        }
        return isValid;
    }

    const valiAndPasswordModify = () => {
        if (validation()) {
            modifyPassword()
        }
    };

    // 백엔드에서 변경 후 프론트엔드로 반환 전 로그인함수실행
    const modifyPassword = (() => {
        setIsLoading(true);
        axios({
            method: 'post',
            url: '/member/modifyPassword',
            params: {
                email: email,
                password: password
            }
        })
            .then(() => {
                login(email, password);
            })
            .catch((error) => {
                setNotJoinError(true);
                setIsLoading(false);
                console.log("변경실패 : ", error)
            })
    })

    const login = ((email, password) => {
        axios({
            method: 'post',
            url: '/member/login',
            data: {
                id: email,
                password: password,
                loginPath: location.pathname,
            }
        })
            .then((response) => {
                const currentTime = new Date();
                sessionStorage.setItem('AToken', response.headers.get('Authorization'))
                sessionStorage.setItem('lastLoginTime', currentTime);
                navigate("/")
            })
            .catch((error) => {
                setNotJoinError(true);
                console.log("변경실패 : ", error)
            })
            .finally(() => {
                setIsLoading(false);
            })
    })

    return (
        <div className="FindContainer font-t-cont">
            <div className="FindMain">
                <div className="FindFrame">
                    <div className="AccPgLogo">
                        <Link to={"/"}>
                            <img alt="mainLogo" style={{width:"159px", height:"38px", opacity: "0.8"}} src={HeaderLogo}/>
                        </Link>
                    </div>
                    <div className="FindPgIdPwContainer">
                        {!codeSendSt ?
                            <div>
                                <div className="JoinForm acc-icon">
                                    <div className="JoinIcon acc-lg-icon"><i><GoMail/></i></div>
                                    <div className="acc-input-form">
                                        <input className="JoinInput" type="text" placeholder=" "
                                               value={email} onChange={(e) => handleEmail(e.target.value)} maxLength={40}
                                               onKeyDown={(e) => {
                                                   if (e.key === 'Enter') {
                                                       generateTempPassword();
                                                   }
                                               }}
                                        />
                                        <label className="JoinLabel">{t(`ipfLabel`)}</label>
                                    </div>
                                </div>
                                {notJoinError &&
                                    <div className="JoinErrorMsg">
                                        <p className="JoinErrMsg">&nbsp;{t(`ipfErr1`)}</p>
                                    </div>
                                }

                                {emailError &&
                                    <div className="JoinErrorMsg">
                                        <p className="JoinErrMsg">&nbsp;{t(`ipfErr2`)}</p>
                                    </div>
                                }
                                <div style={{marginTop: "15px"}}>
                                    <style>
                                        {`@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}`}
                                    </style>
                                    <button onClick={generateTempPassword} className="bt-btn-B-B wd-100pc ht-45 font-s-13">
                                        {isLoading ? (
                                            <div className="spinner"></div>
                                        ) : (
                                        <p style={{
                                        margin: "0",
                                        fontSize: "15px",
                                        color: "white",
                                        zIndex: 10,
                                        fontWeight: "lighter"
                                    }}>{t(`idfBt`)}</p>
                                        )}
                                    </button>
                                </div>
                            </div>
                            :
                            <div>
                                <div className="JoinForm acc-icon" style={{marginTop: "25px"}}>
                                    <div className="acc-lg-icon"><i><AiFillLock/></i></div>
                                    <div className="acc-input-form">
                                        <input className="JoinInput" type="password" placeholder=" "
                                               value={tempPassword} onChange={(e)=> handleTempPassword(e.target.value)}
                                               minLength="8" maxLength="20"
                                               onKeyDown={(e) => {
                                                   if (e.key === 'Enter') {
                                                       valiAndPasswordModify();
                                                   }
                                               }}/>
                                        <label className="JoinLabel">{t(`ipfTempPw`)}</label>
                                    </div>
                                </div>
                                { resPassWordError ?
                                    <div className="JoinErrorMsg">
                                        <p className="JoinErrMsg">&nbsp;{t(`ipfTempErr`)}</p>
                                    </div>
                                    :
                                    null
                                }
                                <div className="JoinForm acc-icon">
                                    <div className="acc-lg-icon"><i><AiFillLock/></i></div>
                                    <div className="acc-input-form">
                                        <input className="JoinInput" type="password" placeholder=" "
                                               value={password} onChange={(e)=> handlePassword(e.target.value)}
                                               maxLength={20}/>
                                        <label className="JoinLabel">{t(`ipfCgPw`)}</label>
                                    </div>
                                </div>
                                { passwordError ?
                                    <div className="JoinErrorMsg">
                                        <p className="JoinErrMsg">&nbsp;{t(`ipfCgPwErr`)}</p>
                                    </div>
                                    :
                                    null
                                }
                                <div className="JoinForm acc-icon">
                                    <div className="acc-lg-icon"><i><AiFillLock/></i></div>
                                    <div className="acc-input-form">
                                        <input className="JoinInput" type="password" placeholder=" "
                                               value={confirmPassword} onChange={(e)=> handleConfirmPassword(e.target.value)}
                                               maxLength={20}
                                               onKeyDown={(e) => {
                                                   if (e.key === 'Enter') {
                                                       valiAndPasswordModify();
                                                   }
                                               }}/>
                                        <label className="JoinLabel">{t(`ipfCgConfPw`)}</label>
                                    </div>
                                </div>
                                {passwordMatchError ?
                                    <div className="JoinErrorMsg">
                                        <p className="JoinErrMsg">&nbsp;{t(`ipfCgConfErr`)}</p>
                                    </div>
                                    :
                                    null
                                }
                                <div style={{marginTop: "15px"}}>
                                    <style>
                                        {`@keyframes spin {0% { transform: rotate(0deg); }100% { transform: rotate(360deg); }}`}
                                    </style>
                                        <button onClick={valiAndPasswordModify} className="colorFullBt user-center" style={{
                                        width: "100%",
                                        height: "50px",
                                        textAlign: "center",
                                        fontSize: "20px",
                                        borderRadius: "4px",
                                    }}>
                                            {isLoading ? (
                                                <div className="spinner"></div>
                                            ) : (
                                            <p style={{
                                        fontSize: "15px",
                                        color: "white",
                                        zIndex: 10,
                                        fontWeight: "lighter"
                                    }}>{t(`ipfCgDoneBt`)}</p>
                                            )}
                                        </button>

                                </div>

                            </div>
                        }
                        <div className="user-center" style={{height:"40px", position:"absolute", left:"70px", bottom:0, minWidth:"350px", flexDirection:"row", alignItems:"flex-start"}}>
                            <p className="btorage-hover c-point font-s-13" onClick={selectLangTrueFn}>{t(`lang`)}</p>
                            <p className="btorage-hover c-point font-s-13 m-l-30">{t(`UseInfo`)}</p>
                            <p className="btorage-hover c-point font-s-13 m-l-30">{t(`infoPolicy`)}</p>
                        </div>
                    </div>
                </div>
            </div>
            {selectLangSt ?
                <SelectLangModal selectLangTrueFn={selectLangTrueFn} selectLangFalseFn={selectLangFalseFn}
                                 handleSelectLangChange={handleSelectLangChange}/>
                :
                null
            }
        </div>
    )
}

const spinnerStyle = {
    border: "4px solid rgba(255, 255, 255, 0.2)",
    borderTop: "4px solid white",
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    animation: "spin 1s linear infinite",
};

// @keyframes 정의
const styleSheet = document.styleSheets[0];
styleSheet.insertRule(`
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}`, styleSheet.cssRules.length);

export default UserIdPwFindPg
