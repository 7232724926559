import React from "react";
import {useTranslation} from "react-i18next";
import errRobotIMG from "../../assets/img/errRobot.png";
import {useNavigate} from "react-router-dom";

function ErrStatusPg({ status }) {

    const navigate = useNavigate();
    const {t} = useTranslation();

    const getErrMessage = () => {
        switch (status) {
            case 401:
            case 402:
            case 403:
                return t('statusErr401');
            case 404:
                return t('statusErr404');
            case 500:
                return t('statusErr500');
            case 501:
                return t('statusErr501');
            case 502:
            case 503:
                return t('statusErr502');
            default:
                return t('statusErrEtc');
        }
    };

    const mainRefresh = () => {
        navigate(`/`);
    }

    return(
        <div className="display-flex flex-column just-cont-center text-center bt-background-grey ht-100vh font-t-cont gap15 font-c-default">
            <div className="display-flex just-cont-center wd-100pc ht-auto m-b-30">
                <div className="wd-140 ht-150"><img className="display-inline wd-100pc ht-100pc" alt="mainLogo" src={errRobotIMG}/></div>
            </div>
            <div className="p-l-30 p-r-30"><p className="font-s-20">{getErrMessage()}</p></div>
            <div className="p-l-30 p-r-30"><p className="font-s-18">{t(`statusErrMsg`)}</p></div>
            <div className="m-t-50 p-t-20"><button className="bt-btn-B-B wd-140 ht-40" onClick={mainRefresh}>Back To Home</button></div>
        </div>
    )
}

export default ErrStatusPg
