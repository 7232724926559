import React, {useEffect, useRef, useState} from 'react';
import '../../css/web/AdminInScanPg.css';
import {Tab, Tabs} from "react-bootstrap";
import axios from "axios";
import AdminPagination from "../../components/web/layoutsadmin/AdminPagination";
import AdminPageEvent from "../../components/web/layoutsadmin/AdminPageEvent";
import AdminYmdEvent from "../../components/web/layoutsadmin/AdminYmdEvent";
import AdminSearchEvent from "../../components/web/layoutsadmin/AdminSearchEvent";
import excel from "exceljs";
import commonCheck from "../../assets/img/commonCheck.png";
import {MdKeyboardArrowDown} from "react-icons/md";
import MsgSelectModal from "../../components/web/modal/MsgSelectModal";
import MsgCheckModal from "../../components/web/modal/MsgCheckModal";
import {RxCheck, RxCopy} from "react-icons/rx";
import maCateImg from "../../assets/img/m-a-cate.png";
import {useWebSocket} from "../../services/WebsocketContext";

function AdminInScanPg() {

    /** region DataTable */

    const [data, setData] = useState([]);
    const [shipcoData, setShipcoData] = useState([]);

    /** endregion */

    /** region Function */

    const LCAT = localStorage.getItem('adminToken');
    const [shipcoName, setShipcoName] = useState("");
    const [selectedShipCompany, setSelectedShipCompany] = useState('');
    const [shipcoCode] = useState("");
    const [wayNo, setwayNo] = useState("");

    const [adminInfo, setAdminInfo] = useState([]);
    const selectedAdminInfo = (() => {
        axios({
            method: 'get',
            url: '/member/findMember',
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                setAdminInfo(response.data)
            })
            .catch((error) => {
                console.log("Admin 조회실패 : ", error)
            })
    })

    useEffect(() => {
        selectedAdminInfo();
    }, [])

    const isSubscribedRef = useRef(false);
    const {subscribe, unsubscribe, websocketConnect, isConnected, stompClient} = useWebSocket();

    useEffect(() => {
        const initializeConnection = async () => {
            if (!adminInfo.mbNum || isSubscribedRef.current) return;

            if (!stompClient && !isConnected) {
                await websocketConnect(adminInfo.mbNum);
            }
            if (isConnected && stompClient && !isSubscribedRef.current) {
                isSubscribedRef.current = true;
            }
        }

        initializeConnection();

        return () => {
            if (isSubscribedRef.current) {
                isSubscribedRef.current = false;
            }
        };

    }, [adminInfo.mbNum, isConnected, stompClient]);

    const scan = (e) => {setwayNo(e.target.value);};
    const shipSelect = (e) => {setShipcoName(e.target.value);};

    const [tabSt, setTabSt] = useState('');

    const handleSelectTabs = ((key) => {
        setTabSt(key);
    })

    const [copiedIndexes, setCopiedIndexes] = useState([]);

    const handleCopy = (text, index) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setCopiedIndexes([...copiedIndexes, index]);
                setTimeout(() => {
                    setCopiedIndexes(copiedIndexes.filter(i => i !== index));
                }, 1000);
            })
            .catch((error) => {console.error("실패: ", error);});
    };

    //region ScrollPagination

    const [displayLimit, setDisplayLimit] = useState(10);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [data.length, displayLimit]);

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
            if (data.length > displayLimit && displayLimit < 30) {
                setDisplayLimit(prevLimit => prevLimit + perPg);
            }
        }
    };

    const loadMoreData = () => {
        setDisplayLimit(prevLimit => prevLimit + perPgOrd);
    };

    // endregion

    //region Pagination

    const [currentPage, setCurrentPage] = useState(1);
    const [perPg, setperPg] = useState(10);
    const indexOfLast = currentPage * perPg;
    const indexOfFirst = indexOfLast - perPg;
    const pageChange = (currentPage) => {setCurrentPage(currentPage);};
    const handleSelectChange = (e) => {
        setperPg(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const [currentOrdPage, setCurrentOrdPage] = useState(1);
    const [perPgOrd, setperPgOrd] = useState(10);
    const indexOfLastOrd = currentOrdPage * perPgOrd;
    const indexOfFirstOrd = indexOfLastOrd - perPgOrd;
    const handleOrdSelectChange = (e) => {
        const newPerPage = parseInt(e.target.value);
        setperPgOrd(newPerPage);
        // setDisplayLimit(newPerPage);
        setCurrentOrdPage(1);
    };

    useEffect(() => {
        setDisplayLimit(perPg);
    }, [perPg]);

    //endregion

    //region CheckBox

    const [checked, setChecked] = useState([]);
    const [checkedOrd, setCheckedOrd] = useState([]);

    const checkBoxChange = (id) => {
        setChecked((prevSelectedData) => {
            if (prevSelectedData.includes(id)) {
                return prevSelectedData.filter((item) => item !== id);
            } else {
                return [...prevSelectedData, id];
            }
        });
    };

    const allcheckBoxChange = (e) => {
        if (e.target.checked) {
            const allIds = data.map((item) => item.seqNum);
            setChecked(allIds);
        } else {
            setChecked([]);
        }
    };

    const checkBoxOrdChange = (id) => {
        setCheckedOrd((prevSelectedData) => {
            if (prevSelectedData.includes(id)) {
                // id가 있으면 제거
                return prevSelectedData.filter((item) => item !== id);
            } else {
                // id가 없으면 추가
                return [...prevSelectedData, id];
            }
        });
    };

    const allcheckBoxOrdChange = (e) => {
        if (e.target.checked) {
            // 전체 체크박스가 체크되면 현재 화면에 보여지는 데이터만 선택
            const visibleIds = alarmSearchData.slice(0, alarmDisplayLimit).map((item) => item.seqNum);
            setCheckedOrd(visibleIds);
        } else {
            // 전체 체크박스 해제 시 모든 선택 초기화
            setCheckedOrd([]);
        }
    };

    //endregion

    //region Search

    const [searchInput, setSearchInput] = useState('');
    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value);
    };

    const searchData = data.filter((cData) => {
        return cData.wayNo.toLowerCase().includes(searchInput.toLowerCase());
    });

    //endregion

    //region DateSelect

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const startHandle = (e) => {const selectedStartDate = e.target.value;setStartDate(selectedStartDate);};
    const endHandle = (e) => {setEndDate(e.target.value);};
    const stringStartDate = startDate.replace(/-/g, '');
    const stringEndDate = endDate.replace(/-/g, '');

    const [startDateAlarm, setStartDateAlarm] = useState('');
    const [endDateAlarm, setEndDateAlarm] = useState('');
    const startHandleAlarm = (e) => {const selectedStartDate = e.target.value;setStartDateAlarm(selectedStartDate);};
    const endHandleAlarm = (e) => {setEndDateAlarm(e.target.value);};
    const stringStartDateAlarm = startDateAlarm.replace(/-/g, '');
    const stringEndDateAlarm = endDateAlarm.replace(/-/g, '');


    const currentDate = new Date();
    const twoWeeksAgo = new Date();
    twoWeeksAgo.setDate(currentDate.getDate() - 7);

    const defaultMonth = () => {
        const stringStartDate = formatDate(twoWeeksAgo);
        const stringEndDate = formatDate(currentDate);
        setStartDate(stringStartDate);
        setEndDate(stringEndDate);

        setStartDateAlarm(stringStartDate);
        setEndDateAlarm(stringEndDate);
    }

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    useEffect(() => {
        defaultMonth();
    }, [])

    useEffect(() => {
        if (startDate && endDate && startDateAlarm && endDateAlarm) {
            dateSelect();
        }
        selectRegAlarmList();
    }, [startDate, endDate, startDateAlarm, endDateAlarm])

    useEffect(() => {
        shipCompanySelect();
    }, [])

    //endregion DateSelect

    const [dontTrackingShipcoSt, setDontTrackingShipcoSt] = useState(false);
    const closeDontTrackingShipco = (() => {
        setDontTrackingShipcoSt(false);
    });

    const dbSelectedDeliveryTracking = ((selectedDomestic, dsTrackNum) => {
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const width = 1200;
        const height = 800;
        const left = (screenWidth / 2) - (width / 2);
        const top = (screenHeight / 2) - (height / 2);

        if (selectedDomestic === "CJ대한통운") {
            const url = `https://trace.cjlogistics.com/next/tracking.html?wblNo=${dsTrackNum}`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "로젠택배") {
            const url = `https://www.ilogen.com/web/personal/trace/${dsTrackNum}`;
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "우체국") {
            const url = `https://service.epost.go.kr/trace.RetrieveDomRigiTraceList.comm?sid1=${dsTrackNum}&displayHeader=N`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "한진택배") {
            const url = `https://www.hanjin.com/kor/CMS/DeliveryMgr/WaybillResult.do?mCode=MN038&schLang=KR&wblnumText2=${dsTrackNum}`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "롯데택배") {
            const url = `https://www.lotteglogis.com/open/tracking?invno=${dsTrackNum}`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else if (selectedDomestic === "경동택배") {
            const url = `https://kdexp.com/newDeliverySearch.kd?barcode=${dsTrackNum}`
            window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
        } else {
            setDontTrackingShipcoSt(true);
        }
    })

    /** endregion */

    /** region Event */

    const shipCompanySelect = () => {
        axios.get('/shipcompany/shipcoselect')
            .then((response) => {
                const shipType = response.data.filter(cData => cData.courierCompanyName !== '국내배송');
                setShipcoData(shipType);
            }, [])
            .catch((error) => {
                console.log("adminErr[00] : ", error)
                const status = error?.response?.status;
                const errorPages = [400, 401, 402, 403, 404, 405, 500, 501, 502, 503];
                if (status && errorPages.includes(status)) {
                    window.location.href = '/ErrStatusPg';
                }
            })
    }

    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            onScan();
        }
    };

    const dateSelect = () => {
        axios({
            method: 'get',
            url: '/instore/admininscandateselect',
            params: {
                startDate: stringStartDate,
                endDate: stringEndDate,
            },
            headers: {
                Authorization: LCAT
            },
        })
            .then((response) => {
                const sortedData = response.data.sort((a, b) => {
                    return new Date(a.regTime) - new Date(b.regTime);
                });
                setData(sortedData);
            })
            .catch((error) => {
                console.log("adminErr[00] : ", error)
                const status = error?.response?.status;
                const errorPages = [400, 401, 402, 403, 404, 405, 500, 501, 502, 503];
                if (status && errorPages.includes(status)) {
                    window.location.href = '/ErrStatusPg';
                }
            })
    };

    const onScan = () => {
        if (shipcoName === '' || shipcoName === '배송사 선택') {
            alert("배송사를 선택 해주세요.");
            // playAlertSound(); 경고음 발생(스캔실패)
            return;
        }

        let wayNoLength;
        if (shipcoName === 'CJ대한통운' || shipcoName === '롯데택배' || shipcoName === '한진택배') {
            wayNoLength = 12;
        } else if (shipcoName === '로젠택배') {
            wayNoLength = 11;
        } else if (shipcoName === '우체국') {
            wayNoLength = 13;
        } else if (shipcoName === '쿠팡') {
            wayNoLength = 14;
        } else {
            // wayNoLength = -1;
            return;
        }

        if (wayNo.length !== wayNoLength) {
            console.log(`운송장번호 ${wayNo}에 대한 ${wayNoLength}를 충족하지 않습니다. ${shipcoName}`);
            setwayNo("");
            return;
        }

        // if (wayNoLength !== -1 && wayNo.length !== wayNoLength) {
        //     alert(`${shipcoName}의 추적번호 ${wayNoLength}자릿수를 확인하세요`);
        //     return;
        // }

        axios.post('/instore/admininscansave', {
            wayNo: wayNo,
            wayNoCode: wayNo,
            shipcoName: shipcoName,
            shipcoCode: shipcoCode
        }, {
            headers: {
                'Authorization': LCAT
            }
        })
            .then(() => {
                setwayNo("");
                dateSelect();
            })
            .catch((error) => {
                //중복스캔 그냥 넘김
                if (error.response.status === 500 || error.response.status === 400) {
                    setwayNo("");
                }
            })
    };

    const selectRegAlarmList = (() => {
        axios({
            method: 'get',
            url: '/instore/selectRegAlarmList',
            params: {
                startDate: stringStartDateAlarm,
                endDate: stringEndDateAlarm,
            },
        })
            .then((response) => {
                // let sortedData = response.data.sort((a, b) => b.seqNum - a.seqNum);
                // setAlarmList(sortedData);

                // userNm 컬럼값이 동일한 데이터들끼리 그룹화
                const groupedData = response.data.reduce((acc, cur) => {
                    acc[cur.userNm] = acc[cur.userNm] || [];
                    acc[cur.userNm].push(cur);
                    return acc;
                }, {});

                // 그룹 내에서 seqNum으로 내림차순 정렬
                Object.keys(groupedData).forEach(key => {
                    groupedData[key].sort((a, b) => b.seqNum - a.seqNum);
                });

                // 그룹별로 정렬된 데이터를 하나의 배열로 합치기
                let sortedData = [];
                Object.values(groupedData).forEach(group => {
                    sortedData = sortedData.concat(group);
                });
                setAlarmList(sortedData);
            })
            .catch((error) => {
                console.log("adminErr[00] : ", error)
                const status = error?.response?.status;
                const errorPages = [400, 401, 402, 403, 404, 405, 500, 501, 502, 503];
                if (status && errorPages.includes(status)) {
                    window.location.href = '/ErrStatusPg';
                }
            })
    })

    const ordDtMove = (ordNum) => () => {
        window.open(`/btcorpad/orderdtmodi/${ordNum}`, '_blank');
    };

    // region Excel

    const date = new Date();
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const DateViewValue = year + month + day;

    const excelDown = () => {
        try {
            const selectedRows = data.filter((item) => checked.includes(item.seqNum));

            const workbook = new excel.Workbook();
            workbook.created = new Date();
            workbook.modified = new Date();

            workbook.addWorksheet('Data');

            const sheetOne = workbook.getWorksheet('Data');

            sheetOne.getRow(1).font = {
                name: '맑은 고딕',
                bold: true,
                size: 11
            };
            sheetOne.getRow(1).alignment = {
                horizontal: 'center',
                vertical: 'middle'
            };
            sheetOne.getRow(1).height = 22;
            sheetOne.columns = [
                {header: '', key: '', width: 0.7},
                {header: '작업일자', key: 'regDate', width: 12, style: { alignment: { horizontal: 'center' }}},
                {header: '배송사명', key: 'shipcoName', width: 22, style: { alignment: { horizontal: 'center' }}},
                {header: '운송장번호', key: 'wayNo', width: 22, style: { alignment: { horizontal: 'center' }}},
                {header: '스캔일시', key: 'regTime', width: 22, style: { alignment: { horizontal: 'center' }}},
                {header: '중복스캔일시', key: 'modiTime', width: 22, style: { alignment: { horizontal: 'center' }}},
                {header: '작업자명', key: 'regNm', width: 15, style: { alignment: { horizontal: 'center' }}}
            ];

            selectedRows.map((item, index) => {
                sheetOne.addRow(item);

                // 추가된 행의 컬럼 설정(헤더와 style이 다를 경우)
                for(let loop = 1; loop <= 8; loop++) {
                    const col = sheetOne.getRow(index + 2).getCell(loop);
                    col.font = {name: '맑은 고딕', size: 10};
                }
            });

            workbook.xlsx.writeBuffer().then((data) => {
                const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = `입고내역_` + DateViewValue + `.xlsx`;
                anchor.click();
                window.URL.revokeObjectURL(url);
            })
        } catch(error) {
            console.error(error);
        }
    }

    // endregion

    /** endregion */

    const [alarmDisplayLimit, setAlarmDisplayLimit] = useState(50);
    const alarmPerPgOrd = 50;  // 한 번에 더 보여줄 데이터 수

    const loadMoreAlarmData = () => {
        setAlarmDisplayLimit(prevLimit => prevLimit + alarmPerPgOrd);
    };

    const [alarmList, setAlarmList] = useState([]);
    const [alarmSearchInput, setAlarmSearchInput] = useState('');

    const handleAlarmSearchInputChange = (event) => {
        setAlarmSearchInput(event.target.value);
    };
    const alarmSearchData = alarmList.filter((cData) => {
        const searchLower = alarmSearchInput.toLowerCase();
        return (
            cData.ordNum.toLowerCase().includes(searchLower) ||
            cData.userNm.toLowerCase().includes(searchLower) ||
            cData.wayNo.toLowerCase().includes(searchLower) ||
            (cData.productDTO?.name?.toLowerCase().includes(searchLower)) ||
            (cData.productDTO?.option?.toLowerCase().includes(searchLower))
        );
    });

    const [allUpdateProdSt, setAllUpdateProdSt] = useState(false);
    const allUpdateProdStMsg1 = "선택하신 모든 데이터를 입고처리하시겠습니까?"
    const allUpdateProdStMsg2 = "처리 후 복구할 수 없습니다"
    const openAllUpdateProdSt = (() => {
        if (checkedOrd.length > 0) {
            setAllUpdateProdSt(true);
        } else {
            setAllUpdateProdNullSt(true);
        }
    })
    const closeAllUpdateProdSt = (() => {
        setAllUpdateProdSt(false);
    })
    const [allUpdateProdNullSt, setAllUpdateProdNullSt] = useState(false);
    const closeAllUpdateProdNullSt = (() => {
        setAllUpdateProdNullSt(false);
    })

    const [updateProdSt, setUpdateProdSt] = useState(false);
    const updateProdStMsg1 = "선택하신 1행의 데이터를 입고처리하시겠습니까?"
    const updateProdStMsg2 = "처리 후 복구할 수 없습니다"
    const openUpdateProdSt = ((seqNum) => {
        setCheckedOrd([seqNum]);
        setUpdateProdSt(true);
    })
    const closeUpdateProdSt = (() => {
        setUpdateProdSt(false);
    })

    const updateProdArrival = ((seqNums) => {
        axios({
            method: 'post',
            url: '/instore/updateProdArrival',
            Authorization: LCAT,
            data: seqNums
        })
            .then(async (response) => {
                const updatedAlarms = alarmList.map(alarm => {
                    if (seqNums.includes(alarm.seqNum)) {
                        return {
                            ...alarm,
                            productDTO: {
                                ...alarm.productDTO,
                                prodSt: "IS05"
                            }
                        };
                    }
                    return alarm;
                });
                setAlarmList(updatedAlarms);
                if (allUpdateProdSt) {
                    closeAllUpdateProdSt();
                } else {
                    closeUpdateProdSt();
                }
                if (stompClient) {
                    await stompClient.send("/app/prodSt/adminProdStUpdate", {}, JSON.stringify(response.data));
                } else {
                    console.error("Stomp client is not connected.");
                }
            })
            .catch((error) => {
                console.log("adminErr[01] : ", error)
                const status = error?.response?.status;
                const errorPages = [400, 401, 402, 403, 404, 405, 500, 501, 502, 503];
                if (status && errorPages.includes(status)) {
                    window.location.href = '/ErrStatusPg';
                }
            })
    })

    return (
        <div className="AdminInScanPg font-t-cont">
            <div className="admin-work-title">
                <img className="wd-35 ht-35" alt="" src={maCateImg}/>
                <p className="p-t-6">입고스캔관리</p>
            </div>
            <div className="admin-wrap">
                <div className="admin-wrap-card m-b-40">
                    <div className="scan-bar">
                        <select className="admin-combo-not-font combo-plus" onChange={shipSelect} value={shipcoName}
                                disabled={tabSt === '입고조회' ? false : tabSt === '입고확인' ? true : ""}>
                            <option value="배송사 선택">선택</option>
                            {shipcoData.map((cData) => (
                                <option value={cData.courierCompanyName} key={cData.seqnum}>
                                    {cData.courierCompanyName}
                                </option>
                            ))}
                        </select>
                        <input className="admin-input-line scan-waybill" type="text" placeholder="여기를 클릭 후 스캔"
                               maxLength="20" onKeyDown={onKeyPress} value={wayNo} onChange={scan} disabled={tabSt === '입고조회' ? false : tabSt === '입고확인' ? true : ""}/>
                        <div className="scan-msg">
                            <b className="scan-select-text c-def">{shipcoName}</b>
                        </div>
                    </div>
                </div>
                <div className="admin-wrap-card">
                    <Tabs defaultActiveKey="입고조회" className="admin-Tabs mb-3" onSelect={handleSelectTabs}>
                        <Tab eventKey="입고조회" title="입고조회">
                            <div className="admin-event-wrap">
                                <div className="admin-event-wrap-btn">
                                    <AdminPageEvent perPg={perPg} handleSelectChange={handleSelectChange}/>
                                    <AdminYmdEvent startDate={startDate} endDate={endDate} startHandle={startHandle} endHandle={endHandle}/>
                                    <div style={{marginRight: "15px", marginTop: "3px"}}>
                                        <select  className="admin-combo" style={{width:"110px", height:"1.78rem", paddingLeft:"7px", fontSize:"14px"}} onChange={(e) => setSelectedShipCompany(e.target.value)}>
                                            <option value="">전체</option>
                                            <option value="CJ대한통운">CJ대한통운</option>
                                            <option value="롯데택배">롯데택배</option>
                                            <option value="로젠택배">로젠택배</option>
                                            <option value="우체국">우체국</option>
                                            <option value="한진택배">한진택배</option>
                                            <option value="쿠팡">쿠팡</option>
                                            <option value="기타택배">기타택배</option>
                                            <option value="FedEx">FedEx</option>
                                            <option value="EMS">EMS</option>
                                            <option value="SHIP">SHIP</option>
                                        </select>
                                    </div>
                                    <AdminSearchEvent searchInput={searchInput} handleSearchInputChange={handleSearchInputChange} />
                                </div>
                                <div className="admin-event-wrap-btn gap10">
                                    <button onClick={excelDown} className="admin-btn-A wd-100 ht-30 font-s-13 btn-click-event">EXCEL</button>
                                </div>
                            </div>
                            <div className="div-tb m-b-10">
                                <div className="div-tb-head ht-60">
                                    <div style={{width: "40px"}}>
                                        <input className="admin-input-chkBox m-0-a" type="checkbox" checked={checked.length === data.length} onChange={allcheckBoxChange}/>
                                    </div>
                                    <div style={{width: "120px"}}><p>스캔일자</p></div>
                                    <div style={{width: "120px"}}><p>배송사명</p></div>
                                    <div style={{width: "150px"}}><p>운송장번호</p></div>
                                    <div style={{width: "150px"}}><p>운송장번호바코드</p></div>
                                    <div style={{width: "150px"}}><p>스캔일시</p></div>
                                    <div style={{width: "90px"}}><p>작업자</p></div>
                                    <div style={{width: "150px"}}><p>수정일시(중복스캔)</p></div>
                                    <div style={{width: "90px"}}><p>수정자</p></div>
                                </div>
                                {searchData.filter((cData) => selectedShipCompany === '' || cData.shipcoName === selectedShipCompany).slice(indexOfFirst, indexOfLast).map((cData, index) => (
                                    <div key={cData.seqNum} className="div-tb-body ht-70">
                                        <div style={{width: "40px"}}>
                                            <input className="admin-input-chkBox m-0-a" type="checkbox" name={cData.seqNum} checked={checked.includes(cData.seqNum)}
                                                   onChange={() => checkBoxChange(cData.seqNum)}/>
                                        </div>
                                        <div style={{width: "120px"}}>
                                            <p>{cData.regDate.slice(0, 4) + '-' + cData.regDate.slice(4, 6) + '-' + cData.regDate.slice(6, 8)}</p>
                                        </div>
                                        <div style={{width: "120px"}}><p>{cData.shipcoName}</p></div>
                                        <div style={{width: "150px"}}>
                                            <a className="font-b c-point text-underline" style={{color: "#276AB7"}}
                                               onClick={() => dbSelectedDeliveryTracking(cData.shipcoName, cData.wayNoCode.trim())}>
                                                {((cData.shipcoName === 'CJ대한통운'
                                                    || cData.shipcoName === '롯데택배'
                                                    || cData.shipcoName === '한진택배'
                                                    || cData.shipcoName === '쿠팡') && cData.wayNo.length === 12)
                                                    ? `${cData.wayNo.slice(0, 4)}-${cData.wayNo.slice(4, 8)}-${cData.wayNo.slice(8, 12)}`
                                                    : (cData.shipcoName === '로젠택배' && cData.wayNo.length === 11)
                                                        ? `${cData.wayNo.slice(0, 4)}-${cData.wayNo.slice(4, 8)}-${cData.wayNo.slice(8, 11)}`
                                                        : (cData.shipcoName === '우체국' && cData.wayNo.length === 13)
                                                            ? `${cData.wayNo.slice(0, 5)}-${cData.wayNo.slice(5, 9)}-${cData.wayNo.slice(9, 13)}`
                                                            : cData.wayNo}
                                            </a>
                                        </div>
                                        <div style={{width: "150px"}}>
                                            <p className="font-b">{cData.wayNoCode.trim()}
                                                {!copiedIndexes.includes(index) && (
                                                    <RxCopy className="m-l-7 font-s-18 c-point btorage-hover btn-click-event" style={{opacity: "0.65"}}
                                                        onClick={() => handleCopy(cData.wayNoCode.trim(), index)}/>
                                                )}
                                                {copiedIndexes.includes(index) && <RxCheck className="m-l-7 font-s-18 font-c-btorage" style={{opacity: "0.65"}} />}
                                            </p>
                                        </div>
                                        <div style={{width: "150px"}}><p>{cData.regTime}</p></div>
                                        <div style={{width: "90px"}}><p>{cData.regNm}</p></div>
                                        <div style={{width: "150px"}}><p>{cData.modiTime}</p></div>
                                        <div style={{width: "90px"}}><p>{cData.modiNm}</p></div>
                                    </div>
                                ))}
                            </div>
                            <AdminPagination perPg={perPg} totalData={data.length} paginate={currentPage} pageChange={pageChange}/>
                        </Tab>
                        <Tab eventKey="입고확인" title={
                            <span style={{display:"flex", alignItems:"center", justifyContent:"center"}}>입고확인
                                {alarmList && alarmList.length > 0 ?
                                    <span style={{
                                        fontWeight: 'bold', color: 'white', fontSize: "12px", marginLeft: "5px",
                                        borderRadius: "20px", padding: "0 6px", backgroundColor: "darkorange"
                                    }}>{alarmList?.length}</span>
                                    :
                                    null
                                }
                            </span>
                        }>
                            <div className="admin-event-wrap">
                                <div className="admin-event-wrap-btn">
                                    <AdminPageEvent perPg={perPg} handleSelectChange={handleOrdSelectChange}/>
                                    <AdminYmdEvent startDate={startDateAlarm} endDate={endDateAlarm} startHandle={startHandleAlarm} endHandle={endHandleAlarm}/>
                                    <AdminSearchEvent searchInput={alarmSearchInput} handleSearchInputChange={handleAlarmSearchInputChange} />
                                </div>
                                <button className="admin-btn-C wd-100 ht-30 font-s-13 btn-click-event"onClick={openAllUpdateProdSt}>일괄입고</button>
                            </div>
                            <div className="div-tb m-b-10">
                                <div className="div-tb-head ht-60">
                                    <div style={{width: "40px"}}>
                                        <input className="admin-input-chkBox m-0-a" type="checkbox"
                                               checked={alarmSearchData.slice(0, alarmDisplayLimit).every(item => checkedOrd.includes(item.seqNum))}
                                               onChange={allcheckBoxOrdChange}/>
                                    </div>
                                    <div style={{width: "120px"}}><p>주문번호</p></div>
                                    <div style={{width: "120px"}}><p>고객명</p></div>
                                    <div style={{width: "80px"}}><p>배송사</p></div>
                                    <div style={{width: "80px"}}><p>운송장번호</p></div>
                                    <div style={{width: "200px"}}><p>상품</p></div>
                                    <div style={{width: "200px"}}><p>옵션</p></div>
                                    <div style={{width: "60px"}}><p>수량</p></div>
                                    <div style={{width: "80px"}}><p>입고처리</p></div>
                                    <div style={{width: "150px"}}><p>입고일시</p></div>
                                </div>
                                {alarmSearchData.slice(0, alarmDisplayLimit).map((al, i) => (
                                    <div key={i} className="div-tb-body ht-70">
                                        <div style={{width: "40px"}}>
                                            <input className="admin-input-chkBox m-0-a" type="checkbox" name={al.seqNum} checked={checkedOrd.includes(al.seqNum)}
                                                   onChange={() => checkBoxOrdChange(al.seqNum)}/>
                                        </div>
                                        <div onClick={ordDtMove(al.ordNum)} style={{width: "120px", cursor:"pointer", textDecoration:"underline"}}>
                                            <p style={{cursor:"pointer"}} className="font-b">{al.ordNum}</p>
                                        </div>
                                        <div style={{width: "120px"}}><p>{al.userNm}</p></div>
                                        <div style={{width: "80px"}}><p>{al.shipcoNm}</p></div>
                                        <div style={{width: "80px"}}><p className="font-b">{al.wayNo}</p></div>
                                        <div style={{width: "200px"}}><p>{al.productDTO?.name}</p></div>
                                        <div style={{width: "200px"}}><p>{al.productDTO?.option}</p></div>
                                        <div style={{width: "60px"}}><p>{al.productDTO?.quantity}개</p></div>
                                        <div className="user-center" style={{width: "80px"}}>
                                            {al.productDTO?.prodSt === "IS00" ?
                                                <img onClick={() => openUpdateProdSt(al.seqNum)} style={{
                                                    width: "30px",
                                                    height: "30px",
                                                    opacity: "0.5",
                                                    cursor: "pointer"
                                                }} alt="arrival" src={commonCheck}/>
                                                :
                                                <p style={{
                                                    margin: "0",
                                                    color: "#6EDA60",
                                                    fontSize: "14px",
                                                    fontWeight:"bold"
                                                }}>센터도착</p>
                                            }
                                            </div>
                                        <div style={{width: "150px"}}><p>{al.regTime}</p></div>
                                    </div>
                                ))}
                            </div>
                            {displayLimit >= 30 && data.length > displayLimit && (
                                <div className="text-center m-t-20">
                                    <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreData}>더보기
                                        <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                                    </button>
                                </div>
                            )}
                            {alarmDisplayLimit < alarmSearchData.length && (
                                <div className="text-center m-t-20">
                                    <button className="admin-btn-long admin-btn-basic p-l-10" onClick={loadMoreAlarmData}>더보기
                                        <MdKeyboardArrowDown className="m-l3 font-s-17"/>
                                    </button>
                                </div>
                            )}
                        </Tab>
                    </Tabs>
                </div>
            </div>

            { allUpdateProdSt ?
                <MsgSelectModal allUpdateProdSt={allUpdateProdSt} allUpdateProdStMsg1={allUpdateProdStMsg1} allUpdateProdStMsg2={allUpdateProdStMsg2}
                                closeAllUpdateProdSt={closeAllUpdateProdSt} updateProdArrival={updateProdArrival} checkedOrd={checkedOrd}/>
                :
                updateProdSt ?
                    <MsgSelectModal updateProdSt={updateProdSt} updateProdStMsg1={updateProdStMsg1} updateProdStMsg2={updateProdStMsg2}
                                    closeUpdateProdSt={closeUpdateProdSt} updateProdArrival={updateProdArrival} checkedOrd={checkedOrd}/>
                    :
                    allUpdateProdNullSt ?
                        <MsgCheckModal allUpdateProdNullSt={allUpdateProdNullSt} closeAllUpdateProdNullSt={closeAllUpdateProdNullSt}/>
                        :
                    null
            }
        </div>
    );
}

export default AdminInScanPg
