import React, {useEffect, useRef, useState} from "react";
import '../../css/mobile/MChatFileUploadModal.css'
import commonUpload from "../../assets/img/commonUpload.png";
import commonClose from "../../assets/img/commonClose.png";
import testImg from "../../assets/img/boxLogo.png";
import {useTranslation} from "react-i18next";
import chatVideoImg from "../../assets/img/chatVideoImg.png";
import chatPdfImg from "../../assets/img/chatPdfImg.png";
import chatExcelImg from "../../assets/img/chatExcelImg.png";
import chatFileImg from "../../assets/img/chatFolderImg.png";
import fileExchange from "../../assets/img/adminFileExchange.png";
import MsgCheckModal from "../web/modal/MsgCheckModal";
import MMsgCheckModal from "./MMsgCheckModal";

function MChatFileUploadModal({showChatFileClose, selectedFile, selectedFileUrl, saveChatFile,
                                  chatFileModifySt, chatFileModifyFalseFn, messageToModify, chatFileModify,
                                  nonSelectedFile, nonShowChatFileClose, nonSelectedFileUrl, saveNonChatFile, nonShowChatFile,
                                  nonChatFileModifySt, nonChatFileModifyFalseFn, nonChatFileModify, nonMessageToModify}) {

    const { t } = useTranslation();

    const [selectedModifyFile, setSelectedModifyFile] = useState(selectedFile)
    const [selectedModifyFileUrl, setSelectedModifyFileUrl] = useState('');

    const [nonSelectedModifyFile, setNonSelectedModifyFile] = useState(nonSelectedFile)
    const [nonSelectedModifyFileUrl, setNonSelectedModifyFileUrl] = useState('');

    const [modifyFile, setModifyFile] = useState('');
    const [nonModifyFile, setNonModifyFile] = useState('');

    useEffect(()=> {
        if (chatFileModifySt) {
            setModifyFile("https://toeloperfile.s3.ap-northeast-2.amazonaws.com/ordChatMessage/" + messageToModify.chatKey)
        } else if (nonChatFileModifySt) {
            setNonModifyFile("https://toeloperfile.s3.ap-northeast-2.amazonaws.com/nUserChatMsg/" + nonMessageToModify.chatKey)
        }
    }, [])

    const [fileSizeOverSt, setFileSizeOverSt] = useState(false);
    const closeFileSizeOverSt = (() => {
        setFileSizeOverSt(false);
    })
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const maxSize = 128 * 1024 * 1024;
        if (file.size > maxSize) {
            setFileSizeOverSt(true);
        } else {
            if (chatFileModifySt) {
                setSelectedModifyFile(file);
                setSelectedModifyFileUrl(URL.createObjectURL(file));
            } else if (nonChatFileModifySt) {
                setNonSelectedModifyFile(file);
                setNonSelectedModifyFileUrl(URL.createObjectURL(file));
            }
            renderFilePreview();
        }
    };

    const renderFilePreview = () => {
        if (selectedModifyFile) {
            return renderChangeFilePreview();
        } else if (nonSelectedModifyFile) {
            return nonRenderChangeFilePreview();
        }
        else if (selectedFile) {
            return renderUploadFilePreview();
        }
        else if (modifyFile) {
            return renderModifyFilePreview();
        }
        else if (nonModifyFile) {
            return nonRenderModifyFilePreview();
        }
        else if (nonSelectedFile) {
            return nonRenderUploadFilePreview();
        }
        else {
            return null;
        }
    };

    // 사용자(주문서/비회원) new 파일업로드 통합
    const renderUploadFilePreview = () => {
        if (!selectedFile) {
            return null;
        }
        if (selectedFile) {
            if (selectedFile.type.startsWith('image/')) {
                return <img style={{maxWidth:"300px", maxHeight:"220px"}} alt="" src={selectedFileUrl}/>;
            }

            if (selectedFile.type.startsWith('video/')) {
                return <img style={{maxWidth:"300px", maxHeight:"220px"}} alt="" src={chatVideoImg}/>;
            }
            if (selectedFile.type.startsWith('application/pdf')) {
                return <img style={{maxWidth:"300px", maxHeight:"220px"}} alt="" src={chatPdfImg}/>;
            }

            if (selectedFile) {
                let fileExtension = selectedFile.name.split('.').pop().toLowerCase();

                if (['xlsx', 'xls', 'xlsm', 'xlsb', 'xltx'].includes(fileExtension)) {
                    return <img style={{maxWidth:"300px", maxHeight:"220px"}} alt="" src={chatExcelImg}/>;
                }
            } else return <img style={{maxWidth:"300px", maxHeight:"220px"}} alt="" src={chatFileImg}/>;
        }
    };

    // 관리자 비회원채팅 new 파일업로드
    const nonRenderUploadFilePreview = (() => {
        if (!nonSelectedFile) {
            return null;
        }
        if (nonSelectedFile) {
            if (nonSelectedFile.type.startsWith('image/')) {
                return <img style={{maxWidth:"300px", maxHeight:"220px"}} alt="" src={nonSelectedFileUrl}/>;
            }

            if (nonSelectedFile.type.startsWith('video/')) {
                return <img style={{maxWidth:"300px", maxHeight:"220px"}} alt="" src={chatVideoImg}/>;
            }
            if (nonSelectedFile.type.startsWith('pdf/')) {
                return <img style={{maxWidth:"300px", maxHeight:"220px"}} alt="" src={chatPdfImg}/>;
            }

            if (nonSelectedFile) {
                let fileExtension = nonSelectedFile.name.split('.').pop().toLowerCase();

                if (['xlsx', 'xls', 'xlsm', 'xlsb', 'xltx'].includes(fileExtension)) {
                    return <img style={{maxWidth:"300px", maxHeight:"220px"}} alt="" src={chatExcelImg}/>;
                }
            } else return <img style={{maxWidth:"300px", maxHeight:"220px"}} alt="" src={chatFileImg}/>;
        }
    })


    // 관리자 주문서수정 렌더링
    const renderModifyFilePreview = () => {
        const isImage = modifyFile.endsWith('.png') || modifyFile.endsWith('.jpg') || modifyFile.endsWith('.jpeg') || modifyFile.endsWith('.gif')
            || modifyFile.endsWith('.PNG') || modifyFile.endsWith('.JPG') || modifyFile.endsWith('.JPEG') || modifyFile.endsWith('.GIF');
        const isVideo = modifyFile.endsWith('.mp4') || modifyFile.endsWith('.avi');
        const isPdf = modifyFile.endsWith('.pdf') || modifyFile.endsWith('.PDF');

        if (isImage) {
            return <img style={{maxWidth:"300px", maxHeight:"220px"}} alt="" src={modifyFile}/>;
        }

        if (isVideo) {
            return <video style={{maxWidth:"300px", maxHeight:"220px"}} controls src={modifyFile}></video>;
        }

        if (isPdf) {
            return <object style={{maxWidth:"300px", maxHeight:"220px"}} type="application/pdf" data={modifyFile}></object>;
        }

        let fileExtension = modifyFile.split('.').pop().toLowerCase();
        if (['xlsx', 'xls', 'xlsm', 'xlsb', 'xltx'].includes(fileExtension)) {
            return <img style={{maxWidth:"300px", maxHeight:"220px"}} alt="" src={chatExcelImg}/>;
        }

        return <img style={{maxWidth:"300px", maxHeight:"220px"}} alt="" src={chatFileImg}/>;
    };

    const fileInputRef = useRef(null);
    const handleFileButtonClick = () => {
        fileInputRef.current.click();
    };


    // 관리자 주문서수정 변경
    const renderChangeFilePreview = () => {
        if (!selectedModifyFile) {
            return null;
        }
        if (selectedModifyFile.type.startsWith('image/')) {
            return <img style={{maxWidth:"300px", maxHeight:"220px"}} alt="" src={selectedModifyFileUrl}/>;
        }

        if (selectedModifyFile.type.startsWith('video/')) {
            return <video style={{maxWidth:"300px", maxHeight:"220px"}} alt="" src={chatVideoImg}/>;
        }
        if (selectedModifyFile.type.startsWith('application/pdf')) {
            return <img style={{ maxWidth:"300px", maxHeight:"220px" }} alt="PDF preview" src={chatPdfImg} />;
        }

        if (selectedModifyFile) {
            let fileExtension = selectedModifyFile.name.split('.').pop().toLowerCase();

            if (['xlsx', 'xls', 'xlsm', 'xlsb', 'xltx'].includes(fileExtension)) {
                return <img style={{maxWidth:"300px", maxHeight:"220px"}} alt="" src={chatExcelImg}/>;
            }
        }  else return <img style={{maxWidth:"300px", maxHeight:"220px"}} alt="" src={chatFileImg}/>;
    };

    // 관리자 비회원수정 렌더링
    const nonRenderModifyFilePreview = () => {
        const isImage = nonModifyFile.endsWith('.png') || nonModifyFile.endsWith('.jpg') || nonModifyFile.endsWith('.jpeg') || nonModifyFile.endsWith('.gif')
            || nonModifyFile.endsWith('.PNG') || nonModifyFile.endsWith('.JPG') || nonModifyFile.endsWith('.JPEG') || nonModifyFile.endsWith('.GIF');
        const isVideo = nonModifyFile.endsWith('.mp4') || nonModifyFile.endsWith('.avi');
        const isPdf = nonModifyFile.endsWith('.pdf') || nonModifyFile.endsWith('.PDF');

        if (isImage) {
            return <img style={{maxWidth:"300px", maxHeight:"220px"}} alt="" src={nonModifyFile}/>;
        }

        if (isVideo) {
            return <video style={{maxWidth:"300px", maxHeight:"220px"}} controls src={nonModifyFile}></video>;
        }

        if (isPdf) {
            return <object style={{maxWidth:"300px", maxHeight:"220px"}} type="application/pdf" data={nonModifyFile}></object>;
        }

        let fileExtension = nonModifyFile.split('.').pop().toLowerCase();
        if (['xlsx', 'xls', 'xlsm', 'xlsb', 'xltx'].includes(fileExtension)) {
            return <img style={{maxWidth:"300px", maxHeight:"220px"}} alt="" src={chatExcelImg}/>;
        }

        return <img style={{maxWidth:"300px", maxHeight:"220px"}} alt="" src={chatFileImg}/>;
    };

    // 관리자 비회원수정 변경
    const nonRenderChangeFilePreview = () => {
        if (!nonSelectedModifyFile) {
            return null;
        }
        if (nonSelectedModifyFile.type.startsWith('image/')) {
            return <img style={{maxWidth:"300px", maxHeight:"220px"}} alt="" src={nonSelectedModifyFileUrl}/>;
        }

        if (nonSelectedModifyFile.type.startsWith('video/')) {
            return <video style={{maxWidth:"300px", maxHeight:"220px"}} alt="" src={chatVideoImg}/>;
        }
        if (nonSelectedModifyFile.type.startsWith('pdf/')) {
            return <img style={{ maxWidth:"300px", maxHeight:"220px" }} alt="PDF preview" src={chatPdfImg} />;
        }

        if (nonSelectedModifyFile) {
            let fileExtension = nonSelectedModifyFile.name.split('.').pop().toLowerCase();

            if (['xlsx', 'xls', 'xlsm', 'xlsb', 'xltx'].includes(fileExtension)) {
                return <img style={{maxWidth:"300px", maxHeight:"220px"}} alt="" src={chatExcelImg}/>;
            }
        }  else return <img style={{maxWidth:"300px", maxHeight:"220px"}} alt="" src={chatFileImg}/>;
    };

    return (
        <div className="m-a-file-modal-container">
            <div className="m-a-file-modal-frame">
                <div className="m-a-file-modal-hd-frame">
                    <div className="m-a-file-icon-container">
                        <img alt='' src={commonUpload} className="m-a-file-icon"/>
                    </div>
                    <div className="m-a-file-head-text">
                        <div className="font-t-title font-s-18">{(chatFileModifySt || nonChatFileModifySt) ? t('cfuHeader1') : t('cfuHeader2')}</div>
                    </div>
                    <div className="m-a-file-close">
                        <img onClick={chatFileModifySt ? chatFileModifyFalseFn : nonShowChatFile ? nonShowChatFileClose :
                            nonChatFileModifySt ? nonChatFileModifyFalseFn : showChatFileClose} className="m-a-file-close-img" src={commonClose} alt="close"/>
                    </div>
                </div>

                <div className="m-a-file-content-container user-center">
                    <div>
                        <div className="m-a-file-content-img user-center">
                            {renderFilePreview()}
                        </div>
                    </div>
                </div>
                <div className="m-a-file-content-fileNm user-center">
                    <div className="m-a-file-content-fileNm-text font-t-head">
                        {
                            nonSelectedModifyFile ? nonSelectedModifyFile.name
                                :
                                !nonSelectedModifyFile && nonMessageToModify ? nonMessageToModify.fileNm
                                    :
                                    selectedModifyFile ? selectedModifyFile.name
                                        :
                                        nonSelectedFile ? nonSelectedFile.name
                                            :
                                            !selectedModifyFile && messageToModify ? messageToModify.fileNm
                                                :
                                                selectedFile ? selectedFile.name
                                                    :
                                                    null
                        }
                    </div>
                </div>

                        <div className="m-a-file-content-file-exchange-frame user-center">
                            {chatFileModifySt || nonChatFileModifySt ?
                                <div>
                                    <input type="file" style={{display: "none"}} id="fileInput" ref={fileInputRef} onChange={handleFileChange}/>
                                    <label htmlFor="fileInput"/>
                                <img onClick={handleFileButtonClick} className="m-a-file-content-file-exchange-img" src={fileExchange} alt="exchange"/>
                                </div>
                                :
                                null
                            }
                        </div>

                {
                    chatFileModifySt ?
                        <div className="m-a-file-bt-container">
                            <button className="m-a-colorBt ht-40 m-b-5 wd-80" onClick={()=> {chatFileModify(selectedModifyFile, messageToModify)}}>
                                <div className="m-a-colorBt-text font-t-cont">{t(`modRegModiBt`)}</div>
                            </button>
                        </div>
                    :
                    nonChatFileModifySt ?
                    <div className="m-a-file-bt-container">
                    <button className="m-a-colorBt ht-40 m-b-5 wd-80" onClick={()=> {nonChatFileModify(nonSelectedModifyFile, nonMessageToModify)}}>
                    <div className="m-a-colorBt-text font-t-cont">{t(`modRegModiBt`)}</div>
                    </button>
                    </div>
                        :
                        nonShowChatFile ?
                    <div className="m-a-file-bt-container">
                    <button className="m-a-colorBt ht-40 m-b-5 wd-80" onClick={saveNonChatFile}>
                    <div className="m-a-colorBt-text font-t-cont">{t(`sendBt`)}</div>
                    </button>
                    </div>
                            :
                    <div className="m-a-file-bt-container">
                    <button className="m-a-colorBt ht-40 m-b-5 wd-80" onClick={saveChatFile}>
                    <div className="m-a-colorBt-text font-t-cont">{t(`sendBt`)}</div>
                    </button>
                    </div>
                }

            </div>



            {
                fileSizeOverSt ?
                    <MMsgCheckModal fileSizeOverSt={fileSizeOverSt} closeFileSizeOverSt={closeFileSizeOverSt}/>
                    :
                    null
            }

        </div>
    )
}

export default MChatFileUploadModal
